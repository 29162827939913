
import { _get } from "../../../utils/api";

const useSearchSfInputFields = () => {
  
  const searchSfInputFields = async (value) => {
    const url = `/integrations/sales_force/lead/input_fields?field_name=${value}`;
    const result = await _get(url, process.env.REACT_APP_API_ENDPOINT, 5000);
    if(!result) {
      return null;
    }
    if (result?.status >= 200 && result?.status < 300) {
      return result?.data?.data;
    }
    return null;
  };

  const searchSfInputFieldValues = async (value) => {
    const url = `/integrations/sales_force/lead/input_field_values?field_name=${value}`;
    const result = await _get(url);
    if (result?.status >= 200 && result?.status < 300) {
      return result?.data?.data;
    }
    return null;
  };

  return { searchSfInputFields, searchSfInputFieldValues };
};

export { useSearchSfInputFields }; 
