import React from "react";
import { reduxForm } from "redux-form";
import { injectIntl } from 'react-intl';
import styled from "styled-components";
import SignUpGuideDashboard from "../../../../components/layouts/SignUpGuideDashboard";
import EmailIcon from "../../../../assets/img/email_sent.svg";

const Wrapper = styled.div`
  text-align: center;
`;

const EmailImage = styled.img`
  min-width: 374px;
  min-height: 172px;
`;

const HeadText = styled.p`
  margin-top: 16px;
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #112D48
`;

const SubText = styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #66657C;
  margin-top: 16px;
`;

const EmailInvitation = (props) => {
  return (
    <SignUpGuideDashboard {...props}>
      <Wrapper>
        <EmailImage src={EmailIcon}/>
        <HeadText>確認メールを送信しました</HeadText>
        <SubText>確認メールを送信しました。リンクをクリックし、メールアドレスの登録を完了させてください。</SubText>
      </Wrapper>
    </SignUpGuideDashboard>
  );
}

EmailInvitation.propTypes = {};

export default injectIntl(
  reduxForm({
    form: "EmailInvitation",
  })(EmailInvitation)
);
