import React from "react";
import styled from "styled-components";
import {
  InputSelect,
  Label,
  Row,
} from "@d-lighted/design-system";

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${(props) => props.width};
`;

const InputLabel = styled(Label)`
  margin-bottom: 10px;
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;


export default function DropdownCase(props) {
  const dropdownOptions = [];
  props.data.bookingCalendarMultipleChoiceOptions.forEach(option => {
    dropdownOptions.push({
      label: option?.choiceTypeName,
      value: option?.choiceTypeName,
    });
  });

  return (
    <Row mt="15px" alignItems="center" mb="1rem">
      <InputWrapper width="100%">
        <InputLabel>
          {props.data.labelName}
        </InputLabel>
        <InputDiv>
          <InputSelect options={dropdownOptions} />
        </InputDiv>
      </InputWrapper>
    </Row>
  )
}
