import React, { useEffect } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Field } from "redux-form";
import styled from "styled-components";
import { 
  Row,
  Col,
  Label,
  InputSelect,
} from "@d-lighted/design-system";
import messages from "../i18n/MustAttendArea";

const Text = styled(Label)`
  display: inline-block;
  line-height: 1.4;
  margin-top: auto;
  margin-bottom: auto;
  color: ${props => props.color ? props.color : '#56C9C3'};
`;

const OptionalAttendeesSettings = (props) => {
  const { intl, membersCount, isMembersLoaded, optionalAttendeesNumber, change } = props;
  const options = [
    {
      value: "priority_order",
      label: `${intl.formatMessage({ ...messages.priorityOrder })}`
    },
    {
      value: "random",
      label: `${intl.formatMessage({ ...messages.randomOrder })}`
    }
  ];
  let attendeesOptions = [{
    value: 0,
    label: '0'
  }];
  const maxAllowedAttendees = (membersCount || optionalAttendeesNumber ) || 0;
  for(let i = 1; i <= maxAllowedAttendees; i++) {
    attendeesOptions.push({value: i, label: `${i}`})
  }

  useEffect(() => {
    if(isMembersLoaded()) {
      if(optionalAttendeesNumber.value > membersCount) {
        change("optionalAttendeesNumber", attendeesOptions[membersCount]);
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  return(
    <>
      <Row mt="15px">
        <Col display="flex" justifyContent="left">
          <Text color="#314143">
            <FormattedMessage {...messages.optionalGuideLeft} />
          </Text>
        </Col>
        <Col display="flex" justifyContent="left" width="80px">
          <Field
            id="optionalAttendeesNumber" 
            name="optionalAttendeesNumber" 
            component={InputSelect} 
            options={attendeesOptions}
          />
        </Col>
        <Col display="flex" justifyContent="left">
          <Text color="#314143">
            <FormattedMessage {...messages.optionalGuideMid} />
          </Text>
        </Col>
        <Col display="flex" justifyContent="left" width="150px">
          <Field 
            id="optionAttendeeOptions" 
            name="optionAttendeeOptions" 
            component={InputSelect} 
            options={options} 
            color="#314143"
          />
        </Col>
        <Col display="flex" justifyContent="left">
          <Text color="#314143">
            <FormattedMessage {...messages.optionalGuideEnd} />
          </Text>
        </Col>
      </Row>
    </>
  );
};

export default injectIntl(OptionalAttendeesSettings);
