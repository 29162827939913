import React from "react";
import styled from "styled-components";
import { IconNew as Icon, Label } from "@d-lighted/design-system";
import { useSelector } from "react-redux";

import Preview from "./Preview";
import { FormattedMessage, defineMessages } from 'react-intl';
import { isBookingTypeIrregular } from "../../utils/userUtils";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.width};
`;

const CalendarWrapper = styled.div`
  padding-top: 40px;
`;

const Wrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ScaledDivWrapper = styled.div`
  height: 100%;
`;

const ScaledDiv = styled.div`
  zoom: ${(p) => p.scale};
  -moz-transform: scale(${(p) => p.scale});
  -moz-transform-origin: 50% 0;
`;

const messages = defineMessages({
  preview: {
    id: 'components.preview.scaledPreview.preview',
    defaultMessage: 'Preview',
  },
  disclaimer: {
    id: 'components.preview.scaledPreview.disclaimer',
    defaultMessage: 'Availability state is not real. Please check the reservation page for current availability state.',
  }
});

const DisclaimerLabel = styled(Label)`
  margin-left: 10px;
  font-size: 12px;
`

const DisclaimerIcon = styled(Icon)`
  position: relative;
  top: 4px;
  margin-right: 4px;
  font-size: 18px;
  opacity: 0.5;
`;

export default function CalendarPreview() {
  const { data } = useSelector((state) => state.calendarSetting);
  const isIrregular = isBookingTypeIrregular();

  const scale = window.screen.width >= 1920 ? 0.8 : 0.7;
  const width = window.screen.width >= 1920 ? "51vw" : "48vw";

  if (!data) {
    return null;
  }

  return (
    <Center width={width}>
      <CalendarWrapper id="calendar-wrapper">
        <Label color="#68878d"> <FormattedMessage {...messages.preview} /> </Label>
        {!isIrregular && <DisclaimerLabel>
          <DisclaimerIcon className="material-icons">
            error
          </DisclaimerIcon>
          <FormattedMessage {...messages.disclaimer} />
        </DisclaimerLabel>}
        <Wrapper id="wrapper">
          <ScaledDivWrapper>
            <ScaledDiv id="smallDiv" scale={scale}>
              <Preview />
            </ScaledDiv>
          </ScaledDivWrapper>
        </Wrapper>
        {/* <BottomLabelDiv>
          <BottomLabel onClick={(e) => console.log(e)}>
            新しいウィンドウで開く
          </BottomLabel>
        </BottomLabelDiv> */}
      </CalendarWrapper>
    </Center>
  );
}
