import { defineMessages } from 'react-intl';

const MustAttendAreaMessages = defineMessages({
  mustAttendHelp: {
    id: 'components.form.memberSelection.mustAttendArea.help',
    defaultMessage: 'Drag & drop any must attendees here',
  },
  optionalAttendHelp: {
    id: 'components.form.memberSelection.optionalAttendArea.help',
    defaultMessage: 'Drag & drop optional attendees here"',
  },
  editorHelp: {
    id: 'components.form.memberSelection.editorsArea.help',
    defaultMessage: 'Drag & drop editors here',
  },
  mustAttendees: {
    id: "components.form.memberSelection.mustAttendArea.mustAttend",
    defaultMessage: "Mandatory attendees",
  },
  optionalAttendees: {
    id: "components.form.memberSelection.mustAttendArea.optionalAttendees",
    defaultMessage: "Optional attendees",
  },
  editors: {
   id: "components.form.memberSelection.mustAttendArea.editors",
   defaultMessage: "Editors"
  },
  optionalGuideLeft: {
    id: "components.form.memberSelection.mustAttendArea.optionalGuideLeft",
    defaultMessage: "Select",
  },
  optionalGuideMid: {
    id: "components.form.memberSelection.mustAttendArea.optionalGuideMid",
    defaultMessage: "person below on",
  },
  optionalGuideEnd: {
    id: "components.form.memberSelection.mustAttendArea.optionalGuideEnd",
    defaultMessage: "basis",
  },
  priorityOrder: {
    id: 'components.form.memberSelection.mustAttendArea.priorityOrder',
    defaultMessage: 'Priority',
  },
  randomOrder: {
    id: 'components.form.memberSelection.mustAttendArea.randomOrder',
    defaultMessage: 'Random',
  },
});

export default MustAttendAreaMessages;
