import React from "react";
import styled from "styled-components";
import { Label, Col, Row, Button, Modal as BaseModal } from "@d-lighted/design-system";

const Modal = styled(BaseModal)`
  width: auto;
  min-height: auto;
  height: auto;
  padding: 8px 10px 16px;
`;

const SuccessMessage = styled.div`
  width: 449px;
  padding: 16px 0 0 0;
  border-radius: 5px;
`;

const SuccessMessageLabel = styled.span `
  margin: 10px 0;
`;

function MultiguestAppointmentConfirmedModal(props) {

  return (
    <Modal {...props}>
        <SuccessMessage>
          <Col>
            <Row mb="14px">
              <Col xs>
                <Label fontSize="22px">
                  メールを送信しました
                </Label>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <SuccessMessageLabel>
                  <Label>
                    日時を確定し、カレンダー予定を作成、<br/>
                    会議情報をお客様にメールを送信しました。
                  </Label>
                </SuccessMessageLabel>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <Button mt="30px"
                  onClick={() => { props.returnToDashboard() }}
                >
                  ダッシュボードに戻る
                </Button>
              </Col>
            </Row>
          </Col>
        </SuccessMessage>
    </Modal>
  )
}

export default MultiguestAppointmentConfirmedModal;
