import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { MOBILEBREAKPOINT } from "../../../constants/breakpoint";

const Table = styled.table`
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  @media (max-width: ${MOBILEBREAKPOINT}){
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
`;

const Col = styled.col`
  width: ${props => props.width}px;
`;

function TableColLayout({columnWidths, children}) {
  const ColumnLayout = columnWidths.map((val, key) => {
    return (
      <Col key={key} width={val}/>
    );
  });
  
  return (
    <Table>
      <colgroup>
        { ColumnLayout }
      </colgroup>
      {children}
    </Table>
  );
}

TableColLayout.propTypes = {
  columnWidths: PropTypes.array.isRequired
};

export default TableColLayout;
