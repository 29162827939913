import React, { useState } from 'react';
import styled from "styled-components";
import { useLocation, useParams } from "react-router-dom";
import { Label, Col, Row, Button, Loader } from "@d-lighted/design-system";
import { injectIntl } from 'react-intl';

import { PrimaryLightBackground } from "../../../components/utils/Helper";
import StyledRow from "../modules/StyledRow";
import BackButton from "../modules/BackButton";
import Title from "../modules/Title";
import moment from 'moment';
import { put } from '../../../utils/api';
import MultiguestAppointmentConfirmedModal from '../modules/MultiguestAppointmentConfirmedModal';
import messages from "../i18n/MultiGuest";

const Container = styled.div`
  margin: 41px;
  padding: 33px 55px;
  border-radius: 6px;
  opacity: 1;
  background:white;
`;

const Date = styled.div`
  margin-top:16px;
  margin-bottom:16px;
  font-size: 20px;
  font-weight: 500;
  color:#142c2b;
`;

const Time = styled.div`
  font-size: 21px;
  font-weight: bold;
  color:#142c2b;
`;

const Participants = styled.div`
  margin-top:30px;
  margin-bottom:30px;
`;

const ParticipantsList = styled.div`
  margin-top:16px;
  color:#484f63;
  font-size:15px;
`;

const CustomerList = styled.div`
  margin-top:16px;
  margin-bottom:16px;
  color:#484f63;
  font-size:15px;
`;

const Center = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

function SchedulingFinalizeConfirm(props) {
  const { useNotify, intl } = props;
  const location = useLocation();
  const params = useParams();
  const notify = useNotify(intl);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const { begin_at, end_at, visitors } = location.state?.values

  const onSubmit = () => {
    setLoading(true);
    put(`/booking_calendars/${params.uid}/multiguest/${params.multiguest_calendar_uid}/`, location.state?.values).then(response => {
      if(response.status === 200) {
        setComplete(true);
      }else{
        setLoading(false);
        switch (response.data?.code) {
          case "E13029":
            notify.setError(messages.notifyMultiguestScheduledEventsAppointmentErrorTitle, messages.zoomNotIntegrated);
            break;
          case "E17004":
            notify.setError(messages.notifyMultiguestScheduledEventsAppointmentErrorTitle, messages.webexNotIntegrated);
            break;
          default:
            notify.setError(
              messages.notifyMultiguestScheduledEventsAppointmentErrorTitle,
              <p dangerouslySetInnerHTML={{__html: response.data?.message}} />
            );
        }
      }
      setLoading(false);
    })
  }

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <PrimaryLightBackground>
      <StyledRow onClick={() => { props.history.push(`/booking_calendars/${params.uid}/multiguest/list`) }}>
        <BackButton multiguest />
      </StyledRow>
      <StyledRow>
        <Title title={location.state?.appointment?.title} />
      </StyledRow>
      <Container>
        <Row>
          <Col xs>
            <div>
              <Label>日時</Label>
              <Date>{moment(begin_at).format('MM/DD')}</Date>
              <Time>{moment(begin_at).format('hh:mm')} - {moment(end_at).format('hh:mm')}</Time>
            </div>
            <Participants>
              <Label>社内参加者</Label>
              { location.state?.availableHosts?.map((host, index) => (
                  <ParticipantsList key={index}>{host.name}</ParticipantsList>
                ))
              }
            </Participants>
            <div>
              <Label>お客様</Label>
              <div>
                {visitors.map((visitor) => {
                  return <CustomerList key={visitor.name}>
                    {visitor.company_name
                      ? `${visitor.name} 様 - ${visitor.company_name}`
                      : `${visitor.name} 様`}
                  </CustomerList>
                })}
              </div>
            </div>
          </Col>
          {complete &&
            <MultiguestAppointmentConfirmedModal
              isOpen
              returnToDashboard={() => { props.history.push({ pathname: '/' }) }}
            />
          }
        </Row>
        <Row mt="65px">
          <Col xs={1}>
            <Button outlined minWidth="0" onClick={ () => (props.history.goBack()) }>戻る</Button>
          </Col>
          <Col>
            <Button width="393px" onClick={onSubmit}>日時を確定し、お客様にメールを送信</Button>
          </Col>
        </Row>
      </Container>
    </PrimaryLightBackground>
  );
};

export default injectIntl(SchedulingFinalizeConfirm);
