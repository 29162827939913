import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { apiUtils } from "../../../utils/apiUtils";
import { _get, post, put } from "../../../utils/api";
import { setCurrentUser, overrideCurrentUserCompany, removeCurrentUser } from "../../../utils/userUtils";
import messages from "./i18n/useOAuth";

function useOauth(notify) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isSettingUpdated, setCalUpdated] = useState(false);
  const [calendarUpdateSucceeded, setsettingUpdateSuccess] = useState(false);
  const [hasUpdateError, setUpdateError] = useState(false);
  const [resetPasswordAllowed, setResetPassword] = useState(false);
  const onBoardingCalendarSetting = useSelector((state) => state.calendarSetting);

  const startAuthentication = (values) => {
    const url = `/oauth/oauth_logins`;
    setLoading(true);
    put(url, values)
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          notify.setError(messages.authError, result?.data?.message);
          setUpdateError(true);
        }
        else {
          const { data } = result;
          setCurrentUser(data.data);
          setUpdateError(false);
        }
        setLoading(false);
        setCalUpdated(true);
      });
  };

  const registerAuthEmployee = async(values) => {
    setLoading(true);
    const url = '/auth?product=scheduling&registered_product_type=scheduling';
    const result = await post(url, values);
    setLoading(false);
    if(result.status >= 200 && result.status < 300) {
      const confirmationUrl = result.data?.data?.confirmation_url;
      if(confirmationUrl) {
        // used for endtest automation
        const confirmationUrlElement = document.createElement('input');
        confirmationUrlElement.type = 'hidden';
        confirmationUrlElement.id = 'confirmationUrl';
        confirmationUrlElement.value = confirmationUrl;

        document.body.appendChild(confirmationUrlElement);
      }
    
      return true;
    };
    
    notify.setError(messages.registerError, result?.data?.error?.message);
    return false;
  }

  const registerEmployee = (values) => {
    removeCurrentUser();
    const url = `/oauth/oauth_logins`;
    setLoading(true);
    post(url, values)
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          let head =  "";
          switch(result?.data?.code) {
            case "E17014":
              head = messages.schedulerEror;
              break;
            case "E17015":
              head = messages.signInError;
              break;
            case "E01001":
              head = messages.signInError;
              break;
            default:
              head = messages.authError;
          }
          notify.setError(head, result?.data?.message);
          setUpdateError(true);
        }
        else {
          const { data } = result;
          setCurrentUser(data.data);
          setUpdateError(false);
        }
        setLoading(false);
        setCalUpdated(true);
      });
  };

  const startOAuth = (scheduler, redirectPath) => {
    const authUrl = `/oauth/oauth_logins?scheduler=${scheduler}&redirect_path=${redirectPath}`;
    _get(authUrl).then(result => {
      if (result?.status < 200 || result?.status >= 300) {
        notify.setError(messages.authError, result?.data?.message);
      }
      else {
        window.open(result?.data, "_self");
      }
    });
  };

  const firstSignIn = (values) => {
    removeCurrentUser();
    setLoading(true);
    const url = `/auth/sign_in?first_login=true`;
    const params = Object.assign({ sign_in_from: "scheduling_web" }, values);
    post(url, params).then(response => {
      if (response?.status !== 200) {
        notify.setError(messages.signInError, response?.data?.error?.message);
      } 
      else {
        const { data } = response;
        setCurrentUser(data.data);
        setResetPassword(true);
      }
      setLoading(false);
    });
  }

  const updateEmployeeInfo = (values) => {
    const url = `/employee/registration?product=scheduling`;
    setLoading(true);
    setCalUpdated(false);
    setUpdateError(true);
    put(url, values)
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          notify.setError(messages.authError, result?.data?.errors?.full_messages?.[0]);
          setUpdateError(true);
          setLoading(false);
        }
        else {
          const { data } = result;
          setCurrentUser(data);
          _get('/company', process.env.REACT_APP_API_ENDPOINT).then(result => {
            overrideCurrentUserCompany(result?.data?.company);
            setUpdateError(false);
            setLoading(false);
          });
        }
      });
      setCalUpdated(true);
  };

  const getInviatedCalendarSetting = () => {
    setLoading(true);
    const url = `/booking_calendar_settings/onboarding`;
    _get(url)
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          notify.setError(messages.calendarFetchError, result?.data?.message); 
          setsettingUpdateSuccess(false);
        }
        else {
          dispatch({
            type: "calendarSetting_update",
            payload: apiUtils.formatAxiosResponse(result)
          });
          setsettingUpdateSuccess(true)
        }
        setLoading(false);
      });
  }

  return {
    onBoardingCalendarSetting,
    isSettingUpdated,
    hasUpdateError,
    calendarUpdateSucceeded,
    isLoading,
    resetPasswordAllowed,
    actions: {
      registerEmployee,
      startOAuth,
      firstSignIn,
      startAuthentication,
      getInviatedCalendarSetting,
      updateEmployeeInfo,
      registerAuthEmployee
    },
  };
}

export { useOauth };
