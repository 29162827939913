import { defineMessages } from 'react-intl';

export default defineMessages({
  free: {
    id: 'common.free',
    defaultMessage: 'Free',
  },
  trial: {
    id: 'common.trial',
    defaultMessage: 'Trial',
  },
  trialWithExpiredDate: {
    id: 'common.trialWithExpiredDate',
    defaultMessage: '{days} days left',
  },
  standard: {
    id: 'common.standard',
    defaultMessage: 'Standard',
  },
  enterprise: {
    id: 'common.enterprise',
    defaultMessage: 'Enterprise',
  },
  premium: {
    id: 'common.premium',
    defaultMessage: 'Premium'
  }
});
