import React from "react";
import { Div, Hr } from "../Helper";
import { SingleDate, TimeSlots } from "./Day";
import { moment } from "../momentUtils";

export function CalendarBody(props) {
  const {
    onChange,
    timeSlots,
    businessWeekDays,
    options: {
      isDateEarlier,
      calculateAvailableTime,
      getHoursOfTheDay,
      getJapaneseDay,
      currentWeek: { week },
      duration,
    },
  } = props;

  const availableTimeSlots = calculateAvailableTime(
    timeSlots.availableTimeslots
  );

  /** if day has empty slots return empty array explicit */
  function handleEmptySlotDays(day, availableTimeSlots) {
    if (availableTimeSlots[day.format("MM-DD-yyyy")] === undefined) {
      return [];
    } else {
      return availableTimeSlots[day.format("MM-DD-yyyy")];
    }
  }

  return (
    <Div
      display="flex"
      flexDirection={["column", "row"]}
      justifyContent="space-between"
      width="100%"
    >
      {week.map((day, index) => {

        if (!businessWeekDays[day.isoWeekday() - 1])
          return null;
        
        return (
          <Div
            key={day.format("lll")}
            width="100%"
            bg={`${isDateEarlier(day, moment()) === "equal" && "#e6f4ef"}`}
            px="15px"
            borderRadius={["0px", "10px"]}
            pb={[null, "35px"]}
          >
            <Div width="100%" px={["-15px", "0px"]}>
              <Div
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
              >
                <SingleDate
                  date={day.date()}
                  day={day}
                  getJapaneseDay={getJapaneseDay}
                  isDateEarlier={isDateEarlier}
                />
                <TimeSlots
                  hoursOfTheDay={getHoursOfTheDay}
                  day={day}
                  dayIndex={index}
                  timeSlots={handleEmptySlotDays(day, availableTimeSlots)}
                  handleChange={onChange}
                  isPreview={props.isPreview}
                  duration={duration}
                  minSchedulableTime={props.minSchedulableTime}
                  maxSchedulableTime={props.maxSchedulableTime}
                />
              </Div>
              <Hr
                display={["block", "none"]}
                border="none"
                height="2px"
                bg="#d2e9e7"
                mb="0px"
              />
            </Div>
          </Div>
        );
      })}
    </Div>
  );
}
