import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';

function useLocale() {
  const { locale } = useSelector((state) => state.locale);
  const dispatch = useDispatch();
  const setLocaleAction = createAction('change_locale');

  const setLocale = locale => {
    dispatch(setLocaleAction(locale || 'ja'));
  };

  return [locale, setLocale];
}

const LOCALES = {
  EN: 'en',
  JA: 'ja'
};

export { useLocale, LOCALES };