import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Row, IconNew as Icon } from "@d-lighted/design-system";
import { FormattedMessage } from 'react-intl';
import defineMessages from "../i18n/appointmentsTable";

const ReturnLink = styled.span`
  color: #00BBB5;
  cursor: pointer;
`;

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  padding: 40px 0 0 50px;
`;

export default function BackButton(props) {
  const { pageReload } = props;
  const history = useHistory();
  return (
    <StyledRow onClick={() => { pageReload ? window.location.href = '/' : history.push('/') }}>
      <Icon
        className="material-icons"
        fontSize="18px"
        isCursor={true}
      >
        arrow_back_ios
      </Icon>
      <ReturnLink className="nav_header_brand_text_big">
        {<FormattedMessage {...defineMessages.returnToDashboard} />}
      </ReturnLink>
    </StyledRow>
  )
}
