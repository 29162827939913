import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Button,
} from "@d-lighted/design-system";
import messages from "../i18n/AddMembers";
import SelectionDropdown from "./SelectionDropdown";

const AddMembers = (props) => {
  const { 
    loading,
    pageType,
    ind, 
    handleAddEmployee, 
    handleSearchEmployee, 
    headerText,
    searchPlaceholder,  
    closeText,
    recordComponent,
    comparator,
    memberSearchList,
    memberSearchInput,
    toggle,
    searching,
    searchFieldTouched,
    noSuchMember,
    isQueryEmail,
    invitationText,
    approvalPendingText,
    sendInvitation,
    emailInvitationStatus,
  } = props;
  const isSearching = (
    (ind === "default" && searching.notCustomMembers) || 
    (ind === 0 && searching.mustAttendMembers) || 
    (ind === 1 && searching.optionalMembers) || 
    (ind === 2 && searching.editorMembers)
  );

  const newAttendeeType = (index) => {
    let attendeeType;
    switch(index) {
      case 0:
        attendeeType = { attendee: true, mustAttend: true };
        break;
      case 1:
        attendeeType = { attendee: true, mustAttend: false };
        break;
      case 2:
        attendeeType = { attendee: false, mustAttend: false };
        break;
      default:
        attendeeType = { attendee: false, mustAttend: false };
    }
    return attendeeType;
  }

  return (
    <>
      <Button
        outlined
        mt="15px"
        fontSize="14px"
        fontWeight="normal"
        height="32px"
        onClick={(e) => {
          e.preventDefault();
          toggle(ind);
        }}
        disabled={!pageType}
      >
        <FormattedMessage {...messages.addEmployees} />
      </Button>
      {isSearching && (
        <SelectionDropdown
          toggle={() => toggle(ind)}
          loading={loading}
          handleAdd={handleAddEmployee}
          handleSearch={handleSearchEmployee}
          headerText={headerText}
          searchPlaceholder={searchPlaceholder}
          closeText={closeText}
          recordComponent={recordComponent}
          comparator={comparator}
          attendeeType={newAttendeeType(ind)}
          memberSearchList={memberSearchList}
          memberSearchInput={memberSearchInput}
          searchFieldTouched={searchFieldTouched}
          noSuchMember={noSuchMember}
          isQueryEmail={isQueryEmail}
          invitationText={invitationText}
          approvalPendingText={approvalPendingText}
          sendInvitation={sendInvitation}
          emailInvitationStatus={emailInvitationStatus}
          {...props}
        />
      )}
    </>
  )
};

export default injectIntl(AddMembers);
