import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@d-lighted/design-system';
import TableBodyItem from './TableBodyItem';
import Text from './Text';
import EmailIcon from "../../elements/EmailIcon";

export function generateVisitorDescriptionText(visitor) {
  if (!visitor) return '';
  if (!visitor.name && !visitor.company_name) {
    return visitor.email;
  }
  return (
    <Label color="#000">{`${visitor.name} 様` || `${visitor.email}`}
      {visitor.company_name ? `(${visitor.company_name})` : null}
      <EmailIcon uid={visitor.uid} visitorEmail={visitor.email}/>
    </Label>
  )
}

function VisitorsList({ visitors }) {
  const visitorItems = visitors.map(visitor => {
    const visitorText = generateVisitorDescriptionText(visitor);
    return (
      <Text key={visitor.uid} mb={visitors.length === 1 ? 0 : 10}>
        {visitorText}
      </Text>
    );
  });
  return <TableBodyItem>{visitorItems}</TableBodyItem>;
}

VisitorsList.defaultProps = {
  visitors: [],
};

VisitorsList.propTypes = {
  visitors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      company_name: PropTypes.string,
      uid: PropTypes.string,
    }),
  ),
};

export default VisitorsList;
