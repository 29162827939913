import axios from "axios";
import { useEffect, useState } from "react";
import { createAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { apiUtils } from "./apiUtils";

/** Axios instant are initialized with default configuration. */
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use((request) => {
  if (process.env.NODE_ENV === "development") {
    console.info("Starting Request", request);
  }

  let auth_headers = JSON.parse(localStorage.getItem("auth_headers"));
  if (auth_headers != null) {
    request.headers["access-token"] = auth_headers["access-token"];
    request.headers["client"] = auth_headers["client"];
    request.headers["expiry"] = auth_headers["expiry"];
    request.headers["uid"] = auth_headers["uid"];
  }

  return request;
});

const URL_BLOCKLIST = ['/auth?product=scheduling&invite'];
const isNotBlocklisted = (url) => !URL_BLOCKLIST.some(blockElement => (url || '').startsWith(blockElement));

axios.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === "development") {
      console.info("Response:", response);
    }
    if(isNotBlocklisted(response.config.url)) {
      saveAuthHeader(response.headers);
    }
    return response;
  },
  async (error) => {
    if (process.env.NODE_ENV === "development") {
      console.log(error.response);
    }
    const originalRequest = error.config;
    const refresh_token = localStorage.getItem("refresh_token");

    if (
      error.response.status === 401 &&
      refresh_token &&
      !originalRequest.retry &&
      !originalRequest.noIntercept
    ) {
      originalRequest.retry = true;

      let auth_headers = localStorage.getItem("auth_headers");

      const options = {
        method: "get",
        url: "/auth/refresh_token",
        headers: {
          uid: auth_headers["uid"],
          client: auth_headers["client"],
          "refresh-token": refresh_token,
        },
        noIntercept: true,
      };

      return await axios(options).then((response) => {
        saveAuthHeader(response.headers);
        if (response.status > 299) {
          window.location.pathname = "/sign_in";
          window.localStorage.clear();
        }
        return axios(response);
      });
    }
    return error.response;
  }
);

/** Function that trigger GET request */
async function _get(url, baseURL, timeout) {
  return await axios.get(url, { baseURL, timeout }).catch((e) => e.response);
}

/** Function that trigger POST request */
async function post(url, body, baseURL) {
  return await axios.post(url, body, { baseURL }).catch((e) => e.response);
}

/** Function that trigger Put request */
async function put(url, body, baseURL) {
  return await axios.put(url, body, { baseURL }).catch((e) => e.response);
}

/** Function that trigger Delete request */
async function remove(url, baseURL) {
  return await axios.delete(url, { baseURL }).catch((e) => e.response);
}

function useGet(request, baseURL) {
  const token = undefined;
  const [response, setResponse] = useState(undefined);
  const [query, setQuery] = useState(request.query);
  const [reload, setReload] = useState(apiUtils.timeStamp());
  const initialState = useSelector((state) => state[request.name]);
  const dispatch = useDispatch();
  const getAction = createAction(`${request.name}_get`);
  useEffect(() => {
    _get(apiUtils.concatUrlQuery(request.url, query), baseURL).then(
      (response) => {
        let formattedResponse = apiUtils.formatAxiosResponse(response);
        if (apiUtils.isStateNew(initialState, formattedResponse)) {
          setResponse(formattedResponse);
          dispatch(getAction(formattedResponse));
        }
        return 0;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, query, request.name, request.url, token, reload]);
  return {
    response,
    handle: { setQuery, setReload: () => setReload(apiUtils.timeStamp()) },
  };
}

function saveAuthHeader(headers) {
  if (headers["access-token"]) {
    let auth_headers = {
      "access-token": headers["access-token"],
      client: headers["client"],
      expiry: headers["expiry"],
      uid: headers["uid"],
    };
    localStorage.setItem("auth_headers", JSON.stringify(auth_headers));
    if (headers["refresh-token"])
      localStorage.setItem("refresh_token", headers["refresh-token"]);
    return true;
  } else {
    return false;
  }
}

export { useGet, post, put, remove, _get };
