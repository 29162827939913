import React from 'react';
import PropTypes from 'prop-types';

function IconDots({width, height, fill, className}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 4.667"
    >
      <title id="icon-dots-title">Icon Dots</title>
      <path
        d="M15.484,2.333a2.259,2.259,0,1,1,4.516,0,2.259,2.259,0,1,1-4.516,0Zm-7.742,0a2.259,2.259,0,1,1,4.516,0,2.259,2.259,0,1,1-4.516,0ZM0,2.333A2.3,2.3,0,0,1,2.258,0,2.3,2.3,0,0,1,4.516,2.333,2.3,2.3,0,0,1,2.258,4.667,2.3,2.3,0,0,1,0,2.333Z"
        fill={fill}
      />
    </svg>
  );
}

IconDots.defaultProps = {
  width: 20,
  height: 4.667,
  fill: "#9bb0b5",
};

IconDots.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default IconDots;
