import SamlSignIn from "./pages/SamlSignIn";
import SignIn from "./pages/SignIn";

/** Do not include any private routes here */
/** If private route is required, filter function is required in route.js file  */
export const signInRoutes = [
  {
    component: SignIn,
    path: "/sign_in",
    exact: true,
    feature: "sign_in",
    type: "public",
    gauid: 'Signin',
  },
  {
    component: SamlSignIn,
    path: "/sign_in/saml",
    exact: true,
    feature: "saml_sign_in",
    type: "public",
    gauid: 'SamlSignin',
  },
];
