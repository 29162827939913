import { createReducer } from "@reduxjs/toolkit";

const INITIAL_STATUS = {
  status: null,
  data: null,
  url: null,
  isSuccess: false,
  hasError: false,
  initial: true,
};

function reducerFn(resource) {
  return createReducer(
    resource.initialState
      ? { ...resource.initialState }
      : { ...INITIAL_STATUS },
    {
      [`${resource.name}_get`]: (state, action) => {
        return { ...state, ...action.payload };
      },
      [`${resource.name}_update`]: (state, action) => {
        return { ...state, ...action.payload };
      },
      [`${resource.name}_reset}`]: () => {
        return { ...INITIAL_STATUS };
      },
    }
  );
}

export function generateReducers(resources) {
  return Object.keys(resources).reduce((reducers, resource) => {
    let currentResource = resources[resource];
    reducers[currentResource.name] = reducerFn(currentResource);
    return reducers;
  }, {});
}
