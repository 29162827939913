import { defineMessages } from 'react-intl';

export default defineMessages({
  signInError: {
    id: 'notify.signIn.error',
    defaultMessage: 'Login error',
  },
  authError: {
    id: 'notify.auth.error',
    defineMessages: 'An authentication error has occurred.',
  },
  schedulerEror: {
    id: 'notify.auth.schedulerError',
    defineMessages: 'Could not log in using the specified method.',
  },
  registerError: {
    id: 'notify.auth.registerError',
    defineMessages: 'Registration error occurred.',
  },
  calendarFetchError: {
    id: 'notify.auth.calendarFetchError',
    defineMessages: 'Failed to fetch booking calendar settings',
  }
});
