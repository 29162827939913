import { createAction, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATUS = {
  status: {},
};

export const update = createAction('calendar_online_update');

const onlineReducer =  createReducer(INITIAL_STATUS,
  builder => {
    builder.addCase(update, (state, action) => {
      state.status = { ...state.status, ...action.payload.status };
    })
  }
);

export default onlineReducer;
