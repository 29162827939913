import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@d-lighted/design-system';
import { useParams } from 'react-router-dom';
import messages from './i18n/TemplateBlocked';

const IconWrapper = styled.div`
  position: absolute;
  left: -7px;
${({indented, extraIndentation, iconTopPadding}) => indented ? `
  top: ${iconTopPadding ? iconTopPadding : 'initial'};
  left: ${extraIndentation ? `calc(${extraIndentation} + -7px)` : '-7px'};
` : ''}
`;

const TBlockedWrapper = styled.div`
  position: relative;
  flex: 1 1;
`;

const Header = styled.div`
  margin-left: ${({ ml }) => (ml ? ml : '0')};
`;

const Fields = styled.div`
${({indented, extraIndentation}) => indented ? `
  margin-left: ${extraIndentation ? `calc(${extraIndentation} + 32px)` : '32px'};
` : ''} \`;
${props => props.blocked ? `
  pointer-events: none;
  user-select: none;
  opacity: 0.6;
  
  * {
    pointer-events: none;
    user-select: none;
  }
` : ''}`;

export function WarningIcon({ indented, extraIndentation, iconTopPadding }) {
  return (
    <IconWrapper indented={indented} extraIndentation={extraIndentation} iconTopPadding={iconTopPadding}>
      <Tooltip
        message={<FormattedMessage {...messages.templateBlocked} />}
        width={310}
        icon="lock"
        iconColor="#e84c3d"
      />
    </IconWrapper>
  );
}

export function TemplateBlocked({ 
    blocked,
    header,
    fields,
    fieldsStyle,
    extraIndentation,
    iconTopPadding,
    unlockedFieldsBlocking,
    showHeaderOnlyOnBlock,
  }) {
  const { settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const isBlocked = blocked === "administrator";
  const isBlocking = unlockedFieldsBlocking ? false : (isTemplatePage && !isBlocked) || (!isTemplatePage && isBlocked);

  return (
    <TBlockedWrapper>
      {header && (!showHeaderOnlyOnBlock || (showHeaderOnlyOnBlock && isBlocked)) && (
        <Header ml={!isTemplatePage && isBlocked ? '26px' : '0'}>
          {!isTemplatePage && isBlocked && <WarningIcon />}
          {header}
        </Header>
      )}
      {fields && !header && !isTemplatePage && isBlocked && <WarningIcon indented extraIndentation={extraIndentation} iconTopPadding={iconTopPadding}/>}
      {fields && (
        <Fields 
          style={fieldsStyle}
          blocked={isBlocking}
          indented={!header && !isTemplatePage && isBlocked}
          extraIndentation={extraIndentation}
          ref={node => node && (isBlocking ? node.setAttribute('inert', '') : node.removeAttribute('inert'))}
        >
          {fields}
        </Fields>
      )}
    </TBlockedWrapper>
  );
}

TemplateBlocked.defaultProps = {
  blocked: 'all',
  fieldsFlex: false,
  header: null,
  fieldsStyle: null,
  warningStyle: null,
  extraIndentation: null,
  iconTopPadding: null,
  unlockedFieldsBlocking: false,
  showHeaderOnlyOnBlock: false,
};

TemplateBlocked.propTypes = {
  blocked: PropTypes.string,
  header: PropTypes.node,
  fields: PropTypes.node,
  fieldsStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  warningStyle: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  extraIndentation: PropTypes.string,
  iconTopPadding: PropTypes.string,
  unlockedFieldsBlocking: PropTypes.bool,
  showHeaderOnlyOnBlock: PropTypes.bool,
};