import { defineMessages } from 'react-intl';

export default defineMessages({
  tableHeadPeriod: {
    id: 'components.layouts.appointmentsTable.period',
    defaultMessage: 'Period',
  },
  tableHeadDate: {
    id: 'components.layouts.appointmentsTable.time',
    defaultMessage: 'DateTime',
  },
  tableHeadTitle: {
    id: 'components.layouts.appointmentsTable.title',
    defaultMessage: 'Title',
  },
  tableHeadVisitor: {
    id: 'components.layouts.appointmentsTable.visitor',
    defaultMessage: 'Visitor',
  },
  tableHeadAssignee: {
    id: 'components.layouts.appointmentsTable.assignee',
    defaultMessage: 'Assignee',
  },
  tableHeadCode: {
    id: 'components.layouts.appointmentsTable.code',
    defaultMessage: 'Code',
  },
  tableHeadRoom: {
    id: 'components.layouts.appointmentsTable.room',
    defaultMessage: 'Room',
  },
  tableHeadUrl: {
    id: 'components.layouts.appointmentsTable.url',
    defaultMessage: 'URL',
  },
  returnToDashboard: {
    id: 'components.layouts.appointmentsTable.returnToDashboard',
    defaultMessage: 'Return to Dashboard',
  },
  downloadAppointments: {
    id: 'components.layouts.appointmentsTable.downloadAppointments',
    defaultMessage: 'Download Appointments',
  },
  notifySuccess: {
    id: 'notify.appointments.delete.success',
    defaultMessage: 'Appointment deleted successfully.',
  },
  notifyError: {
    id: 'notify.appointments.delete.error',
    defaultMessage: 'Appointment couldn\'t be deleted.',
  },
  confirmation: {
    id: 'components.layouts.appointmentsTable.deleteConfirmation',
    defaultMessage: 'Are you sure you want to delete this appointment?',
  }
});
