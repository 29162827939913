import React from "react";
import {
  InputWithLabel,
  Row,
} from "@d-lighted/design-system";
import RequiredMark from "./RequiredMark";

export default function TextFieldCase({ data }) {
  const required = data.bookingCalendarSettingBookingCalendarCustomField.inputRequired;
  return (
    <Row key={data.uid} mt="15px" alignItems="center" mb="1rem">
      <InputWithLabel
        labelLeft={
          <span>
            {data.labelName}
            {required && <RequiredMark />}
          </span>
        }
        placeholder="" />
    </Row>
  )
}
