import { put } from "../utils/api";
import { useState } from "react";
import { convertToPermission } from "../components/forms/TemplatePermissionSelector";
import { useParams } from "react-router-dom";

function useSavedTemplateSettings() {
  const [ templateUid, setTemplateUid ] = useState(null);
  const { settings } = useParams();

  const saveTemplateSettings = async (permissions, result) => {
    if (![settings].includes('templates')) return result;

    const urlTemplateSettings = `/booking_calendars/templates/${templateUid}/settings`;
    const permissionBody = convertToPermission(permissions);

    return put(urlTemplateSettings, permissionBody).then(permissionResult => {
      const mixedResult = result || permissionResult;
      mixedResult.data.permissions = permissionResult.data.template_settings;
      return mixedResult;
    });
  }

  const saveWithTemplateSettings = async (promise, permissions) => {
    if (![settings].includes('templates')) return await promise;

    return promise.then(result => {
     if (result.status > 299) return result;
    
     return saveTemplateSettings(permissions, result);
    });
 }

  return {
    templateUid,
    setTemplateUid,
    saveWithTemplateSettings,
    saveTemplateSettings,
  };
}

export { useSavedTemplateSettings };
