import React from "react";
import { Col, Row } from "@d-lighted/design-system";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SortableComponent from "./SortableComponent";
import { SortableListWrapper, SortableItemWrapper} from "../../../components/elements/DragAndDroppable";

const SortableList = ({fields, isBlocked, ...props}) => {
  const { onSortEnd, handleEditField, handleDeleteField } = props;

  function onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    return onSortEnd(source.index, destination.index);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable key={`droppable-key`} droppableId={`droppable-id`} isDropDisabled={isBlocked}>
          {(provided) => (
            <SortableListWrapper
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((field, index) => (
                <Draggable
                  key={field.uid}
                  draggableId={field.uid}
                  index={index}
                  isDragDisabled={isBlocked}
                >
                  {(provided) => (
                    <SortableItemWrapper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Row mt="15px">
                        <Col xs display="flex" alignItems="center">
                          <SortableComponent  
                            form={field}
                            handleEditField={handleEditField}
                            handleDeleteField={handleDeleteField}
                            draggable={{...provided.dragHandleProps}}
                            isBlocked={isBlocked}
                          />
                        </Col>
                      </Row>
                    </SortableItemWrapper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </SortableListWrapper>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export { SortableList };
