import Meetings from "./pages/Meetings";
import { templatesEnabledAdminValidation } from "utils/userUtils";

export const meetingsRoutes = [
  {
    component: Meetings,
    path: "/:settings(templates|booking_calendars)/:uid/edit/meetings",
    exact: true,
    feature: "会議",
    type: "private",
    gauid: 'Bp/Meeting',
    validation: templatesEnabledAdminValidation,
  },
];
