import { defineMessages } from 'react-intl';

export default defineMessages({
  meetingType: {
    id: 'views.meetings.pages.meetings.meetingType',
    defaultMessage: 'Meeting Type',
  },
  comingToVisit: {
    id: 'views.meetings.pages.meetings.meetingType.comingToVisit',
    defaultMessage: 'Visitor (Reception code will be issued)',
  },
  webConference: {
    id: 'views.meetings.pages.meetings.meetingType.webConference',
    defaultMessage: 'Web Conference (Web Conference URL will be issued)',
  },
  goingToVisit: {
    id: 'views.meetings.pages.meetings.meetingType.goingToVisit',
    defaultMessage: 'Other (When interviewing or phone calling)',
  },
  provider: {
    id: 'views.meetings.pages.meetings.meetingType.webConference.provider',
    defaultMessage: 'Web Conference Provider',
  },
  google: {
    id: 'views.meetings.pages.meetings.meetingType.webConference.google',
    defaultMessage: 'Google',
  },
  microsoft: {
    id: 'views.meetings.pages.meetings.meetingType.webConference.microsoft',
    defaultMessage: 'Microsoft Teams',
  },
  zoom: {
    id: 'views.meetings.pages.meetings.meetingType.webConference.zoom',
    defaultMessage: 'Zoom',
  },
  webex: {
    id: 'views.meetings.pages.meetings.meetingType.webConference.webex',
    defaultMessage: 'Webex',
  },
  durations: {
    id: 'views.meetings.pages.meetings.durations',
    defaultMessage: 'Durations',
  },
  d15: {
    id: 'views.meetings.pages.meetings.durations.15',
    defaultMessage: '15Min',
  },
  d20: {
    id: 'views.meetings.pages.meetings.durations.20',
    defaultMessage: '20Min',
  },
  d30: {
    id: 'views.meetings.pages.meetings.durations.30',
    defaultMessage: '30Min',
  },
  d40: {
    id: 'views.meetings.pages.meetings.durations.40',
    defaultMessage: '40Min',
  },
  d45: {
    id: 'views.meetings.pages.meetings.durations.45',
    defaultMessage: '45Min',
  },
  d60: {
    id: 'views.meetings.pages.meetings.durations.60',
    defaultMessage: '60Min',
  },
  d90: {
    id: 'views.meetings.pages.meetings.durations.90',
    defaultMessage: '90Min',
  },
  d120: {
    id: 'views.meetings.pages.meetings.durations.120',
    defaultMessage: '120Min',
  },
  d180: {
    id: 'views.meetings.pages.meetings.durations.180',
    defaultMessage: '180Min'
  },
  dCustom: {
    id: 'views.meetings.pages.meetings.durations.custom',
    defaultMessage: 'Custom',
  },
  attendanceRule: {
    id: 'views.meetings.pages.meetings.attendanceRule',
    defaultMessage: 'Attendance Rule',
  },
  allWillAttend: {
    id: 'views.meetings.pages.meetings.attendanceRule.allWillAttend',
    defaultMessage: 'All will attend',
  },
  oneWillAttend: {
    id: 'views.meetings.pages.meetings.attendanceRule.oneWillAttend',
    defaultMessage: 'One will attend (in the order of member list)',
  },
  randomOneWillAttend: {
    id: 'views.meetings.pages.meetings.attendanceRule.randomOneWillAttend',
    defaultMessage: 'One will attend (random)',
  },
  customWillAttend: {
    id: 'views.meetings.pages.meetings.attendanceRule.customWillAttend',
    defaultMessage: 'Custom rule',
  },
  conferenceRoom: {
    id: 'views.meetings.pages.meetings.conferenceRoom',
    defaultMessage: 'Conference Room',
  },
  meetingTitle: {
    id: 'views.meetings.pages.meetings.meetingTitle',
    defaultMessage: 'Meeting Title',
  },
  meetingTitlePlaceholder: {
    id: 'views.meetings.pages.meetings.meetingTitlePlaceholder',
    defaultMessage: 'Coming to visit {お客様の会社名}{お客様の氏名}様',
  },
  meetingAccessibility: {
    id: 'views.meetings.pages.meetings.meetingAccessibility',
    defaultMessage: 'Meeting Accessibility',
  },
  meetingVisibilityPublic: {
    id: 'views.meetings.pages.meetings.meetingVisibilityPublic',
    defaultMessage: 'Public',
  },
  meetingVisibilityPrivate: {
    id: 'views.meetings.pages.meetings.meetingVisibilityPrivate',
    defaultMessage: 'Private',
  },
  meetingTitleInfo: {
    id: 'views.meetings.pages.meetings.meetingTitleInfo',
    defaultMessage: 'Title reflect on {br} appointment and linked calendar. {br}If you have set the company name to "Hide" in the field settings,{br} then {お客様の会社名} will also be blank.',
  },
  meetingVisibilityInfo: {
    id: 'views.meetings.pages.meetings.meetingVisibilityInfo',
    defaultMessage: 'Visibility reflect on {br} appointment and linked calendar.'
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyDurations: {
    id: 'notify.meetings.error.durations',
    defaultMessage: 'You cannot leave durations unspecified; You must specify at least one duration.',
  },
  notifyMembers: {
    id: 'notify.meetings.error.members',
    defaultMessage: 'You cannot leave attendees unspecified; you must specify at least one attendee.',
  },
  notifyWebConferenceType: {
    id: 'notify.meetings.error.webConferenceType',
    defaultMessage: 'You cannot leave attendees unspecified; you must specify at least one attendee.',
  },
  members: {
    id: 'components.form.memberSelection.members',
    defaultMessage: 'Members',
  },
  membersInTemplate: {
    id: 'components.form.memberSelection.members.template',
    defaultMessage: 'You can not select members during template edition.',
  },
  pageTypeTooltip: {
    id: 'views.meetings.pages.meetings.pageTypeTooltip',
    defaultMessage: 'You can add members to the booking page when the booking page is for team. This feature is only available for paid users',
  },
  building: {
    id: 'views.meetings.pages.meetings.building',
    defaultMessage: 'Building',
  },
  unselected: {
    id: 'views.meetings.pages.meetings.building.unselected',
    defaultMessage: 'Unselected',
  },
  team: {
    id: 'views.meetings.pages.meetings.team',
    defaultMessage: 'For team',
  },
  calendarNotIntegrated: {
    id: 'components.form.memberSelection.calendarNotIntegrated',
    defaultMessage: 'Calendar has not been linked yet',
  },
  zoomNotIntegrated: {
    id: 'components.form.memberSelection.zoomNotIntegrated',
    defaultMessage: 'Zoom integration has not been completed',
  },
  meetingToolNotIntegrated: {
    id: 'views.meetings.pages.meetings.meetingType.webConference.meetingToolNotIntegrated',
    defaultMessage: 'No Web conference tool has been linked yet',
  },
  modalTitle: {
    id: 'views.meetings.pages.meetings.modal.title',
    defaultMessage: 'Building Selection',
  },
  modalSelect: {
    id: 'views.meetings.pages.meetings.modal.select',
    defaultMessage: 'Select',
  },
  modalSelected: {
    id: 'views.meetings.pages.meetings.modal.selected',
    defaultMessage: 'Selected',
  },
  modalFooter: {
    id: 'views.meetings.pages.meetings.modal.footer',
    defaultMessage: '※If you do not select a building, you may not be able to get all the meeting room information.',
  },
  modalButton: {
    id: 'views.meetings.pages.meetings.modal.button',
    defaultMessage: 'Do not select building',
  },
  templateModalTitle: {
    id: 'views.meetings.pages.meetings.templateModal.title',
    defaultMessage: 'Template Update',
  },
  templateModalInstruction: {
    id: 'views.meetings.pages.meetings.templateModal.instruction',
    defaultMessage: 'Please select one of the following',
  },
  templateModalRadio1: {
    id: 'views.meetings.pages.meetings.templateModal.radio1',
    defaultMessage: 'Update only the template',
  },
  templateModalRadio2: {
    id: 'views.meetings.pages.meetings.templateModal.radio2',
    defaultMessage: 'Update all booking pages applying this template',
  },
  adminEdit: {
    id: 'views.meetings.pages.meetings.select.adminOption',
    defaultMessage: 'Only admin can edit',
  },
  allEdit: {
    id: 'views.meetings.pages.meetings.select.allOption',
    defaultMessage: 'All can edit',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  loading: {
    id: 'common.loading',
    defaultMessage: 'Loading',
  },
  cancel: {
    id: "common.cancel",
    defaultMessage: "Cancel",
  },
  slotDuration: {
    id: 'views.meetings.pages.meetings.gridDisplay.slotDuration',
    defaultMessage: 'Slot duration',
  },
  durationsTooltip: {
    id: 'views.meetings.pages.meetings.durations.tooltip',
    defaultMessage: 'A URL for the reservation page would be issued for each checked duration.{br}' +
      'When a reservation is confirmed, the appointment will be confirmed in the duration of the URL.{br}' +
      'For example, if the URL is for a 60-minute duration, the appointment will be confirmed for 60 minutes.'
  },
  slotDurationTooltip: {
    id: 'views.meetings.pages.meetings.gridDisplay.slotDuration.tooltip',
    defaultMessage: 'This is the interval of the time selection frame that will be displayed on the reservation page.{br}' +
      'You can check the display on the preview screen on the right.{br}' +
      'For example, in the case of 15 minutes, time slots will be displayed at intervals of 15 minutes.{br}' +
      '※ In the case of [Duration < Time Slot Duration], the time selection frame will be displayed at the interval of [Duration].'
  },
  // temporary text
  durationsLimit: {
    id: 'validation.durationsLimit',
    defaultMessage: 'The number of available durations should be more than 1 and not exceed 5.',
  },
  priorityOrder: {
    id: 'components.form.memberSelection.mustAttendArea.priorityOrder',
    defaultMessage: 'Priority',
  },
  randomOrder: {
    id: 'components.form.memberSelection.mustAttendArea.randomOrder',
    defaultMessage: 'Random',
  },
  securityLevel: {
    id: 'views.meetings.pages.meetings.securityLevel',
    defaultMessage: 'Security Level',
  },
  attendeesLimitDefault: {
    id: 'views.meetings.pages.meetings.attendeesLimitDefault',
    defaultMessage: 'Max allowed attendees is {count}',
  },
  attendeesLimitCustom: {
    id: 'views.meetings.pages.meetings.attendeesLimitCustom',
    defaultMessage: 'Max allowed must attendees and optional attendees is {count}',
  },
  updateConfirm: {
    id: 'notify.template.pages.detail.updateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it? \n\nChanges to this screen may cause the booking page to behave unexpectedly.After making any changes, please inform the person in charge of the booking page of the changes.',
  },
});
