import clipboardCopy from "copy-to-clipboard";

function isPromise(p) {
  return p && Object.prototype.toString.call(p) === "[object Promise]";
}

function isString(s) {
  return typeof s === 'string' || s instanceof String;
}

// Some browsers have partial or none support to async Clipboard API, needed to 
// receive a promise to with the copy target, so this wrapper is to allow that,
// having the third party library has a fallback just in case.
// https://caniuse.com/mdn-api_clipboarditem
// https://caniuse.com/async-clipboard
export function copy (text) {
  if (window.ClipboardItem && window.navigator.clipboard && window.isSecureContext) {
    let blobText = text;
    if (isString(text)) {
      blobText = new Blob([text], { type: "text/plain" });
    }

    return window.navigator.clipboard.write([new window.ClipboardItem({ "text/plain": blobText })]);
  }
  
  if(isPromise(text)) {
    return text.then( async response => { clipboardCopy(await response.text()) });
  }
  
  clipboardCopy(text);
}