import styled from "styled-components";
import { borders, color, display, flexbox, justifyContent, margin, padding, position, layout, textAlign } from "styled-system";
import LogoMailer from "../../assets/img/logo_mailer.svg";
import { isPremiumPlan } from "../../utils/userUtils";

export const defaultRowMargin = "-15px";
export const defaultColPadding = "15px";

export const Div = styled.div`
  ${display}
  ${flexbox}
  ${position}
  ${margin}
  ${padding}
  ${color}
  ${justifyContent}
  ${textAlign}
  ${layout}
  ${borders}
  ${props => props.noScrollbar && `
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const StyledLink = styled.a`
  ${margin}
  ${padding}
  ${color}
`;

export const Hr = styled.hr`
  ${borders}
  ${color}
  ${margin}
  ${layout}
`;

export const Center = styled.div`
  width: 100px;
  height: 100px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const PrimaryLightBackground = styled.div`
  background-color: ${(props) => props.theme.colors.paleGreen};
  height: 1000px;
  width: 100%;
`;

export const emailLogoPath = (logo_url) => {
  if(isPremiumPlan() && logo_url?.constructor === String) {
    return logo_url;
  }
  return LogoMailer;
}

export const appointmentCancellationLimitLabel = (value) => {
  if(value >= 15) {
    return value + "分";
  }else{
    return value + "日";
  }
}
