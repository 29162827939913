import React from "react";
import { Input, IconNew as Icon, Col, Row } from "@d-lighted/design-system";
import { injectIntl } from 'react-intl';
import { DragIcon, Line } from "../../../components/elements/DragIcon";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import messages from '../i18n/Modules';
import { SortableListWrapper, SortableItemWrapper } from "../../../components/elements/DragAndDroppable";

const CustomFieldWrapper = styled.div`
  display: flex;
  border-radius: 5px;
  width: 100%;
  align-items: center;
  &:hover {
    border-color: #00bbb5;
  }
`;

const SortableItem = ({
  choiceTypeName,
  bookingCalendarCustomFieldId,
  removeChoice,
  updateChoice,
  itemIndex,
  id,
  intl,
  ...props
}) => {
    return (
      <>
        <Input
          name={bookingCalendarCustomFieldId}
          placeholder={intl.formatMessage({...messages.optionsPlaceholder})}
          mb="0"
          value={choiceTypeName}
          onChange={e => {
            e.preventDefault();
            updateChoice(id, e.target.value);
          }}
          height="44px"
        />
        <Icon
          isCursor
          onClick={() => removeChoice(id)}
          className="material-icons"
          color="red"
          fontSize="23px"
          style={{ marginLeft: "10px" }}
        >
          close
        </Icon>
      </>
    );
};

const SortableList =
  ({ choices, removeChoice, updateChoice, onSortEnd, ...props }) => {

    function onDragEnd(result) {
      const { source, destination } = result;
      // dropped outside the list
      if (!destination) {
        return;
      }
      return onSortEnd(source.index, destination.index);
    }

    return (
      <div style={{ display: "block" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable key={`droppable-key`} droppableId={`droppable-id`}>
          {(provided) => (
            <SortableListWrapper
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {choices.map((choice, index) => (
                <Draggable
                  key={choice.uid}
                  draggableId={`choice-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <SortableItemWrapper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Row mt="15px">
                        <Col xs display="flex" alignItems="center">
                          <CustomFieldWrapper>
                            <DragIcon {...provided.dragHandleProps} >
                              <Line className="line" />
                              <Line className="line" />
                              <Line className="line" />
                            </DragIcon>
                            <SortableItem
                              key={`choice-${index}-${choice.bookingCalendarCustomFieldId}`}
                              removeChoice={removeChoice}
                              updateChoice={updateChoice}
                              itemIndex={index}
                              index={index}
                              {...choice}
                              {...props}
                            />
                          </CustomFieldWrapper>
                        </Col>
                      </Row>
                    </SortableItemWrapper>
                  )}
                </Draggable>
              ))}
                {provided.placeholder}
            </SortableListWrapper>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default injectIntl(SortableList);
