import React from "react";
import styled from "styled-components";
import { defineMessages ,FormattedMessage } from 'react-intl';
import FontAwesome from "react-fontawesome";

const Warning = styled.div`
  background-color: #f6f8f8;
  padding: 16px;
  text-align: center;
`;

const InfoIcon = styled(FontAwesome)`
  color: #68878d;
  font-size: 44px;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 18px;
`;

const TitleWrapper = styled.div`
  color: #314143;
  margin: 4.3px 52px 14px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.9px;
`;

const BodyWrapper = styled.div`
  margin: 4.3px 12px 36px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  color: #68878d;
`;

const messages = defineMessages({
  title: {
    id: "components.layouts.noSupportForMobile.title",
    defaultMessage: "Not Supported on mobile version site",
  },
  description:{
    id:"components.layouts.noSupportForMobile.description",
    defaultMessage: "Sorry. This setting is not yet available on the mobile site. Instead, try it on your PC or use it from the PC version site.",
  },
  button:{
    id:"components.layouts.noSupportForMobile.button",
    defaultMessage: "Display PC version site",
  }
})

export function NoSupportWarning() {

  return (
    <Warning>
      <InfoIcon name="info-circle" className="fas fa-info-circle"/>
      <TitleWrapper>
        <FormattedMessage {...messages.title} />
      </TitleWrapper>
      <BodyWrapper>
        <FormattedMessage {...messages.description} />
      </BodyWrapper>
    </Warning>
  );
}
