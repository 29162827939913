import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { 
  Row,
  Col,
  Label,
  Avatar,
} from "@d-lighted/design-system";
import messages from "../i18n/MustAttendArea";
import userDefault from "../../../../assets/img/userDefault.png";

const Wrapper = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const AddMemberGuide = styled.div`
  background-color: #E6F4EF;
  margin-top: 13px;
  margin-bottom: 13px;
  padding: 20px 20px;
  min-height: 20px;
  border: ${props => (props.isDraggingOver ? '5px dotted #4FBBB5' : '3px dotted #4FBBB5')};
`;

const Text = styled(Label)`
  display: inline-block;
  line-height: 1.4;
  margin-top: auto;
  margin-bottom: auto;
  color: ${props => props.color ? props.color : '#56C9C3'};
`;

const DragAndDroppableArea = (props) => {
  const { snapshot: { isDraggingOver }, membersCount, ind, children } = props;
  return(
    <Wrapper>
      {membersCount === 0 ?
        <Row>
          <Col width="100%">
            <AddMemberGuide isDraggingOver={isDraggingOver}>
              {!isDraggingOver && 
                <Row>
                  <Col>
                    <Avatar
                      src={userDefault}
                      mr="12px"
                      ml="0px"
                      mt="-5px"
                    />
                  </Col>
                  <Col>
                    <Text top="5px">
                      { ind === 0 &&
                        <FormattedMessage {...messages.mustAttendHelp} />
                      }
                      { ind === 1 &&
                        <FormattedMessage {...messages.optionalAttendHelp} />
                      }
                      { ind === 2 &&
                        <FormattedMessage {...messages.editorHelp} />
                      }
                    </Text>
                  </Col>
                </Row>
              }
            </AddMemberGuide>
          </Col>
        </Row>
      :
        children
      }
    </Wrapper>
  );
};

export default injectIntl(DragAndDroppableArea);
