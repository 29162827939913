import React from 'react';
import styled from 'styled-components';
import FontAwesome from "react-fontawesome";

const WarningIcon = styled(FontAwesome)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  position: relative;
  margin-top: ${props => props.top}px;
  color: #e84c3d;
`;

const Wrapper = styled.span`
  display: inline-grid;
  position: relative;
  top: ${props => props.top}px;
  left: 8px;
`;

const IconWrapper = styled.span`
  display: inline-block;
  &:hover + .display-text, & + .display-text:hover {
    visibility: visible;
    opacity: 1;
  }
`;

const Anchor = styled.span`
    visibility: hidden;
    opacity: 0;
    content: '';
    transition: 0.2s;
    position: initial;
    z-index: 2;
`;

const WarningBoard = styled.span`
  background-color: #e84c3d;
  color: #fff;
  width: 232.2px;
  height: 65.3px;
  border-radius: 5px;
  margin-left: -110px;
  margin-top: 10px;
  position: absolute;
  font-size: 10px;
  z-index: 2;

  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #e84c3d transparent;
  } 
`;

const WarningText = styled.p`
  width: 202px;
  height: 41px;
  font-family: NotoSansJP;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: white;
  margin: 14px 14px 10px 14px;
`;

function ToolWarning({ message, top }) {
  return (
    <Wrapper top={top}>
      <IconWrapper>
        <WarningIcon
          name="exclamation-circle" 
          className="fas fa-exclamation-circle fa-lg"
          role="button"
          // isCursor={true}
        >
        </WarningIcon>
      </IconWrapper>
      <Anchor className="display-text">
        <WarningBoard>
          <WarningText>
            {message}
          </WarningText>
        </WarningBoard>
      </Anchor>
    </Wrapper>
  );
}

ToolWarning.defaultProps = {
  message: '', 
  subMessage: '',
  width: 330,
  left: 800,
  top: 0
};

export default ToolWarning;
