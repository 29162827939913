import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { Label } from "@d-lighted/design-system";
import appointmentPropType from './appointmentPropType';
import TableBodyItem from './TableBodyItem';
import Flex from './Flex';

const LabelBlock = styled(Label)`
  display: block;
`;

function AppointmentCode({ appointment, renderIcon }) {
  const visitorCodes = appointment.visitors.map(visitor => {
    if (!visitor.code) return null;
    return <LabelBlock color="#000" key={visitor.uid}>{visitor.code}</LabelBlock>;
  });

  return (
    <TableBodyItem first>
      <Flex align="center">
        {renderIcon}
        <LabelBlock color="#000">
          {compact(visitorCodes).length ? visitorCodes : appointment.code}
        </LabelBlock>
      </Flex>
    </TableBodyItem>
  )
}

AppointmentCode.defaultProps = {
  renderIcon: null,
}

AppointmentCode.propTypes = {
  appointment: appointmentPropType.isRequired,
  renderIcon: PropTypes.node,
}
export default AppointmentCode;
