import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { IntlProvider } from 'react-intl';

import { useLocale, LOCALES} from '../utils/localeUtils';
import { getCurrentUser } from '../utils/userUtils';
import locale_ja from '../locale/ja';
import locale_en from '../locale/en';

const messages = {};
messages[LOCALES.JA] = locale_ja;
messages[LOCALES.EN] = locale_en;

function LocaleProvider ({ children }) {
  const [unloaded, setUnloaded] = useState(true);
  const [locale, setLocale] = useLocale();
  const currentUser = getCurrentUser();
  
  if (unloaded && currentUser != null && !isEmpty(currentUser) && currentUser.company != null) {
    setLocale(currentUser.company.locale_code);
    setUnloaded(false);
  }
  
  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocaleProvider;
