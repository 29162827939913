import { useEffect } from "react";

export function OnClickOutside(ref, toggle) {
    useEffect(() => {
      // Alert if clicked on outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggle(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
      // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [ref]);
}