import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  //layouts
  Grid,
  Col,
  Row,
} from "@d-lighted/design-system/dist/index";

import messages from "../i18n/DashboardEmpty";
import { TYPES } from "../../../redux/reducers/calendarsReducer";

const Wrapper = styled.div`
  margin-top: 50px;
`;

// Set the proper Css for these 2 text elements
const PrimaryText = styled.p`
  color: #68878d;
  font-size: 17px;
  font-weight: bold;
  margin-top: 16px;
`;

const SecondaryText = styled.p`
  color: #68878d;
  font-size: 15px;
  font-weight: normal;
  margin-top: 20px;
`;

export default function DashboardEmpty({ type }) {
  return (
    <Wrapper>
      <Grid fluid>
        <Row>
          <Col xs display="flex" flexDirection="column" alignItems="center">
            <img
              src={require("../../../assets/img/pic_calendar.png")}
              alt="calendar_image"
            />
            {type && type === TYPES.TEMPLATE && (
              <>
                <PrimaryText>
                  <FormattedMessage {...messages.primary} />
                </PrimaryText>
                <SecondaryText>
                  <FormattedMessage {...messages.secondary} />
                </SecondaryText>
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  );
}

DashboardEmpty.propTypes = {
  type: PropTypes.string,
};
