/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import { reduxForm, Field } from "redux-form";
import {
  Button,
  Col,
  Row,
  ErrorText,
  Input,
  Label as BaseLabel,
  Hr,
  Loader
} from "@d-lighted/design-system";
import { post, put } from "../../../utils/api";
import { useValidation } from "../../../utils/validation";
import { getCurrentUser, setCurrentUser, getSubdomain } from "../../../utils/userUtils";
import { useLocale } from "../../../utils/localeUtils";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../i18n/SignIn";
import BackgroundImage from '../../../assets/img/signin_background.png';
import { customMedia } from '../../../utils/mobileBreakpointUtils';
import * as imageUtil from '../../../utils/imageUtils';
import { useOauth } from "../../onboarding/hooks/useOauth";
import { 
  Subtitle,
  SocialButton,
  SocialImage
} from "../components/OAuthComponents";
import LOGO from "../../../assets/img/img-scheduling-logo.svg";
import GoogleIcon from '../../../assets/img/google_icon.svg';
import MicrosoftIcon from '../../../assets/img/microsoft_icon.svg';
import { SHOW_MOBILE } from "../../../constants/breakpoint";
import { capitalizedString } from "../../onboarding/utils/utils";
import { Center } from "../../onboarding/components/Center";
import { usePromotion } from "../hooks/usePromotion";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  background-color: #fff;
  ${customMedia.lessThan('sm')`
    display: block;
  `};
`;

const FieldWrapper = styled.div`
  width: 50%;
  padding-top: 80px;
  text-align: center;
  ${customMedia.lessThan('sm')`
    width: 100%;
    display: block;
    padding-top: 10px;
  `};
`;

const FieldSection = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: ${(props) => props.top ? props.top : "40px"} 0 0 32px;
  text-align: left;
  ${customMedia.lessThan('sm')`
    width: 100%;
    margin: 0;
    padding: 0;
  `};
`;

const FormBody = styled.div`
  ${customMedia.lessThan('sm')`
  width: auto;
  text-align: left;
  padding: 16px;
  `};
  .fieldLabel span {
  font-weight: 500;
  }
  .fieldLabel .error-text {
  color: #e84c3d;
  }
  padding: 16px 0;
`;

const Label = styled(BaseLabel)`
  letter-spacing: 0px;
`;

const BottomTextBody = styled.div`
  ${customMedia.lessThan('sm')`
    width: auto;
    padding: 0 16px;
  `};
  text-align: right;
  margin-bottom: 34px;
`;

const ForgotPasswordBody = styled.div`
  margin-bottom: 4px;
`;

const LoginButton = styled(Button)`
  height: 46px;
  font-size: 16px;
  border-radius: 20px;
  padding: 10px 0px 12px;
  background-color: #28afd5;
  &:hover {
    background-color: #0e8eb3;
  }
`;

const Box = styled.div`
  max-width="100%";
`;

const PromotionSection = styled.div`
  background: #B4ECFE;
  width: 50%;
  display: flex;
  height: ${window.innerHeight}px;
  align-items: center;
  justify-content: center;
  flex-flow:column;
  ${customMedia.lessThan('sm')`
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 24px;
    margin: 24px 0;
    background-image: none;
  `};
  background-image: url(${BackgroundImage});
  background-position: top;
  a {
    max-width: 100%;
  }
  img {
    width: 514px;
    height: 268px;
    cursor: pointer;
    border-radius: 2px;
    filter: drop-shadow(0px 20px 30px rgba(17, 45, 72, 0.16));
    ${customMedia.lessThan('sm')`
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 0 0 24px;
      display: block;
      margin: auto
    `};
  }
`;

const PromotionHeading = styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
  ${customMedia.lessThan('sm')`
    font-size: 16px;
    padding: 16px 0;
    margin: 0;
  `};
`;

const LogoImage = styled.img`
  ${customMedia.lessThan('sm')`
    width: 230px;
    margin: 16px 0 16px 0;
    text-align: left;
    padding: 0 16px;
  `};
`;

const ProductHeading = styled.p`
  font-size: 30px;
  font-weight: 500;
  margin: 15px 0px;
  ${customMedia.lessThan('sm')`
    font-size: 24px;
    text-align: center;
  `};
`;

const SubHeading = styled.p`
  margin: 15px 0px;
  color: #68878d;
  ${customMedia.lessThan('sm')`
    display: none;
  `};
`;

const SignIn = (props) => {
  const { required, email } = useValidation(props.intl);
  const notify = props.useNotify(props.intl);
  const location = useLocation();
  const [, setLocale] = useLocale();
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const { actions, isSettingUpdated, hasUpdateError, isLoading } = useOauth(notify);
  const {promotionLink, promotionTitle, promotionActions } = usePromotion();
  const isMobileView = SHOW_MOBILE(window.innerWidth);
  const [isEmailLogin, setIsEmailLogin] = useState(!!location?.state?.email);

  const setUser = (response) => {
    if (response?.status !== 200) {
      notify.setError(messages.notifyError, response?.data?.error?.message);
    } else {
      const { data } = response;
      const currentUser = setCurrentUser(data.data);
      if(currentUser?.company) {
        setLocale(currentUser?.company?.locale_code);
        return window.location.href = '/';
      }
      return window.location.href = '/onboarding/business_info';
    }
  };

  const onSubmit = async (values) => {
    const params = Object.assign({ sign_in_from: "scheduling_web" }, values);
    let response = await post("/auth/sign_in", params);
    setUser(response);
  };

  const signIn = async (values) => {
    const params = Object.assign({ sign_in_from: "scheduling_web" }, values);
    let response = await put(`/auth/one_time_token?${(new URLSearchParams(params).toString())}`);
    setUser(response);
  };

  const authorizeSamlToken= async (values) => {
    const { samlEmail, oneTimetoken, provider } = values;

    const response = await post(
      `integrations/saml/sessions?email=${samlEmail}&one_time_token=${oneTimetoken}&provider=${provider}`,
    )
      .catch(e => e.response);

    if (response?.status !== 200) {
      notify.setError(messages.notifyError, response?.data?.error?.message);
    } else {
      const { data } = response;
      const currentUser = setCurrentUser(data.data);
      setLocale(currentUser.company.locale_code);
      history.push('/');
    }
  }

  const resetUrl = () => {
    Object.keys(query).forEach(key => delete query[key]);
    props.history.replace({ search: new URLSearchParams(query).toString() });
  }

  const formattedBody = (code, scheduler) => {
    let body = {
      scheduler,
      code,
      redirect_path: `${window.location.origin}/sign_in`
    }
    return body;
  };

  useEffect(() => {
    if(query.token != null && query.client_id != null) {
      return signIn({
        token: query.token,
        client_id: query.client_id
      });
    }
    
    if(query.one_time_token) {
      const values = { 
        samlEmail: encodeURIComponent(query.email),
        oneTimetoken: encodeURIComponent(query.one_time_token),
        provider: query.provider
      };
      authorizeSamlToken(values);
    }
    
    const state = query?.state;
    if(["outlook_auth", "google_oauth"].includes(state)) {
      const schedulerName = state.split("_")[0];
      // after OAuth
      if(query?.error) {
        notify.setError("認証エラーが発生しました。", `${capitalizedString(schedulerName)}のトークンがありません。お手数ですが、再度連携後、もう一度お試しください。`);
        return resetUrl();
      }
      if(query?.code) {
        const body = formattedBody(query?.code, schedulerName);
        actions.registerEmployee(body);
        return resetUrl();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  useEffect(() => {
    if(isSettingUpdated) {
      if(!hasUpdateError) {
        const currentUser = getCurrentUser();
        if(currentUser?.company) {
          setLocale(currentUser?.company?.locale_code);
          return window.location.href = '/';
        }
        return window.location.href = '/onboarding/business_info';
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isSettingUpdated, hasUpdateError]);

  useEffect(() => {
    promotionActions.getPromotionContent();
  }, [])

  const onOAuthSubmit = (scheduler) => {
    actions.startOAuth(scheduler, `${window.location.origin}/sign_in`);
  }

  if(isLoading) {
    return(
      <Center>
        <Loader/>
      </Center>
    )
  }

  return (
    <Wrapper>
      <FieldWrapper>
        <FieldSection>
          <LogoImage src={LOGO} />
          <SubHeading>
            <FormattedMessage {...messages.formHeading} />
          </SubHeading>
          <ProductHeading mb={4}>
            <FormattedMessage {...messages.loginToScheduling} />
          </ProductHeading>
          {isEmailLogin ?
            <form>
              <FormBody>
                <Row mb="25px">
                  <Col xs>
                    <Row mb="13px">
                      <Col xs>
                        <Label fontSize="14px" color="#000" fontWeight="normal">
                          <FormattedMessage {...messages.email} />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs>
                        <Field
                          id="email"
                          name="email"
                          component={Input}
                          validate={[required, email]}
                          placeholder="example@receptionist.com"
                          height="40px"
                          borderColor="#e9eff4"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <Row mb="13px">
                      <Col xs>
                        <Label fontSize="14px" color="#000" fontWeight="normal">
                          <FormattedMessage {...messages.password} />
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          component={Input}
                          validate={[required]}
                          placeholder="*****"
                          height="40px"
                          borderColor="#e9eff4"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {props.error && (
                  <Row mb="20px">
                    <Col xs>
                      <ErrorText>{props.error}</ErrorText>
                    </Col>
                  </Row>
                )}

                <BottomTextBody>
                  <ForgotPasswordBody>
                    {/*TODO: Change this with subdomain*/}
                    <Label
                      isCursor
                      fontSize="13px"
                      color= "#28afd5"
                      onClick={() =>
                        window.open(
                          `${window.location.protocol}//${getSubdomain()}${process.env.REACT_APP_WEB_PATH}/password/restore`,
                          "_self"
                        )
                      }
                    >
                      <FormattedMessage {...messages.forgotPassword} />
                    </Label>
                    <br />
                  </ForgotPasswordBody>
                  <BottomTextBody>
                    { /* Remove this line of code once testing is done */}
                    <Label isCursor fontSize="13px"  color= "#28afd5" onClick={() => window.open("/sign_in/saml", "_self")}><FormattedMessage {...messages.SAML} /></Label>
                    { /* Uncomment this line of code once testing is done */}
                    {/* <Label isCursor fontSize="13px"  color= "#28afd5" onClick={() => window.open(`${process.env.REACT_APP_WEB_ROOT}/sign_in/saml`, "_self")}><FormattedMessage {...messages.SAML} /></Label> */}
                   </BottomTextBody>
                </BottomTextBody>

                <LoginButton
                  onClick={props.handleSubmit((values) => onSubmit(values))}
                >
                  <FormattedMessage {...messages.login} />
                </LoginButton>
              </FormBody>
            </form>
          :
            <FormBody>
              <Row mt={isMobileView ? "30px" : "20px"}>
                <Col xs>
                  <SocialButton
                    fontWeight="500"
                    width="100%"
                    onClick={() => onOAuthSubmit("google")}
                  >
                    <SocialImage src={GoogleIcon} />
                    Googleアカウントでログイン
                  </SocialButton>
                </Col>
              </Row>
              <Row mt="20px">
                <Col xs>
                  <SocialButton
                    fontWeight="500"
                    width="100%"
                    onClick={() => onOAuthSubmit("outlook")}
                  >
                    <SocialImage src={MicrosoftIcon} />
                    Microsoftアカウントでログイン
                  </SocialButton>
                </Col>
              </Row>
            </FormBody>
          }
        </FieldSection>
        <FieldSection top="0">
          <FormBody>
            <Row>
              <Col xs>
                <Hr
                  border="none"
                  height="1px"
                  color="#e5e5e5"
                  mt="40px"
                  ml="0px"
                  width="100%"
                />
              </Col>
              <Col style={{"alignSelf": "flex-end"}} xs={3}>
                <Subtitle>または</Subtitle>
              </Col>
              <Col xs>
                <Hr
                  border="none"
                  height="1px"
                  color="#e5e5e5"
                  mt="40px"
                  ml="0px"
                  width={"100%"}
                />
              </Col>
            </Row>
            <Row mt={isMobileView ? "30px" : "20px"}>
              <Col xs display="flex" flexDirection="column" alignItems="center">
                <Label
                  isCursor
                  fontSize="16px"
                  color= "#28afd5"
                  fontWeight="500"
                  onClick={() => setIsEmailLogin(!isEmailLogin)}
                >
                  {isEmailLogin ?
                    <FormattedMessage {...messages.oauthLoginText} />
                  :
                    <FormattedMessage {...messages.emailLoginText} />
                  }
                </Label>
              </Col>
            </Row>           
          </FormBody>
        </FieldSection>
      </FieldWrapper>
      <PromotionSection>
        <PromotionHeading>{promotionTitle}</PromotionHeading>
        <Box>
          {imageUtil.getProductPromotionImage('scheduling', promotionLink)}
        </Box>
      </PromotionSection>
    </Wrapper>
  );
};

SignIn.propTypes = {};

export default injectIntl(
  reduxForm({
    form: "sign_in",
  })(SignIn)
);
