import { getCurrentUser } from "../../../utils/userUtils";

export function setFirstCalendarSetting(setting) {
  const firstCalendarSetting = JSON.parse(localStorage.getItem('first_calendar_setting')) || {};
  if(Object.keys(firstCalendarSetting).length !== 0) {
    localStorage.setItem('first_calendar_setting', {});
  }
  localStorage.setItem('first_calendar_setting', JSON.stringify(setting));
};

export function getFirstCalendarSetting() {
  const uid = getCurrentUser()?.uid;
  return JSON.parse(localStorage.getItem('first_calendar_setting'))[uid] || {};
};

export function setOnboardingAllowed(lock=false, page="/") {
  const uid = getCurrentUser()?.uqid;
  const onBoardingLock = JSON.parse(localStorage.getItem('onboarding_lock')) || {};
  onBoardingLock[uid] = { allowed: lock, page }
  localStorage.setItem('onboarding_lock', JSON.stringify(onBoardingLock));
};

export function getOnboardingState() {
  const uid = getCurrentUser()?.uqid;
  return JSON.parse(localStorage.getItem('onboarding_lock'))?.[uid] || { allowed: false, page: "/" };
}

export function saveAuthorizationInfo(info) {
  localStorage.setItem('authorization_info', JSON.stringify(info));
}

export function getAuthorizationInfo() {
  return JSON.parse(localStorage.getItem('authorization_info')) || {};
}

export function capitalizedString(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export function toOnboardingPage(props, page) {
  let path;
  switch(page) {
    case "signup":
      path = "/signup";
      break;
    case "register":
      path = "/onboarding/business_info";
      break;
    case "integrations":
      path = "/onboarding/integrations";
      break;
    case "booking_page":
      path = "/onboarding/booking_page";
      break;
    case "more":
      path = "/onboarding/more_customizations";
      break;
    case "employee_signup":
      path = "/onboarding/employee/signup";
      break;
    case "employee_booking_page":
      path = "/onboarding/employee/reservation";
      break;
    case "employee_integrations":
      path = "/onboarding/employee/integrations";
      break;
    case "sign_in":
      path = "/sign_in";
      break;
    default:
      path = "/";
  }
  if(page !== "sign_in") {
    setOnboardingAllowed(true, page);
  }
  return props.history.replace(path);
}

export function leaveOnboarding(props, path="/") {
  setOnboardingAllowed(false, "/");
  if(path === "/") {
    return window.location.href = '/';
  }
  return props.history.replace(path);
}
