/* eslint-disable  react-hooks/exhaustive-deps*/
import React from "react";
import {
  Field,
} from "redux-form";
import {
  Label,
  RadioButton,
  Col,
  Row,
  Input,
} from "@d-lighted/design-system";
import { FormattedMessage } from "react-intl";
import messages from "../i18n/Meetings";
import PlanIcon from "../../../components/elements/PlanIcon";
import Tooltip from "../../../components/elements/Tooltip";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import TemplatePermissionSelector from "components/forms/TemplatePermissionSelector";
import DesktopBreakpoint from "@d-lighted/design-system/dist/components/Utils/DesktopBreakpoint";
import PhoneBreakpoint from "@d-lighted/design-system/dist/components/Utils/PhoneBreakpoint";
import { EmailVariableText } from "../../notifications/components/EmailVariableText";
import { useValidation } from "../../../utils/validation";
const CalendarEventCustomization = (props) => {
  const { intl, isTemplatePage, permissions } = props;
  const { required, emptyString, inputLength } = useValidation(intl);

  return (
    <>
      <TemplateBlocked
        blocked={permissions?.calendarEventCustomizations?.value}
        header={
          <Row mb="10px">
            <Col xs display="flex" alignItems="center">
              <Label>
                <FormattedMessage {...messages.meetingTitle} />
                <Tooltip
                  message={props.intl.formatMessage(
                    { ...messages.meetingTitleInfo },
                    {
                      br: <br />,
                    }
                  )}
                  width={340}
                />
              </Label>
              <PlanIcon featurePlan="enterprise" />
            </Col>
            {isTemplatePage && (
              <Col>
                <TemplatePermissionSelector name="calendarEventCustomizations" />
              </Col>
            )}
          </Row>
        }
        fields={
          <>
            <Row mt="12px">
              <Col xs>
                <DesktopBreakpoint>
                  <EmailVariableText
                    elementId="title"
                    intl={intl}
                    form="Meetings"
                    filter="calendarEventCustomization"
                  />
                  <Field
                    id="title"
                    name="title"
                    component={Input}
                    placeholder={intl.formatMessage({...messages.meetingTitlePlaceholder})}
                    validate={[required, emptyString, inputLength]}
                    height="41px"
                    borderRadius="0 0 5px 5px"
                  />
                </DesktopBreakpoint>
                <PhoneBreakpoint>
                  <EmailVariableText
                    elementId="title"
                    intl={intl}
                    form="Meetings"
                    filter="calendarEventCustomization"
                  />
                  <Field
                    id="title"
                    name="title"
                    component={Input}
                    placeholder={intl.formatMessage({...messages.meetingTitlePlaceholder})}
                    validate={[required, emptyString, inputLength]}
                    height="41px"
                    borderRadius="0 0 5px 5px"
                  />
                </PhoneBreakpoint>
              </Col>
            </Row>

            <Row mb="26px" mt="26px">
              <Col xs>
                <Row mb="10px">
                  <Col xs display="flex" alignItems="center">
                    <Label>
                      <FormattedMessage {...messages.meetingAccessibility} />
                      <Tooltip
                        message={props.intl.formatMessage(
                          { ...messages.meetingVisibilityInfo },
                          {
                            br: <br />,
                          }
                        )}
                        width={340}
                      />
                    </Label>
                    <PlanIcon featurePlan="enterprise" />
                  </Col>
                </Row>
                <Row mt="12px">
                  <Col xs>
                    <DesktopBreakpoint>
                      <Row>
                        <Col xs={3}>
                          <Field
                            id="meetingAccessibility"
                            name="visibility"
                            trueFor="public"
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.meetingVisibilityPublic} />
                          </Field>
                        </Col>
                        <Col xs={3}>
                          <Field
                            id="meetingAccessibility"
                            name="visibility"
                            trueFor="private"
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.meetingVisibilityPrivate} />
                          </Field>
                        </Col>
                      </Row>
                    </DesktopBreakpoint>
                    <PhoneBreakpoint>
                      <Row>
                        <Col xs={3}>
                          <Field
                            id="meetingAccessibility"
                            name="visibility"
                            trueFor="public"
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.meetingVisibilityPublic} />
                          </Field>
                        </Col>
                        <Col xs={3}>
                          <Field
                            id="meetingAccessibility"
                            name="visibility"
                            trueFor="private"
                            component={RadioButton}
                          >
                            <FormattedMessage {...messages.meetingVisibilityPrivate} />
                          </Field>
                        </Col>
                      </Row>
                    </PhoneBreakpoint>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      />
    </>
  );
};

export default CalendarEventCustomization;