import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Loader, Pagination, InfiniteScroll } from "@d-lighted/design-system";
import DashboardEmpty from "../../home/pages/DashboardEmpty";
import Template from "../../home/modules/Template";
import FontAwesome from "react-fontawesome";
import { DotLoader } from "../../../components/elements/DotLoader";
import DesktopBreakpoint from "@d-lighted/design-system/dist/components/Utils/DesktopBreakpoint";
import PhoneBreakpoint from "@d-lighted/design-system/dist/components/Utils/PhoneBreakpoint";

const StyledLoader = styled(Loader)`
  margin-top: 15vh;
  margin-right: auto;
  margin-left: auto;
`;

const StyledLoadMore = styled(Loader)`
  margin: 14px auto 13px;
`;

function TemplateContent(props) {
  const { tabTemplate, isLoading, handleFetchMoreTemplates, type } = props;

  const TabTemplatePagination = (
    <Pagination
      currentPage={tabTemplate.currentPage}
      totalPages={tabTemplate.totalPages}
      onClick={handleFetchMoreTemplates}
      angleLeft={<FontAwesome name="angle-left" />}
      angleRight={<FontAwesome name="angle-right" />}
    />
  );
  return (
    <>
      {tabTemplate.templates.length === 0 && isLoading && <StyledLoader />}
      {tabTemplate.templates.length ? (
        <>
          <PhoneBreakpoint>
            <InfiniteScroll
              currentPage={tabTemplate.currentPage}
              totalPages={tabTemplate.totalPages}
              fetchMoreData={handleFetchMoreTemplates}
              isLoading={isLoading}
              loadMore={<DotLoader />}
            >
              {tabTemplate.templates.map(template => {
                return (
                  <Template
                    key={template.settingsUid}
                    template={template}
                    type={type}
                    {...props}
                  />
                );
              })}
            </InfiniteScroll>
          </PhoneBreakpoint>
          <DesktopBreakpoint>
            {TabTemplatePagination}
            {isLoading && <StyledLoadMore />}
            {!isLoading &&
              tabTemplate.templates.map((template) => {
                return (
                  <Template
                    key={template.settingsUid}
                    template={template}
                    {...props}
                  />
                );
              })}
            {TabTemplatePagination}
          </DesktopBreakpoint>
        </>
      ) : (
        <>{!isLoading && <DashboardEmpty type={type} />}</>
      )}
    </>
  );
}

TemplateContent.propTypes = {
  tabTemplate: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  handleFetchMoreTemplates: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default TemplateContent;
