import { defineMessages } from 'react-intl';

export default defineMessages({
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  chats: {
    id: 'views.notifications.pages.notifications.chats',
    defaultMessage: 'Notification Chats',
  },
  slackChannel: {
    id: 'views.notifications.pages.notifications.slackChannel',
    defaultMessage: 'Notification Channel',
  },
  chatworkGroupId: {
    id: 'views.notifications.pages.notifications.chatworkGroupId',
    defaultMessage: 'Group Chat ID',
  },
  microsoftTeamsTeamUrl: {
    id: 'views.notifications.pages.notifications.microsoftTeamsTeamUrl',
    defaultMessage: 'Microsoft Teams team URL',
  },
  microsoftTeamsChannelUrl: {
    id: 'views.notifications.pages.notifications.microsoftTeamsChannelUrl',
    defaultMessage: 'Microsoft Teams channel URL',
  },
  slackByDM: {
    id: 'views.notifications.pages.notifications.slackByDM',
    defaultMessage: 'DM notification to participants',
  },
  slackByDMOnlyAllowed: {
    id: 'views.notifications.pages.notifications.slackByDMOnlyAllowed',
    defaultMessage: '*On the booking page, participants will be notified by DM. {br} If you want to notify the channel, {br} please switch to team booking page.',
  },
  slackByChannel: {
    id: 'views.notifications.pages.notifications.slackByChannel',
    defaultMessage: 'Notify the specified channel',
  },
  googleChatRoomWebhook: {
    id: 'views.notifications.pages.notifications.googleChatRoomWebhook',
    defaultMessage: 'Google Chat Webhook URL',
  },
  timing: {
    id: "views.notifications.pages.notifications.timing",
    defaultMessage: "Notification Timing",
  },
  timingConfirmation: {
    id: "views.notifications.pages.notifications.timing.confirmation",
    defaultMessage: "Notify participants when appointments are confirmed",
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
