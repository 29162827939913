import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "@d-lighted/design-system";
import ScaledPreview from "../preview/ScaledPreview";
import { PrimaryLightBackground } from "../utils/Helper";
import useWindowSize from "../utils/useWindowSize";

const DashboardWrapper = styled.div`
  max-width: 100%;
`;

export function Dashboard(props) {
  const size = useWindowSize();

  return (
    <DashboardWrapper>
      <Grid fluid p="0">
        <Row backgroundColor="#fff" m="0">
          <Col xs sm={12} md={5} lg={5} pl={["16px","30px"]} pr={["16px","20px"]} overflow={["inherit"]}>
            {props.children}
          </Col>
          {size.width >= 1024 && <Col xs md={7} lg={7} pr="0">
            <PrimaryLightBackground>
              <ScaledPreview />
            </PrimaryLightBackground>
          </Col>}
        </Row>
      </Grid>
    </DashboardWrapper>
  );
}
