import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { _get, post, put } from "../../../utils/api";
import { apiUtils } from '../../../utils/apiUtils';
import { setCurrentUser } from "../../../utils/userUtils";
import { getFirstCalendarSetting } from "../utils/utils";

function useOnboarding(notify) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isSettingUpdated, setCalUpdated] = useState(false);
  const [hasUpdateError, setUpdateError] = useState(false);
  const onBoardingCalendarSetting = useSelector((state) => state.calendarSetting) || getFirstCalendarSetting();
  
  const updateCalendarSetting = (data) => {
    dispatch({
      type: "calendarSetting_update",
      payload: apiUtils.formatAxiosResponse(data)
    });
  };

  const createBusinessInfo = (values) => {
    setLoading(true);
    const url = `/booking_calendar_settings/onboarding`;
    post(url, values)
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          notify.setError("会社の登録に失敗しました。", result?.data?.message);
          setLoading(false);
        }
        else {
          updateCalendarSetting(result);
          const url = `/employee/show_me`;
          _get(url, process.env.REACT_APP_API_ENDPOINT).then(result => {
            const { data } = result;
            setCurrentUser(data?.employee);
          });
          // show animation for 2 seconds
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      });
  };

  const updateOnboardingCalendar = (values) => {
    const url = `/booking_calendar_settings/onboarding`;
    setLoading(true);
    put(url, values)
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          notify.setError("オンボーディング予約ページ設定を更新できませんでした。", result?.data?.message);
          setUpdateError(true);
        }
        else {
          updateCalendarSetting(result);
          setUpdateError(false);
        }
        setLoading(false);
        setCalUpdated(true);
      });
  };

  const startOAuth = (provider, redirectPath) => {
    const authUrl = `/integrations/${provider}/auth?&redirect_path=${redirectPath}`;
    _get(authUrl).then(result => {
      if (result?.status < 200 || result?.status >= 300) {
        notify.setError("認証エラーが発生しました。", result?.data?.message);
      }
      else {
        return window.open(result?.data?.auth_url, "_self");
      }
    });
  };

  const getInviatedCalendarSetting = () => {
    const url = `/booking_calendar_settings/onboarding`;
    setLoading(true);
    _get(url)
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          notify.setError("オンボーディング予約カレンダー設定の取得に失敗しました。", result?.data?.message); 
        }
        else {
          updateCalendarSetting(result);
        }
        setLoading(false);
      });
  };

  return {
    onBoardingCalendarSetting,
    isSettingUpdated,
    hasUpdateError,
    isLoading,
    actions: {
      createBusinessInfo,
      startOAuth,
      updateOnboardingCalendar,
      getInviatedCalendarSetting
    },
  };
}

export { useOnboarding };
