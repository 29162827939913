import React from "react";
import { Field } from "redux-form";
import { 
  Row,
  Col,
  Label as BaseLabel,
  Checkbox,
} from "@d-lighted/design-system";
import styled from "styled-components";

const CheckboxWrapper = styled.div`
  box-sizing: border-box;
  font-weight: bold;
  height: 52px;
  font-size: 12px;
  padding: 1px;
  border-radius: 4px;
  color: #68878d;
  vertical-align: top;
`;

const Label = styled(BaseLabel)`
  font-family: YuGothic;
  font-style: normal;
  font-weight: 500;
  font-size: 13.5px;
  line-height: 16px;
  color: #112D48;
  display:block;
`;

const PrivacyAgreement = ({privacyChecked}) => {

  const openHelpUrl = (id) => {
    window.open(`http://help.receptionist.jp/?p=${id}`)
  };

  return (
    <>
      <Row mt="33px">
        <Col xs display="flex" justifyContent="left">
          <CheckboxWrapper>
            <Field
              id="privacyAgree"
              name="privacyAgree"
              useArrayValue={false}
              component={Checkbox}
              validate={[privacyChecked]}
            >
              <Label>
                私は<a href="#/" onClick={() => openHelpUrl("398")}>利用規約</a>及び<a href="#/" onClick={() => openHelpUrl("402")}>個人情報保護方針</a>を読み、これに同意します。
              </Label>
            </Field>
          </CheckboxWrapper>
        </Col>
      </Row>
    </>
  );
}

export default PrivacyAgreement;
