import messages from '../i18n/InputScreenSetting';
import textfieldImg from "../../../assets/img/ico_shorttext.svg";
import textareaImg from "../../../assets/img/ico_longtext.svg";
import radioImg from "../../../assets/img/ico_radio.svg";
import messageImg from "../../../assets/img/ico_message.svg";
import dropdownImg from "../../../assets/img/ico_dropdown.svg";
import invisibleImg from "../../../assets/img/ico_invisible.svg";

export const options = intl => [
  {
    value: 0,
    label: intl.formatMessage({ ...messages.textField }),
    type: "text_field",
  },
  {
    value: 1,
    label: intl.formatMessage({ ...messages.textArea }),
    type: "text_area",
  },
  {
    value: 2,
    label: intl.formatMessage({ ...messages.radioButton }),
    type: "radio_button",
  },
  {
    value: 3,
    label: intl.formatMessage({ ...messages.checkBox }),
    type: "check_box",
  },
  {
    value: 4,
    label: intl.formatMessage({ ...messages.blockOfText }),
    type: "block_of_text",
  },
  {
    value: 5,
    label: intl.formatMessage({ ...messages.dropdown }),
    type: "dropdown",
  },
  {
    value: 6,
    label: intl.formatMessage({ ...messages.dateField }),
    type: "date",
  },
  {
    value: 7,
    label: intl.formatMessage({ ...messages.hiddenField }),
    type: "hidden_field",
  },
  {
    value: 8,
    label: intl.formatMessage({ ...messages.privacyField }),
    type: "privacy_field",
  },
];

// TODO node形式でlabelを渡すとconsoleにwarningが出るので、string形式でlabelを渡すようにする
export const restrictionOptions = intl => [
  {
    value: 0,
    label: intl.formatMessage({ ...messages.freeField }),
    type: "nothing",
  },
  {
    value: 1,
    label: intl.formatMessage({ ...messages.email }),
    type: "email",
  },
  {
    value: 2,
    label: intl.formatMessage({ ...messages.phoneNo }),
    type: "phone_no",
  },
];

export const optionsIcon = fieldType => {
  let fieldImage;
  switch (fieldType) {
    case "text_field": {
      fieldImage = textfieldImg;
      break;
    }
    case "text_area": {
      fieldImage = textareaImg;
      break;
    }
    case "radio_button": {
      fieldImage = radioImg;
      break;
    }
    case "block_of_text": {
      fieldImage = messageImg;
      break;
    }
    case "dropdown": {
      fieldImage = dropdownImg;
      break;
    }
    case "hidden_field": {
      fieldImage = invisibleImg;
      break;
    }
    default: {
      fieldImage = null;
    }
  }
  return fieldImage;
};
