import { Button } from "@d-lighted/design-system";
import styled from "styled-components";
import { MOBILEBREAKPOINT } from "../../../constants/breakpoint";

export const LogoImage = styled.img`
  height: 30px;
  @media (min-width:${MOBILEBREAKPOINT}){
    margin-left: -40px;
  }
`;

export const Subtitle = styled.div`
  font-family: YuGothic;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #66657C;
  text-align: center;
`;

export const SocialButton = styled(Button)`
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D3D9E5;
  border-radius: 100px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  height: 43px;
  line-height: 22px;
  padding: 0;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  justify-content: center;
`;

export const SocialImage = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 20px;
`;
