import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Input, Loader, Row, Col, Label } from '@d-lighted/design-system';
import { PlanBlocked } from 'components/layouts/PlanBlocked';
import * as messageIdConstants from 'components/layouts/constants/messageIdConstants';

import messages from '../i18n/ReservationFormSetting';
import salesforce_icon from 'assets/img/icon_salesforce.svg';
import { useSearchSfInputFields } from '../hooks/useSearchSfFields';

const InputList = styled.div`
  display: inline-block;
  width: 100%;
  overflow-y: auto;
  z-index: 100;
  padding-top: -20px;
  list-style: none;
  max-height: 220px;
  margin-top: 20px;
`;

const ListItem = styled.div`
  color: #484e62;
  font-size: 14px;
  font-weight: 200;
  height: 52px;
`;

const LoaderAnchor = styled.div`
  height: 56px;
  width: 56px;
  margin: 0 auto;

  &> * {
    height: 56px;
    width: 56px;
    margin-top: 0px;
  }
`;

const Wrapper = styled.div`
  margin: 1px;
  border-bottom: solid 1px #e5e5e5;
  height: 30px;
  display: flex;
  cursor: pointer;
  &:hover {
    border-color: #00bbb5;
  }
`;

const ImageIcon = styled.img`
  width: 42px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  top: 5px;
`;

const SalesforceField = ({authenticated, showList, setShowList, saveSfFieldChoices, ...props}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const { searchSfInputFields } = useSearchSfInputFields();
  const handleDebouncedSearch = useCallback(debounce((value, setItems) => handleSearchSfInputFields(value, setItems), 500), []);  

  const handleSearchSfInputFields = (value, callback) => {
    setLoading(true);
    setShowList(true);
    searchSfInputFields(value).then(fields => {
      if(fields) {
        callback(fields);
      }
      setLoading(false);
    });
  };
  
  const updateQuery = async e => {
    handleDebouncedSearch(e?.target?.value, setItems);
  };

  const handleSelectField = item => {
    setShowList(false);
    props.change('salesforceFieldId', item);
  };

  const hideDropdown = (e) => {
    saveSfFieldChoices(e.target.value);
    if(showList) {
      setShowList(false);
    }
  };

  const accessBlockedMessage = () => {
    if(!authenticated) {
      return messageIdConstants.sfNotLinked;
    }
    return null;
  }

  return (
    <div>
      <PlanBlocked mt="26px" plan="premium" messageId={accessBlockedMessage()}>
        <Row mt="15px">
          <Col xs>
            <Label>
              <FormattedMessage {...messages.salesforceFieldTitle} />
            </Label>
            <ImageIcon src={salesforce_icon} />
          </Col>
        </Row>
        <Row mt="15px">
          <Col xs>
            <Field
              id="salesforceFieldId"
              name="salesforceFieldId"
              component={Input}
              placeholder={null}
              onChange={(e) => updateQuery(e)}
              isSearchable={false}
              onBlur={(e) => hideDropdown(e)}
            />
          </Col>
        </Row>
        {showList && <Row>
          <Col xs>
            <InputList>
              {loading && <LoaderAnchor>
                <Loader />
              </LoaderAnchor>}
              {!loading && (items || []).map((item, index) => {
                return (
                  <ListItem key={index}>
                    <Wrapper onMouseDown={() => handleSelectField(item)}>
                      {item}
                    </Wrapper>
                  </ListItem>
                )
              })}
            </InputList>
          </Col>
        </Row>}
      </PlanBlocked>
    </div>
  )
};

export default SalesforceField;