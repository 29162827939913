import styled from 'styled-components';
import {
  display,
  space,
  color,
  width,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  style,
} from 'styled-system';

const wordBreak = style({
  prop: 'wordBreak',
  cssProperty: 'wordBreak',
  key: 'wordBreak',
});

const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'textDecoration',
  key: 'textDecoration',
});

const cursor = style({
  prop: 'cursor',
  cssProperty: 'cursor',
  key: 'cursor',
});

const Text = styled.div`
  ${display}
  ${space}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${wordBreak}
  ${textDecoration}
  ${cursor}
`
Text.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...wordBreak.propTypes,
  ...textDecoration.propTypes,
  ...cursor.propTypes,
};

export default Text;
