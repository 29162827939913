import { templatesEnabledAdminValidation } from "utils/userUtils";

import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";

export const homeRoutes = [
  {
    component: Dashboard,
    path: "/",
    exact: true,
    feature: "dashboard",
    type: "private",
    gauid: 'Dashboard',
  },
  {
    component: Home,
    path: "/:settings(templates|booking_calendars)/:uid/edit",
    exact: true,
    feature: "一般",
    type: "private",
    gauid: 'Bp/General',
    validation: templatesEnabledAdminValidation,
  },
];
