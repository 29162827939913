import React, { useEffect } from "react";
import { compose } from "redux";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from "reselect";
import { default as qS } from "query-string";
import { 
  Row,
  Col,
  Button,
  Label,
  Loader
} from "@d-lighted/design-system";
import styled from "styled-components";
import { Link } from "../../components/Link";
import { OnBoardingDashboard } from "../../../../components/layouts/OnBoardingDashboard";
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../../../constants/breakpoint";
import ZoomIcon from "../../../../assets/img/zoom.svg";
import WebexIcon from "../../../../assets/img/ico_webex.svg";
import { getCurrentUser } from "../../../../utils/userUtils";
import { leaveOnboarding, toOnboardingPage, getOnboardingState, setFirstCalendarSetting } from "../../utils/utils";
import { useOnboarding } from "../../hooks/useOnboarding";
import { Center } from "../../components/Center";
import { pushToGA, events } from "../../../../utils/gtmUtils";

const DesktopOnlyBR = styled.br`
  @media (max-width: ${MOBILEBREAKPOINT}){
    display: none;
  }
`;

const SocialButton = styled(Button)`
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D3D9E5;
  border-radius: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  height: 43px;
  justify-content: center;
  line-height: 22px;
  padding: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const SocialImage = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 20px;
`;

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 62px;

  @media (max-width: ${MOBILEBREAKPOINT}){
    padding-top: 30px;
    flex-direction: column-reverse;
  }
`;

const Wrapper = styled.div`
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MOBILEBREAKPOINT}){
    justify-content: flex-start;
  }
`;

const formSelector = state => getFormValues('MeetingToolIntegration')(state);

const MeetingToolIntegration = (props) => {
  const { intl, handleSubmit, useNotify } = props;
  const notify = useNotify(intl);
  const { actions, onBoardingCalendarSetting, isSettingUpdated, hasUpdateError, isLoading } = useOnboarding(notify);
  const onboarding = getOnboardingState();
  const webConfType = onBoardingCalendarSetting?.data?.webConferenceType;
  const capitalizedWebConfType = webConfType?.charAt(0)?.toUpperCase() + webConfType?.slice(1);
  const params = qS.parse(window.location.search);

  const isMobileView = SHOW_MOBILE(window.innerWidth);


  const resetUrl = () => {
    Object.keys(params).forEach(key => delete params[key]);
    props.history.replace({ search: new URLSearchParams(params).toString() });
  }

  const formattedBody = (scheduler) => {
    let body = {
      onboarding: {
        web_conference_type: scheduler,
        oauth_code: params?.code,
        redirect_path: "/onboarding/employee/integrations"
      }
    }
    return body;
  };

  useEffect(() => {
    if(!onboarding?.allowed || onboarding?.page !== "employee_integrations") return leaveOnboarding(props);
    if(!getCurrentUser()?.company) return toOnboardingPage(props, "sign_in");
    actions.getInviatedCalendarSetting();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const state = params?.state;
    if(state === "webex" || state === "zoom") {
      if(state?.error) {
        const capitalizedState = state.charAt(0).toUpperCase() + state.slice(1);
        notify.setError("ミーティングツールの連携エラーが発生しました。", `${capitalizedState}のトークンがありません。お手数ですが、再度連携後、もう一度お試しください。`);
      }
      if(params?.code) {
        const body = formattedBody(state);
        actions.updateOnboardingCalendar(body);
      }
      return resetUrl();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [window?.location]);

  useEffect(() => {
    if(isSettingUpdated) {
      if(!hasUpdateError) {
        return toOnboardingPage(props, "employee_booking_page");
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isSettingUpdated]);

  const onSubmit = async() => {
    setFirstCalendarSetting(onBoardingCalendarSetting);
    if(["webex", "zoom"].includes(webConfType)) {
      return actions.startOAuth(webConfType, "/onboarding/employee/integrations");
    }
  }

  const selectedWebConfIcon = () => {
    switch(webConfType) {
      case "webex":
        return WebexIcon;
      case "zoom":
        return ZoomIcon;
      default:
        return ZoomIcon;;
    }
  }

  if(isLoading) {
    return(
      <Center>
        <Loader/>
      </Center>
    )
  }

  const handleLeaveOnboarding = () => {
    pushToGA({...events.leave_onboarding("Ob/employee/integrations")});
    leaveOnboarding(props)
  };

  return (
    <OnBoardingDashboard top="0">
      <Wrapper>
        <Row mt={isMobileView ? "30px" : "0"}>
          <Col xs>
            <Label
              fontSize="24px"
              fontWeight="700"
              letterSpacing="1px"
              color="#484F63"
            >
              <p>
                次は、ご利用のWeb会議ツールと連携しましょう。
              </p>
            </Label>
          </Col>
        </Row>
        <Row mt="12px">
          <Col xs>
            <Label 
              fontSize="15px"
              fontWeight="400"
            >
              <p>
                予約ページからアポが確定した際<DesktopOnlyBR />
                選択されたWeb会議ツール用のWeb会議URLが自動で発行されます。
              </p>
            </Label>
          </Col>
        </Row>
        <Row 
          mt="12px"
        >
          <Col xs>
            <SocialWrapper>
              <Link onClick={() => handleLeaveOnboarding()}>
                後で
              </Link>
              <SocialButton
                fontWeight="500"
                width={isMobileView ? "100%" : "311px"}
                onClick={handleSubmit((values) => onSubmit(values))}
              >
                <SocialImage src={selectedWebConfIcon()} />
                {capitalizedWebConfType}アカウントと連携
              </SocialButton>
            </SocialWrapper>
          </Col>
        </Row>
      </Wrapper>
    </OnBoardingDashboard>
  );
}

const mapStateToProps = createStructuredSelector({
  formValues: formSelector,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'MeetingToolIntegration',
    initialValues: {
      onBoardingCalendarSetting: "",
    },
  }),
  injectIntl,
)(MeetingToolIntegration);
