const colors = {
  green: "#00bbb5",
  lightGreen: "rgba(0, 187, 181, 0.3)",
  paleGreen: "#e6f4ef",
  grayThin: '#9bb0b5',
  paleGreen2: "#62908F",
  deepGreen: "#008581",
  shadowGreen: "#2ce1dc",
  linkBlue: "#0000EE",
  red: "#e84c3d",
  pink: "#f76783",
  paleRed: "#fff7f6",
  paleRed2: "#C48685",
  white: "#ffffff",
  textDark: "#484f63",
  purple: "#705bcf",
  darkCyanText: "#142c2b",
  premiumPurpleBg: "#6e52ff1a",
  premiumPurpleFont: "#6e52ff",
  enterpriseBlueBg: "#57c2e91a",
  enterpriseBlueFont: "#57c2e9",
  shadow: {
    green: "#2ce1dc",
  },
  background: {
    gray: "rgb(55, 58, 71)",
    expand: "rgba(247, 249, 249, 0.5)",
    page: "#fafbfc",
    field: "#efefef",
  },
  gray: {
    light1: "#9bb0b5",
    paleBlue: "#8ca0b3",
    bodySub: "#e7e9ea",
    body: "#484e62",
    body2: "#939393",
    head: "#2d3341",
    cool: "#9bb0b5",
    label: "#67878d",
    label2: "#68878D",
    disabled: "#737373",
    heavy: "#323232",
    bg: "#f7f9f9",
    bg2: "#ACACAC",
    bg3: "#314143",
    // border: "#D8D8D8",
    border2: "#e9eff4",
    border3: "#d2e9e7",
    deep: "#25262a",
    asset: "#9BB0B5",
    itemText: "#464C62",
    itemText2: "#474E63",
    shadow: "#D2E0E9",
    border: "#e5e5e5",
  },
  blue: {
    border: "#4184F3",
    link: "#0000EE",
  },
};

// numbers are converted to px
const space = [0, 4, 8, 16, 32, 45, 64, 128, 256, 512];

// typographic scale
const fontSizes = [13, 14, 15, 16, 18, 20, 26];

// border-radius
const radii = [3, 5, 6];

const borders = [0, "1px solid", "2px solid"];

const shadows = [`0 1px 2px 0 ${colors.text}`, `0 1px 4px 0 ${colors.text}`];

const fontWeights = {
  thin: 100,
  normal: 300,
  bolder: 500,
  bold: 700,
};

const letterSpacings = {
  normal: "normal",
  caps: "0.25em",
};

const flexboxgrid = {
  gridSize: 12, // columns
  // gutterWidth: 0, // rem
  // outerMargin: 0, // rem
  mediaQuery: "only screen",
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 76, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 64, // em
    lg: 75, // em
  },
};

const theme = {
  colors,
  space,
  fontSizes,
  radii,
  borders,
  shadows,
  fontWeights,
  letterSpacings,
  flexboxgrid,
};

export default theme;
