import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconNew as Icon } from '@d-lighted/design-system';
import { events, pushToGA } from "../../utils/gtmUtils";
import { generateHelpUrl } from "../../utils/userUtils";
import { MOBILEBREAKPOINT } from '../../constants/breakpoint';
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  display: inline-grid;
  position: relative;
  left: 8px;
`;

const IconWrapper = styled.div`
  display: inline-block;
  box-sizing: border-box;
  
  &:hover + .display-text {
    visibility: visible;
    opacity: 1;
  }
`;

const Anchor = styled.div`
    visibility: hidden;
    content: '';
    transition: 0.2s;
    position: initial;
    z-index: 2;

    &:hover {
      transition: 0s;
      visibility: visible;
      opacity: 1;
    }
`;

export const DisplayText = styled.div`
  background-color: white;
  border: 1px solid #e5e5e5;
  color: #314143;
  max-width: ${props => props.width}px;
  width: max-content;
  left: 20px;
  box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.4);
  padding: 12px;
  border-radius: 6px;
  position: absolute;
  font-size: 12px;
  z-index: 2;
  top: -10px;
  font-weight: 200;

  @media (max-width: ${MOBILEBREAKPOINT}){
    top: 22px;
    left : ${props => props.windowWidth <= parseInt(MOBILEBREAKPOINT,10) && props.left ? `${-props.left+15}px` : 'inherit' };
    max-width : calc(100vw - 55px);
    width : calc(100vw - 55px);
  }
`;

const TextHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
`;

const TextBody = styled.div``; // This component is just for readability

const HelpLink = styled.span`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 12px;
  float: right;
  color: #02BBB5;
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
  
  & a {
    color: inherit;
    text-decoration: inherit;
  }
`;

const LinkIcon = styled(Icon)`
  color: inherit;
  font-size: 14px;
  margin-right: 4px;
  top: 6px;
  position: relative;
`;

let startTime, endTime;
let anchorDisplayed = false;

function hoverDuration() {
  endTime = new Date();
  let timeDiff = endTime - startTime;
  // strip the ms
  timeDiff /= 1000;
  return Math.round(timeDiff % 60);
}

function handleMouseEnter() {
  startTime = new Date();
}

function handleAnchorMouseLeave(onHover) {
  if(onHover) {
    anchorDisplayed = false;
    // duration in seconds
    const duration = hoverDuration();
    if(duration > 1) {
      onHover(duration);
    }
  }
}

function handleIconMouseLeave(onHover) {
  if(onHover) {
    setTimeout(() => { 
      if(!anchorDisplayed) {
        const duration = hoverDuration();
        if(duration > 1) {
          return onHover(duration);
        }
      }
      else {
        anchorDisplayed = false;
      }
    }, 250);
  }
}

function Tooltip({ message, subMessage, width, header, helpPath, onHover}) {
  const ref = useRef();
  const helpUrl = generateHelpUrl();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowSize = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(()=>{
    window.addEventListener('resize', updateWindowSize);
    return ()=>{
      window.removeEventListener('resize',updateWindowSize);
    }
  },[]);
  
  return (
    <Wrapper ref={ref}>
      <IconWrapper>
        <Icon
          className="material-icons"
          fontSize="18px"
          mt="3px"
          color="#68878d"
          isCursor={true}
          onMouseEnter={() => { handleMouseEnter() }} 
          onMouseLeave={() => { handleIconMouseLeave(onHover) }}
        >
          help
        </Icon>
      </IconWrapper>
      <Anchor className="display-text">
        <DisplayText 
          width={width}
          left={windowWidth <= parseInt(MOBILEBREAKPOINT,10) ? ref?.current?.offsetLeft :null}
          windowWidth={windowWidth}
          onMouseEnter={() => { anchorDisplayed = true }}
          onMouseLeave={() => { handleAnchorMouseLeave(onHover)}}
        >
          {header && (
            <TextHeader>
              {header}
            </TextHeader>
          )}
          <TextBody>
            {message}
            {subMessage && (

              <span>
              <br />
                {subMessage}
            </span>
            )}
          </TextBody>
          {helpPath && (
            <HelpLink>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${helpUrl}${helpPath}`}
                onClick={() =>
                  pushToGA({
                    ...events.click_link_help(`/${helpPath}`),
                  })
                }
              >
                <LinkIcon className="material-icons" >help_outline</LinkIcon>
                <FormattedMessage id="components.tooltip.moreDetails"　defaultMessage="More details" />
              </a>
            </HelpLink>
          )}
        </DisplayText>
      </Anchor>
    </Wrapper>
  );
}

Tooltip.defaultProps = {
  message: '', 
  subMessage: '',
  width: 340,
  helpPath: null,
  header: null,
  onHover: () => {},
};

Tooltip.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  helpPath: PropTypes.string,
  onHover: PropTypes.func,
  width: PropTypes.number,
}

export default Tooltip;
