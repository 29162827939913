import React from 'react';
import styled from 'styled-components';
import { Loader } from '@d-lighted/design-system';
import { MOBILEBREAKPOINT } from '../../../../constants/breakpoint';


const Wrapper = styled.div`
  position: absolute;
  display: block;
  padding: 24px 20px 14px 20px;
  width: 400px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.4);
  overflow: hidden;
  z-index: 3;
  margin: -54px -18px;
  @media (max-width: ${MOBILEBREAKPOINT}){
    width: calc(100% - 12%);
    margin-left: -6px;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const NoItemsMessage = styled.span`
  color: #484e62;
  font-size: 12px;
  font-weight: 100;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  color: #484e62;
`;

const MemberList = styled.div`
  display: block;
  width: 100%;
  overflow-y: scroll;
  z-index: 100;
  padding-top: 20px;
  list-style: none;
  max-height: 220px;
`;

const Anchor = styled.a`
  font-size: 12px;
  font-weight: 300;
  color: #00bbb5;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const ListItem = styled.div`
  color: #484e62;
  font-size: 14px;
  font-weight: 200;
  height: 52px;
`;

const LoaderAnchor = styled.div`
  height: 56px;
  width: 56px;
  margin: 0 auto;

  &> * {
    height: 56px;
    width: 56px;
    margin-top: -22px;
  }
`;

const SelectionDropdown = (props) => {
  const {
    toggle,
    loading,
    handleAdd,
    headerText,
    closeText,
    items
  } = props;
  const comparator = props.comparator || (() => false);
  const RecordComponent = props.recordComponent;

  const handleClick = (employee) => {
    handleAdd(employee)
  };

  return (
    <Wrapper>
      <Header>
        {headerText}
        <Anchor onClick={toggle}>
          {closeText}
        </Anchor>
      </Header>
      <MemberList>
        {loading && <LoaderAnchor>
          <Loader />
        </LoaderAnchor>}
        {!loading && (items || []).map((item, index) => {
          const selected = comparator(item);
          return (
            <ListItem key={index}>
              { RecordComponent ? <RecordComponent item={item} selected={selected} handleClick={handleClick} /> : item.name }
            </ListItem>
          )
        })
        }
        {!loading && !(items || []).length && <NoItemsMessage>{props.noItemsMessage}</NoItemsMessage>}
      </MemberList>
    </Wrapper>
  )
};

export default SelectionDropdown;