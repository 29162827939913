import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import { useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateExternalMultiguestCalendarSetting } from 'redux/reducers/externalMultiguestCalendarReducer';

import {
  Label,
  Col,
  Row,
  Button,
  RadioButton,
  Loader,
  Checkbox,
} from "@d-lighted/design-system";
import { PrimaryLightBackground } from "../../../components/utils/Helper";
import StyledRow from "../modules/StyledRow";
import BackButton from "../modules/BackButton";
import Title from "../modules/Title";
import messages from "../i18n/MultiGuest";
import { useMultiguestCalendar } from '../hooks/useMultiguestCalendar';
import { Center } from '../../../components/preview/Helper';
import Tooltip from '../../../components/elements/Tooltip';

const Container = styled.div`
  margin: 41px;
  padding: 10px 0 30px;
  border-radius: 6px;
  opacity: 1;
`;

const CancelButton = styled.button`
  color: white;
  height: 50px;
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  padding: 10px 0px 12px;
  background: #9BB0B5;
  &:hover {
    background: #748285;
  }
  &:disabled {
    cursor:no-drop;
    opacity:0.4;
  }
`;

function Settings(props) {
  const { useNotify, intl, history, submitting, pristine, initialize} = props
  const params = useParams();
  const notify = useNotify(intl);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const  { data } = useSelector((state) => state.calendarSetting);

  const { updateMultiguestCalendarSettings, getMultiguestCalendarSettings } = useMultiguestCalendar(notify)

  const fetchMultiguestCalendarSettings = () => {
    setLoading(true)
    getMultiguestCalendarSettings(params.multiguest_calendar_setting_uid).then(response => {
      initialize(response)
      dispatch(updateExternalMultiguestCalendarSetting(response))
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchMultiguestCalendarSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params]);

  const onSubmit = (values) => {
    setLoading(true)
    updateMultiguestCalendarSettings(values).then(response => {
      dispatch(updateExternalMultiguestCalendarSetting(response))
      setLoading(false)
    })
  }

  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <PrimaryLightBackground>
      <StyledRow onClick={() => { history.goBack() }}>
        <BackButton />
      </StyledRow>
      <StyledRow>
        <Title title="複数人調整設定: 25年度-新卒採用面接" />
      </StyledRow>
      <Container>
        <Row bg="white" pl="35px" pt="25px" borderRadius="10px">
          <Col xs>
            <Row mb="14px">
              <Col xs>
                <Label>
                  <FormattedMessage {...messages.hideAttendeesInformation} />
                  <Tooltip
                    message={intl.formatMessage(
                      { ...messages.durationsTooltip },
                      {
                        br: <br />,
                        a: (chunks) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://scheduling.help.receptionist.jp/how-to-create-pages/#time2"
                          >
                            {chunks}
                          </a>
                        ),
                      }
                    )}
                    width="auto"
                    top={2}
                    left={400}
                  />
                </Label>
              </Col>
            </Row>
            <Row mb="10px" width="400px">
              <Col xs>
                <Field
                  id="hideAttendeesInfoOnBookingPage"
                  name="hideAttendeesInfoOnBookingPage"
                  trueFor={true}
                  component={RadioButton}
                >
                  <FormattedMessage {...messages.privateAttendeesInformation} />
                </Field>
              </Col>
            </Row>
            <Row mb="25px" width="400px">
              <Col xs>
                <Field
                  id="hideAttendeesInfoOnBookingPage"
                  name="hideAttendeesInfoOnBookingPage"
                  trueFor={false}
                  component={RadioButton}
                >
                  <FormattedMessage {...messages.publicAttendeesInformation} />
                </Field>
              </Col>
            </Row>
            {(data?.meetingType === 'web_conference' && data?.webConferenceType === 'zoom') &&
              (<>
              <Row mb="14px">
                <Col xs>
                  <Label>
                    その地
                  </Label>
                </Col>
              </Row>
              <Row mb="14px" width="400px">
                <Col xs>
                  <Field
                    id="enableExternalAttendees"
                    name="enableExternalAttendees"
                    useArrayValue={false}
                    component={Checkbox}
                  >
                    <FormattedMessage {...messages.enableExternalMembersToAttend} />
                  </Field>
                </Col>
              </Row>
              <Row  mb="25px">
                <Col xs>
                  <Label>
                  {intl.formatMessage(
                      { ...messages.zoomIntegrationWarning },
                      {
                        a: (chunks) => (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://scheduling.help.receptionist.jp/integration-with-zoom/#gsc.tab=0"
                          >
                            {chunks}
                          </a>
                        ),
                      }
                    )}
                  </Label>
                </Col>
              </Row>
              </>
              )
            }
            <Row mb="20px" mr="10px">
              <Col xs>
                <CancelButton
                  width="100%"
                  bg="grey"
                  disabled={pristine || submitting}
                  onClick={props.handleSubmit((values) => onSubmit(values))}
                >
                  キャンセル
                </CancelButton>
              </Col>
              <Col xs>
                <Button
                  width="100%"
                  disabled={pristine || submitting}
                  onClick={props.handleSubmit((values) => onSubmit(values))}
                >
                  設定を保存
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </PrimaryLightBackground>
  );
};

export default reduxForm({
  form: "MultiGuestCalendarSettings"
})(injectIntl(Settings));
