import { defineMessages } from 'react-intl';

const Layouts = defineMessages({
  general: {
    id: "components.layouts.general",
    defaultMessage: "General",
  },
  meetings: {
    id: "components.layouts.meetings",
    defaultMessage: "Meetings",
  },
  dateAndTimeSetting: {
    id: "components.layouts.dateAndTimeSetting",
    defaultMessage: "Date and time setting",
  },
  businessDateAndTime: {
    id: "components.layouts.businessDateAndTime",
    defaultMessage: "Business Time",
  },
  advancedDateAndTimeSettings: {
    id: "components.layouts.advancedDateAndTimeSettings",
    defaultMessage: "Advanced Settings",
  },
  forms: {
    id: "components.layouts.forms",
    defaultMessage: "Booking Forms",
  },
  formsSettings: {
    id: "components.layouts.formsSettings",
    defaultMessage: "Forms Settings",
  },
  completionMessage: {
    id: "components.layouts.completionMessage",
    defaultMessage: "Completion Message",
  },
  notifications: {
    id: "components.layouts.notifications",
    defaultMessage: "Notifications",
  },
  chatNotifications: {
    id: 'components.layouts.chatNotifications',
    defaultMessage: 'Chat Notifications',
  },
  emailNotifications: {
    id: 'components.layouts.emailNotifications',
    defaultMessage: 'Email Notifications',
  },
  integrations: {
    id: "components.layouts.integrations",
    defaultMessage: "External Integrations",
  },
  integration_salesforce: {
    id: "components.layouts.integrations.salesforce",
    defaultMessage: "Salesforce",
  },
  appointmentList:{
    id: "views.home.modules.calendar.listAppointments",
    defaultMessage: "Appointments",
  },
  admissionPeriod: {
    id: "views.availability.pages.availability.admissionPeriod",
    defaultMessage: 'Admission Period',
  },
});

export default Layouts;