import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'views.home.pages.home.title',
    defaultMessage: 'Booking page title',
  },
  titlePlaceholder: {
    id: 'views.home.pages.home.title.placeholder',
    defaultMessage: 'Example: Booking page of  RECEPTIONIST Inc. with ●●'
  },
  link: {
    id: 'views.home.pages.home.link',
    defaultMessage: 'Booking page link',
  },
  description: {
    id: 'views.home.pages.home.description',
    defaultMessage: 'Description',
  },
  descriptionPlaceholder: {
    id: 'views.home.pages.home.description.placeholder',
    defaultMessage: 'Example: Please apply for ●● from the available date and time.'
  },
  embeddedCode: {
    id: 'views.home.pages.home.embeddedCode',
    defaultMessage: 'Embedded Code',
  },
  linkPlaceholder: {
    id: 'views.home.pages.home.link.placeholder',
    defaultMessage: 'booking_receptionist',
  },
  copyCode: {
    id: 'views.home.pages.home.copyCode',
    defaultMessage: 'Copy Code',
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  delete: {
    id: 'views.home.modules.home.delete',
    defaultMessage: 'Delete this booking page'
  },
  deleteConfirm: {
    id: 'views.home.modules.home.deleteConfirm',
    defaultMessage: 'Are you sure you want to delete it?'
  },
  instructionMessageTitle: {
    id: 'views.home.modules.home.instructionMessage.title',
    defaultMessage: 'Instruction message',
  },
  instructionMessagePlaceholder: {
    id: 'views.home.modules.home.instructionMessage.placeholder',
    defaultMessage: 'Instruction message {予約ページURL}',
  },
  instructionMessageTooltip: {
    id: 'views.home.modules.home.instructionMessage.tooltip',
    defaultMessage: "When you copy the URL of a reservation page, this is the information that can be copied along with it.{br} When copying the URL, you can choose to include or exclude the guidance text.{br} ※Please enter {予約ページURL} with parentheses in the place where you want the URL to appear.",
  },
  titleForIrregular: {
    id: 'views.home.pages.home.titleForIrregular',
    default: 'Registration page title',
  },
  linkForIrregular: {
    id: 'views.home.pages.home.linkForIrregular',
    default: 'Registration page link',
  },
  templateTitle: {
    id: 'views.home.pages.home.templateTitle',
    defaultMessage: "Booking page template title",
  },
  icon: {
    id: 'views.home.pages.home.icon',
    defaultMessage: "Booking page icon",
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
