import styled from "styled-components";
import { Grid as BaseGrid, Row as BaseRow, Col as BaseCol } from "react-styled-flexboxgrid";
import { borders, color, layout, margin, padding } from "styled-system";
import { MOBILEBREAKPOINT } from "../../constants/breakpoint";

export const PrimaryLightBackground = styled.div`
  background-color: #f7f9f9;
  display: table;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  @media (max-width: ${MOBILEBREAKPOINT}){
    overflow: hidden;
    display: block;
  }
`;

export const PrimaryWhiteBackground = styled.div`
  background-color: #fff;
  display: table;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

export const Row = styled(BaseRow)`
  ${margin}
  ${padding}
`;

export const Col = styled(BaseCol)`
  ${margin}
  ${padding}
`;

export const Grid = styled(BaseGrid)`
  ${margin}
  ${padding}
`;

export const Hr = styled.hr`
  ${borders}
  ${color}
  ${margin}
  ${layout}
`;