import { defineMessages } from 'react-intl';

export default defineMessages({
  subject: {
    id: 'components.layouts.emailNotifications.emailSubject',
    defaultMessage: 'Email subject',
  },
  subjectPlaceholder: {
    id: 'components.layouts.emailNotifications.completeEmail.subjectPlaceholder',
    defaultMessage: 'An email subject should contain {自社の会社名}',
  },
  leadText: {
    id: 'components.layouts.emailNotifications.leadText',
    defaultMessage: 'Leading text',
  },
  leadTextPlaceholder: {
    id: 'components.layouts.emailNotifications.leadTextPlaceholder',
    defaultMessage: 'A large text that serves as leading text',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  enterprise:{
    id: 'components.layouts.blocked.enterprise',
    defaultMessage: "You can use this feature by subscribing to enterprise or above plan.{br}Please <a>contact us</a> for details.",
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  variableText: {
    id: 'components.layouts.emailNotifications.commonEmail.variable',
    defaultMessage: 'Select email variable text',
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
})
