import React from "react";
import styled from "styled-components";
import { Heading } from "@d-lighted/design-system";

import { Div } from "../../../../components/preview/Helper";
import { moment } from "../../../../components/preview/momentUtils";
import DropdownContent from "./Dropdown"
import { default as BaseButton } from "../../../../components/forms/Button";

const Dropdown = styled.div`
  display:none;
  box-sizing: border-box;
  background-color: #fff;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0;
  transition: opacity 0.3s;
  width: 251px;
  padding: 22px 16px 8px 16px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 5%);
  border: 1px solid #e5e5e5;
  &::before{
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #e5e5e5;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
  }
`;

const ButtonDiv = styled(Div)`
  position: relative;
  display: inline-block;
  ${props => !props.isDisabled && `
    &:hover ${Dropdown} {
      display: block;
      opacity: 1;
      width: 100%;
    }
  `}
`;

const Button = styled(BaseButton)`
  padding: 0;
  ${props => props.outlined && `
    background-color: white;
    color: ${props.outlinedColor || "#00bbb5"};
    border: 1px solid ${props.outlinedColor || "#00bbb5"};
    &:hover {
      background-color: #E6F4EF;
    }
  `};
  ${props => !props.isCustomerAvailable && `
    background-color: #6e52ff;
    color: white;
    &:hover {
      background-color: #6e52ff;
    }
  `}
`;

const ButtonLabel = styled.div`
  margin-top: ${props => props.showPerson ? "-7px" : "0px"};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: -5px;
`;

const PlanSpan = styled.span`
  font-size: 11px;
  margin-right: 10px;
`;

const PersonSpan = styled.span`
  font-size: 11px;
  font-weight: bold;
  padding: 0px 10px;
  border-radius: 4px;
  background-color: #EAE6FE;
  color: #6e52ff;
  ${props => props.disabled && `
    color: rgba(20,44,43,0.2) !important;
  `}
`;

export function SingleDate({ day, date, isDateEarlier, getJapaneseDay }) {
  return (
    <Div
      display="block"
      alignItems="baseline"
      textAlign="center"
      width="100%"
    >
      <Div width="100%">
        <Heading size={1} color={`${(isDateEarlier(day, moment()) === "earlier" && "rgba(20, 44, 43, 0.2)") || (isDateEarlier(day, moment()) === "equal" && "#00bbb5")}`} >{date}</Heading>
      </Div>
      <Div ml="0px" mb="10px" width="100%">
        <Heading size={5} color={`${(isDateEarlier(day, moment()) === "earlier" && "rgba(20, 44, 43, 0.2)") || (isDateEarlier(day, moment()) === "equal" && "#00bbb5")}`}>{getJapaneseDay(day.day())}</Heading>
      </Div>
    </Div>
  );
}

export const TimeSlots = ({
  hoursOfTheDay,
  timeSlots,
  day,
  duration,
  handleChange,
  busySlots,
  dropdownOnConfirm,
  multiguestCalendarDetail,
}) => {
  const schedulableFrom = moment(
    multiguestCalendarDetail.schedulableFrom,
    'YYYY-MM-DD',
  );
  const schedulableUntil = moment(
    multiguestCalendarDetail.schedulableUntil,
    'YYYY-MM-DD',
  );

  function guestSlot(slot, time) {
    const result = {
      selectedGuests: [],
      availableHosts: []
    };
    result.isSchedulable = slot.isBetween(
      schedulableFrom,
      schedulableUntil,
      '',
      '[]',
    );

    (multiguestCalendarDetail.guests || []).map(guest => {
      return guest.candidateSlots.map(candidateSlot => {
        if (
          time === moment(candidateSlot.startAt).tz('Asia/Tokyo').format('HH:mm') &&
          slot.isSame(moment(candidateSlot.startAt, 'YYYY-MM-DD').tz('Asia/Tokyo'))
        ) {
          result.isMultiguest = true;
          result.selectedGuests = result.selectedGuests.concat(guest);
        }
        return result;
      });
    });
    return result;
  }

  return (
    <Div
      noScrollbar
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
    >
      {hoursOfTheDay.map(currentTime => {
        const { isMultiguest, selectedGuests, isSchedulable } = guestSlot(
          day,
          currentTime,
        );

        const currentStart = moment(`${day.format('L')} ${currentTime}`, 'L LT');
        const currentEnd = currentStart.clone().add(duration, 'minutes');
        const endTime = currentEnd.format('HH:mm');

        const isPastDay = day.isBefore(moment().format('YYYY-MM-DD'));
        const showPerson = selectedGuests.length > 0;
        const disabled = isPastDay || !isSchedulable || selectedGuests.length === 0;

        const overlaySlot = !timeSlots.includes(currentTime)

        return (
          <ButtonDiv key={currentTime} margin="5px 0px" isDisabled={disabled}>
            <Button
              outlined
              invertOnHover
              isCustomerAvailable={overlaySlot}
              outlinedColor="#6e52ff"
              disabled={disabled}
              fontSize="20px"
              borderRadius="3px"
              width="100%"
              minWidth="0"
              height="63px"
              boxShadow="0 1px 6px 0 #d2e9e7"
              onClick={e => handleChange(e, day, currentTime)}
              fontWeight="bold"
            >
              <div className="shown">{currentTime}</div>
              <div className="hidden">{endTime}</div>
              <ButtonLabel showPerson={showPerson}>
                {showPerson && !disabled && selectedGuests.length > 0 &&
                  <Wrapper>
                    {overlaySlot && <PlanSpan>予定あり</PlanSpan>}
                    <PersonSpan disabled={disabled}>
                    {isMultiguest ? `${selectedGuests.length}名` : ''}
                    </PersonSpan>
                  </Wrapper>
                }
              </ButtonLabel>
            </Button>
              <Dropdown>
                <DropdownContent
                  day={day}
                  currentTime={currentTime}
                  dropdownOnConfirm={dropdownOnConfirm}
                  guests={selectedGuests}
                  overlaySlot={overlaySlot}
                  currentStart={currentStart}
                  currentEnd={currentEnd}
                  attendees={multiguestCalendarDetail?.attendees}
                  mustAttendees={multiguestCalendarDetail?.mustAttendees}
                  busySlots={busySlots}
                  disabled={disabled}
                />
              </Dropdown>
          </ButtonDiv>
        );
      })}
    </Div>
  );
};
