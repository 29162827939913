import React, { useState } from 'react';
import { useHistory } from "react-router";
import styled, { keyframes } from 'styled-components';
import Lottie from "react-lottie";
import { Label } from '@d-lighted/design-system';
import { MOBILEBREAKPOINT } from '../../constants/breakpoint';
import { isMobileDevice } from '../../utils/mobileViewChecker';

const Wrapper = styled.div`
  position: ${props => props.position};
  max-width: 100%;
  max-height: 100%;
  margin-left: ${props => props.left};
  margin-top: ${props => props.top};
  -webkit-transform:scale(${props => props.scale ? props.scale : '1.0'});
  -moz-transform-scale(${props => props.scale ? props.scale : '1.0'});
  display: block;
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}){
    padding: 0 18px;
  }
`;

const ProgressBarWrapper = styled.div`
  display: block;
  text-align: center;
  @media (max-width: ${MOBILEBREAKPOINT}){
    margin-top: 40px;
  }
`;

const ProgressBarOne = styled.div`
  margin: 30px;
  height: 8px;
  max-width: 400px;
  background-color: #E6F4EF;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
`;

const ProgressMoving = keyframes`
  0% {
    width: 0%; 
    background: #00BBB5;
  }
  100% {
    width: 100%; 
    background: #00BBB5;
  } 
`;

const ProgressBarTwo = styled.div`
  height: 8px;
  border-radius: 30px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  animation: ${ProgressMoving} 3s infinite;
`;

const SubTextWrapper = styled.div`
  display: block;
`;

function ToolLottie({ height, width, left, top, duration, redirectUrl, lottie, headText, subText, scale, position }) {
  const isMobileView = isMobileDevice();
  const [stopped, setStopped] = useState(false);
  const history = useHistory();
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: true,
      hideOnTransparent: true
    }
  };

  setTimeout(() => {
    setStopped(true);
    if(redirectUrl) {
      history.push(redirectUrl);
    }
  }, duration);

  return (
    <Wrapper 
      top={top} 
      left={left}
      scale={scale}
      position={position}
    >
      <Lottie 
        options={defaultOptions}
        height={height}
        width={width}
        isStopped={stopped}
      />
      {headText && 
        <Label
          fontSize={ isMobileView ? "14px" : "28px"}
          fontWeight="600"
          letterSpacing="1px"
          color="#484F63"
        >
          {headText}
        </Label>
      }
      <SubTextWrapper>
        {subText &&
          <Label
            fontSize={ isMobileView ? "7px" : "14px"}
            fontWeight="400"
            letterSpacing="1px"
            color="#68878D"
          >
            {subText}
          </Label>
        }
      </SubTextWrapper>
      {headText &&
        <ProgressBarWrapper>
          <ProgressBarOne>
            <ProgressBarTwo />
          </ProgressBarOne>
        </ProgressBarWrapper>
      }
    </Wrapper>
  );
}

ToolLottie.defaultProps = {
  height: "100%", 
  width: "100%",
  left: 0,
  top: 0,
  duration: 3000,
  redirectUrl: null,
  scale: '1.0',
  position: 'absolute'
};

export default ToolLottie;
