import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { FormattedMessage } from 'react-intl';
import copy from 'copy-to-clipboard';
import { Label, Col, Row, InputWithRightAddon, Button, Modal as BaseModal } from "@d-lighted/design-system";
import messages from '../i18n/MultiGuest';

const Modal = styled(BaseModal)`
  width: auto;
  min-height: auto;
  height: auto;
  padding: 8px 10px 16px;
`;

const SuccessMessage = styled.div`
  width: 449px;
  padding: 16px 0 0 0;
  border-radius: 5px;
`;

const SuccessMessageLabel = styled.span `
  margin: 10px 0;
`;

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    color: #fff;
    border-radius: 6px;
    width: 85px;
    height: 25px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;

    transition: visibility 0s .375s, opacity .375s ease-in-out !important;

    &.show{
        transition: visibility 0s 0s, opacity .375s ease-in-out !important;
    }
  }
`;

function MultiguestUrlCreatedModal(props) {

  return (
    <Modal {...props}>
        <Col xs>
            <SuccessMessage>
              <Col xs>
                <Row mb="14px">
                  <Col xs>
                    <Label fontSize="22px">
                      {props.membersCanAttend ?
                        <FormattedMessage {...messages.multiguestUrlCreated} /> :
                        <FormattedMessage {...messages.externalMultiguestUrlCreated} />
                      }
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <SuccessMessageLabel>
                      <Label>
                        {props.membersCanAttend ?
                          <FormattedMessage {...messages.multiguestUrlInformation} /> :
                          <FormattedMessage {...messages.externalMultiguestUrlInformation} />
                        }
                      </Label>
                    </SuccessMessageLabel>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    {props.membersCanAttend &&
                      <>
                        <InputWithRightAddon
                          placeholder="https://booking.receptionist.jp"
                          value={props.multiguestUrl}
                          addonText="コピー"
                          data-for="multiguestUrlTooltip"
                          data-tip="コピーしました"
                          data-event="click"
                          data-event-off="click"
                          disabled
                          onClick={() => copy(props.multiguestUrl)}
                        />
                        <CustomReactTooltip
                          isCapture={true}
                          id="multiguestUrlTooltip"
                          delayHide={1000}
                          place="bottom"
                          effect="solid"
                          backgroundColor="#6e52ff"
                        />
                      </>
                    }
                    <Button mt="14px"
                      onClick={() => {props.returnToDashboard()}}
                    >
                      <FormattedMessage {...messages.returnToDashboard} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </SuccessMessage>
          </Col>
    </Modal>
  )
}

export default MultiguestUrlCreatedModal;
