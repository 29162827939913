import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import humps from 'lodash-humps';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Label, Button as BaseButton, Icon, Loader } from "@d-lighted/design-system";

import TableBodyItem from './TableBodyItem';
import appointmentPropTypes from './appointmentPropTypes';
import HostList from './HostList';
import VisitorList from './VisitorList';
import CustomerList from './CustomerList';
import AppointmentCode from './AppointmentCode';
import Text from './Text';
import { copy } from "../../../utils/copyUtils";
import IconDots from "../../../components/icons/IconDots";
import { getCustomFieldsValue, displayCustomFields } from "./utils/customFieldHelper"
import { useMultiguestCalendar } from '../../../views/multiguest/hooks/useMultiguestCalendar';
import Timeline from "components/elements/Timeline";

const ExpandButton = styled.div`
  display: inherit;
  float: right;
  z-index: 1;
  cursor: pointer;
  margin-left: 5px;
  padding-right: 40px;
`;

const Tbody = styled.tbody`
  position: relative;
`;

const Button = styled(BaseButton)`
  img {
    border-radius: 0;
  }
`;

const DeleteWrapper = styled.div`
  flex: 1 1 100%;
  min-height: 24px;
`;

const DeleteAnchor = styled.button`
  color: #e84c3d;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 10px;
  margin-top: 10px;
`;

const PaddedTD = styled.td`
  padding: 18px;
`;

const SubRowWrapper = styled.div`
  background-color: #f7f9f9;
  width: 100%;
  border-radius: 10px;
  min-height: 101px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    color: #fff;
    border-radius: 6px;
    width: 85px;
    height: 25px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;

    transition: visibility 0s .375s, opacity .375s ease-in-out !important;

    &.show{
        transition: visibility 0s 0s, opacity .375s ease-in-out !important;
    }
  }
`;

const DropDownContent = styled.div`
  display: ${(props) => (props.showDropdownOptions ? "block" : "none")};
  position: absolute;
  top: 40px;
  right: 20px;
  margin-top: 2px;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropDownItem = styled.a`
  color: #314143;
  padding: 12px 16px;
  margin: 5px;
  border-radius: 4px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: ${(props) => props.width};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const messages = defineMessages({
  copyButton: {
    id: 'components.layouts.table.appointmentCard.copyButton',
    defaultMessage: 'Copy URL',
  },
  deleteAppointment: {
    id: 'components.layouts.table.appointmentCard.deleteAppointment',
    defaultMessage: 'Delete appointment',
  },
  deleteMultiguestCalendar: {
    id: 'components.layouts.table.multiguest.deleteCalendar',
    defaultMessage: 'Delete calendar',
  },
});

function VisitorsCustomFields({ visitors, handleDeleteAppointment }) {
  let visitorCustomFields = [];
  let customFieldsObject = {};

  visitors.map(visitor => {
    customFieldsObject = getCustomFieldsValue(visitor);
    visitorCustomFields = displayCustomFields(customFieldsObject, visitor.uid);
    visitorCustomFields = visitorCustomFields.filter((item) => item != null);
    return visitorCustomFields;
  });

  return (
    <tr>
      <PaddedTD colSpan="100%">
        <SubRowWrapper>
          <DeleteWrapper>
            <DeleteAnchor onClick={() => handleDeleteAppointment()}>
              <FormattedMessage {...messages.deleteAppointment} />
            </DeleteAnchor>
          </DeleteWrapper>
          {(visitorCustomFields.length) > 0 && visitorCustomFields}
        </SubRowWrapper>
      </PaddedTD>
    </tr>
  );
}

function AppointmentTable({ appointment, handleDeleteAppointment, isIrregular }) {
  const [toggle, setToggle] = useState(false);
  const { appointmentTime, beginAt, endAt } = humps(appointment);
  const beginFrom = moment(appointmentTime, "YYYY.MM.DD hh:mm A");

  return (
    <Tbody>
      <tr>
        <AppointmentCode appointment={appointment} />
        <TableBodyItem>
          <Label color="#000">
            {isIrregular ? <>
              {moment(beginAt).format("MM/DD/YYYY")} ~
              <br/>
              {moment(endAt).format("MM/DD/YYYY")}
            </> : <>
              {beginFrom.format("M/DD")}
              <br/>
              {beginFrom.format("H:mm A")}
            </>}
          </Label>
        </TableBodyItem>
        <TableBodyItem>
          <Label color="#000">{appointment.title}</Label>
        </TableBodyItem>
        <VisitorList visitors={appointment.visitors} />
        <HostList hosts={appointment.hosts} />
        <TableBodyItem>
          <Label color="#000">{appointment.meeting_room}</Label>
        </TableBodyItem>
        <TableBodyItem>
          <ExpandButton id="expandButton" onClick={() => setToggle(!toggle)} className="expand-button">
            <IconDots />
          </ExpandButton>
        </TableBodyItem>
      </tr>
      {toggle && (
        <VisitorsCustomFields
          visitors={appointment.visitors}
          handleDeleteAppointment={handleDeleteAppointment}
        />
      )}
    </Tbody>
  )
}

function AppointmentVisitTable(props) {
  const { appointment, meetingType, handleDeleteAppointment, isIrregular } = props;
  const [toggle, setToggle] = useState(false);
  const { appointmentTime, beginAt, endAt } = humps(appointment);
  const beginFrom = moment(appointmentTime, "YYYY.MM.DD hh:mm A");
  const organizer = appointment?.visitors?.filter(visitor => visitor.organizer)?.[0]?.name;
  return (
    <Tbody>
      <tr>
        <TableBodyItem first>
          <Label color="#000">
            {isIrregular ? <>
              {moment(beginAt).format("MM/DD/YYYY")} ~
              <br/>
              {moment(endAt).format("MM/DD/YYYY")}
            </> : <>
              {beginFrom.format("M/DD")}
              <br/>
              {beginFrom.format("H:mm A")}
            </>}
          </Label>
        </TableBodyItem>
        <TableBodyItem>
          <Label color="#000">{appointment.title}</Label>
        </TableBodyItem>
        <VisitorList visitors={appointment.visitees} />
        <HostList hosts={appointment.visitors} />
        {meetingType === "web_conference" &&
          <>
            <TableBodyItem>
              <Button
                outlined
                height="42px"
                width="150px"
                fontSize="15px"
                data-for={`appointmentCard${appointment.uid}`}
                data-tip="コピーしました"
                data-event='click'
                data-event-off='click'
                onClick={() => copy(appointment.web_conference_visitee_url)}
              >
                <Icon text={<FormattedMessage {...messages.copyButton} />} />
              </Button>
              <CustomReactTooltip
                isCapture={true}
                id={`appointmentCard${appointment.uid}`}
                delayHide={1000}
                place="bottom"
                effect="solid"
                backgroundColor="#6e52ff"
              />
              <Text mt="11px">
                <Label color="#000">{`Web会議ホスト: ${organizer}`}</Label>
              </Text>
            </TableBodyItem>
          </>
        }
        <TableBodyItem>
          <Label color="#000">{appointment.place}</Label>
        </TableBodyItem>
        <TableBodyItem>
          <ExpandButton id="expandButton" onClick={() => setToggle(!toggle)} className="expand-button">
            <IconDots />
          </ExpandButton>
        </TableBodyItem>
      </tr>
      {
        toggle && <VisitorsCustomFields
          visitors={appointment.visitees}
          handleDeleteAppointment={handleDeleteAppointment}
        />
      }
    </Tbody >
  )
}

function AppointmentScheduling(props) {
  const { appointment, onClickConfirm, handleDeleteCalendar, useNotify, intl, bookingCalendarMembersCanAttend } = props;
  const notify = useNotify(intl);
  const { getMultiguestCalendarAvailableSlots } = useMultiguestCalendar(notify);
  const startAt = moment(appointment.schedulableFrom).format('MM/DD');
  const endAt = moment(appointment.schedulableUntil).format('MM/DD');
  const timeRange = `${startAt} ~ ${endAt}`;
  const [toggle, setToggle] = useState(false);
  const [availableSlots, setAvailableSlots] = useState(undefined);
  const elements = appointment.guests.map(guest => {
    return ({
      status: guest.status,
      email: guest.email
    })
  });

  window.onclick = function (event) {
    if (
      !event.target.matches("#expandButton") &&
      !event.target.matches("svg") &&
      !event.target.matches("path")
    ){
      setToggle(false)
    }
  }

  useEffect(() => {
    getMultiguestCalendarAvailableSlots(appointment.uid).then(response => {
      setAvailableSlots(response)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[appointment])

  return (
    <Tbody>
      <tr>
        <TableBodyItem first>
          <Label color="#000">{appointment.title}</Label>
        </TableBodyItem>
        <TableBodyItem>
          <Label color="#000">{appointment.duration}分</Label>
        </TableBodyItem>
        <TableBodyItem>
          <Label color="#000">{timeRange} </Label>
        </TableBodyItem>
        {
          !bookingCalendarMembersCanAttend && <Timeline elements={elements} direction="vertical" appointment={appointment} />
        }
        { bookingCalendarMembersCanAttend &&
          <>
            <CustomerList customers={appointment.guests} />
            <TableBodyItem>
            { availableSlots === undefined ? <Loader width="20" height="20" /> : <Text>{availableSlots}枠</Text> }
            </TableBodyItem>
            <TableBodyItem>
              <Button
                outlined
                mr="20px"
                height="42px"
                width="150px"
                fontSize="15px"
                data-for={`appointmentCard${appointment.uid}`}
                data-tip="コピーしました"
                data-event='click'
                data-event-off='click'
                onClick={() => copy(`${process.env.REACT_APP_BOOK_ME_ENDPOINT}/${appointment.calendarUrl}`)}
              >
                <Icon text={<FormattedMessage {...messages.copyButton} />} />
              </Button>
              <CustomReactTooltip
                isCapture={true}
                id={`appointmentCard${appointment.uid}`}
                delayHide={1000}
                place="bottom"
                effect="solid"
                backgroundColor="#6e52ff"
              />
              <Button
                height="42px"
                width="130px"
                fontSize="14px"
                disabled={appointment.guests.length === 0 || appointment.confirmed}
                onClick={() => onClickConfirm(appointment)}
              >
                日時を確定する
              </Button>
            </TableBodyItem>
            </>
        }
        <TableBodyItem>
          <ExpandButton id="expandButton" onClick={() => setToggle(!toggle)} className="expand-button">
            <IconDots />
          </ExpandButton>
          <DropDownContent showDropdownOptions={toggle}>
            <DropDownItem
              width="auto"
              onClick={() => handleDeleteCalendar(appointment.uid)}
            >
              <FormattedMessage {...messages.deleteMultiguestCalendar} />
            </DropDownItem>
          </DropDownContent>
        </TableBodyItem>
      </tr>
    </Tbody >
  )
}


export default class AppointmentCard extends React.Component {
  render() {
    const { appointment, meetingType, handleDeleteAppointment, isIrregular, isMultiGuest, useNotify, intl, bookingCalendarMembersCanAttend } = this.props;

    if (isMultiGuest) {
      return (
        <AppointmentScheduling
          appointment={appointment}
          onClickConfirm={this.props.onClickConfirm}
          handleDeleteCalendar={this.props.handleDeleteCalendar}
          useNotify={useNotify}
          intl={intl}
          bookingCalendarMembersCanAttend={bookingCalendarMembersCanAttend}
        />
      )
    }
    return (
      meetingType === "coming_to_visit" ?
        <AppointmentTable
          isIrregular={isIrregular}
          appointment={appointment}
          handleDeleteAppointment={handleDeleteAppointment}
        /> :
        <AppointmentVisitTable
          isIrregular={isIrregular}
          appointment={appointment}
          meetingType={meetingType}
          handleDeleteAppointment={handleDeleteAppointment}
        />
    );
  }
}

AppointmentCard.defaultProps = {
  isIrregular: false,
};

AppointmentCard.propTypes = {
  appointment: appointmentPropTypes.isRequired,
  isIrregular: PropTypes.bool,
};
