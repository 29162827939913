import styled from "styled-components";
import {
  Row,
} from "@d-lighted/design-system";

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  padding: 40px 0 0 50px;
`;

export default StyledRow;