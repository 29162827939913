/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from "styled-components";
import {
  Row,
  Col,
  Label,
  Button,
  Loader,
  DesktopBreakpoint,
  Input
} from "@d-lighted/design-system";

import { isTrialExpired } from "utils/userUtils";
import { remove } from "utils/api";
import { PrimaryLightBackground } from "components/utils/Helper";
import BackButton from "../../appointments/modules/BackButton";
import { MOBILEBREAKPOINT } from "constants/breakpoint";
import { useTemplates } from "../hooks/useTemplates";
import Calendar from "../modules/Calendar";
import messages from "../i18n/templates";
import { useValidation } from "utils/validation";

const Wrapper = styled.div`
  margin-top: ${props => props.mt ? props.mt : '0px'};
`;

const Container = styled.div`
  margin: 24px 40px;
  padding: 10px 0 30px;
  border-radius: 6px;
  opacity: 1;
  @media (max-width: ${MOBILEBREAKPOINT}){
    margin: 0px;
    padding: 0px;
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;

const Link = styled.a `
  color: #e95648;
  cursor: pointer;
  text-decoration: underline;
`;

const WhiteCol = styled(Col)`
  background-color: white;
  border-radius: 6px;
  padding: 24px;
`;

const Templates = (props) => {
  const { handleSubmit, intl, useNotify, pristine, submitting, initialize } = props;
  const notify = useNotify(intl);
  const { details, loading, saving, actions } = useTemplates(notify);
  const { required, positiveNumber } = useValidation(intl);
  
  useEffect(() => {
    if(details != null) {
      const { limitCalendars } = details;

      initialize({
        limit_calendars: limitCalendars,
      })
    }
  }, [details]);
  
  // TODO: This should be refactored in some hook in the future
  const onDeleteCalendar = async uid => {
    if (window.confirm(intl.formatMessage({...messages.deleteBookingpageConfirm}))) {
      let response = await remove(`/booking_calendars/${uid}`);
      if (response?.status !== 200) {
        notify.setError(messages.deleteCalendarError, response?.data?.message);
      } else {
        notify.setNotify(messages.deleteCalendarSuccess);
        actions.getTemplateDetail(true);
      }
    }
  };
  
  const onDeleteTemplate = () => {
    actions.deleteTemplate();
  };
  
  const onSubmit = values => {
    actions.updateTemplateDetail(values);
  };
  
  if (loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Wrapper mt={isTrialExpired() ? '40px' : '0px'}>
      <PrimaryLightBackground>
        <DesktopBreakpoint>
          <BackButton pageReload={true} />
          <Container>
            <Row mb="26px">
              <Col xs>
                <Label fontSize="20px" color="#142c2b">
                  {details?.title}
                </Label>
              </Col>
              <Col>
                <Link href onClick={() => onDeleteTemplate()}>
                  <FormattedMessage {...messages.deleteTemplate} />
                </Link>
              </Col>
            </Row>
            <Row mb="32px">
              <Col xs>
                <Row mb="16px">
                  <Col>
                    <Label>
                      <FormattedMessage {...messages.templateSettings} />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <WhiteCol xs>
                    <Row mb="6px">
                      <Col xw>
                        <Label>
                          <FormattedMessage {...messages.templatePageLimit} />
                        </Label>
                      </Col>
                    </Row>
                    <Row mb="16px" display="flex" alignItems="center">
                      <Col>
                        <Field
                          name="limit_calendars"
                          component={Input}
                          type="number"
                          min="1"
                          height="38px"
                          width="80px"
                          placeholder=""
                          validate={[required, positiveNumber]}
                        />
                      </Col>
                      <Col xs>
                        <Label>
                          <FormattedMessage {...messages.page} />
                        </Label>
                      </Col>
                    </Row>
                    <Row pt="5px">
                      <Col xw>
                        <Button 
                          minWidth="68px"
                          height="42px"
                          disabled={saving || pristine || submitting}
                          onClick={handleSubmit(values => onSubmit(values))}
                        >
                          <FormattedMessage {...messages.save} />
                        </Button>
                      </Col>
                    </Row>
                  </WhiteCol>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <Row mb="16px">
                  <Col>
                    <Label>
                      <FormattedMessage {...messages.templateList} />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    {details?.bookingCalendars?.map(calendar => (
                      <Calendar calendar={calendar} onDelete={onDeleteCalendar}/>
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </DesktopBreakpoint>
      </PrimaryLightBackground>
    </Wrapper>
  );
};

Templates.propTypes = {};

export default reduxForm({
  form: "Template",
})(injectIntl(Templates));
