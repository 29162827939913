import { defineMessages } from 'react-intl';

export default defineMessages({
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyCalendarSuccess: {
    id: 'notify.calendar.delete.success',
    defaultMessage: 'Calendar deleted successfully.',
  },
  notifyCalendarError: {
    id: 'notify.calendar.delete.error',
    defaultMessage: 'Calendar couldn\'t be deleted.',
  },
});