import { LabelWithTooltip } from "@d-lighted/design-system";
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { getPlan } from "../../utils/userUtils";
import theme from "../../constants/styledTheme";
import { defineMessages, FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  margin-left: ${({ ml }) => `${ml}px`};
  margin-bottom: ${({ mb }) => `${mb}px`};
`;

const messages = defineMessages({
  enterprise: {
    id: "common.plan.enterprise",
    defaultMessage: "ENTERPRISE",
  },
  enterpriseInfo: {
    id: "common.plan.enterpriseInfo",
    defaultMessage:
      "This feature is for enterprise plan.{br}Please subscribe to enterprise plan or premium plan when registering credit card in order to continue using the feature even after trial period.",
  },
  premium: {
    id: "common.plan.premium",
    defaultMessage: "PREMIUM",
  },
  premiumInfo: {
    id: "common.plan.premiumInfo",
    defaultMessage:
      "This feature is for premium plan.<br/>Please subscribe to premium plan when registering credit card in order to continue using the feature even after trial period.",
  },
});

const PlanIcon = ({ featurePlan, ml, mb, width }) => {
  const plan = getPlan();

  const backgroundColor = {
    enterprise: theme.colors.enterpriseBlueBg,
    premium: theme.colors.premiumPurpleBg,
  };

  const fontColor = {
    enterprise: theme.colors.enterpriseBlueFont,
    premium: theme.colors.premiumPurpleFont,
  };

  const labelText = {
    enterprise: <FormattedMessage {...messages.enterprise} />,
    premium: <FormattedMessage {...messages.premium} />,
  };

  const tooltipText = {
    enterprise: (
      <FormattedMessage {...messages.enterpriseInfo} values={{ br: <br /> }} />
    ),
    premium: (
      <FormattedMessage {...messages.premiumInfo} values={{ br: <br /> }} />
    ),
  };
  
  return (
    plan === "trial" && (
      <Wrapper ml={ml} mb={mb}>
        <LabelWithTooltip
          bgColor={backgroundColor[featurePlan]}
          fontColor={fontColor[featurePlan]}
          labelText={labelText[featurePlan]}
          tooltipText={tooltipText[featurePlan]}
          width={width}
        />
      </Wrapper>
    )
  );
};

PlanIcon.defaultProps = {
  featurePlan: "",
  ml: 8,
  mb: 0,
  width: 300,
};

PlanIcon.propTypes = {
  featurePlan: PropTypes.string,
  ml: PropTypes.number,
  mb: PropTypes.number,
  width: PropTypes.number,
};

export default PlanIcon;
