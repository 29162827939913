import { useParams } from 'react-router';
import humps from "lodash-humps";

import { _get } from '../../../utils/api';
import messages from '../i18n/MultiGuest';

function useMultiguestCalendarList(notify) {
  const { uid } = useParams();

  const getMultiguestCalendarList = async (currentPage) => {
    const url = `/booking_calendars/${uid}/multiguest?page=${currentPage}`;
    const result = await _get(url);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.notifyMultiguestScheduledEventsErrorTitle, messages.notifyMultiguestScheduledEventsError);
      return result
    } else {
      return humps(result.data);
    }
  };

  return { getMultiguestCalendarList };
}

export { useMultiguestCalendarList };
