import { useState, useEffect } from 'react';
import { useIntl } from "react-intl";
import { useParams, useHistory } from 'react-router';
import humps from "lodash-humps";
import { put, _get, remove } from "utils/api";

import messages from '../i18n/templates';

function useTemplates(notify) {
  const { uid } = useParams();
  const intl = useIntl();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const history = useHistory();

  const getTemplateDetail = async (skipLoading = false) => {
    const url = `/booking_calendars/templates/${uid}/details`;
    if (!skipLoading) setLoading(true);
    const result = await _get(url);

    setLoading(false);
    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.failedGetDetail, result.data?.error?.message);
    } else {
      const templateDetails = humps(result.data?.template)
      setDetails(templateDetails);
      return templateDetails;
    }
    return  null;
  };

  const updateTemplateDetail = async values => {
    const url = `/booking_calendars/templates/${uid}/details`;
    setSaving(true);
    const result = await put(url, values);

    setSaving(false);
    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.failedUpdateDetail, result.data?.error?.message);
    } else {
      notify.setNotify(messages.successUpdateDetail);
      const templateDetails = humps(result.data?.template)
      setDetails(templateDetails);
      return templateDetails;
    }
    return  null;
  };

  const deleteTemplate = async () => {
    if (window.confirm(intl.formatMessage({...messages.deleteConfirm}))) {
      let response = await remove(`/booking_calendars/templates/${uid}`);
      if (response?.status !== 200) {
        notify.setError(messages.deleteTemplateError, response?.data?.message);
      } else {
        history.push('/?tab=template');
      }
    }
  };
  
  useEffect(() => {
    getTemplateDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return { 
    details, 
    loading, 
    saving,
    actions: {
      getTemplateDetail,
      updateTemplateDetail,
      deleteTemplate,
    }
  };
}

export { useTemplates };
