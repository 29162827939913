import React from 'react';
import { useParams } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import {
  Col,
  Row,
  IconNew as Icon
} from "@d-lighted/design-system";
import messages from "../i18n/EmailNotifications";
import { BackLabel } from '../../../components/elements/BackLabel';

const GoBackToEmailNotifications = ({ history }) => {
  const { uid, settings } = useParams();
  return(
    <Row mt="26px">
      <Col
        isCursor
        display="flex"
        alignItems="flex-end"
        onClick={() => {
          history.push({
            pathname: `/${settings}/${uid}/edit/email_notifications`
          });
        }}
      >
        <Icon
          isCursor
          className="material-icons"
          fontWeight="100"
          fontSize="17px"
          color="#9bb0b5"
        >
          navigate_before
        </Icon>
        <BackLabel fontSize="13px">
          <FormattedMessage {...messages.backToEmailNotifications} />
        </BackLabel>
      </Col>
    </Row>
  );
};

export default GoBackToEmailNotifications;
