import React, { useEffect } from "react";
import styled from "styled-components";
import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { snakeCase } from "lodash";
import createHumps from 'lodash-humps/lib/createHumps';
import moment from "moment";
import { ErrorText } from "@d-lighted/design-system";

import { PrimaryLightBackground } from "../../../components/utils/Helper";
import StyledRow from "../modules/StyledRow";
import BackButton from "../modules/BackButton";
import Title from "../modules/Title";
import Booking from "../modules/SchedulingFinalizeModules/Booking";
import { useMultiguestAppointment } from '../hooks/useMultiguestAppointment';
import CalendarNotIntegrated from "../../../components/preview/CalendarNotIntegrated";

const Wrapper = styled.div`
  padding-top: 71px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 1.6;
  color: #314143;
`;

const snakes = createHumps(snakeCase);

function SchedulingFinalize(props) {
  const { useNotify, intl} = props;
  const location = useLocation();
  const { uid,  multiguest_calendar_uid: multiguestCalendarUid } = useParams();
  const notify = useNotify(intl);

  const { appointment, appointmentError, fetchAppointment } = useMultiguestAppointment(uid, multiguestCalendarUid, notify);
  const { data } = useSelector((state) => state.calendarSetting);

  const businessOpeningTime = Object.keys(data.businessTimes).map(i => data.businessTimes[i].openingTime).sort()[0];
  const businessClosingTime = Object.keys(data.businessTimes).map(i => data.businessTimes[i].closingTime).sort()[0];

  const dropdownOnConfirm = (day, currentTime, selectedGuests, availableHosts) => {

    const begin = moment(`${day.year()}-${day.format('M')}-${day.date()} ${currentTime}`,'YYYY-MM-DD hh:mm');
    const selectedSlot = {
      begin_at: begin.toISOString(true),
      end_at: moment(begin).add(appointment.duration, 'm').toISOString(true),
      visitors: snakes(selectedGuests),
      booking_calendar_uid: appointment.calendarDetail.uid,
    }

    props.history.push({
      pathname: `/booking_calendars/${uid}/multiguest/${multiguestCalendarUid}/confirm`,
      state: {
        appointment: location.state?.appointment,
        values: selectedSlot,
        availableHosts: availableHosts
      }
    });
  }

  useEffect(() => {
    fetchAppointment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (appointmentError) {
    return (
      <Wrapper>
       <ErrorText>
         <CalendarNotIntegrated />
       </ErrorText>
      </Wrapper>
    );
  }

  return (
    <PrimaryLightBackground>
      <StyledRow onClick={() => { props.history.push(`/booking_calendars/${uid}/multiguest/list`) }}>
        <BackButton multiguest />
      </StyledRow>
      <StyledRow>
        <Title title={appointment.title} />
      </StyledRow>
      <Booking
        appointment={appointment}
        dropdownOnConfirm={dropdownOnConfirm}
        businessOpeningTime={businessOpeningTime}
        businessClosingTime={businessClosingTime}
      />
    </PrimaryLightBackground>
  )
}

export default reduxForm({
  form: "SchedulingFinalize",
})(SchedulingFinalize);
