import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import humps from "lodash-humps";

import { default as qS } from "query-string";
import { _get, remove } from "../utils/api";
import {
  TYPES,
  TABS,
  fetchCalendars,
  resetCalendars as reset,
} from "../redux/reducers/calendarsReducer";
import * as templateActions from "../redux/reducers/templatesReducer";
import messages from './i18n/useCalendarSetting';
import { update as updateOnlineStatus } from "../redux/reducers/onlineReducer";

function checkTabType(type) {
  return Object.keys(TYPES)
    .slice(0, 4)
    .map((k) => TYPES[k])
    .includes(type)
    ? type
    : null;
}

function useCalendars() {
  const calendars = useSelector((state) => state.calendars);
  const templates = useSelector((state) => state.templates);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (calendars.all.calendars.length > 0) {
      const status = calendars.all.calendars.map((c) => {
        return { alias: c.alias, online: c.online };
      });
      dispatch(updateOnlineStatus({ status }));
    }
  }, [calendars.all, dispatch]);

  useEffect(() => {
    if (calendars.attendee.calendars.length > 0) {
      const status = calendars.attendee.calendars.map((c) => {
        return { alias: c.alias, online: c.online };
      });
      dispatch(updateOnlineStatus({ status }));
    }
  }, [calendars.attendee, dispatch]);

  useEffect(() => {
    if (calendars.editor.calendars.length > 0) {
      const status = calendars.editor.calendars.map((c) => {
        return { alias: c.alias, online: c.online };
      });
      dispatch(updateOnlineStatus({ status }));
    }
  }, [calendars.editor, dispatch]);

  function fetchCalendarsBase(type, page, query = "", sort = "", isMobileView, calendars, employeeUids = []) {
    const params = { page: page, employee_uids: JSON.stringify(employeeUids) };
    const previousData = calendars ? calendars[type].calendars : [];
    if (type !== TYPES.QUERY || type !== TYPES.QUERY_EMPLOYEE) params.type = "" + type;
    if (query !== "") params.query = "" + query;
    if (sort !== "") params.sort = "" + sort;
    const queryString = new URLSearchParams(params);

    setIsLoading(true);
    _get(`/v2/booking_calendars?${queryString.toString()}`)
      .then((result) => {
        let totalCalendar;
        const fetchedData = result.data.calendars;
        if (isMobileView && !sort) {
          totalCalendar = [...previousData, ...fetchedData];
        } else {
          totalCalendar = fetchedData;
        }

        const payload = {
          [type]: {
            calendars: humps(totalCalendar),
            currentPage: result.data.meta.current_page,
            totalPages: result.data.meta.total_pages,
            query: params.query || "",
            sort,
          },
        };

        dispatch(fetchCalendars(payload));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
    _get(`/v2/booking_calendars/team_pages`).then((result) => {
      dispatch(fetchCalendars(humps(result.data)));
    });
  }

  function fetchTemplatesBase(type, page, query = "", isMobileView) {
    const params = { page: page };
    const previousData = templates
    const queryString = new URLSearchParams(params);

    setIsLoading(true);
    _get(`/booking_calendars/templates?${queryString.toString()}`)
      .then((result) => {
        let totalTemplates;
        const fetchedData = result.data.templates;
        if (isMobileView) {
          totalTemplates = [...previousData, ...fetchedData];
        } else {
          totalTemplates = fetchedData;
        }

        const payload = {
          templates: humps(totalTemplates),
          currentPage: result.data.meta.current_page,
          totalPages: result.data.meta.total_pages,
        };

        dispatch(templateActions.fetchTemplates(payload));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  const debouncedFetchCalendarsBase = useCallback(
    debounce(
      (type, page, query, sort, isMobileView, calendars, employeeUids) =>
        fetchCalendarsBase(type, page, query, sort, isMobileView, calendars, employeeUids),
      200
    ),
    []
  );

  const debouncedFetchTemplatesBase = useCallback(
    debounce(
      (type, page, query, isMobileView) =>
        fetchTemplatesBase(type, page, query, isMobileView),
      200
    ),
    []
  );

  const fetchAll = (page = 1, sort, isMobileView = false) => {
    debouncedFetchCalendarsBase(TYPES.ALL, page, "", sort, isMobileView, calendars);
  };

  const fetchAttendees = (page = 1, sort, isMobileView = false) => {
    debouncedFetchCalendarsBase(
      TYPES.ATTENDEE,
      page,
      "",
      sort,
      isMobileView,
      calendars
    );
  };

  const fetchEditors = (page = 1, sort, isMobileView = false) => {
    debouncedFetchCalendarsBase(
      TYPES.EDITOR,
      page,
      "",
      sort,
      isMobileView,
      calendars
    );
  };

  const fetchQuery = (page = 1, query = "", sort = "", isMobileView = false) => {
    debouncedFetchCalendarsBase(
      TYPES.QUERY,
      page,
      query,
      sort,
      isMobileView,
      calendars
    );
  };

  const fetchTemplates = (page = 1, isMobileView = false) => {
    debouncedFetchTemplatesBase(
      TYPES.TEMPLATE,
      page,
      "",
      isMobileView
    );
  };

  const fetchEmployeeQuery = (page = 1, query = "", sort = "", isMobileView = false, employeeUids) => {
    debouncedFetchCalendarsBase(
      TYPES.QUERY_EMPLOYEE,
      page,
      query,
      sort,
      isMobileView,
      calendars,
      employeeUids
    );
  };

  const resetCalendars = () => {
    dispatch(reset());
    dispatch(templateActions.resetTemplates());
  };

  const deleteCalendar = async (uid, notify, tab, tabSettings) => {
    const url = `/booking_calendars/${uid}`;
    const result = await remove(url);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.notifyCalendarError, result.data?.error?.message);
    } else {
      const queryString = qS.parse(window.location.search);
      const updatedQueryString = {
        ...queryString,
        page: 1,
      };
      const rootTabUrl = window.location.pathname + '?' + new URLSearchParams(updatedQueryString).toString();
      window.location.href = rootTabUrl;
    }
    return null;
  };

  return {
    calendars,
    templates,
    isLoading,
    types: TYPES,
    tabs: TABS,
    actions: {
      fetchAll,
      fetchAttendees,
      fetchEditors,
      fetchTemplates,
      fetchQuery,
      fetchEmployeeQuery,
      fetchCalendarsBase: debouncedFetchCalendarsBase,
      resetCalendars,
      deleteCalendar,
    },
  };
}

export { useCalendars, checkTabType };
