import React from 'react';
import styled from 'styled-components';
import { useLocale, LOCALES } from '../../utils/localeUtils';

const LangText = styled.span`
  color: ${props => (props.active ? '#E5E5E5' : '#00BBB5')};;
  cursor: pointer;
  padding: 0 10px;
  border-right: 1px solid #E5E5E5;
 
  &:last-child {
    border-right: none
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  margin-left: 20px;
  box-sizing: border-box;
  padding: 10px 0;
`;

function LanguageSelector() {
  const [locale, setLocale] = useLocale();
  
  return (
    <Wrapper>
      <LangText
        active={locale === LOCALES.EN}
        onClick={() => setLocale(LOCALES.EN)}
      >EN</LangText>
      <LangText
        active={locale === LOCALES.JA}
        onClick={() => setLocale(LOCALES.JA)}
      >JP</LangText>
    </Wrapper>
  );
}

LanguageSelector.defaultProps = { };

export default LanguageSelector;
