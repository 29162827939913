import React from "react";
import { Field } from "redux-form";
import { Input, Col, Row, Label } from "@d-lighted/design-system";
import messages from '../i18n/Modules';
import { useValidation } from "../../../utils/validation";

export function HiddenField({ intl }) {
  const { required, inputLength } = useValidation(intl);

  return (
    <>
      <Row>
        <Col xs>
          <Row mb="13px" mt="25px">
            <Col xs>
              <Label fontSize="14px">
                {intl.formatMessage({ ...messages.hiddenFieldLabel })}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <Field
                id="hiddenField"
                name="hiddenField"
                component={Input}
                height="40px"
                placeholder={null}
                validate={[required, inputLength]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
