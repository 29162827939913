import React from "react";
import styled from "styled-components";
import {Heading, IconButton, Row, Col } from "@d-lighted/design-system";

/** Local Component */
import { moment } from "../../../../components/preview/momentUtils";

const MainRow = styled(Row)`
  width: 54.9vw;
  @media(min-width: 1920px) {
    width: 39vw;
  }
`;

const ColorInfo = styled.div`
  display: flex;
  font-size: 13px;
  color: #68878d;
  margin-right: ${p => p.mr || "0px"}
`;

const ColorSquare = styled.div`
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  margin-right: 7px;
  background-color: ${p => p.bg};
  ${p => p.borderColor && `
    border: solid 1px ${ p.borderColor};
  `}
`;

export function CalendarHeader({
  options,
  getPrevWeek,
  getNextWeek,
  isTimeSlotLoading,
  timeSlotsHasError,
  children,
}) {
  const {
    currentWeek: { week, selectedDate },
  } = options;

  return (
    <React.Fragment>
      {!timeSlotsHasError &&
        <MainRow mt="15px" pl="5px">
          <Col col>
            <Row
              width="250px"
              justifyContent="space-between"
              mb="10px"
            >
              <Col col sm={8} pr="0px">
                <Heading size={6}>{selectedDate.year()}年</Heading>
                <Heading size={2}>
                  {week[0].format("M/D")} - {week[week.length - 1].format("M/D")}
                </Heading>
              </Col>
              <Col col sm={4} display="flex" justifyContent="flex-end" px="0px">
                <IconButton
                  mr="3px"
                  disabled={
                    isTimeSlotLoading || moment(week[0]).isBefore(moment())
                  }
                  onClick={getPrevWeek}
                >
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </IconButton>
                <IconButton disabled={isTimeSlotLoading} onClick={getNextWeek}>
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </IconButton>
              </Col>
            </Row>
            <Row>
              <Col display="flex" mb="12px">
                <ColorInfo mr="20px">
                  <ColorSquare bg="#6e52ff" />
                  お客様と社内メンバーがどちらも参加可能な日時
                </ColorInfo>
                <ColorInfo>
                  <ColorSquare borderColor="#6e52ff" />
                  お客様が参加可能だが社内メンバーの予定と重複している日時
                </ColorInfo>
              </Col>
              {/* <Col mb="12px">
              </Col> */}
            </Row>
          </Col>
        </MainRow>
      }
      <Row>
        <Col xs padding="0px">{children}</Col>
      </Row>
    </React.Fragment>
  );
}
