import React from "react";
import styled from "styled-components";
import { Grid, Row, Col } from "@d-lighted/design-system";

const DashboardWrapper = styled.div`
  max-width: 100%;
`;

export function DashboardWithoutPreview(props) {
  return (
    <DashboardWrapper>
      <Grid fluid p="0">
        <Row m="0">
          <Col
            xs
            pl={["16px", "30px"]}
            pr={["16px", "20px"]}
            overflow={["hidden", "auto"]}
          >
            {props.children}
          </Col>
        </Row>
      </Grid>
    </DashboardWrapper>
  );
}
