import React from "react";

import { SingleDate, TimeSlots } from "./Day";
import { Div } from "../../../../components/preview/Helper";
import { moment } from "../../../../components/preview/momentUtils";

export function CalendarBody(props) {
  const {
    isTimeSlotLoading,
    isPreview,
    multiguestCalendarDetail,
    duration,
    onChange,
    timeSlots,
    dropdownOnConfirm,
    minSchedulableTime,
    maxSchedulableTime,
    businessWeekDays,
    options: {
      isDateEarlier,
      calculateAvailableTime,
      getHoursOfTheDay,
      getJapaneseDay,
      currentWeek: { week },
    },
  } = props;


  let availableTimeSlots = {}

  if (!isTimeSlotLoading) availableTimeSlots = calculateAvailableTime(timeSlots.availableTimeSlots);

  /** if day has empty slots return empty array explicit */
  function handleEmptySlotDays(day, availableTimeSlots) {
    if (availableTimeSlots[day.format("MM-DD-yyyy")] === undefined) {
      return [];
    } else {
      return availableTimeSlots[day.format("MM-DD-yyyy")];
    }
  }

  return (
    <Div
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      {week.map((day, index) => {
        if (!businessWeekDays[day.isoWeekday() - 1])
          return null;

        return (
          <Div
            key={day.format("lll")}
            width="100%"
            bg={`${isDateEarlier(day, moment()) === "equal" && "#e6f4ef"}`}
            px="15px"
            borderRadius="10px"
            pb="35px"
          >
            <Div width="100%" px="0px">
              <Div
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="100%"
              >
                <SingleDate
                  date={day.date()}
                  day={day}
                  getJapaneseDay={getJapaneseDay}
                  isDateEarlier={isDateEarlier}
                />
                <TimeSlots
                  hoursOfTheDay={getHoursOfTheDay}
                  day={day}
                  dayIndex={index}
                  timeSlots={handleEmptySlotDays(day, availableTimeSlots)}
                  busySlots={timeSlots.busySlots}
                  handleChange={onChange}
                  isPreview={isPreview}
                  duration={duration}
                  minSchedulableTime={minSchedulableTime}
                  maxSchedulableTime={maxSchedulableTime}
                  dropdownOnConfirm={dropdownOnConfirm}
                  multiguestCalendarDetail={multiguestCalendarDetail}
                />
              </Div>
            </Div>
          </Div>
        );
      })}
    </Div>
  );
}
