import React from "react";
import styled from "styled-components";
import { IconNew as Icon } from "@d-lighted/design-system";
import { FormattedMessage } from 'react-intl';
import defineMessages from "../i18n/MultiGuest";

const ReturnLink = styled.span`
  color: #00BBB5;
  cursor: pointer;
`;

export default function BackButton({multiguest}) {
  return (
    <>
      <Icon
        className="material-icons"
        fontSize="18px"
        isCursor={true}
      >
        arrow_back_ios
      </Icon>
      <ReturnLink className="nav_header_brand_text_big">
        { multiguest
          ? <FormattedMessage {...defineMessages.returnToMultiguestList} />
          : <FormattedMessage {...defineMessages.returnToDashboard} />
        }
      </ReturnLink>
    </>
  )
}
