import { createAction, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATUS = {
  calendar: {},
};

export const update = createAction('multiguest_calendar_update');
export const reset = createAction('multiguest_calendar_reset');

const multiguestCalendarReducer =  createReducer(INITIAL_STATUS,
  builder => {
    builder.addCase(update, (state, action) => {
      state.calendar = { ...state.calendar, ...action.payload.calendar };
    });
    builder.addCase(reset, (state) => {
      state.calendar = { ...INITIAL_STATUS };
    })
  }
);

export default multiguestCalendarReducer;
