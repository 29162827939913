import { defineMessages } from 'react-intl';

export default defineMessages({
  inputRestriction: {
    id: 'views.customField.inputRestriction',
    defaultMessage: 'Question to guest',
  },
  options: {
    id: 'views.customField.options',
    defaultMessage: 'Osptions',
  },
  addOptions: {
    id: 'views.customField.addOptions',
    defaultMessage: 'Add options',
  },
  deleteConfirmation: {
    id: 'views.customField.deleteConfirmation',
    defaultMessage: 'Delete this field?',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  optionsPlaceholder: {
    id: 'views.customField.optionsPlaceholder',
    defaultMessage: 'Enter a choice',
  },
  blockOfTextPlaceholder: {
    id: 'views.customField.blockOfTextPlaceholder',
    defaultMessage: 'This text is a dummy copy and should not be read.',
  },
  blockOfTextLabel: {
    id: 'views.customField.blockOfTextLabel',
    defaultMessage: 'Contents'
  },
  hiddenFieldLabel: {
    id: 'views.customField.hiddenFieldLabel',
    defaultMessage: '設定値'
  }
});
