import React from 'react';
import styled from 'styled-components';
import { width } from 'styled-system';
import { MOBILEBREAKPOINT } from '../../constants/breakpoint';

const Icon = styled.img`
  height: 18px;
  margin-right: 5px;
  position: relative;
  top: 2px;
  ${width}
  @media (max-width: ${MOBILEBREAKPOINT}){
    margin-right:8px;
  }
`;

const Wrapper = styled.div`
  display: inline-grid;
  position: relative;
  top: -3px;
  left: 8px;
`;

const IconWrapper = styled.div`
  cursor: ${props => props.message ? 'pointer' : 'default'};
  display: inline-block;
  box-sizing: border-box;
  &:hover + .display-text {
    visibility: visible;
    opacity: 1;
  }
`;

const Anchor = styled.div`
    visibility: hidden;
    opacity: 0;
    content: '';
    transition: 0.2s;
    position: initial;
    z-index: 2;
`;

const InfoBoard = styled.div`
  background-color: #fcfcfc;
  color: #fff;
  min-width: 140px;
  max-width: 300px;
  width: fit-content;
  height: 41.3px;
  border-radius: 5px;
  margin-left: -95px;
  margin-top: 10px;
  position: absolute;
  font-size: 10px;
  z-index: 2;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);

  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: 100%;
    left: 102px;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #fcfcfc transparent;
  }
`;

const InfoText = styled.p`
  min-width: 162px;
  max-width: 250px;
  white-space: nowrap;
  height: 17px;
  font-family: NotoSansJP;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #2a4867;
  margin: 8px 17px 10px 17px;
  padding-bottom: 2.5px;
`

function ToolInfo({ icon, alt, message, width }) {
  return (
    <Wrapper>
      <IconWrapper message={message}>
        <Icon
          src={icon}
          alt={alt}
          width={width}
        >
        </Icon>
      </IconWrapper>
      <Anchor className="display-text">
        {message &&
          <InfoBoard>
            <InfoText>
              {message}
            </InfoText>
          </InfoBoard>
        }
      </Anchor>
    </Wrapper>
  );
}

ToolInfo.defaultProps = {
  message: '',
  icon: '',
  alt: '',
  width: '18px',
};

export default ToolInfo;
