import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '@d-lighted/design-system';
import Text from './Text';
import TableBodyItem from './TableBodyItem';

function HostList({ hosts }) {
  const hostItems = hosts.map(host => (
    <Text key={host.name} mb={hosts.length === 1 ? 0 : 10}>
      <Label color="#000">{`${host.name}`}</Label>
    </Text>
  ));
  return <TableBodyItem>{hostItems}</TableBodyItem>;
}

HostList.defaultProps = {
  hosts: [],
};

HostList.propTypes = {
  hosts: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};

export default HostList;
