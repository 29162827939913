import Templates from "views/templates/pages/Templates";
import { templatesEnabledAdminValidation } from "utils/userUtils";

export const templatesRoutes = [
  {
    component: Templates,
    path: "/templates/:uid",
    exact: true,
    feature: "templates",
    type: "private",
    gauid: 'templates',
    validation: templatesEnabledAdminValidation,
  }
];
