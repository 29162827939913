/* eslint-disable  react-hooks/exhaustive-deps*/
import React from "react";
import { Field } from "redux-form";
import { FormattedMessage } from 'react-intl';
import { InputSelect, Label, Col, Row } from "@d-lighted/design-system";
import messages from '../i18n/Modules';

function InputRestrictionField({ options }) {
  return (
    <>
      <Row mt="25px">
        <Col xs>
          <Label>
            <FormattedMessage {...messages.inputRestriction} />
          </Label>
        </Col>
      </Row>
      {/* 
        Options down below are hardcoded for instance.
        Option can be updated it can be replaced from props.
      */}
      <Row mt="15px">
        <Col xs>
          <Field
            id="textFieldInputRestriction"
            name="textFieldInputRestriction"
            isSearchable={false}
            component={InputSelect}
            options={options}
            height="44px"
          />
        </Col>
      </Row>
    </>
  );
}

export default InputRestrictionField;
