import React from 'react';
import styled from 'styled-components';

const Icon = styled.span`
  display: inline-block;
  margin-left: 2px;
  color: #fb3a30;
  font-size: 20px;
  position: relative;
  top: 4px;
`;

export default function RequiredMark() {
  return <Icon>*</Icon>;
}
