import { useState } from "react";
import { _get } from "../../../utils/api";

function usePromotion() {
  const [promotionLink, setLink] = useState(process.env.REACT_APP_SCHEDULING_LP_ROOT);
  const [promotionTitle, setTitle] = useState("お客様におすすめのサービス");

  const getPromotionContent = () => {
    ["link", "title"].forEach(type => {
      const url = `${window.location.origin}/promotion/${type}.txt`
      _get(url).then(response => {
        const content = response.data.replace(/(\r\n|\n|\r)/gm, "");
        if(content && response.status === 200) {
          if(type === "link") return setLink(content);
          if(type === "title") return setTitle(content);
        }
      })
    });
  };

  return {
    promotionLink,
    promotionTitle,
    promotionActions: {
      getPromotionContent,
    },
  };
}

export { usePromotion };
