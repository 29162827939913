import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.layouts.emailNotifications.title',
    defaultMessage: 'Mail List',
  },
  titleCommonCustomizations: {
    id: 'components.layouts.emailNotifications.title.customizations',
    defaultMessage: 'Common email customizations',
  },
  completeEmail: {
    id: 'components.layouts.emailNotifications.completeEmail',
    defaultMessage: 'Booking complete email',
  },
  reminderEmail: {
    id: 'components.layouts.emailNotifications.reminderEmail',
    defaultMessage: 'Reminder email',
  },
  scheduleDeletionEmail: {
    id: 'components.layouts.emailNotifications.scheduleDeletionEmail',
    defaultMessage: 'Schedule deletion email',
  },
  backToEmailNotifications: {
    id: 'components.layouts.emailNotifications.backToEmailNotifications',
    defaultMessage: 'Back to Email settings list',
  },
  personInCharge: {
    id: 'components.layouts.emailNotifications.commonEmail.personInCharge',
    defaultMessage: 'Person in charge',
  },
  personInChargePlaceholder: {
    id: 'components.layouts.emailNotifications.commonEmail.personInChargePlaceholder',
    defaultMessage: 'Make sure you insert {自社の会社名} for company name and {自社の担当者全員の氏名} for contact person',
  },
  contactInfo: {
    id: 'components.layouts.emailNotifications.contactInfo',
    defaultMessage: 'Contact Information'
  },
  contactInfoPlaceholder: {
    id: 'components.layouts.emailNotifications.contactInfoPlaceholder',
    defaultMessage: 'Make sure you insert{自社の担当者１名の氏名} for contact person and {自社の担当者１名のメールアドレス} for email'
  },
  completedMessageEmail: {
    id: 'views.booking.pages.reservationFinished.completedMessageEmail',
    defaultMessage: 'Message to guest',
  },
  completedMessageEmailPlaceholder: {
    id: 'views.booking.pages.reservationFinished.completedMessageEmail.placeholder',
    defaultMessage: 'Message to be displayed in the summary field of email body',
  },
  logo: {
    id: 'components.layouts.emailNotifications.commonEmail.logo',
    defaultMessage: 'Logo (*will be resized to 250px wide and 100px high)'
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
