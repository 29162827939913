import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Loader, Pagination, InfiniteScroll } from "@d-lighted/design-system";
import DashboardEmpty from "../../home/pages/DashboardEmpty";
import Calendar from "../../home/modules/Calendar";
import FontAwesome from "react-fontawesome";
import { DotLoader } from "../../../components/elements/DotLoader";
import DesktopBreakpoint from "@d-lighted/design-system/dist/components/Utils/DesktopBreakpoint";
import PhoneBreakpoint from "@d-lighted/design-system/dist/components/Utils/PhoneBreakpoint";

const StyledLoader = styled(Loader)`
  margin-top: 15vh;
  margin-right: auto;
  margin-left: auto;
`;

const StyledLoadMore = styled(Loader)`
  margin: 14px auto 13px;
`;

function TabContent(props) {
  const { tabCalendar, isLoading, handleFetchMoreCalendars, type } = props;

  const TabContentPagination = (
    <Pagination
      currentPage={tabCalendar.currentPage}
      totalPages={tabCalendar.totalPages}
      onClick={handleFetchMoreCalendars}
      angleLeft={<FontAwesome name="angle-left" />}
      angleRight={<FontAwesome name="angle-right" />}
    />
  );
  return (
    <>
      {tabCalendar.calendars.length === 0 && isLoading && <StyledLoader />}
      {tabCalendar.calendars.length ? (
        <>
          <PhoneBreakpoint>
            <InfiniteScroll
              currentPage={tabCalendar.currentPage}
              totalPages={tabCalendar.totalPages}
              fetchMoreData={handleFetchMoreCalendars}
              isLoading={isLoading}
              loadMore={<DotLoader />}
            >
              {tabCalendar.calendars.map((calendar) => {
                return (
                  <Calendar
                    key={calendar.settingsUid}
                    calendar={calendar}
                    type={type}
                    {...props}
                  />
                );
              })}
            </InfiniteScroll>
          </PhoneBreakpoint>
          <DesktopBreakpoint>
            {TabContentPagination}
            {isLoading && <StyledLoadMore />}
            {!isLoading &&
              tabCalendar.calendars.map((calendar) => {
                return (
                  <Calendar
                    key={calendar.settingsUid}
                    calendar={calendar}
                    type={type}
                    {...props}
                  />
                );
              })}
            {TabContentPagination}
          </DesktopBreakpoint>
        </>
      ) : (
        <>{!isLoading && <DashboardEmpty type={type} />}</>
      )}
    </>
  );
}

TabContent.propTypes = {
  tabCalendar: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isLoading: PropTypes.bool.isRequired,
  handleFetchMoreCalendars: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default TabContent;
