import React from 'react';
import styled from 'styled-components';
import { Button } from '@d-lighted/design-system';
import { FormattedMessage, defineMessages } from 'react-intl';


const ItemLeft = styled.div`
  display: inline-flex;
  min-width: 0px;
`;

const ItemText = styled.p`
  float: left;
  padding-left: 18px;
  color: #484e62;
  padding-right: 5px;
  align-items: center;
  display: flex;
`;

const ItemRight = styled.div`
  position: relative;
  margin-right: 20px;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
`;

const Wrapper = styled.div`
  margin: 1px;
  border-bottom: solid 1px #e5e5e5;
  min-height: 52px;
  display: flex;
`;

const messages = defineMessages({
  add: {
    id: 'components.form.memberSelection.add',
    defaultMessage: 'Add',
  },
  added: {
    id: 'components.form.memberSelection.added',
    defaultMessage: 'Added',
  }
});

const ListItemRecord = (props) => {
  const { item, selected, handleClick } = props;
  return <Wrapper>
    <ItemLeft>
      <ItemText>{item.label}</ItemText>
    </ItemLeft>
    <ItemRight>
      <Button width="65px" minWidth="65px" height="30px" borderRadius="3px" fontSize="12px" disabled={selected} onClick={() => handleClick(item)}>
        {!selected ?
          <FormattedMessage {...messages.add} />
          :
          <FormattedMessage {...messages.added} />
        }
      </Button>
    </ItemRight>
  </Wrapper>
};

export default ListItemRecord;
