import React from "react";
import styled from "styled-components";
import {Heading, IconButton, Row, Col } from "@d-lighted/design-system";
import calendarIcon from "../../../assets/img/Icons-Content-Calendar.svg";
import timezoneIcon from "../../../assets/img/globe.svg";

/** Local Component */
import { moment } from "../momentUtils";

const MainRow = styled(Row)`
  width: 54.9vw;
  @media(min-width: 1920px) {
    width: 39vw;
  }
`;

export function CalendarHeader({
  options,
  getPrevWeek,
  getNextWeek,
  isTimeSlotLoading,
  timeSlotsHasError,
  children,
}) {
  const {
    currentWeek: { week, selectedDate },
  } = options;

  return (
    <>
      {!timeSlotsHasError &&
        <MainRow mt={["10px", "15px"]} pl={[null, "5px"]}>
          <Col col>
            <Row
              width={["100%", "350px"]}
              justifyContent="space-between"
              mb="10px"
            >
              <Col col sm={6} pr="0px">
                <Heading size={6}>{selectedDate.year()}年</Heading>
                <Heading size={2}>
                  {week[0].format("M/D")} - {week[week.length - 1].format("M/D")}
                </Heading>
              </Col>
              <Col col sm={3} display="flex" justifyContent="flex-end" px="0px">
                <IconButton
                  mr="3px"
                  disabled={
                    isTimeSlotLoading || moment(week[0]).isBefore(moment())
                  }
                  onClick={getPrevWeek}
                >
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </IconButton>
                <IconButton disabled={isTimeSlotLoading} onClick={getNextWeek}>
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </IconButton>
              </Col>
              <Col col sm={3} display="flex">
                <div >
                  <IconButton
                    borderRadius="0px"
                  >
                    <img src={calendarIcon} alt="Favicon" />
                  </IconButton>
                </div>
              </Col>
            </Row>
            <Row>
              <Col mb={["5px", "12px"]}>
                <Heading size={3} color="#68878d">ご希望の日時を選択してください</Heading>
              </Col>
            </Row>
          </Col>
          <Col
            col
            display={'flex'}
            justifyContent="flex-end"
            flexGrow={1}
            mr={-55}
          >
            <div >
              <IconButton
                width="auto"
                fontSize="14px"
                padding="10px 15px"
              >
                <img
                  src={timezoneIcon}
                  alt="timezone"
                  style={{ marginRight: '10px', verticalAlign: 'bottom' }}
                />
                UTC+9:00 Tokyo
              </IconButton>
            </div>
          </Col>
        </MainRow>
      }
      <Row>
        <Col xs padding="0px">{children}</Col>
      </Row>
    </>
  );
}
