import React, { useEffect, useState } from "react";
import { reduxForm, Field } from "redux-form";
import { useParams } from "react-router-dom";
import { injectIntl } from 'react-intl';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Label,
  Col,
  Row,
  Hr,
  Button,
  InputSelect,
  Input,
  TextArea
} from "@d-lighted/design-system";
import { actions as reduxActions } from "redux/actions";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { Dashboard } from "components/layouts/Dashboard";
import { useValidation } from "utils/validation";
import Tooltip from "components/elements/Tooltip";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import { convertToOption, convertToPermission } from "components/forms/TemplatePermissionSelector";

import GoBackToEmailNotifications from "../components/GoBackToEmailNotifications";
import messages from "../i18n/ReminderEmailNotification";
import { EmailVariableText } from "../components/EmailVariableText";
import { useCalendarEmailCustomizations } from "../hooks/useCalendarEmailCustomizations";
import { useCalendarSetting } from "hooks/useCalendarSetting";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";

const InlineWrapper = styled.div`
  display: inline-block;
  margin-right: ${props => props.mr ? props.mr : '0'}px;
  margin-left: ${props => props.ml ? props.ml : '0'}px;
  color: #314143;
  font-weight: 200;
`;

const Header = styled.div`
  height: 15px;
  margin-top: 15px;
`;

const ReminderEmailNotification = (props) => {
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { locale } = useSelector((state) => state.locale);
  const { useNotify, intl, pristine, submitting, initialize, handleSubmit, history } = props;
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  const { required, emptyString, inputLength, blockOfTextLength } = useValidation(intl);
  const notify = useNotify(intl);
  const { calendarActions } = useCalendarSetting(notify, uid);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.calendarSetting);
  const { reminderEmailCustomization, actions, isSuccess } = useCalendarEmailCustomizations(notify, uid);
  const [reminderEnabled, setReminderEnabled] = useState(true);
  const teamBookingPage = data?.pageType;
  const remindTimeOptions = [];
  for(let i = 0; i <= 23; i++) {
    remindTimeOptions.push({ value: i, label: `${i}${intl.formatMessage({...messages.remindHr})}`});
  }
  const remindDayOptions = [
    { 
      value: 'on_the_day', label: `${intl.formatMessage({ ...messages.remindDaySame })}`
    },
    {
      value: 'a_day_before', label: `${intl.formatMessage({ ...messages.remindDayBefore })}`
    }
  ];
  
  useEffect(() => {
    if(!teamBookingPage) {
      return history?.push(`/${settings}/${uid}/edit/email_notifications`);
    }
    actions.fetchCalendarEmailCustomizations();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTemplateUid(data?.bookingCalendarTemplateUid);
      const {
        subject,
        leadText,
      } = reminderEmailCustomization;
      const reminderAbsoluteDay =  { 
        value: reminderEmailCustomization?.reminderAbsoluteDay || 'on_the_day', 
        label:  (reminderEmailCustomization?.reminderAbsoluteDay === 'a_day_before') ? `${intl.formatMessage({ ...messages.remindDayBefore })}` : `${intl.formatMessage({ ...messages.remindDaySame })}`
      };
      const reminderAbsoluteHour =  { 
        value: reminderEmailCustomization?.reminderAbsoluteHour || 7, 
        label: `${(reminderEmailCustomization?.reminderAbsoluteHour || 7)}${intl.formatMessage({ ...messages.remindHr })}` 
      };
      const reminderAllowed = isSuccess ? reminderEmailCustomization?.reminderAllowed : true;
      initialize({
        subject,
        leadText,
        reminderAllowed: reminderAllowed,
        reminderAbsoluteDay,
        reminderAbsoluteHour,
        permissions: convertToOption(data?.permissions, intl),
      });
      setReminderEnabled(reminderAllowed);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [reminderEmailCustomization, locale, data]);

  const formatBody = (values) =>  {
    let body = {
      booking_calendar_email_customizations: {
        reminder_email_customization: {
          subject: values.subject,
          lead_text: values.leadText,
          reminder_allowed: values.reminderAllowed,
          reminder_absolute_day: values.reminderAbsoluteDay.value,
          reminder_absolute_hour: values.reminderAbsoluteHour.value
        }
      }
    };
    return body;
  };

  const middlewareGenerator = permissions => {
    if(isTemplatePage) {
      return promise => saveWithTemplateSettings(promise, permissions);
    }

    return promise => promise
  };

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }
  
  const handleOnSubmit = async (values) => {
    const body = formatBody(values);

    const middleware = middlewareGenerator(values.permissions);

    const result = actions.updateCalendarEmailCustomizations(body, middleware);

    if(isTemplatePage) {
      result.then(result => {
        if (result == null) return;

        dispatch({
          type: `${reduxActions.getCalendarSetting.name}_update`,
          payload: { data: { ...data, permissions: convertToPermission(values.permissions, false)}},
        });
      });
    } 
    else {
      calendarActions.getCalendarSetting();
    }
  };

  return (
    <Dashboard>
      <GoBackToEmailNotifications
        {...props}
      />
      <PlanBlocked mt="26px" plan="enterprise">
        <TemplateBlocked
          blocked={data?.permissions?.emailTypeReminder}
          header={<Header />}
          showHeaderOnlyOnBlock
          fields={
            <>
              <Row mt="25px">
                <Col xs>
                  <Label>
                    {intl.formatMessage({...messages.title})}
                    <Tooltip
                      message={intl.formatMessage({ ...messages.titleTooltip })}
                      width={340}
                    />
                  </Label>
                </Col>
              </Row>
              <Row mt="15px">
                <Col xs>
                  <Field
                    id="reminderAllowed"
                    name="reminderAllowed"
                    component={Checkbox}
                    useArrayValue={false}
                    onChange={(enabled) => setReminderEnabled(enabled)}
                  >
                    {intl.formatMessage({...messages.sendReminderEmail})}
                  </Field>
                </Col>
              </Row>
              {reminderEnabled &&
              <>
                <Row mt="25px">
                  <Col xs>
                    <Label>
                      {intl.formatMessage({...messages.sendReminderEmailTime})}
                      <Tooltip
                        message={intl.formatMessage({ ...messages.titleTooltip })}
                        width={340}
                      />
                    </Label>
                  </Col>
                </Row>
                <Row mt="15px">
                  <Col xs display="flex" alignItems="center">
                    <Field
                      name="reminderAbsoluteDay"
                      component={InputSelect}
                      width="115px"
                      options={remindDayOptions}
                    />
                    <InlineWrapper ml="7" mr="7">
                      {intl.formatMessage({...messages.remindHrPreText})}
                    </InlineWrapper>
                    <Field
                      name="reminderAbsoluteHour"
                      component={InputSelect}
                      width="115px"
                      options={remindTimeOptions}
                    />
                    <InlineWrapper ml="7">
                      {intl.formatMessage({...messages.remindHrPosText})}
                    </InlineWrapper>
                  </Col>
                </Row>
                <Row mt="25px">
                  <Col xs>
                    <Label>
                      {intl.formatMessage({...messages.subject})}
                    </Label>
                    <EmailVariableText
                      intl={intl}
                      elementId="subject"
                      form="ReminderEmailNotification"
                    />
                    <Field
                      id="subject"
                      name="subject"
                      component={Input}
                      placeholder={intl.formatMessage({...messages.subjectPlaceholder})}
                      validate={[required, emptyString, inputLength]}
                      height="36px"
                      borderRadius="0 0 5px 5px"
                    />
                  </Col>
                </Row>
                <Row mt="25px" mb="25px">
                  <Col xs>
                    <Label>{intl.formatMessage({...messages.leadText})}</Label>
                    <EmailVariableText
                      elementId="leadText"
                      intl={intl}
                      form="ReminderEmailNotification"
                    />
                    <Field
                      id="leadText"
                      name="leadText"
                      component={TextArea}
                      labelLeft={intl.formatMessage({...messages.leadText})}
                      placeholder={intl.formatMessage({...messages.leadTextPlaceholder})}
                      validate={[required, emptyString, blockOfTextLength]}
                      height="216px"
                      borderRadius="0 0 5px 5px"
                    />
                  </Col>
                </Row>
              </>
              }
            </>
          }
        />
        <Hr border="none" height="1px" color="#e5e5e5" mt="20px" />
        <Row mt="20px">
          <Col xs>
            <Button
              disabled={pristine || submitting}
              onClick={handleSubmit((values) => onSubmit(values))}
              fontWeight="500"
            >
              {intl.formatMessage({...messages.save})}
            </Button>
          </Col>
        </Row>
      </PlanBlocked>
    </Dashboard>
  );
};

export default reduxForm({
  form: 'ReminderEmailNotification'
})(injectIntl(ReminderEmailNotification));
