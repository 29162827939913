import styled from "styled-components";
import FontAwesome from "react-fontawesome";

export const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-right: 10px;
  align-items: center;
  min-width: 0;
  opacity: ${props => props?.disabled ? 0.4 : 1}
`;

export const FieldImageWrapper = styled.div`
  position: relative;
  display: table;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: rgba(247, 249, 249, 1);
`;

export const FieldIcon = styled(FontAwesome)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${props => props?.color ? props?.color : '#00bbb5'};
`;

export const FieldImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FieldName = styled.span`
  font-size: 15px;
  color: #68878d;
  width: calc(100% - 80px);
  margin-left: ${props => props?.left ? props?.left : '0px'};
`;

export const FieldLabel = styled.div`
  font-size: 15px;
  color: #314143;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
