import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { Label } from "@d-lighted/design-system/dist/index";
import { MOBILEBREAKPOINT } from "../../../constants/breakpoint";

const TableHeadItem = styled.th`
  text-align: left;
  height: 80px;
  vertical-align: middle;
  padding-left: ${props => (props.first ? '40px' : '')};
  @media (max-width: ${MOBILEBREAKPOINT}){
    padding-left: 24px;
  }
`;

const THead = styled.thead``;

function TableHeadList({ cols }) {
  const tableItems = cols.map((col, key) => {
    switch (key) {
      case (0):
        return <TableHeadItem first key={key}><Label>{col}</Label></TableHeadItem>;
      case (col.length):
        return <TableHeadItem key={key}><Label>{col}</Label></TableHeadItem>;
      default:
        return <TableHeadItem key={key}><Label>{col}</Label></TableHeadItem>;
    }
  });
  return (
    <THead>
      <tr>
        {tableItems}
      </tr>
    </THead>
  );
}

TableHeadList.propTypes = {
  tableData: PropTypes.array
}

export default TableHeadList;
