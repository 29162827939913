import { useState } from "react";
import { _get } from "../../../utils/api";

function useEmployee() {
  const [isLoading, setLoading] = useState(false);

  const handleEmployeeSearch = async(value, callback) => {
    setLoading(true);
    let url = `/employees/search_with_authorized_schedulers?name=${value}`;
    let result = await _get(url);
    if (result.status >= 200 && result.status < 300) {
      callback(result?.data?.employees);
    }
    setLoading(false);
  };

  return {
    isEmployeeLoading: isLoading,
    employeeActions: {
      handleEmployeeSearch,
    },
  };
}

export { useEmployee };
