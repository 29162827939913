import { defineMessages } from 'react-intl';

export default defineMessages({
  checked: {
    id: 'validation.checked',
    defaultMessage: 'Check required field.',
  },
  conferenceRoomLimit: {
    id: 'validation.conferenceRoomLimit',
    defaultMessage: 'The number of conference room should not exceed 5.',
  },
  individualTypeConferenceRoomLimit: {
    id: 'validation.individualTypeConferenceRoomLimit',
    defaultMessage: 'The number of conference room should not exceed 20.',
  },
  durationsLimit: {
    id: 'validation.durationsLimit',
    defaultMessage: 'The number of available durations should be more than 1 and not exceed 5.',
  },
  descriptionLength: {
    id: 'validation.descriptionLength',
    defaultMessage: 'The number of conference room should not exceed 5.',
  },
  inputLength: {
    id: 'validation.inputLength',
    defaultMessage: 'Please do not exceed 255 characters.',
  },
  passwordLength: {
    id: 'validation.passwordLength',
    defaultMessage: 'Must be at least 8 characters.',
  },
  passwordString: {
    id: 'validation.passwordString',
    defaultMessage: 'Use at least three of the following types of characters: uppercase letters, lowercase letters, numbers, and special symbols.',
  },
  email: {
    id: 'validation.email',
    defaultMessage: 'Please enter a valid email address.',
  },
  companyEmailDomain: {
    id: 'validation.companyEmailDomain',
    defaultMessage: 'Please enter your company domain',
  },
  required: {
    id: 'validation.required',
    defaultMessage: 'Required field.',
  },
  emptyString: {
    id: 'validation.emptyString',
    defaultMessage: 'Empty strings are not allowed.',
  },
  bookingUrl: {
    id: 'validation.bookingUrl',
    defaultMessage: 'Please enter a valid URL.'
  },
  bookingUrlVariable: {
    id: 'validation.bookingUrl.variable',
    defaultMessage: 'The keyword {予約ページURL} is required where you want the URL to appear'
  },
  url: {
    id: 'validation.url',
    defaultMessage: 'Please enter a valid URL.'
  },
  availableEventPrefixesLength: {
    id: 'validation.availableEventPrefixesLength',
    defaultMessage: 'The number of keywords should not exceed 10.',
  },
  blockOfTextLength: {
    id: 'validation.blockOfTextLength',
    defaultMessage: 'Please do not exceed 2000 characters.'
  },
  imageSizeLength: {
    id: 'validation.imageSizeLength',
    defaultMessage: 'Max allowed size is 5MB.'
  },
  privacy: {
    id: 'validation.privacy',
    defaultMessage: 'You must agree to the Privacy Policy.'
  },
  httpsUrlRequired: {
    id: 'validation.httpsUrlRequired',
    defaultMessage: 'Please enter a valid URL.'
  },
  positiveNumber: {
    id: 'validation.positiveNumber',
    defaultMessage: 'Please enter a value over 0.'
  },
  phoneNumberRequired: {
    id: 'validation.PhoneNumberRequired',
    defaultMessage: 'Please enter a valid URL.'
  }
});
