import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { Input, Loader } from '@d-lighted/design-system';
import { debounce } from 'lodash';
import SendEmailInvitation from './SendEmailInvitation';
import {OnClickOutside} from "../../../../hooks/onClickOutside";
import { MOBILEBREAKPOINT } from '../../../../constants/breakpoint';

const Wrapper = styled.div`
  position: absolute;
  display: block;
  padding: 24px 20px 14px 20px;
  width: 465px;
  background-color: #ffffff;
  box-shadow: 0 20px 26px 0 rgba(63, 78, 90, 0.14);
  overflow: hidden;
  z-index: 3;
  margin: -54px -18px;
  border-radius: 12px;
  @media (max-width: ${MOBILEBREAKPOINT}){
    margin-left: -6px;
    width: calc(100% - 16%);
  }
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  color: #484e62;
`;

const SearchInput = styled(Input)`
  height: 49px;
  padding: 14px;
  border-radius: 4.4px;
  border: 1px solid #e9eff4;
  &::placeholder{
    color: rgba(20, 44, 43, 0.2);
  }
`;

const MemberList = styled.div`
  display: block;
  width: 100%;
  overflow-y: auto;
  z-index: 100;
  padding-top: 20px;
  list-style: none;
  max-height: 220px;
`;

const Anchor = styled.a`
  font-size: 12px;
  font-weight: 300;
  color: #00bbb5;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const ListItem = styled.div`
  color: #484e62;
  font-size: 14px;
  font-weight: 200;
  height: 52px;
`;

const LoaderAnchor = styled.div`
  height: 56px;
  width: 56px;
  margin: 0 auto;

  &> * {
    height: 56px;
    width: 56px;
    margin-top: -22px;
  }
`;

const NoSuchMemberWrapper = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 16px;
  color: #68878d;
`;

const SelectionDropdown = (props) => {
  const {
    toggle,
    loading,
    handleAdd,
    handleSearch,
    searchPlaceholder,
    headerText,
    closeText,
    attendeeType,
    memberSearchList,
    memberSearchInput,
    searchFieldTouched,
    noSuchMember,
    isQueryEmail,
    invitationText,
    approvalPendingText,
    sendInvitation,
    emailInvitationStatus,
  } = props;

  const comparator = props.comparator || (() => false);
  const [userSearchInput, setUserSearchInput] = useState(memberSearchInput);
  const RecordComponent = props.recordComponent;
  const handleDebouncedSearch = useCallback(debounce((value) => handleSearch(value), 500), []);
  const {isEmail, query} = isQueryEmail;
  const wrapperRef = useRef(null);
  OnClickOutside(wrapperRef, toggle);

  const updateQuery = async e => {
    const targetValue = e?.target?.value;
    setUserSearchInput(targetValue);
    handleDebouncedSearch(targetValue);
  };

  const handleClick = (employee) => {
    handleAdd(employee, attendeeType)
  };

  return (
    <Wrapper ref={wrapperRef}>
      <Header>
        {headerText}
        <Anchor onClick={toggle}>
          {closeText}
        </Anchor>
      </Header>
      <SearchInput
        value={userSearchInput}
        width="100%"
        placeholder={searchPlaceholder}
        onChange={updateQuery}
      />
      <MemberList>
        {loading && <LoaderAnchor>
          <Loader />
        </LoaderAnchor>}
        {!loading && searchFieldTouched && memberSearchList.length === 0 && !isEmail && <NoSuchMemberWrapper>{noSuchMember}</NoSuchMemberWrapper>}
        {!loading && memberSearchList.length === 0 && isEmail && 
          <SendEmailInvitation item={query} emailInvitationStatus={emailInvitationStatus} invitationText={invitationText} handleClick={() => sendInvitation(query, attendeeType)} approvalPendingText={approvalPendingText} /> }
        {!loading && (memberSearchList || []).map((item, index) => {
          const selected = comparator(item);
          return (
            <ListItem key={index}>
              { RecordComponent ? <RecordComponent item={item} selected={selected} approvalPendingText={approvalPendingText} handleClick={handleClick} /> : item.name }
            </ListItem>
          )
        })}
      </MemberList>
    </Wrapper>
  )
};

export default SelectionDropdown;