import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconNew as Icon} from '@d-lighted/design-system';

const Blocked = styled.div`
  pointer-events: none;
  user-select: none;
  opacity: 0.2;
  margin-top: -16px;
`;

const Warning = styled.div`
  background-color: #e6f4ef;
  border-radius: 5px;
  box-sizing: border-box;
  color: #68878d;
  font-family: NotoSansJP, sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  padding: 13px 12px 13px 36px;
  text-align: left;
  margin-top: ${props => props.mt};
  margin-bottom: 8px;
  
  & a {
    color: #00bbb5;
  }
`;

const InfoIcon = styled(Icon)`
  position: absolute;
  margin-left: -24px;
`;

export default function RestrictContainerBox({
  isAvailable,
  children,
  guideText,
}) {
  if(isAvailable) return (<>{children}</>);

  return (
    <div>
      <Warning mt="40px">
        <InfoIcon
          className="material-icons"
          fontSize="17px"
          color="#9DDBD6"
        >
          info
        </InfoIcon>
        {guideText}
      </Warning>
      <Blocked>
        {children}
      </Blocked>
    </div>
  );
}

RestrictContainerBox.defaultProps = {
  mt: '0',
};

RestrictContainerBox.propTypes = {
  children: PropTypes.node.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  guideText: PropTypes.node.isRequired,
};