export function downloadFile(url, fileName) {
  const a = document.createElement('a');
  a.style.cssText = 'display: none';
  a.href = url;
  if ('download' in a) {
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const download = event => {
      event.preventDefault();
      window.navigator.msSaveOrOpenBlob(url, fileName);
    };
    a.addEventListener('click', download);
    document.body.appendChild(a);
    a.click();
    a.removeEventListener('click', download);
    document.body.removeChild(a);
  } else {
    throw Error('File download not supported.')
  }
}
