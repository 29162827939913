import styled from "styled-components";

export const SortableListWrapper = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const SortableItemWrapper = styled.div`
  padding: 1px;
  margin: 0 0 1px;
  text-align: center;
  color: #FFF;
`;
