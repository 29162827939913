import { createAction, createReducer } from "@reduxjs/toolkit";

export const TYPES = { 
  ALL: 'all',
  ATTENDEE: 'attendee',
  EDITOR: 'editor',
  TEMPLATE: 'template',
  QUERY: 'query',
  QUERY_EMPLOYEE: 'q_employee',
};

export const TABS = { all: 2, attendee: 0, editor: 1, template: 3 };

const INITIAL_STATUS = {
  all: {
    calendars: [],
    currentPage: 0,
    totalPages: 0,
    sort: "created_asc",
  },
  attendee: {
    calendars: [],
    currentPage: 0,
    totalPages: 0,
    sort: "created_asc",
  },
  editor: {
    calendars: [],
    currentPage: 0,
    totalPages: 0,
    sort: "created_asc",
  },
  query: {
    calendars: [],
    currentPage: 0,
    totalPages: 0,
    sort: "created_asc",
  },
  q_employee: {
    calendars: [],
    currentPage: 0,
    totalPages: 0,
    sort: "created_asc",
  },
  teamCalendarCount: null,
  teamCalendarLimit: null,
};

export const fetchCalendars = createAction('fetch_calendars');

export const resetCalendars = createAction('reset_calendars');

const calendarsReducer =  createReducer({ ...INITIAL_STATUS },{
    'fetch_calendars': (state, action) => {
      return { ...state, ...action.payload };
    },
    'reset_calendars': () => {
      return { ...INITIAL_STATUS };
    }
  }
);

export default calendarsReducer;
