import React from "react";
import {
  Row,
} from "@d-lighted/design-system";
import RequiredMark from "./RequiredMark";
import SmallCalendar from "./SmallCalendar";

export default function DateField({ id, data }) {
  const required = data.bookingCalendarSettingBookingCalendarCustomField.inputRequired;
  return (
    <Row key={data.uid} mt="15px" alignItems="center" mb="1rem">
      <SmallCalendar
        id={id}
        labelLeft={
          <span>
            {data.labelName}
            {required && <RequiredMark />}
          </span>
        }
        placeholder="" />
    </Row>
  )
}
