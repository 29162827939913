import React, { useEffect } from "react";
import styled from "styled-components";
import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import { 
  Row,
  Col,
  Button,
  Label,
} from "@d-lighted/design-system";
import { getCurrentUser  } from "../../../../utils/userUtils";
import SalesforceIcon from "../../../../assets/img/icon_salesforce.svg";
import ProductLogo from "../../../../assets/img/logo_mailer.svg";
import IconMembers from "../../../../assets/img/icon_members.png";
import IconSettings from "../../../../assets/img/icon_settings.png";
import IconZoom from "../../../../assets/img/zoom.svg";
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../../../constants/breakpoint";
import { Link } from "../../components/Link";
import { pushToGA, events } from "../../../../utils/gtmUtils";
import { leaveOnboarding, toOnboardingPage } from "../../utils/utils";
import { getOnboardingState } from "../../utils/utils";
import { useAuth } from "../../../../hooks/useAuth";

const BodyWrapper = styled.div`
  padding-left: 60px;
  min-height: 100%;
  max-width: 100%;

  @media (max-width: ${MOBILEBREAKPOINT}){
    padding: 0 18px;
    width: calc(100% - 36px);
  }
`;

const Wrapper = styled.div`
  max-width: 100%;
`;

const Section = styled.div`
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 283px;
  background-color: white;
  border: 1px solid #E7E9EA;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  flex: 1 1 40%;
  float: left;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  position: relative;
`;

const HeaderImage = styled.img`
  margin-left: 30px;
  width: 100px;
  height: 50px;
  padding-right: 25px;
  color: #314143;
`;

const SubText = styled.p`
  max-width: 100%;
  word-wrap: break-word;
`;

const MoreCustomizations = props => {
  const { innerWidth } = window;
  const isMobileView = SHOW_MOBILE(innerWidth);
  const mobileProps = isMobileView ? {xs: 12} : {};
  const { data } = useSelector(state => state?.calendarSetting);
  const baseSettingsUrl = `/booking_calendars/${data?.uid}`;
  const isNotWebConference = data?.meetingType !== 'web_conference';
  const onboarding = getOnboardingState();
  const { authActions } = useAuth();

  useEffect(() => {
    if(!onboarding?.allowed || onboarding?.page !== "more") return leaveOnboarding(props);
    if(!getCurrentUser()?.company) return toOnboardingPage(props, "register");
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = (uri) => {
    pushToGA({...events.leave_onboarding("Ob/more_customizations")});
    return leaveOnboarding(props, uri);
  };

  const toWebApp = async() => {
    const url = await authActions.getOneTimeAccessUrl('/info/integrations');
    return window.location.href = url;
  };

  return (
    <Wrapper>
      <HeaderImage
        className="footer_page_image align-text-bottom"
        src={ProductLogo}
        alt="logo"
      />
      <BodyWrapper>
        <Row mt="17px">
          <Col {...mobileProps}>
            <Row>
              <Col {...mobileProps}>
                <Label
                  fontSize={isMobileView ? "22px" : "24px"}
                  fontWeight={isMobileView ? "600" : "700"}
                  letterSpacing="1px"
                  color="#484F63"
                >
                  <SubText>
                    もっと予約ページをカスマイズしましょう
                  </SubText>
                </Label>
                <Label fontSize="15px">
                  ここでは人気のカスタマイズ項目をご用意しました。あなたのビジネス専用のオリジナルページを作成できます。
                </Label>
              </Col>
            </Row>
          </Col>
          <Col xs={isMobileView ? 12 : 1}>
            <Button
              fontWeight="500"
              width={isMobileView ? "100%" : "329px"}
              marginTop={isMobileView ? "20px" : 0}
              onClick={() =>  handleRedirect("/")}
            >
              スキップして管理画面へ
            </Button>
          </Col>
        </Row>
        <Row mt="40px">
          <Col xs={12} sm={11} md={5} lg={4}>
            <Section>
              <Label
                fontSize="12px"
                fontWeight="700"
                color="#68878D"
              >
                メンバー設定
              </Label>
              <Row mt="10px" display={isMobileView ? "block" : "flex"}>
                <Col>
                  <Icon src={IconMembers}/>
                </Col>
                <Col>
                  <Label
                    fontSize="24px"
                    fontWeight="700"
                    color="#484F63"
                  >
                    同僚を招待して代理調整
                  </Label>
                </Col>
              </Row>
              <Row mt="10px">
                <Label fontSize="18px">
                  お客様が予約ページから予約確定後、Salesforce上に新規リードの作成。
                  または登録済みのリード・取引先責任者に対して、新規行動を自動的に作成することができます。
                </Label>
              </Row>
              <Row>
                <Link 
                  onClick={() => handleRedirect(`${baseSettingsUrl}/edit/meetings`)}
                >
                  カスタマイズする ➔
                </Link>
              </Row>
            </Section>
          </Col>
          <Col xs={12} sm={11} md={5} lg={4}>
            <Section>
              <Label
                fontSize="12px"
                fontWeight="700"
                color="#68878D"
              >
                外部連携
              </Label>
              <Row mt="10px">
                <Col>
                  <Icon src={SalesforceIcon}/>
                </Col>
                <Col>
                  <Label
                    fontSize="24px"
                    fontWeight="700"
                    color="#484F63"
                  >
                    Salesforce連携
                  </Label>
                </Col>
              </Row>
              <Row mt="10px">
                <Label fontSize="18px">
                  お客様が予約ページから予約確定後、Salesforce上に
                  新規リードの作成。または登録済みのリード・取引先
                  責任者に対して、新規行動を自動的に作成することが
                  できます。
                </Label>
              </Row>
              <Row>
                <Link 
                  onClick={() => handleRedirect(`${baseSettingsUrl}/integrations/salesforce`)}
                >
                  カスタマイズする ➔
                </Link>
              </Row>
            </Section>
          </Col>
        </Row>
        <Row>
        {isNotWebConference && <Col xs sm={11} md={5} lg={4}>
            <Section>
              <Label
                fontSize="12px"
                fontWeight="700"
                color="#68878D"
              >
                会議設定
              </Label>
              <Row mt="10px">
                <Col>
                  <Icon src={IconZoom}/>
                </Col>
                <Col>
                  <Label
                    fontSize="24px"
                    fontWeight="700"
                    color="#484F63"
                  >
                    Zoom連携
                  </Label>
                </Col>
              </Row>
              <Row mt="10px">
                <Label fontSize="18px">
                  お客様が予約ページから予定を登録すると、Web会議URLを自動発行します。
                  Web会議プラットフォームは他にもGoogleMeet, Microsoft Teamsにも対応しています。
                </Label>
              </Row>
              <Row>
                <Link 
                  onClick={() => toWebApp()}
                >
                  カスタマイズする ➔
                </Link>
              </Row>
            </Section>
          </Col>}
          <Col xs={12} sm={11} md={5} lg={4}>
            <Section>
              <Label
                fontSize="12px"
                fontWeight="700"
                color="#68878D"
              >
                日時設定
              </Label>
              <Row mt="10px" display={isMobileView ? "block" : "flex"}>
                <Col>
                  <Icon src={IconSettings}/>
                </Col>
                <Col>
                  <Label
                    fontSize="24px"
                    fontWeight="700"
                    color="#484F63"
                  >
                    営業時間・バッファなどの設定
                  </Label>
                </Col>
              </Row>
              <Row mt="10px">
                <Label fontSize="18px">
                  予約を受け付ける曜日と曜日ごとの営業時間を指定できます。
                  祝日は予約できないようにすることも可能です。
                  また、確定している予定の前後に、予約を入れられない時間帯（バッファ）の設定もできます。
                </Label>
              </Row>
              <Row>
                <Link 
                  onClick={() => handleRedirect(`${baseSettingsUrl}/edit/availability/business_hours`)}
                >
                  カスタマイズする ➔
                </Link>
              </Row>
            </Section>
          </Col>
        </Row>
      </BodyWrapper>
    </Wrapper>
  );
}

export default reduxForm({
  form: 'MoreCustomizations'
})(MoreCustomizations);
