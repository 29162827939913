import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Layouts } from "components/layouts/Layouts";
import { homeRoutes } from "views/home/homeRoutes";
import { availabilityRoutes } from "views/availability/availabilityRoutes";
import { reservationFormRoutes } from "views/booking/bookingRoutes";
import { meetingsRoutes } from "views/meetings/meetingsRoutes";
import { notificationsRoutes } from "views/notifications/notificationsRoutes";
import { signInRoutes } from "views/signIn/signInRoutes";
import { appointmentsRoutes } from "views/appointments/appointmentsRoutes";
import { integrationRoutes } from "views/integrations/integrationRoutes";
import { multiguestRoutes } from "views/multiguest/multiguestRoutes";
import { onboardingRoutes } from "views/onboarding/onboardingRoutes";
import { templatesRoutes } from "views/templates/templatesRoutes";
import { getPlan, PLANS, getCurrentUser, getSubdomain } from "utils/userUtils";
import { getOnboardingState, toOnboardingPage } from "views/onboarding/utils/utils";
import { pushToGA, events } from "utils/gtmUtils";

const combineRoutes = [
  ...appointmentsRoutes,
  ...meetingsRoutes,
  ...homeRoutes,
  ...availabilityRoutes,
  ...reservationFormRoutes,
  ...notificationsRoutes,
  ...signInRoutes,
  ...integrationRoutes,
  ...onboardingRoutes,
  ...multiguestRoutes,
  ...templatesRoutes,
];

const HelmetGeneration = ({ path }) => {
  return <Helmet
    title={ path !== "/sign_in" ? "日程調整ツール「調整アポ」" : "ログイン - 日程調整ツール「調整アポ」" }
  />
}

export const routeGenerator = ({ ...props }) => {
  return combineRoutes
    .map((route) => (route.type === "public") 
      ? publicRoute(route, props) : privateRoute(route, props)
    );
};

const triggerPageView = (gauid) => {
  if (gauid) {
    pushToGA({...events.triggerPageView(gauid)});
  }
};

const publicRoute = (route, props) =>
  <Route
    key={route.path}
    path={route.path}
    exact={route.exact}
    render={(restProps) => {
      triggerPageView(route.gauid);
      return (
        <Layouts label={route.feature} {...restProps} {...props}>
          <HelmetGeneration path={route.path} />
          <route.component {...restProps} {...props} />
        </Layouts>
      );
    }}
  />
;
  
const privateRoute = (route, props) =>
  <Route
    key={route.path}
    path={route.path}
    exact={route.exact}
    render={(restProps) => {
      const currentUser = getCurrentUser();
      const authenticated = !!currentUser?.id;
      const subdomain = getSubdomain();

      if (
        currentUser != null &&
        authenticated &&
        route.path !== '/sign_in' &&
        window.location.host !== `${subdomain}${process.env.REACT_APP_SCHEDULING_WEB_PATH}`
      ) {
        localStorage.clear();
        window.location.href = `${window.location.protocol}//${subdomain}${process.env.REACT_APP_SCHEDULING_WEB_PATH}/sign_in`;
      }

      if (!authenticated)
        return <Redirect
          to={{
            pathname: "/sign_in",
            search: window.location.search
          }}
        />;
      if(route.feature !== "onboard" && getOnboardingState()?.allowed) return <Redirect to={{ pathname: toOnboardingPage(restProps, getOnboardingState()?.page) }} />;
      if(route.path !== "/sign_in" && route.path !== "/" && (getPlan() === PLANS.SUSPENDED)) {
        return <Redirect to={{ pathname: "/" }} />;
      }
      if (route.validation == null || route.validation()) {
        triggerPageView(route.gauid);
        return (
          <Layouts label={route.feature} {...restProps} {...props}>
            <HelmetGeneration path={route.path} />
            <route.component {...restProps} {...props} />
          </Layouts>
        );
      }
      
      return <Redirect to={{ pathname: "/" }} />;
    }}
  />
;
