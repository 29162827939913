/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { FormattedMessage, injectIntl} from 'react-intl';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import {
  TextArea,
  Button,
  Label,
  Input,
  Col,
  Row,
  Hr
} from "@d-lighted/design-system";
import { useValidation } from 'utils/validation';
import { actions as reduxActions } from "redux/actions";
import { Dashboard } from "components/layouts/Dashboard";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import { convertToOption, convertToPermission } from "components/forms/TemplatePermissionSelector";

import messages from '../i18n/CompleteEmailNotification';
import GoBackToEmailNotifications from "../components/GoBackToEmailNotifications";
import { EmailVariableText } from "../components/EmailVariableText";
import { useCalendarEmailCustomizations } from "../hooks/useCalendarEmailCustomizations";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { useCalendarSetting } from "hooks/useCalendarSetting";

const Header = styled.div`
  height: 15px;
  margin-top: 15px;
`;

function CompleteEmailNotification({ handleSubmit, intl, ...props }) {
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const notify = props.useNotify(intl);
  const { pristine, submitting } = props;
  const { required, emptyString, inputLength, blockOfTextLength } = useValidation(intl);
  const { data } = useSelector(state => state.calendarSetting);
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  const dispatch = useDispatch();
  const { bookingCompleteCustomization, actions, isSuccess } = useCalendarEmailCustomizations(notify, uid);
  const { calendarActions } = useCalendarSetting(notify, uid);
  const teamBookingPage = data?.pageType;
  
  useEffect(() => {
    if(!teamBookingPage) {
      return props?.history?.push(`/${settings}/${uid}/edit/email_notifications`);
    }
    actions.fetchCalendarEmailCustomizations();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTemplateUid(data?.bookingCalendarTemplateUid);
      const {
        subject,
        leadText,
      } = bookingCompleteCustomization || {};
      props.initialize({
        subject,
        leadText,
        permissions: convertToOption(data?.permissions, intl),
      });
    }
  }, [bookingCompleteCustomization, data]);

  function formatBody(values) {
    let body = {
      booking_calendar_email_customizations: {
        booking_complete_email_customization: {
          subject: values.subject,
          lead_text: values.leadText
        }
      },
    };
    return body;
  }

  const middlewareGenerator = permissions => {
    if(isTemplatePage) {
      return promise => saveWithTemplateSettings(promise, permissions);
    }

    return promise => promise
  };

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    const body = formatBody(values);

    const middleware = middlewareGenerator(values.permissions);

    const result = actions.updateCalendarEmailCustomizations(body, middleware);

    if(isTemplatePage) {
      result.then(result => {
        if (result == null) return;

        dispatch({
          type: `${reduxActions.getCalendarSetting.name}_update`,
          payload: { data: { ...data, permissions: convertToPermission(values.permissions, false)}},
        });
      });
    } else {
      calendarActions.getCalendarSetting();
    }
  };

  return (
    <Dashboard>
      <GoBackToEmailNotifications
        {...props}
      />
      <form>
        <PlanBlocked mt="26px" plan="enterprise">
          <TemplateBlocked
            blocked={data?.permissions?.emailTypeCompletion}
            header={<Header />}
            showHeaderOnlyOnBlock
            fields={
              <>
                <Row mb="26px" mt="26px">
                  <Col xs>
                    <Label>{intl.formatMessage({...messages.subject})}</Label>
                    <EmailVariableText
                      intl={intl}
                      elementId="subject"
                    />
                    <Field
                      id="subject"
                      name="subject"
                      component={Input}
                      placeholder={intl.formatMessage({...messages.subjectPlaceholder})}
                      validate={[required, emptyString, inputLength]}
                      height="36px"
                      borderRadius="0 0 5px 5px"
                    />
                  </Col>
                </Row>
                <Row mb="26px">
                  <Col xs>
                    <Label>{intl.formatMessage({...messages.leadText})}</Label>
                    <EmailVariableText
                      elementId="leadText"
                      intl={intl}
                    />
                    <Field
                      id="leadText"
                      name="leadText"
                      component={TextArea}
                      labelLeft={intl.formatMessage({...messages.leadText})}
                      placeholder={intl.formatMessage({...messages.leadTextPlaceholder})}
                      validate={[required, emptyString, blockOfTextLength]}
                      height="216px"
                      borderRadius="0 0 5px 5px"
                    />
                  </Col>
                </Row>
              </>
            }
          />
        </PlanBlocked>
        <PlanBlocked mt="26px" width="400px" plan="standard">
          <Hr border="none" height="1px" color="#e5e5e5" my="20px" />

          <Button
            disabled={pristine || submitting}
            onClick={handleSubmit((values) => onSubmit(values))}
            fontWeight="500"
          >
            <FormattedMessage {...messages.save} />
          </Button>
          <div style={{ height: "20px" }} />
        </PlanBlocked>
      </form>
    </Dashboard>
  )
}

CompleteEmailNotification.propTypes = {};

export default reduxForm({
  form: "CompleteEmailNotification"
})(injectIntl(CompleteEmailNotification));
