import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Col,
  Row
} from "@d-lighted/design-system/dist/index";
import { FormattedMessage } from 'react-intl';
import messages from '../i18n/Dashboard';
import { useAuth } from '../../../hooks/useAuth';
import { events, pushToGA } from '../../../utils/gtmUtils';

const Wrapper = styled.div`
  margin-top: 200px;
`;

const LinkButton = styled.a`
  padding-left: 5px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  width: 200px;
  min-width: 120px;
  height: 44px;
  font-size: 16px;
  color: #ffffff;
  background-color: #00bbb5;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-weight: normal;
  padding-top: 12px;
    text-decoration: none;
  
  &:hover { 
    background-color: #259590;
  }
`;

const MessageHeader = styled(Col)`
  margin-top: 20px;
  color: #68878d;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 1px;
  text-align: center;
`;

const MessageContent = styled(Col)`
  margin-top: 12px;
  color: #68878d;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.1px;
  text-align: center;
  white-space: pre-line;
`;

export default function DashboardSuspended() {
  const { authActions } = useAuth();

  const openBillingtPage = async() => {
    pushToGA({...events.click_link_sp_billing()});
    const accessUrl = await authActions.getOneTimeAccessUrl('/info/billing');
    window.open(accessUrl, "_blank");
  };

  return (
    <Wrapper>
      <Grid fluid>
        <Row>
          <Col xs display="flex" justifyContent="center">
            <img
              src={require("../../../assets/img/pic_calendar.png")}
              alt="calendar_image"
            />
          </Col>
        </Row>
        <Row>
          <MessageHeader xs display="flex" justifyContent="center">
            <FormattedMessage {...messages.suspendedTitle} />
          </MessageHeader>
        </Row>
        <Row mb="22px">
          <MessageContent xs display="flex" justifyContent="center">
            <FormattedMessage {...messages.suspendedContent} />
          </MessageContent>
        </Row>
        <Row>
          <Col xs display="flex" justifyContent="center">
            <LinkButton target="_blank" onClick={() => openBillingtPage()}>
              <FormattedMessage {...messages.suspendedButton} />
            </LinkButton>
          </Col>
        </Row>
      </Grid>
    </Wrapper>
  )
}

DashboardSuspended.propTypes = {};