import React from 'react';
import { Label, Row, Col } from '@d-lighted/design-system';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MessageArea = styled.p`
  height: auto;
  white-space: pre-line;
  margin-top: 5px;
`;

export default function BlockOfTextCase(props) {
  const { labelName, bookingCalendarCustomFieldInputs } = props.data;
  const message = bookingCalendarCustomFieldInputs[0]?.inputTextAreaValue || bookingCalendarCustomFieldInputs?.input_text_area_value;

  return (
    <Row mt="15px" alignItems="center" mb="1rem">
      <Col pl="0px" xs>
        <Label>{labelName}</Label>
        <MessageArea xs>{message}</MessageArea>
      </Col>
    </Row>
  );
}

BlockOfTextCase.defaultProps = {};

BlockOfTextCase.propTypes = {
  data: PropTypes.object.isRequired
};
