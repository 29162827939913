import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  freeField: {
    id: 'views.customField.freeField',
    defaultMessage: 'Free input (max 255 letter)',
  },
  saveSuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  saveError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: "Booking settings couldn't be saved.",
  },
  backToList: {
    id: 'views.customField.backToList',
    defaultMessage: 'Back to list',
  },
  fieldType: {
    id: 'views.customField.fieldType',
    defaultMessage: 'Field type',
  },
  questionToGuest: {
    id: 'views.customField.questionToGuest',
    defaultMessage: 'Question to guest',
  },
  questionPlaceholder: {
    id: 'views.customField.questionPlaceholder',
    defaultMessage: 'Enter a question',
  },
  messageTitle: {
    id: 'views.customField.messageTitle',
    defaultMessage: 'Title',
  },
  messagePlaceholder: {
    id: 'views.customField.messagePlaceholder',
    defaultMessage: 'Points to note',
  },
  requiredField: {
    id: 'views.customField.required',
    defaultMessage: 'Make this field required',
  },
  hiddenField: {
    id: 'views.customField.hidden',
    defaultMessage: 'Hide this field',
  },
  companyNameField: {
    id: 'views.customField.companyNameField',
    defaultMessage: 'Company name',
  },
  guestNameField: {
    id: 'views.customField.nameField',
    defaultMessage: 'Guest name',
  },
  salesforceFieldTitle: {
    id: 'views.customField.salesforceFieldTitle',
    defaultMessage: 'Salesforce field name',
  },
  useOwnPrivacyPolicy: {
    id: 'views.customField.useOwnPrivacyPolicy',
    defaultMessage: 'Use own Privacy Policy',
  },
  privacyPolicyUrl: {
    id: 'views.customField.privacyPolicyUrl',
    defaultMessage: 'Privacy Policy URL',
  },
  privacyPolicyText: {
    id: 'views.customField.privacyPolicyText',
    defaultMessage: 'Privacy Policy Text',
  },
  privacyPolicyTextPlaceholder: {
    id: 'views.customField.privacyPolicyTextPlaceholder',
    defaultMessage: 'I agree to {Privacy Policy}',
  },
  privacyPolicyTextTooltip: {
    id: 'views.customField.privacyPolicyTextTooltip',
    defaultMessage: 'Enclose link text inside {}',
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
