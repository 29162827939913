import React from "react";
import { Field } from "redux-form";
import styled from "styled-components";
import FontAwesome from "react-fontawesome";
import { injectIntl } from "react-intl";
import {
  Avatar,
  Checkbox,
  Col,
  Label,
  Row,
} from "@d-lighted/design-system";
import userDefault from "../../../assets/img/userDefault.png";
import google_icon from "../../../assets/img/g_calendar_icon.png";
import outlook_icon from "../../../assets/img/outlook_icon.png";
import garoon_icon from "../../../assets/img/garoon_icon.png";
import zoom_icon from "../../../assets/img/zoom.svg";
import webex_icon from "../../../assets/img/ico_webex.svg";
import meet_icon from "../../../assets/img/g_meet_icon.svg";
import teams_icon from "../../../assets/img/ms_teams_icon.svg";
import { 
  calendarIntegratedUser,
  zoomNotAllowedUser,
  webexNotAllowedUser,
  meetNotAllowedUser,
  teamsConfNotAlloweduser,
} from "./utils";
import ToolWarning from "../../elements/ToolWarning"
import ToolInfo from "../../elements/ToolInfo";
import { DragIcon, Line } from "../../elements/DragIcon";
import { pushToGA, events } from "../../../utils/gtmUtils";
import { generateHelpUrl, isGaroonIntegrated, getScheduler } from "../../../utils/userUtils";
import { MOBILEBREAKPOINT } from "../../../constants/breakpoint";
import messages from "./i18n/MemberSelection";

const DragIconWrapper = styled.div`
  margin-top: 8px;
`;

const MemberFieldWrapper = styled.div`
  box-sizing: border-box;
  margin: 0px 10px;
  padding: 8px;
  display: flex;
  border-radius: 5px;
  width: 100%;
  border: solid 1px #d2e9e7;
  align-items: center;
  &:hover {
    border-color: #00bbb5;
  }
`;

const LabelLight = styled(Label)`
  font-size: 14px;
  font-weight: 200;
  top: 3px;
  position: relative;
`;

const ClickableIcon = styled.div`
  cursor: pointer;
`;

const Icon = styled(FontAwesome)`
  color: #e84c3d;
`;

const ItemRight = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: ${MOBILEBREAKPOINT}){
    margin-right:0;
  }
`;

const ItemIcons = styled.span`
  display: block;
  padding-top: 5px;
  width: 100%;
  @media (max-width: ${MOBILEBREAKPOINT}){
    margin-right:0;
  }
`;

const FieldWrapper = styled.div`
  min-width: ${props => props.isBigScreen ? '68%' : '40%'};
  word-wrap: break-word;
  text-align: left;
`;

const ApprovalPending = styled.div`
  font-size: 10px;
  margin-top: 4px;
  color: #788584;
`;

const MemberComponent = (props) => {
  const { 
    intl,
    baseIndex, 
    draggable,
    fieldIndex, 
    member,
    willAttend, 
    pageType, 
    handleAttendeeStateChange, 
    removeAndHandleAttendeesChange,
    meetingType,
    webConferenceType,
    attendanceRule,
    containerIndex,
    showWarning,
    mustAttendeesCount,
  } = props;

  const owner = member.owner;
  const authorized_schedulers = member.authorizedSchedulers;
  const calendarEmail = isGaroonIntegrated() ? null : member.calendarEmail;
  const garoonUserName = member.garoonUserName;
  const isNameNull = member.name === null;
  const blockWarning = mustAttendeesCount > 0 && showWarning && containerIndex !== 0;
  const isSchedulerGaroon = getScheduler() === "garoon";

  return (
    <Row key={baseIndex} mt="18px">
      <MemberFieldWrapper>
      <Col xs={9} sm={9} mr={["0px", "-10px"]}>
        <Row>
          <Col xs display="flex" justifyContent="left">
            <DragIconWrapper>
              <DragIcon {...draggable}>
                <Line className="line" />
                <Line className="line" />
                <Line className="line" />
              </DragIcon>
            </DragIconWrapper>
            <Avatar
              src={member?.iconUri?.url || userDefault}
              mr="12px"
              ml="0px"
            />
            <FieldWrapper isBigScreen={window.screen.width >= 1920}>
              <LabelLight primary>{isNameNull ? `${member.email}` : `${member.name}`}</LabelLight>
              {isNameNull && <ApprovalPending>{intl.formatMessage({...messages.approvalPending})}</ApprovalPending>}
            </FieldWrapper>
            <ItemRight>
              <ItemIcons>
                {authorized_schedulers?.google && (
                  <ToolInfo icon={isSchedulerGaroon ? meet_icon: google_icon} alt="Google Calendar" message={calendarEmail}/>
                )}
                {authorized_schedulers?.outlook && (
                  <ToolInfo icon={isSchedulerGaroon ? teams_icon: outlook_icon} alt="Outlook" message={calendarEmail} />
                )}
                {authorized_schedulers?.garoon && (
                  <ToolInfo icon={garoon_icon} alt="Garoon" message={garoonUserName} />
                )}
                {authorized_schedulers?.zoom && (
                  <ToolInfo icon={zoom_icon} alt="Zoom" />
                )}
                {authorized_schedulers?.webex && (
                  <ToolInfo icon={webex_icon} alt="Webex" width="auto"/>
                )}
                {!calendarIntegratedUser(authorized_schedulers) && willAttend[containerIndex][fieldIndex]?.active &&
                  <ToolWarning
                    message={intl.formatMessage({...messages.calendarNotIntegrated}, {
                      a: chunks => (
                        <a target="_blank" rel="noopener noreferrer" href={generateHelpUrl("/member-integration-error")} onClick={() => pushToGA({...events.click_link_help("/member-integration-error")})}>
                          {chunks}
                        </a>
                      )
                    })}
                    top={-3}
                  />
                }
                {
                  showWarning && !blockWarning && (
                    <>
                      {zoomNotAllowedUser(authorized_schedulers, meetingType, webConferenceType) && willAttend[containerIndex][fieldIndex]?.active &&
                        <ToolWarning
                          message={intl.formatMessage({...messages.zoomNotAuthorized})}
                          top={-3}
                        />
                      }
                      {webexNotAllowedUser(authorized_schedulers, meetingType, webConferenceType) && willAttend[containerIndex][fieldIndex]?.active &&
                        <ToolWarning
                          message={intl.formatMessage({...messages.webexNotAuthorized})}
                          top={-3}
                        />
                      }
                      {meetNotAllowedUser(authorized_schedulers, meetingType, webConferenceType, isGaroonIntegrated) && willAttend[containerIndex][fieldIndex]?.active &&
                        <ToolWarning
                          message={intl.formatMessage({...messages.meetNotAuthorized})}
                          top={-3}
                        />
                      }
                      {teamsConfNotAlloweduser(authorized_schedulers, meetingType, webConferenceType, isGaroonIntegrated) && willAttend[containerIndex][fieldIndex]?.active &&
                        <ToolWarning
                          message={intl.formatMessage({...messages.teamsNotAuthorized})}
                          top={-3}
                        />
                      }
                    </>
                  )
                }
              </ItemIcons>
            </ItemRight>
          </Col>
        </Row>
      </Col>
      {pageType && (
        <Col xs={2} sm={2} mr={["0px","-10px"]}>
          <Row>
            <Col xs display="flex" flexDirection="column" alignItems="center">
              {attendanceRule !== "custom_will_attend" &&
                <Field
                  name={`members[${fieldIndex}].attendee`}
                  component={Checkbox}
                  useArrayValue={false}
                  onChange={(event) => handleAttendeeStateChange(event, fieldIndex, containerIndex)}
                />
              }
            </Col>
          </Row>
        </Col>
      )}
      <Col xs={1}>
        {!owner && pageType && (
          <ClickableIcon onClick={() => removeAndHandleAttendeesChange(containerIndex, fieldIndex)}>
            <Icon color="" name="remove" />
          </ClickableIcon>
        )}
      </Col>
      </MemberFieldWrapper>
    </Row>
  );
};

export default injectIntl(MemberComponent);
