import React, { useEffect, useState, useReducer } from "react";
import { formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { injectIntl, FormattedMessage } from "react-intl";
import { cloneDeep } from "lodash";
import {
  Col,
  ErrorText,
  Hr,
  Label,
  Row,
} from "@d-lighted/design-system";
import ListItemRecord from "./components/ListItemRecord";
import { _get, post, remove } from "../../../utils/api";
import { useValidation } from "../../../utils/validation";
import DragAndDroppableArea from "./components/DragAndDroppableArea";
import MemberComponent from "./MemberComponent";
import messages from "./i18n/MemberSelection";
import { SortableListWrapper, SortableItemWrapper } from "../../elements/DragAndDroppable";
import AddMembers from "./components/AddMembers";
import HeaderAndOptions from "./components/HeaderAndOptions";
import { isExternalIntegrated, getMustAttendeesCount } from "./utils";

function unsavedAttendee(state, action) {
  state[action.containerIndex][action.index] = {active: action.active};
  return [...state];
}

const MeetingsReader = formValueSelector("Meetings");

const MemberSelection = (props) => {
  const [loading, setLoading] = useState(false);
  const [willAttend, dispatchSetAttendee] = useReducer(unsavedAttendee, [[{ member: 0, active: false }], [{ member: 0, active: false }], [{ member: 0, active: false }]]);
  const {
    fields,
    meta: { error },
    pageType,
    handleAttendeesChange,
    change,
    intl,
    useNotify,
    alias,
    attendeesLimit,
    isGaroonIntegrated,
  } = props;
  const notify = useNotify(intl);
  const { isEmail } = useValidation(intl);
  const [searching, setSearching] = useState({ notCustomMembers: false, mustAttendMembers: false, optionalMembers: false, editorMembers: false });
  const [containers, setContainers] = useState([[], [], []]);
  const [memberSearchList, setMemberSearchList] = useState([]);
  const [memberSearchInput, setMemberSearchInput] = useState(null);
  const attendanceRule = useSelector((state) => MeetingsReader(state, "attendanceRule"));
  const formMembers = useSelector((state) => MeetingsReader(state, "members"));
  const { status } = useSelector((state) => state.online);
  const bookingCalendarUid = status[alias]?.uid;
  const [searchFieldTouched, setSearchFieldTouched] = useState(false)
  const [isQueryEmail, setEmailQuery] = useState({isEmail: false, query:''})
  const [emailInvitationStatus, setEmailInvitationStatus] = useState(false);
  const showWarningIcon = !isExternalIntegrated(formMembers, props?.meetingType, props?.webConferenceType?.type, attendanceRule, isGaroonIntegrated);
  const mustAttendeesCount = getMustAttendeesCount(containers, attendanceRule);
  let employeeFieldObject;

  useEffect(() => {
    if(attendanceRule) {
      let musAttendees = [];
      let optionalAttendees = [];
      let editors = [];
      const customWillAttend = attendanceRule === "custom_will_attend";
      
      (fields || []).forEach((_, index) => {
        const field = fields.get(index);
        if(customWillAttend) {
          if(field.mustAttend && field.attendee) {
            musAttendees.push(field);
            dispatchSetAttendee({ active: field.attendee, index: musAttendees.length - 1, containerIndex: 0 });
          }
          else if(!field.mustAttend && field.attendee) {
            optionalAttendees.push(field);
            dispatchSetAttendee({ active: field.attendee, index: optionalAttendees.length - 1, containerIndex: 1 });
          }
          else {
            editors.push(field);
            dispatchSetAttendee({ active: field.attendee, index: editors.length - 1, containerIndex: 2 });
          }
        } 
        else {
          dispatchSetAttendee({ active: field.attendee, index: index, containerIndex: 0 });
        }
      });
      if(customWillAttend) {
        return setContainers([musAttendees, optionalAttendees, editors]);
      }

      setContainers([[...fields.getAll()]]);
    }
  }, [fields, attendanceRule]);

  useEffect(() => {
    if(attendanceRule && attendanceRule !== "custom_will_attend") {
      setContainers([[...formMembers]]);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [formMembers]);

  const checkIfExist = (employee) => {
    return fields.getAll().reduce((present, field, index) => {
      if (present < 0 && field.employeeUqid === employee.uqid) {
        return index;
      }
      return present;
    }, -1);
  };

  function removeEmployee(existingIndex) {
    const employee = fields.get(existingIndex);

    if(!employee.owner) {
      removeAndHandleAttendeesChange(existingIndex);
    }
  }

  const removeAndHandleAttendeesChange = async(containerIndex, index) => {
    const removedContainer = containers[containerIndex];
    const member = removedContainer[index];
    if(member && !member?.name) {
      if(window.confirm("ほんとに削除しますか？")) {
        const url = `/company/employees/${member?.employeeUqid}`;
        const result = await remove(url);
        if(result.status >= 200 && result.status < 300) {
          notify.setNotify(messages.deleteSuccess);
        }
        else {
          notify.setError(messages.inviteError, result?.data?.error?.message);
        }
      } 
      else {
        return;
      }
    }
    removedContainer.splice(index, 1);
    setContainers(containers);
    handleAttendeesChange(containers.flat());
    dispatchSetAttendee({active: false, index: index, containerIndex: containerIndex});
    
    change("members", [...containers.flat()]);
  };

  const handleAddEmployee = (employee, attendeeType) => {
    const existingIndex = checkIfExist(employee);
    if (existingIndex < 0) {
      employeeFieldObject = {
        name: employee.name,
        attendee: attendeeType.attendee,
        mustAttend: attendeeType.mustAttend,
        editor: false,
        employeeUqid: employee.uqid,
        uid: employee.uid,
        iconUri: employee?.icon_uri,
        authorizedSchedulers: employee.authorized_schedulers,
        calendarEmail: employee.calendar_email,
        garoonUserName: employee.garoon_user_name,
        email: employee.email,
        owner: employee.owner,
      };
      // fields array NOT updated immediately after push
      handleAttendeesChange([...fields.getAll(), employeeFieldObject]);
      fields.push(employeeFieldObject);
    } else {
      removeEmployee(existingIndex);
    }
  };

  const sendEmailInvitation = async (email, attendeeType) => {
    const attendeesCount = formMembers.filter(formMember => formMember.attendee || formMember.mustAttend).length;
    const isNewOptionalMember = Object.values(attendeeType).every(attendee => attendee === false);
    if(attendeesLimit && (attendeesCount + 1) > attendeesLimit && attendanceRule === "custom_will_attend" && !isNewOptionalMember) {
      return notify.setError(messages.inviteError, intl.formatMessage({...messages.attendeesLimitCustom}, { count: attendeesLimit }));
    }
    const url = `/auth?product=scheduling&invite=true&booking_calendar_uid=${bookingCalendarUid}&booking_calendar_attendee=${attendeeType.attendee}&booking_calendar_must_attend=${attendeeType.mustAttend}`;
    const body = {
      email: email,
      name: email
    };
    const result = await post(url, body);
    if(result.status >= 200 && result.status < 300) {
      let employee = result?.data?.data;
      handleAddEmployee(employee, attendeeType);
      setEmailInvitationStatus(true);
      notify.setNotify(messages.inviteSuccess);
    }
    else {
      setEmailInvitationStatus(false);
      notify.setError(messages.inviteError, result?.data?.error?.message);
    }
  }

  const handleSearchEmployee = async (value) => {
    setLoading(true);
    setMemberSearchInput(value);
    setSearchFieldTouched(true);
    setEmailInvitationStatus(false);
    const isValueEmail = isEmail(value)
    setEmailQuery({isEmail: isValueEmail, query: value})
    let url = `/employees/search_with_authorized_schedulers?name=${encodeURIComponent(value)}`;
    let result = await _get(url);
    if (result.status >= 200 && result.status < 300) {
      setMemberSearchList(result?.data?.employees);
    }
    setLoading(false);
  };

  const handleSelectedComparison = (employee) => {
    for (let i = 0; i < fields.length; ++i) {
      const fieldEmployee = fields.get(i);
      if (fieldEmployee.employeeUqid === employee.uqid) {
        return true;
      }
    }
  };

  const handleAttendeeStateChange = (state, memberIndex, containerIndex) => {
    dispatchSetAttendee({active: state, index: memberIndex, containerIndex});
    change(`members.${memberIndex}.attendee`, state);
  };

  const resetSearchResult = () => {
    setMemberSearchInput(null);
    setMemberSearchList([]);
  };

  const toggleSerchMembers = (containerIndex = null) => {
    switch(containerIndex) {
      case 0:
        if(!searching.mustAttendMembers) resetSearchResult();
        setSearching({ notCustomMembers: false, mustAttendMembers: !searching.mustAttendMembers, optionalMembers: false, editorMembers: false });
        break;
      case 1:
        if(!searching.optionalMembers) resetSearchResult();
        setSearching({ notCustomMembers: false, mustAttendMembers: false, optionalMembers: !searching.optionalMembers, editorMembers: false });
        break;
      case 2:
        if(!searching.editorMembers) resetSearchResult();
        setSearching({ notCustomMembers: false, mustAttendMembers: false, optionalMembers: false, editorMembers: !searching.editorMembers });
        break;
      default:
        if(!searching.notCustomMembers) resetSearchResult();
        setSearching({ notCustomMembers: !searching.notCustomMembers, mustAttendMembers: false, optionalMembers: false, editorMembers: false });
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
  
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
  
    return result;
  };

  const onSortEnd = async(result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(containers[sInd], source.index, destination.index);
      const newState = [...containers];
      newState[sInd] = items;
      setContainers(newState);
      handleAttendeesChange(newState.flat());
      change("members", [...newState.flat()]);
    } 
    else {
      const result = move(containers[sInd], containers[dInd], source, destination);
      const newState = [...containers];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      let dupContainers = cloneDeep(newState[dInd]);
      if(dInd === 0) {
        // must attendees
        dupContainers[destination.index].mustAttend = true;
        dupContainers[destination.index].attendee = true;
      }
      else if(dInd === 1) {
        // optional attendees
        dupContainers[destination.index].mustAttend = false;
        dupContainers[destination.index].attendee = true;
      }
      else {
        // editors
        dupContainers[destination.index].mustAttend = false;
        dupContainers[destination.index].attendee = false;
      }
      newState[dInd] = dupContainers;
      setContainers(newState);
      handleAttendeesChange(newState.flat());
      change("members", [...newState.flat()]);
    }
  };

  const isMembersLoaded = () => {
    return containers.some( container => typeof container != "undefined" && container != null && container.length > 0);
  }

  return (
    <>
      <Row>
        <Col xs>
          {attendanceRule !== "custom_will_attend" ?
            <>
              <Row mt="15px">
                <Col xs={9} sm={7} mr={["0px","-10px"]}>
                  <Row>
                    <Col xs display="flex" justifyContent="center">
                      <Label fontSize="13px">
                        <FormattedMessage {...messages.fullName} />
                      </Label>
                    </Col>
                  </Row>
                </Col>
                {pageType && (
                  <Col xs={3} sm={3} mr={["0px","-10px"]}>
                    <Row>
                      <Col
                        xs
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Label fontSize="13px">
                          <FormattedMessage {...messages.attendee} />
                        </Label>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              <div style={{ display: "block" }}>
                <DragDropContext onDragEnd={onSortEnd}>
                  {(containers || []).map((container, ind) => {
                    return(
                      <>
                        <Droppable key={`key-${ind}`} droppableId={`${ind}`}>
                          {(provided) => (
                            <SortableListWrapper
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {(container || []).map((member, index) => {
                                const uid = member.uid;
                                return (
                                  <div key={`div-one-${index}`}>
                                    <Draggable
                                      key={`${uid}-0`}
                                      draggableId={`${uid}-0`}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <SortableItemWrapper
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          
                                        >
                                          <MemberComponent
                                            key={`0-${uid}`}
                                            member={member}
                                            containerIndex={0}
                                            fieldIndex={index}
                                            baseIndex={`0-${uid}-${index}`}
                                            index={index}
                                            draggable={{...provided.dragHandleProps}}
                                            fields={fields}
                                            willAttend={willAttend}
                                            handleAttendeeStateChange={handleAttendeeStateChange}
                                            removeAndHandleAttendeesChange={removeAndHandleAttendeesChange}
                                            pageType={pageType}
                                            intl={props.intl}
                                            meetingType={props?.meetingType}
                                            webConferenceType={props?.webConferenceType?.type}
                                            attendanceRule={attendanceRule}
                                            showWarning={showWarningIcon}
                                            mustAttendeesCount={mustAttendeesCount}
                                          />
                                        </SortableItemWrapper>
                                      )}
                                    </Draggable>
                                  </div>
                                )
                              })}
                              {provided.placeholder}
                            </SortableListWrapper>
                          )}
                        </Droppable>
                        <AddMembers
                          pageType={pageType}
                          ind="default"
                          loading={loading}
                          searching={searching}
                          handleAddEmployee={handleAddEmployee}
                          handleSearchEmployee={handleSearchEmployee}
                          headerText={props.intl.formatMessage({ ...messages.addEmployees })}
                          searchPlaceholder={props.intl.formatMessage({
                            ...messages.searchEmployees,
                          })}
                          closeText={props.intl.formatMessage({ ...messages.close })}
                          recordComponent={ListItemRecord}
                          comparator={handleSelectedComparison}
                          memberSearchList={memberSearchList}
                          memberSearchInput={memberSearchInput}
                          toggle={toggleSerchMembers}
                          searchFieldTouched={searchFieldTouched}
                          noSuchMember={props.intl.formatMessage({ ...messages.noSuchMember })}
                          isQueryEmail={isQueryEmail}
                          invitationText={props.intl.formatMessage({...messages.sendEmailInvitation}, {email:isQueryEmail.query})}
                          approvalPendingText={props.intl.formatMessage({...messages.approvalPending})}
                          sendInvitation={sendEmailInvitation}
                          emailInvitationStatus={emailInvitationStatus}
                          {...props}
                        />
                      </>
                    )}
                  )}
                </DragDropContext>
              </div>
            </>
          :
            <div style={{ display: "block" }}>
              <DragDropContext onDragEnd={onSortEnd}>
                {(containers || []).map((container, ind) => (
                  <>
                    <HeaderAndOptions
                      ind={ind}
                      membersCount={container.length}
                      isMembersLoaded={() => isMembersLoaded()}
                      {...props}
                    />
                    <Droppable key={`key-${ind}`} droppableId={`${ind}`}>
                      {(provided, snapshot) => (
                        <SortableListWrapper
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <DragAndDroppableArea
                            key={`helpArea`}
                            snapshot={snapshot}
                            membersCount={container.length}
                            ind={ind}
                            {...props}
                          >
                            {(container || []).map((member, index) => {
                              const uid = member.uid;
                              return (
                                <div key={`div-one-${index}`}>
                                  <Draggable
                                    key={`${uid}-${ind}`}
                                    draggableId={`${uid}-${ind}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <SortableItemWrapper
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        
                                      >
                                        <MemberComponent
                                          key={`${ind}-${uid}`}
                                          member={member}
                                          containerIndex={ind}
                                          fieldIndex={index}
                                          baseIndex={`${ind}-${uid}-${index}`}
                                          index={index}
                                          draggable={{...provided.dragHandleProps}}
                                          fields={fields}
                                          willAttend={willAttend}
                                          handleAttendeeStateChange={handleAttendeeStateChange}
                                          removeAndHandleAttendeesChange={removeAndHandleAttendeesChange}
                                          pageType={pageType}
                                          intl={props.intl}
                                          meetingType={props?.meetingType}
                                          webConferenceType={props?.webConferenceType?.type}
                                          attendanceRule={attendanceRule}
                                          showWarning={showWarningIcon}
                                          mustAttendeesCount={mustAttendeesCount}
                                        />
                                      </SortableItemWrapper>
                                    )}
                                  </Draggable>
                                </div>
                              )
                            })}
                          </DragAndDroppableArea>
                          {provided.placeholder}
                        </SortableListWrapper>
                      )}
                    </Droppable>
                    <AddMembers
                      pageType={pageType}
                      ind={ind}
                      loading={loading}
                      searching={searching}
                      handleAddEmployee={handleAddEmployee}
                      handleSearchEmployee={handleSearchEmployee}
                      headerText={props.intl.formatMessage({ ...messages.addEmployees })}
                      searchPlaceholder={props.intl.formatMessage({
                        ...messages.searchEmployees,
                      })}
                      closeText={props.intl.formatMessage({ ...messages.close })}
                      recordComponent={ListItemRecord}
                      comparator={handleSelectedComparison}
                      memberSearchList={memberSearchList}
                      memberSearchInput={memberSearchInput}
                      toggle={toggleSerchMembers}
                      searchFieldTouched={searchFieldTouched}
                      noSuchMember={props.intl.formatMessage({ ...messages.noSuchMember })}
                      isQueryEmail={isQueryEmail}
                      invitationText={props.intl.formatMessage({...messages.sendEmailInvitation}, {email:isQueryEmail.query})}
                      approvalPendingText={props.intl.formatMessage({...messages.approvalPending})}
                      sendInvitation={sendEmailInvitation}
                      emailInvitationStatus={emailInvitationStatus}
                      {...props}
                    /> 
                  </>
                ))}
              </DragDropContext>
            </div>
          }
        </Col>
      </Row>

      <Hr border="none" height="1px" color="#e5e5e5" mt="15px" />

      {error && (
        <Row mb="20px">
          <Col xs>
            <ErrorText>{error}</ErrorText>
          </Col>
        </Row>
      )}
    </>
  );
};

export default injectIntl(MemberSelection);
