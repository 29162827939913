import React, { useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";

import LocaleProvider from './providers/LocaleProvider';
import { overrideCurrentUserCompany } from "./utils/userUtils";
import { useI18NNotification } from "./utils/notificationUtils";
import { routeGenerator } from "./routes";
import { _get } from './utils/api';

function App(props) {
  const [ResponseMessage, useNotify] = useI18NNotification();

  const fetchCompany = async () => {
    const { data: { company } } = await _get('/company', process.env.REACT_APP_API_ENDPOINT);
    return overrideCurrentUserCompany(company);
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return (
    <LocaleProvider>
      <ResponseMessage />
      <Switch>
        {routeGenerator({ ...props, useNotify })}
        <Redirect to="/sign_in" />
      </Switch>
    </ LocaleProvider>
  )
}

export default App;
