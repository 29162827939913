import { createAction, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATUS = {
  locale: 'ja',
};

const change_locale = createAction('change_locale');

const localeReducer =  createReducer(INITIAL_STATUS,
  builder => {
    builder.addCase(change_locale, (state, action) => {
      state.locale = action.payload || 'ja';
    })
  }
);

export default localeReducer;
