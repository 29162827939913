import React from "react";
import styled from "styled-components";
import { Field, reset } from "redux-form";
import { useDispatch } from "react-redux";
import FontAwesome from "react-fontawesome";
import {
  Col,
  Row,
  Label,
  Hr,
  ErrorText,
} from "@d-lighted/design-system";

import AvailableEventPrefixForm from "./AvailableEventPrefixForm";

const ClickableIcon = styled.div`
  cursor: pointer;
`;

const Icon = styled(FontAwesome)`
  color: #e84c3d;
`;

const LabelLight = styled(Label)`
  font-size: 14px;
  font-weight: 200;
  top: 3px;
  position: relative;
`;

const Text = (props) => {
  const { input: { value } } = props;
  return <LabelLight primary>{value}</LabelLight>;
};

const RenderPrefixes = ({ showForm, fields, headerText, closeText, addButtonText, toggle, placeHolder, meta: { submitFailed, error } }) => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault()
    fields.push(e.target[0].value);
    dispatch(reset('AvailableEventPrefixForm'))
  };
  return (
    <>
      <Row>
        <Col xs>
          {(fields || []).map((prefix, index) => {
            return (
              <Row key={index} mt="15px">
                <Col xs={10} mr="-20px" paddingRight="30px">
                  <Row>
                    <Col xs display="flex" justifyContent="left">
                      <Field name={prefix} component={Text} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={1}>
                  <ClickableIcon onClick={() => fields.remove(index)}>
                    <Icon color="" name="remove" />
                  </ClickableIcon>
                </Col>
              </Row>
            )
          })}
          
          <Hr border="none" height="1px" color="#e5e5e5" mt="15px" />
          
          {submitFailed && error && <ErrorText>{error}</ErrorText>}

          {showForm && <Row mt="35px">
            <Col xs={10} mr="-10px">
              <Row>
                <Col xs display="flex">
                  <AvailableEventPrefixForm
                    toggle={toggle}
                    headerText={headerText}
                    closeText={closeText}
                    addButtonText={addButtonText}
                    placeHolder={placeHolder}
                    handleSubmit={handleSubmit}
                  />
                </Col>
              </Row>
            </Col>
          </Row>}
        </Col>
      </Row>
    </>
  )
};

export default RenderPrefixes;
