import React from "react";
import styled from "styled-components";
import { margin, width } from "styled-system";

import { getJapaneseDay } from "../momentUtils";

const Label = styled.div`
  position: relative;
  width: 100%;
  font-size: 15px;
  font-weight: 200;
  letter-spacing: 0.1px;
  color: #68878d;
  margin-bottom: 5px;
  cursor: default;
`;

const CardDiv = styled.div`
  position: relative;
  ${width}
  ${margin}
`;

const Card = styled.div`
  height: 100px;
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const CardBody = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  ${props => props.centerItems && `
    align-items: center;
    justify-content: center;
  `}
`;

const CardDate = styled.div`
  opacity: 0.6;
  font-size: 15px;
  line-height: 1.6;
  color: #314143;
  margin-bottom: 8px;
`;

const CardTime = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
  color: #314143;
`;

const DateTimeCardWithLabel = ({ year, month, date, day, time, nextHour, centerItems, label, width, mt, mb }) => {
  return (
    <>
      {label &&
        <Label>
          {label}
        </Label>
      }
      <CardDiv width={width} mt={mt} mb={mb}>
        <Card>
          <CardBody centerItems={centerItems}>
            <CardDate>
              {year}年 {month}月{date}日 ({getJapaneseDay(day)})
            </CardDate>
            <CardTime>
              {time} - {nextHour}
            </CardTime>
          </CardBody>
        </Card>
      </CardDiv>
    </>
  );
};

DateTimeCardWithLabel.defaultProps = {
  year: "2020",
  month: "07",
  date: "31",
  day: 1,
  time: "11:00",
  nextHour: "12:00",
  centerItems: false,
};

CardDiv.defaultProps = {
  width: "100%",
};

export default DateTimeCardWithLabel;