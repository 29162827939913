import React from "react";
import { FormattedMessage } from "react-intl";
import { formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import {
  Label,
  Col,
  Row,
} from "@d-lighted/design-system/dist/index";
import Icon from "../components/Icon";
import messages from "../i18n/Salesforce";
import theme from "../../../constants/styledTheme";
import salesforce_icon from "../../../assets/img/icon_salesforce.svg";
import InputFieldListItem from "./InputFieldListItem";

const SalesforceReader = formValueSelector("Salesforce");

const InputFieldList = ({customFields, settingUid, settings, ...props}) => {

  const allowSplitGuestName = useSelector((state) =>
    SalesforceReader(state, "allowSplitGuestName")
  );

  const handleEditField = (e, fieldUid) => {
    e.preventDefault();
    props.history.push({
      pathname: `/${settings}/${settingUid}/edit/booking_form/${fieldUid}/booking_form_settings`,
    });
  };
  const defaultFields = [
    {fieldType: "text_field", labelName: props?.intl?.formatMessage({...messages.leadName}), salesforceFieldId: "LastName"},
    {fieldType: "text_field", labelName: props?.intl?.formatMessage({...messages.lastName}), salesforceFieldId: "LastName"},
    {fieldType: "text_field", labelName: props?.intl?.formatMessage({...messages.firstName}), salesforceFieldId: "FirstName"},
    {fieldType: "text_field", labelName: props?.intl?.formatMessage({...messages.company}), salesforceFieldId: "Company"},
    {fieldType: "text_field", labelName: props?.intl?.formatMessage({...messages.email}), salesforceFieldId: "Email"},
  ];
  const uneditableFields = defaultFields.filter((_, index) => allowSplitGuestName ? ![0].includes(index) : ![1, 2].includes(index));

  return (
    <div>
      <Row>
        <Col xs>
          <Label fontSize="15px">
            <FormattedMessage {...messages.integrateField} />
          </Label>
        </Col>
      </Row>
      <Row mt="24px" maxWidth={678}>
        <Col xs>
          <Row alignItems="center">
            <Col xs>
              <Label fontSize="13px" fontWeight="500" color={theme.colors.darkCyanText}>
                <FormattedMessage {...messages.customInputField} />
              </Label>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}></Col>
            <Col xs display="flex" alignItems="center">
              <Icon src={salesforce_icon} width="27px" height="19px" ml="0px" />
              <Label fontSize="13px" fontWeight="500" color={theme.colors.darkCyanText}>
                <FormattedMessage {...messages.salesforceField} />
              </Label>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row maxWidth={678}>
        <Col xs>
          {uneditableFields.map((field, index) => {
            return (
              <InputFieldListItem
                key={index}
                index={index}
                field={field}
                handleEditField={handleEditField}
                editable={false}
                {...props}
              />
            );
          })}
        </Col>
      </Row>
      <Row maxWidth={678}>
        <Col xs>
          {customFields.map((field, index) => {
            return (
              <InputFieldListItem
                key={index}
                index={index}
                field={field}
                handleEditField={handleEditField}
                editable={true}
                {...props}
              />
            );
          })}
        </Col>
      </Row>
    </div>
  );
};

export default InputFieldList;
