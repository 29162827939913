import { useParams } from 'react-router';
import moment from 'moment';
import humps from "lodash-humps";

import { post, put, remove, _get } from '../../../utils/api';
import messages from '../i18n/MultiGuest';

function useMultiguestCalendar(notify) {
  const { uid, multiguest_calendar_setting_uid } = useParams();

  function formatBody(values) {
    return {
      title: values.title,
      duration: values.duration,
      schedulable_from: moment(values.dateRange[0]).format("YYYY/MM/DD"),
      schedulable_until: moment(values.dateRange[1]).format("YYYY/MM/DD"),
      description: values.description,
      completed_message_webpage: values.completedMessageWebpage,
      attendees: values.attendees
    };
  }

  const createMultiguestCalendar = async (params) => {
    const url = `/booking_calendars/${uid}/multiguest`;
    const body = formatBody(params);
    const result = await post(url, body);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.notifyError, result.data?.error?.message);
      return result;
    } else {
      notify.setNotify(messages.notifySuccess, result.data?.message != null ? result.data.message : null );
      return humps(result?.data).bookingCalendarMultiguestScheduledEvent;
    }
  };

  const deleteMultiguestCalendar = async (multiguestUid) => {
    const url = `/booking_calendars/${uid}/multiguest/${multiguestUid}`;
    const result = await remove(url);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.notifyDeleteError, result.data?.error?.message);
    } else {
      notify.setNotify(messages.notifyDeleteSuccess, result.data?.message != null ? result.data.message : null );
      return humps(result);
    }
    return null;
  };

  const getMultiguestCalendarAvailableSlots = async (multiguestUid) => {
    const url = `/booking_calendars/${uid}/multiguest/${multiguestUid}/available_slots`;
    const result = await _get(url);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.failedToRetrieveAvailableSlots, result.data?.error?.message);
    } else {
      return humps(result.data);
    }
    return  null;
  };

  const getMultiguestCalendarSettings = async (multiguest_calendar_setting_uid) => {
    const url = `/booking_calendars/${uid}/booking_calendar_multiguest_settings/${multiguest_calendar_setting_uid}`;
    const result = await _get(url);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.failedToRetrieveAvailableSlots, result.data?.error?.message);
    } else {
      return humps(result.data).bookingCalendarMultiguestSetting;
    }
    return  null;
  };

  const updateMultiguestCalendarSettings = async (params) => {
    const url = `/booking_calendars/${uid}/booking_calendar_multiguest_settings/${multiguest_calendar_setting_uid}`;
    const body = {
      enable_external_attendees: params.enableExternalAttendees,
      hide_attendees_info_on_booking_page: params.hideAttendeesInfoOnBookingPage
    };
    const result = await put(url, body);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.notifyError, result.data?.error?.message);
      return result;
    } else {
      notify.setNotify(messages.notifyMultiguestCalendarSettingSuccess, result.data?.message != null ? result.data.message : null );
      return humps(result?.data);
    }
  };

  return {
    createMultiguestCalendar,
    deleteMultiguestCalendar,
    getMultiguestCalendarAvailableSlots,
    updateMultiguestCalendarSettings,
    getMultiguestCalendarSettings
  };
}

export { useMultiguestCalendar };
