export const SLACK = 'Slack';
export const CHATWORK = 'Chatwork';
export const ONETEAM = 'Oneteam';
export const FACEBOOK_WORKPLACE = 'Facebook Workplace';
export const MICROSOFT_TEAMS = 'Microsoft Teams (webhook)';
export const LINE_WORKS = 'LINE WORKS';
export const DINGTALK = 'DingTalk';
export const GOOGLE_CHAT = 'Google Chat';
export const INCIRCLE = 'InCircle (JobTalk)';
export const SMS = 'SMS';
export const MOBILE = 'Mobile App';
export const MICROSOFT_TEAMS_OAUTH = 'Microsoft Teams';
