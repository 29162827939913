import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Loader } from '@d-lighted/design-system';
import { MOBILEBREAKPOINT } from '../../constants/breakpoint';
import userDefault from '../../assets/img/userDefault.png';


const Wrapper = styled.div`
  position: absolute;
  display: block;
  padding: 0 10px;
  width: 392px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.4);
  border-radius: 5px;
  overflow: hidden;
  z-index: 3;
  top: 145px;
  @media (max-width: ${MOBILEBREAKPOINT}){
    top: 210px;
    left: 9px;
    width: calc(100% - 16%);
  }
`;

const MemberList = styled.div`
  display: grid;
  width: 100%;
  overflow-y: scroll;
  z-index: 100;
  padding: 20px 0px;
  list-style: none;
  max-height: 220px;
`;

const ItemImage = styled.img`
  float: left;
  margin-top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const ListItem = styled.div`
  color: #484e62;
  font-size: 14px;
  font-weight: 200;
  width: 100%;
  cursor: pointer;
  padding: 5px 10px;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const LoaderAnchor = styled.div`
  height: 56px;
  width: 56px;
  margin: auto;

  &> * {
    height: 56px;
    width: 56px;
  }
`;

const ItemText = styled.p`
  float: left;
  padding-left: 18px;
  line-height: 22px;
  color: #142C2B;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
`;

const ItemLightText = styled.p`
  float: left;
  padding-left: 18px;
  line-height: 20px;
  color: #68878d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 200;
`;

const ItemTextWrapper = styled.div`
  display: grid;
  padding: 5px 0px;
`;

const SelectionDropdown = (props) => {
  const {
    loading,
    employees,
    selectedEmployees,
    setSelectedEmployees,
    show,
    setShow,
    handleFetchMoreCalendars,
  } = props;

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const listableEmployees = employees.filter(employee => !selectedEmployees.find(selectedEmployee => selectedEmployee.email === employee.email));
  const handleSelectEmployee = (employee) => {
    setShow(false);
    const updatedSelectedEmployees = selectedEmployees.concat([employee]);
    setSelectedEmployees(updatedSelectedEmployees);
    handleFetchMoreCalendars(1, undefined, updatedSelectedEmployees);
  };

  if(!show) {
    return <></>;
  }
 

  return (
    <Wrapper ref={dropdownRef}>
      <MemberList>
        {loading && <LoaderAnchor>
          <Loader />
        </LoaderAnchor>}
        {!loading && (listableEmployees || []).map((employee, index) => {
          return (
            <ListItem key={index} onClick={() => handleSelectEmployee(employee)}>
              <ItemImage
                src={employee?.icon_uri?.url || userDefault}
              />
              <ItemTextWrapper>
                <ItemText>{employee.name}</ItemText>
                <ItemLightText>{employee.email}</ItemLightText>
              </ItemTextWrapper>
            </ListItem>
          )
        })
        }
      </MemberList>
    </Wrapper>
  )
};

export default SelectionDropdown;