import React from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { Input, Button } from '@d-lighted/design-system';
import { MOBILEBREAKPOINT } from '../../../../constants/breakpoint';

const Wrapper = styled.div`
  position: absolute;
  display: block;
  padding: 24px 20px 14px 20px;
  width: 400px;
  background-color: #ffffff;
  box-shadow: 0 20px 40px 10px rgba(210, 224, 233, 0.4);
  overflow: hidden;
  z-index: 3;
  margin: 0 -18px;
  @media (max-width: ${MOBILEBREAKPOINT}){
    width: calc(100% - 12%);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -6px;
  }
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
  color: #484e62;
`;

const ItemLeft = styled.div`
  display: inline-flex;
  width: 100%;
  margin-right: 10px;
`;

const ItemRight = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
`;

const PrefixWrapper = styled.div`
  margin: 1px;
  min-height: 52px;
  display: flex;
`;

const Anchor = styled.a`
  font-size: 12px;
  font-weight: 300;
  color: #00bbb5;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const AvailableEventPrefixForm = props => {
  const { toggle, handleSubmit, headerText, closeText, addButtonText, placeHolder, pristine, submitting } = props;
  return (
    <Wrapper>
      <Header>
        {headerText}
        <Anchor onClick={toggle}>
          {closeText}
        </Anchor>
      </Header>
      <form onSubmit={handleSubmit}>
        <PrefixWrapper>
          <ItemLeft>
            <Field
              id="availableEventPrefix"
              name="availableEventPrefix"
              component={Input}
              placeholder={placeHolder}
            />
          </ItemLeft>
          <ItemRight>
            <Button
              type="submit"
              width="65px"
              minWidth="65px"
              height="30px"
              borderRadius="3px"
              fontSize="12px"
              disabled={pristine || submitting}
            >
              {addButtonText}
            </Button>
          </ItemRight>
        </PrefixWrapper>
      </form>

    </Wrapper>
  );
};

export default reduxForm({
  form: 'AvailableEventPrefixForm',
})(AvailableEventPrefixForm);
