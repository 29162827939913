import React, { useState } from "react";
import styled from 'styled-components';
import PropTypes from "prop-types";
import { change } from "redux-form";
import { useDispatch} from 'react-redux';

import messages from '../i18n/CompleteEmailNotification';


const SelectEmailVariable = styled.div`
  margin-top: 10px;
  padding: 0px 239px 0px 19px;
  border: solid 1px #d2e9e7;
  border-radius: 5px 5px 0 0;
  background-color: #f7f9f9;
  height:36px;
  &:hover{
    background-color: #e6f4ef;
  }
`;

const EmailVariableTitle = styled.div`
  text-align: left;
  color: #68878d;
  position: relative;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
`;

const DropDownButton = styled.li`
  width: 100%;
  position: relative;
  display: block;
  cursor: pointer;
`;

const DropDownContent = styled.div`
  display: ${props => props.showDropdownOptions ? 'block' : 'none'};
  position: absolute;
  left: 20px;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

const DropDownItem = styled.p`
  color: #314143;
  padding: 12px 16px;
  margin: 5px;
  border-radius: 4px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: ${props => props.width};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const DropDownItemCategory = styled.p`
  margin: 5px;
  font-size: 13px;
  line-height: 2.08;
  letter-spacing: normal;
  text-align: left;
  color: #68878d;
`;

export function EmailVariableText({elementId, intl, form, filter="default"}) {
  const [toggle, toggleVisibility] = useState(false);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    toggleVisibility(!toggle);
  };

  function insertVariable(id, value) {
    const currentElement = document.getElementById(id);
    const insertPosition = currentElement.selectionStart
    const currentValue = currentElement.value;
    const newValue = currentValue.slice(0,insertPosition) + value + currentValue.slice(insertPosition);
    dispatch(change(form, elementId, newValue))
  }

  window.onclick = function(event) {
    if (!event.target.matches('pre') && !event.target.matches('#dropDownHeader')) {
      toggleVisibility(false)
    }
  }

  const availableVariables = filter === "default" ? 
    {
      "お客様情報": ["お客様の会社名", "お客様の氏名"],
      "自社情報": ["自社の会社名", "自社の担当者１名の氏名", "自社の担当者全員の氏名", "自社の担当者１名のメールアドレス", "自社の担当者全員のメールアドレス"]
    } 
  : 
    {
      "お客様情報": ["お客様の会社名", "お客様の氏名"],
      "その他": ["会議形態"],
    }

  return (
    <DropDownButton onClick={() => toggleMenu()}>
      <SelectEmailVariable id="dropDownHeader">
        <EmailVariableTitle><pre>{intl.formatMessage({...messages.variableText})}</pre></EmailVariableTitle>
      </SelectEmailVariable>
      <DropDownContent showDropdownOptions={toggle}>
        {Object.keys(availableVariables).map((head) => {
          return(
            <>
              <DropDownItemCategory>{head}</DropDownItemCategory>
              {availableVariables[head].map((variable) => {
                return(
                  <DropDownItem width="240px" onClick={() => insertVariable(elementId, `{${variable}}`)}>
                    {variable}
                  </DropDownItem>
                )
              })}
            </>
          )
        })}
      </DropDownContent>
    </DropDownButton>
  );
}

EmailVariableText.defaultProps = {
  form: "CompleteEmailNotification"
};

EmailVariableText.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
};
