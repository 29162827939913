import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@d-lighted/design-system';
import { copy } from 'utils/copyUtils';
import ReactTooltip from "react-tooltip";
import { defineMessages, FormattedMessage } from 'react-intl';

const StyledTimelineElement = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  padding-bottom: 10px;

  /* Add the pseudo-element for the line */
  &::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 9px;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: #009d98;
  }

  /* Hide the line for the last element */
  &:last-child::before {
    content: none;
  }
`;

const StyledCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #009d98;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledCircleText = styled.span`
  font-size: 12px;
  color: #009d98;
`;

const StyledEmail = styled.span`
  font-size: 12px;
  white-space: nowrap;
  font-weight: ${({ status }) =>
    status === 'unconfirmed' ? 'bold' : 'normal'};
`;

const StyledContent = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledStatus = styled.span`
  height: 20px;
  font-size: 12px;
  color: ${({ status }) => {
    switch (status) {
      case 'confirmed':
        return '#B7C0CF';
      case 'unconfirmed':
        return '#009d98';
      case 'pending':
        return 'black'; // Black text color for Pending status
      default:
        return 'black'; // Fallback color
    }
  }};
  white-space: nowrap;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: ${({ status }) =>
    status === 'unconfirmed' ? 'bold' : 'normal'};
  background-color: ${({ status }) =>
    status === 'unconfirmed'
      ? '#EBF9F9'
      : '#E2E7EF52'}; /* Background for unconfirmed */
  transition: opacity 0.2s ease-in-out; /* Smooth transition */
  align-content: center;
`;

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    color: #fff;
    border-radius: 6px;
    width: 85px;
    height: 25px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;

    transition: visibility 0s .375s, opacity .375s ease-in-out !important;

    &.show{
        transition: visibility 0s 0s, opacity .375s ease-in-out !important;
    }
  }
`;

const messages = defineMessages({
  confirmed: {
    id: 'components.elements.timeline.confirmed',
    defaultMessage: 'Confirmed',
  },
  unconfirmed: {
    id: 'components.elements.timeline.unconfirmed',
    defaultMessage: 'Unconfirmed',
  },
  pending: {
    id: 'components.elements.timeline.pending',
    defaultMessage: 'Pending',
  }
})

function localizeStatus(status) {
  switch (status) {
    case 'unconfirmed':
      return <FormattedMessage {...messages.unconfirmed} />
    case 'confirmed':
      return <FormattedMessage {...messages.confirmed} />
    default:
      return <FormattedMessage {...messages.pending} />
  }
}

const Timeline = ({ elements, appointment }) => {
  return (
    <div className="timeline">
      {elements.map((element, index) => (
        <StyledTimelineElement key={index}>
          <StyledCircle>
            <StyledCircleText status={element.status}>
              {index + 1}
            </StyledCircleText>
          </StyledCircle>
          <StyledContent>
            <StyledStatus status={element.status}>
              {localizeStatus(element.status)}
            </StyledStatus>
            <StyledEmail status={element.status}>{element.email}</StyledEmail>
            {element.status === 'unconfirmed' && <>
              <Button
                outlined
                borderColor="#fff !important"
                mr="20px"
                height="30px"
                fontSize="14px"
                fontWeight="bold"
                data-for={element.email}
                data-tip="コピーしました"
                data-event='click'
                data-event-off='click'
                onClick={() => copy(`${process.env.REACT_APP_BOOK_ME_ENDPOINT}/${appointment.calendarUrl}?email=${element.email}`)}
              >
                <Icon text="URLをコピーする" />
              </Button>
              <CustomReactTooltip
                isCapture={true}
                id={element.email}
                delayHide={1000}
                place="bottom"
                effect="solid"
                backgroundColor="#6e52ff"
              />
            </> }
          </StyledContent>
        </StyledTimelineElement>
      ))}
    </div>
  );
};

export default Timeline;
