import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Heading, Row, Button, LinkText as BaseLinkText } from "@d-lighted/design-system";

/**Local Import */
import DateTimeCard from "../confirmationForm/DateTimeCard";

const Wrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
`;

const LinkText = styled(BaseLinkText)`
  text-decoration: underline;
`;

const ConfirmationMessage = styled.div`
  font-size: 15px;
  font-weight: 200;
  line-height: 1.6;
  margin-top: 10px;
  white-space: pre-line;
`;

const StyledImage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  position: relative;
  top: 5px;
`;

const StyledSpan = styled.span`
  opacity: 0.6;
  font-size: 15px;
  line-height: 1.6;
  color: #314143;
`;

const RedirectCountdown = styled.div`
  opacity: 0.6;
  font-size: 20px;
  line-height: 1.6;
  font-weight: bold;
  margin-top: 10px;
`;

const CompletePage = (props) => {
  const today = moment();
  const { year, month, date, day, time, nextHour } = {
    year: today.format("YYYY"),
    month: today.format("MM"),
    date: today.format("DD"),
    day: today.get("day"),
    time: `${today.format("HH")}:00`,
    nextHour: `${today.format("HH")}:30`,
  };

  return (
    <>
      <Wrapper>
          <Heading size={2}>アポイントメントが確定しました</Heading>
          {(props.completedMessageWebpage && !props.customRedirect) && <ConfirmationMessage>{props.completedMessageWebpage}</ConfirmationMessage>}
          <Row display="flex" justifyContent="center">
            <DateTimeCard
              year={year}
              month={month}
              date={date}
              day={day}
              time={time}
              nextHour={nextHour}
              centerItems
              width="300px"
              mt="15px"
              mb="25px"
            />
          </Row>
          <Row display="flex" justifyContent="center" mb="25px">
            <img
              width="200px"
              height="150px"
              src={require("../../../assets/img/art-completed.svg")}
              alt="Completed"
            />
          </Row>
          {props.customRedirect ?
            <RedirectCountdown>3秒後に別のサイトに移動します</RedirectCountdown> :
            (<>
              <Row display="flex" justifyContent="center">
                <Button
                  outlined
                  mb="5px"
                >
                  <StyledImage
                    src={require("../../../assets/img/google_icon.svg")}
                  />
                  Googleカレンダーに追加
              </Button>
              </Row>
              <Row display="flex" justifyContent="center">
                <Button
                  outlined
                  mb="10px"
                >
                  <StyledImage
                    src={require("../../../assets/img/outlook_icon.svg")}
                  />
                  Outlookカレンダーに追加
                </Button>
              </Row>
              <StyledSpan>*Webブラウザ版のみ対応</StyledSpan>
            </>)
          }
          <LinkText mt="28px" color="#00bbb5" href="https://scheduling.receptionist.jp/">
            調整アポサービスサイト
          </LinkText>
        </Wrapper>
    </>
  );
};

export default CompletePage;
