import React from "react";
import styled from "styled-components";
import { Button, Modal as BaseModal } from "@d-lighted/design-system";
import { FormattedMessage } from 'react-intl';
import messages from '../i18n/Modules';

const Modal = styled(BaseModal)`
  width: 400px;
  min-height: auto;
  height: auto;
`;

const ModelContainer = styled.div`
  width: 100%;
  padding: 27px;
  overflow-y: auto;
`;

const Body = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  padding-bottom: 40px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ConfirmButton = styled(Button)`
  width: 100px;
`;

const CancelButton = styled(Button)`
  width: 100px;
`;

function ConfirmationDialogue(props) {
  return (
    <Modal onBackgroundClick={props.toggleModal} onEscapeKeydown={props.toggleModal} {...props}>
      <ModelContainer>
        <Body>
          <CenteredDiv>
            <FormattedMessage {...messages.deleteConfirmation} />
          </CenteredDiv>
          <ButtonDiv>
            <ConfirmButton
              disabled={props.isDeleting}
              onClick={() => {
                props.confirmFn();
              }}
            >
              <FormattedMessage {...messages.delete} />
            </ConfirmButton>
            <CancelButton
              disabled={props.isDeleting}
              onClick={() => {
                props.toggleModal();
              }}
            >
              <FormattedMessage {...messages.cancel} />
            </CancelButton>
          </ButtonDiv>
        </Body>
      </ModelContainer>
    </Modal>
  )
}

export default ConfirmationDialogue;