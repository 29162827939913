export function calendarIntegratedUser(schedulers) {
  return (schedulers?.google || schedulers?.outlook || schedulers?.garoon)
}

export function zoomNotAllowedUser(schedulers, meetingType, webConfType) {
  return calendarIntegratedUser(schedulers) && (meetingType === "web_conference" && webConfType === "zoom" && !schedulers?.zoom)
}

export function webexNotAllowedUser(schedulers, meetingType, webConfType) {
  return calendarIntegratedUser(schedulers) && (meetingType === "web_conference" && webConfType === "webex" && !schedulers?.webex)
}

export function meetNotAllowedUser(schedulers, meetingType, webConfType, isGaroonIntegrated) {
  if(!isGaroonIntegrated)
    return false; 
  return schedulers?.garoon && (meetingType === "web_conference" && webConfType === "google" && !schedulers?.google)
}

export function teamsConfNotAlloweduser(schedulers, meetingType, webConfType, isGaroonIntegrated) {
  if(!isGaroonIntegrated)
    return false; 
  return schedulers?.garoon && (meetingType === "web_conference" && webConfType === "microsoft" && !schedulers?.outlook)
}

function useWebConference(authorizedSchedulers, webConfType) {
  if(webConfType === "microsoft") return authorizedSchedulers?.outlook;
  return authorizedSchedulers[webConfType];
}

function isAnyExternalIntegrated(members, meetingType, webConfType, isGaroonIntegrated) {
  const externalTools = isGaroonIntegrated ? ["google", "microsoft", "zoom", "webex"] : ["zoom", "webex"];
  if(meetingType !== "web_conference" || !externalTools.includes(webConfType)) return true;
  if(members.length === 0) return false;
  return members.some(member => (member.attendee || member.mustAttend) && calendarIntegratedUser(member.authorizedSchedulers) && useWebConference(member.authorizedSchedulers, webConfType));
}

function isAllExternalIntegrated(members, meetingType, webConfType, isGaroonIntegrated) {
  const externalTools = isGaroonIntegrated ? ["google", "microsoft", "zoom", "webex"] : ["zoom", "webex"];
  if(meetingType !== "web_conference" || !externalTools.includes(webConfType)) return true;
  if(members.length === 0) return false;
  return members.every(member => (member.attendee || member.mustAttend) && calendarIntegratedUser(member.authorizedSchedulers) && useWebConference(member.authorizedSchedulers, webConfType));
}

export function isExternalIntegrated(members, meetingType, webConfType, attendanceRule, isGaroonIntegrated) {
  if(!members) return;
  if(attendanceRule === "custom_will_attend") {
    const mustMembers = members.filter(member => member.mustAttend);
    const optionalMembers = members.filter(member => !member.mustAttend && member.attendee);
    if(members.filter(member => member.mustAttend && member.name).length > 0) {
      return isAnyExternalIntegrated(mustMembers, meetingType, webConfType, isGaroonIntegrated);
    }
    return isAnyExternalIntegrated(mustMembers, meetingType, webConfType, isGaroonIntegrated) || isAllExternalIntegrated(optionalMembers, meetingType, webConfType, isGaroonIntegrated);
  }
  if(attendanceRule === "all_will_attend") {
    return isAnyExternalIntegrated(members, meetingType, webConfType, isGaroonIntegrated);
  }
  isAllExternalIntegrated(members, meetingType, webConfType, isGaroonIntegrated);
}

export function getMustAttendeesCount(containers, attendanceRule) {
  if(attendanceRule === "custom_will_attend") {
    return containers[0].filter(member => member.mustAttend && member.name).length;
  }
  return 0;
}
