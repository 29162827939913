import React, { useCallback } from "react";
import { renderToString } from 'react-dom/server';
import styled from "styled-components";
import { Grid, Row, Col } from "@d-lighted/design-system";
import { useLocation } from "react-router";
import ReactTooltip from "react-tooltip";
import { PrimaryWhiteBackground } from "../utils/Helper";
import useWindowSize from "../utils/useWindowSize";
import ProductLogo from "../../assets/img/logo_mailer.svg";
import ToolLottie from "../elements/ToolLottie";
import BookingLottie from "../../assets/lottie/booking_preview.json";
import { useOnboarding } from "../../views/onboarding/hooks/useOnboarding";
import Tooltip from '../elements/PreviewTooltip';
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../constants/breakpoint";
import { capitalizedString } from "../../views/onboarding/utils/utils";
import { getCurrentUser } from "../../utils/userUtils";
import { generateLinkURL } from "../../views/home/utils/utils";

const DashboardWrapper = styled.div`
  max-width: 100%;
  background-color: #fff;
  overflow: hidden;
`;

const LeftPreviewWrapper = styled.div`
  width: 33.33%;
  height: 100vh;
  background: white;
`;

const MidPreviewWrapper = styled.div`
  width: 33.33%;
  height: 100vh;
  background: rgb(224,246,254);
  background: linear-gradient(0deg, rgba(224,246,254,1) 0%, rgba(199,237,250,1) 100%);
`;

const RightPreviewWrapper = styled.div`
  width: 33.33%;
  height: 100%;
  background: rgb(212,241,252);
  background: linear-gradient(0deg, rgba(212,241,252,1) 0%, rgba(224,246,254,1) 100%);
`;


const Header = styled.img`
  width: 100px;
  height: 50px;
  padding-right: 25px;
  color: #314143;
`;

const Preview = styled.div`
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  display: flex;
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.1));
  height: ${props => props.hasTooltip ? 'calc(100% - 135px - 15vh)' : '100%'};
  margin: ${props => props.hasTooltip ? '5vh 2vw 10vh 2vw' : '0'};
  position: relative;
`;

const PreviewWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  iframe {
    position:absolute;
    ${props => props.hasTooltip ? 'top:0; left:0;' : ''}
    width: ${props => props.hasTooltip ? '100%' : '1224px'};
    height: ${props => props.hasTooltip ? '100%' : '1050px'};
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.width};
  height: 100vh;
  background-color: ${props => props.color ? props.color : "#F0FAFC"};
  position: absolute;
  overflow-x: hidden;
  overflow-y: hidden; 
`;

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    color: #fff;
    border-radius: 10px;
    width: calc(48vw - 42px);
    height: 90px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: right;
    justify-content: right;
    font-size: 12px;
    font-weight: bold;
    margin-top: -30px !important;
    
    transition: visibility 0s .375s, opacity .375s ease-in-out !important;
    
    &.show{
        transition: visibility 0s 0s, opacity .375s ease-in-out !important;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: ${props => props?.top};
  margin-left: 40px;

  @media (max-width: ${MOBILEBREAKPOINT}){
    margin-top: 0;
    margin-left: 0;
  }
`;

export function OnBoardingDashboard(props) {
  const time = "10:00";
  const size = useWindowSize();
  const location = useLocation();
  const { hasTooltip } = props;
  const currentUser = getCurrentUser();

  const handleTooltipRender = useCallback((node) => {
    if(node) {
      ReactTooltip.show(node);
    }
  }, []);

  const locationArray = location.pathname.split("/");
  const currentLocation = locationArray[locationArray.length - 1];
  const { onBoardingCalendarSetting } = useOnboarding();
  const scheduler = capitalizedString(currentUser.authorized_scheduler);
  const calendarOwner = onBoardingCalendarSetting?.data?.members?.filter(member => member.owner)?.[0]?.name;
  const attendee = onBoardingCalendarSetting?.data?.members?.find(member => member?.email === currentUser?.email)?.attendee;
  const bookingPageUrl = generateLinkURL(onBoardingCalendarSetting?.data?.alias, onBoardingCalendarSetting?.data?.uid, false, onBoardingCalendarSetting?.data?.durations);

  const scale = window.screen.width >= 1920 ? 0.8 : 0.6;
  const width = window.screen.width >= 1920 ? "58vw" : "58vw";
  
  const showDummyPreview =  ["business_info", "signup", "integrations"].includes(currentLocation);
  const normalEmployeeOnboarding = ["employee"].includes(locationArray[locationArray.length - 2]);
  const previewBlocked = currentLocation.includes("more_customizations");

  const isMobileView = SHOW_MOBILE(window.innerWidth);
  const tooltipHeader = normalEmployeeOnboarding ?
      attendee ?
        `あなたと${calendarOwner}さんの空き時間はこのように表示されています。`
      :
        `${calendarOwner}さんの空き時間はこのように表示されています。`
    : 
      `あなたの${scheduler}カレンダーから抽出した空き時間が、このように表示されています。` 

  return (
    <DashboardWrapper>
      <PrimaryWhiteBackground>
      <Grid fluid p="0" h="100%">
        <Row backgroundColor="#fff" m="0">
          <Col xs sm={12} md={5} lg={5} pl={isMobileView ? "18px" : "30px"} pr={isMobileView ? "18px" : "20px"} >
            <Header
              className="footer_page_image align-text-bottom"
              src={ProductLogo}
              alt="logo"
            />
            <Wrapper top={props?.top}>
              {props?.children}
            </Wrapper>
          </Col>
          {size.width >= 1024 && <Col xs md={7} lg={7} pr="0">
            <Center width={width}>
              {!previewBlocked && (
                showDummyPreview?
                  <>
                    <LeftPreviewWrapper/>
                    <MidPreviewWrapper/>
                    <RightPreviewWrapper/>
                    <ToolLottie 
                      lottie={BookingLottie}
                      duration={3000}
                      scale="1.4"
                      left="20%"
                    />
                  </>
                :
                  <>
                    <PreviewWrapper hasTooltip={hasTooltip}>
                      {hasTooltip && <>
                          <div style={{ width: '100%', height: '130px' }}>
                            <CustomReactTooltip
                              id='preview_tooltip'
                              delayHide={100}
                              html={true}
                              place="top"
                              effect="solid"
                              backgroundColor="#00AED4"
                            />
                          </div>
                        </>
                      }
                      <Preview
                        id="preview"
                        name="preview"
                        dangerouslySetInnerHTML={{ 
                          __html: `<iframe src="${bookingPageUrl}" scrolling="yes" frameborder="0" allowfullscreen ${hasTooltip ? '' : `style="-webkit-transform:scale(${scale});-moz-transform-scale(${scale});"}`}/>`
                        }}
                        data-for='preview_tooltip'
                        data-tip={renderToString(<Tooltip time={time} tooltipHeader={tooltipHeader}/>)}
                        data-html={true}
                        data-event=" "
                        data-event-off=" "
                        data-scroll-hide={false}
                        ref={handleTooltipRender}
                        hasTooltip={hasTooltip}
                      />
                    </PreviewWrapper>
                  </>
              )}
            </Center>
          </Col>}
        </Row>
      </Grid>
      </PrimaryWhiteBackground>
    </DashboardWrapper>
  );
}

OnBoardingDashboard.defaultProps = {
  top: '30px'
};

export default OnBoardingDashboard;
