/** actions whose method value is "get"/"other" we have reducers.  */
/** initial state are set in default structure */
/** initialState -- key can be used to set initial state explicitly */
export const actions = {
  getCalendarSetting: {
    name: "calendarSetting",
    url: "/booking_calendar_settings/",
    query: null,
    body: null,
    method: "get",
  },
  getChatSetting: {
    name: "chatSetting",
    url: "/chat/setting_chats",
    query: null,
    body: null,
    method: "get",
  },
  getCalendar: {
    name: "calendar",
    url: "/booking_calendars/",
    query: null,
    body: null,
    method: "get",
  },
  getSettingGates: {
    name: "settingGates",
    url: "/appointments/setting_gates",
    query: null,
    body: null,
    method: "get",
  },
};
