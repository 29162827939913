import React from "react";
import styled from "styled-components";
import FontAwesome from "react-fontawesome";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Col,
  Label,
  Row,
} from "@d-lighted/design-system";
import { Line } from "components/elements/DragIcon";
import Tooltip from "components/elements/Tooltip";
import { useValidation } from "utils/validation";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import messages from "../i18n/MultiGuest";

const DragIconWrapper = styled.div`
  margin-top: 8px;
`;

const MemberFieldWrapper = styled.div`
  box-sizing: border-box;
  margin: 5px 10px;
  padding: 8px;
  display: flex;
  border-radius: 5px;
  width: 100%;
  border: solid 1px #d2e9e7;
  align-items: center;
  place-content: space-between;
  &:hover {
    border-color: #00bbb5;
  }
`;

const ClickableIcon = styled.div`
  cursor: pointer;
`;

const DragIcon = styled.div`
  cursor: move;
  .line + .line {
    margin-top: 4px;
  }
`;

const HostInput = styled.input`
  height: 32px;
  width: 300px;
  border: solid 1px #d2e9e7;
  border-radius: 4px;
  margin-bottom: 8px !important;
  padding: 0 0.75rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #314143;
`;

const Icon = styled(FontAwesome)`
  color: #e84c3d;
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const HostComponent = ({ handleRemoveAttendee, handleAttendeeChange, handleDragEnd, attendees, intl }) => {
  const { required, email } = useValidation(intl);

  const handleChange = (index, field, newValue) => {
    handleAttendeeChange(index, field, newValue)
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedAttendees = reorder(attendees, result.source.index, result.destination.index);
    handleDragEnd(reorderedAttendees);
  };


  return (
      <Row>
        <Col xs mb="10px">
          <Label>
            <FormattedMessage {...messages.externalMultiguestAttendees} />
          </Label>
          <Tooltip
            message="Explain this option"
            width="auto"
            top={2}
            left={400}
          />
        </Col>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="attendees">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {attendees.map((attendee, index) => (
                  <Draggable key={index} draggableId={`attendee-${index}`} index={index}>
                    {(provided) => (
                      <MemberFieldWrapper
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        key={index}
                      >
                        <Row>
                          <Col xs display="flex" justifyContent="left">
                            <DragIconWrapper>
                              <DragIcon draggable>
                                <Line className="line" />
                                <Line className="line" />
                                <Line className="line" />
                              </DragIcon>
                            </DragIconWrapper>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs display="flex" flexDirection="column" alignItems="center">
                            <HostInput
                              name={`attendee-${index}-name`}
                              value={attendee.name}
                              validate={[required]}
                              placeholder="氏名"
                              onChange={e => handleChange(index, "name", e.target.value)}
                            />
                            <HostInput
                              name={`attendee-${index}-company`}
                              value={attendee.company}
                              validate={[required]}
                              placeholder="会社名"
                              onChange={e => handleChange(index, "company", e.target.value)}
                            />
                            <HostInput
                              name={`attendee-${index}-email`}
                              value={attendee.email}
                              validate={[required, email]}
                              placeholder="メールアドレス"
                              onChange={e => handleChange(index, "email", e.target.value)}
                            />
                          </Col>
                        </Row>
                        <Col xs={1}>
                          <ClickableIcon onClick={() => handleRemoveAttendee(index)}>
                            <Icon color="" name="remove" />
                          </ClickableIcon>
                        </Col>
                      </MemberFieldWrapper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Row>

  );
};

export default injectIntl(HostComponent);
