import MultiGuest from "./pages/MultiGuest";
import Scheduling from "./pages/Scheduling";
import SchedulingFinalize from "./pages/SchedulingFinalize";
import SchedulingFinalizeConfirm from "./pages/SchedulingFinalizeConfirm";
import Settings from "./pages/Settings";

export const multiguestRoutes = [
  {
    component: MultiGuest,
    path: "/booking_calendars/:uid/multiguest",
    exact: true,
    feature: "multiguest",
    type: "private",
  },
  {
    component: Scheduling,
    path: "/booking_calendars/:uid/multiguest/list",
    exact: true,
    feature: "multiguestList",
    type: "private",
  },
  {
    component: Settings,
    path: "/booking_calendars/:uid/multiguest/:multiguest_calendar_setting_uid/settings",
    exact: true,
    feature: "multiguesCalendarSettings",
    type: "private",
  },
  {
    component: SchedulingFinalize,
    path: "/booking_calendars/:uid/multiguest/:multiguest_calendar_uid",
    exact: true,
    feature: "multiguestFinalize",
    type: "private",
  },
  {
    component: SchedulingFinalizeConfirm,
    path: "/booking_calendars/:uid/multiguest/:multiguest_calendar_uid/confirm",
    exact: true,
    feature: "multiguestConfirm",
    type: "private",
  },
]
