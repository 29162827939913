import { defineMessages } from 'react-intl';

export default defineMessages({
  admissionPeriod: {
    id: 'views.availability.pages.availability.admissionPeriod',
    defaultMessage: 'Admission Period'
  },
  businessOpeningTime: {
    id: 'views.availability.pages.availability.businessOpeningTime',
    defaultMessage: 'Business Hours',
  },
  businessOpeningPlainTime: {
    id: 'views.availability.pages.availability.businessOpeningPlainTime',
    defaultMessage: 'Business Hours',
  },
  businessOpeningDays: {
    id: 'views.availability.pages.availability.businessOpeningDays',
    defaultMessage: 'Business Days',
  },
  buffer: {
    id: 'views.availability.pages.availability.buffer',
    defaultMessage: 'Buffer',
  },
  bufferTooltip: {
    id: 'views.availability.pages.availability.buffer.tooltip',
    defaultMessage:
      'This is a time slot that is not available for making an appointment before or after a confirmed appointment. Please specify a time slot that will be used to move or prepare for the next appointment.',
  },
  bufferPreText: {
    id: 'views.availability.pages.availability.buffer.preText',
    defaultMessage: 'Before and after',
  },
  bufferPostText: {
    id: 'views.availability.pages.availability.buffer.postText',
    defaultMessage: ', bookings are not allowed.',
  },
  bufferEventsHeader: {
    id: 'views.availability.pages.availability.bufferEvents.header',
    defaultMessage: 'Buffer calendar events',
  },
  preBufferEventsPreText: {
    id: 'views.availability.pages.availability.preBufferEvents.preText',
    defaultMessage: 'Create a pre',
  },
  postBufferEventsPretext: {
    id: 'views.availability.pages.availability.postBufferEvents.preText',
    defaultMessage: 'Create a followed',
  },
  bufferEventsPostText: {
    id: 'views.availability.pages.availability.bufferEvents.postText',
    defaultMessage: 'buffer events before and after a confirmed appointment',
  },
  bufferEventTooltip: {
    id: 'views.availability.pages.availability.bufferEvents.bufferEventTooltip',
    defaultMessage: `
      Buffer appointments can be automatically created before and after the confirmed appointment time. {br}
      If you want to reserve an appointment on the linked calendar for a time slot for travel or preparation, specify this time slot. {br}
      ※<a>Click here</a> for details.
    `
  },
  bookingRange: {
    id: 'views.availability.pages.availability.bookingRange',
    defaultMessage: 'Booking Range',
  },
  bookingRangeNow: {
    id: 'views.availability.pages.availability.bookingRange.now',
    defaultMessage: 'Now on',
  },
  bookingRangeDays: {
    id: 'views.availability.pages.availability.bookingRange.days',
    defaultMessage: ' Days',
  },
  bookingRangeMinPreText: {
    id: 'views.availability.pages.availability.bookingRange.minimum.preText',
    defaultMessage: 'Allow booking from',
  },
  bookingRangeMinPostText: {
    id: 'views.availability.pages.availability.bookingRange.minimum.postText',
    defaultMessage: '.',
  },
  bookingRangeMaxPreText: {
    id: 'views.availability.pages.availability.bookingRange.maximum.preText',
    defaultMessage: 'Allow booking for up to',
  },
  bookingRangeMaxPostText: {
    id: 'views.availability.pages.availability.bookingRange.maximum.postText',
    defaultMessage: 'from now.',
  },
  availableEventPrefix: {
    id: 'views.availability.pages.availability.availableEventPrefix',
    defaultMessage: 'Available Event Prefix',
  },
  availableEventPrefixPlaceholder: {
    id:
      'views.availability.pages.availability.availableEventPrefix.placeholder',
    defaultMessage: 'Example）[Adjustable]',
  },
  availableEventPrefixTooltip: {
    id: 'views.availability.pages.availability.availableEventPrefix.tooltip',
    defaultMessage: `If the keyword is included in the title of the event,
                     the event will be treated as free time and will become available for reservation.
                     ※It will be "reservable" even during the scheduled time when you reply "participate".`,
  },
  addAvailableEventPrefix: {
    id: 'views.availability.pages.availability.addAvailableEventPrefix',
    defaultMessage: 'Add Available Event Prefix',
  },
  add: {
    id: 'views.availability.pages.availability.availableEventPrefix.add',
    defaultMessage: 'Add',
  },
  holidayBlocking: {
    id: 'views.availability.pages.availability.holidayBlocking',
    defaultMessage: 'Holiday Behavior',
  },
  bankHolidayBlocking: {
    id: 'views.availability.pages.availability.bankHolidayBlocking',
    defaultMessage: 'Holiday Behavior',
  },
  holidayBlockingDescription: {
    id: 'views.availability.pages.availability.holidayBlocking.description',
    defaultMessage: 'Mark holidays as unavailable.',
  },
  freeHolidayBlockingDescription: {
    id: 'views.availability.pages.availability.holidayBlocking.free.description',
    defaultMessage: 'Prevent reservations on weekends and holidays.',
  },
  timeMinutes: {
    id: 'views.home.modules.calendar.timeMinutes',
    defaultMessage: ' Min',
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyBusinessHours: {
    id: 'notify.availability.error.businessHours',
    defaultMessage: 'It is not possible save with all days of the week unchecked.',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save',
  },
  close: {
    id: "common.close",
    defaultMessage: "Close",
  },
  sunday: {
    id: 'common.sunday',
    defaultMessage: 'Sun',
  },
  monday: {
    id: 'common.monday',
    defaultMessage: 'Mon',
  },
  tuesday: {
    id: 'common.tuesday',
    defaultMessage: 'Tue',
  },
  wednesday: {
    id: 'common.wednesday',
    defaultMessage: 'Wed',
  },
  thursday: {
    id: 'common.thursday',
    defaultMessage: 'Thur',
  },
  friday: {
    id: 'common.friday',
    defaultMessage: 'Fri',
  },
  saturday: {
    id: 'common.saturday',
    defaultMessage: 'Sat',
  },
  reservable: {
    id: 'views.availability.pages.availability.bookingRange.reservable',
    defaultMessage: 'Specify the reservable range by date',
  },
  reservation: {
    id: 'views.availability.pages.availability.bookingRange.reservation',
    defaultMessage:
      'Specify how many days later you can make a reservation with respect to the current date and time',
  },
  businessReservable: {
    id: 'views.availability.pages.availability.bookingRange.businessReservable',
    defaultMessage: 'Specify how many working days after the earliest booking can be made',
  },
  copyToAll: {
    id: 'views.availability.pages.availability.businessHours.copyToAll',
    defaultMessage: 'Copy To All',
  },
  startDate: {
    id: 'views.availability.pages.availability.businessHours.calendar.startDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'views.availability.pages.availability.businessHours.calendar.endDate',
    defaultMessage: 'End Date',
  },
  pleaseSelect:{
    id: 'common.pleaseSelect',
    defaultMessage: 'Please Select',
  },
  appointmentCancellation:{
    id: 'views.availability.pages.availability.appointmentCancellation',
    defaultMessage: 'Allow appointment changes or cancellations on the customer side'
  },
  appointmentCancellationTooltip:{
    id: 'views.availability.pages.availability.appointmentCancellationTooltip',
    defaultMessage: 'When enabled, a button for appointment change/cancellation will be displayed in the guest e-mail. If disabled, only the e-mail address to which the appointment change/cancellation request should be sent will be displayed.'
  },
  enableAppointmentCancellation:{
    id: 'views.availability.pages.availability.enableAppointmentCancellation',
    defaultMessage: 'Enable'
  },
  disableAppointmentCancellation:{
    id: 'views.availability.pages.availability.disableAppointmentCancellation',
    defaultMessage: 'Disable'
  },
  appointmentCancellationTimeLimit:{
    id: 'views.availability.pages.availability.appointmentCancellationTimeLimit',
    default: 'Cancellable Before'
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
