import React from 'react';
import styled from 'styled-components';

const Plan = styled.span`
  background: #F2F2F2;
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  color: #67878d;
`;

const Wrapper = styled.div`
  display: inline-block;
  margin-left: 20px;
  box-sizing: border-box;
  padding: 10px 0;
`;

function PlanStatusLabel({ planStatus }) {
  if (!planStatus) return null;

  return (
    <Wrapper>
      <Plan>{planStatus}</Plan>
    </Wrapper>
  );
}

PlanStatusLabel.defaultProps = { };

export default PlanStatusLabel;
