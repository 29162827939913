import { isPaidOrTrialPlan, isEnterprisePlan, isPremiumPlan } from "../../../utils/userUtils";
import * as messageIdConstants from "../constants/messageIdConstants";
import { useAuth } from "../../../hooks/useAuth";
const { authActions } = useAuth();

export const isAccessAllowed = (plan) => {
  let allowed = false;
  if(plan) {
    switch(plan) {
      case "premium":
        allowed = isPremiumPlan();
        break;
      case "enterprise":
        allowed = isEnterprisePlan();
        break;
      default:
        allowed = isPaidOrTrialPlan();
        break;
    }
  }
  return allowed;
};

export const planBlockedOpenUrl = async(messageId) => {
  let openUrl = "";
  switch(messageId) {
    case messageIdConstants.sfNotLinked:
      openUrl = await authActions.getOneTimeAccessUrl('/visitor_setting');
      break;
    case messageIdConstants.slackOnlyAllowed:
      openUrl = await authActions.getOneTimeAccessUrl('/chatsetting');
      break;
    case messageIdConstants.chatToolsNotLinked:
      openUrl = await authActions.getOneTimeAccessUrl('/chatsetting');
      break;
    case messageIdConstants.sfFieldMatchError:
      openUrl = `${process.env.REACT_APP_SCHEDULING_HELP_ROOT}/integration-with-salesforce/#careful`;
      break;
    default: 
      openUrl = `${process.env.REACT_APP_SCHEDULING_LP_ROOT}/contact-plan`;
  }
  return openUrl;
};
