import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import FontAwesome from "react-fontawesome";
import styled from "styled-components";
import { defineMessages, injectIntl } from "react-intl";
import { copy } from "../../utils/copyUtils";

const Icon = styled(FontAwesome)`
  width: 13px;
  height: 13px;
  position: relative;
  top: 1px;
  margin-left: -2px;
  color: #00bbb5;
  cursor: pointer;
`;

const Wrapper = styled.span`
  display: inline-grid;
  position: relative;
  top: ${props => props.top}px;
  left: 8px;
`;

const IconWrapper = styled.span`
  display: block;
  &:hover + .display-text, & + .display-text:hover {
    visibility: visible;
    opacity: 1;
  }
  height: 25px;
  width: 25px;
  background: #CFF3EC;
  text-align: center;
  border-radius: 50%;
`;

const messages = defineMessages({
  copyText: {
    id: 'common.copied',
    defaultMessage: 'Copied',
  }
});

const EmailIcon = (props) => {
  const [hoverEnter, setHover] = useState(false);
  const [tipMessage, setMessage] = useState(props.visitorEmail || undefined);

  const handleHover = () => {
    if(hoverEnter) {
      return setHover(false);
    }
    setHover(true);
    setMessage(props.visitorEmail);
  };
  
  const handleCopyEmail = () => {
    if(hoverEnter) {
      copy(props.visitorEmail);
      setMessage(props.intl.formatMessage({...messages.copyText}));
    }
  };

  return (
    <Wrapper top={props.top}>
      <IconWrapper>
        <Icon
          name={hoverEnter ? "copy": "envelope"} 
          className={hoverEnter ? "far fa-copy fa-sm" : "fas fa-envelope fa-sm"}
          role="button"
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          onClick={handleCopyEmail}
          data-for={props.uid}
          data-tip={tipMessage}
        />
        <ReactTooltip isCapture={true}
          id={props.uid}
          getContent={[() => { return tipMessage; }]}
          delayHide={500}
          place="bottom"
          effect="solid"
          backgroundColor="#6e52ff"/>
      </IconWrapper>
    </Wrapper>
  );
}

EmailIcon.defaultProps = {
  top: 0,
  uid: '',
  visitorEmail: '',
};

export default injectIntl(EmailIcon);
