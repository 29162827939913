import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.layouts.emailNotifications.reminderEmail.title',
    defaultMessage: 'Allow reminder email',
  },
  titleTooltip: {
    id: 'components.layouts.emailNotifications.reminderEmail.title.toolTip',
    defaultMessage: 'This will be reflected in both the email to the customer and the email to the person in charge.',
  },
  sendReminderEmail: {
    id: 'components.layouts.emailNotifications.reminderEmail.sendReminderEmail',
    defaultMessage: 'Send reminder emails to guests'
  },
  sendReminderEmailTime: {
    id: 'components.layouts.emailNotifications.reminderEmail.sendReminderEmailTime',
    defaultMessage: 'Reminder email sending time'
  },
  remindHrPreText: {
    id: 'components.layouts.emailNotifications.reminderEmail.remindHrPreText',
    defaultMessage: 'at'
  },
  remindHrPosText: {
    id: 'components.layouts.emailNotifications.reminderEmail.remindHrPosText',
    defaultMessage: 'send reminder email'
  },
  remindHr: {
    id: 'components.layouts.emailNotifications.reminderEmail.remindHr',
    defaultMessage: ':00'
  },
  timeHour: {
    id: 'views.home.modules.calendar.timeHour',
    defaultMessage: ' Hr',
  },
  remindDayBefore: {
    id: 'components.layouts.emailNotifications.reminderEmail.remindDayBefore',
    defaultMessage: 'Prev day',
  },
  remindDaySame: {
    id: 'components.layouts.emailNotifications.reminderEmail.remindDaySame',
    defaultMessage: 'On day',
  },
  subject: {
    id: 'components.layouts.emailNotifications.emailSubject',
    defaultMessage: 'Email subject'
  },
  subjectPlaceholder: {
    id: 'components.layouts.emailNotifications.reminderEmail.subjectPlaceholder',
    defaultMessage: 'An email subject should contain {自社の会社名}',
  },
  leadText: {
    id: 'components.layouts.emailNotifications.leadText',
    defaultMessage: 'Lead text',
  },
  leadTextPlaceholder: {
    id: 'components.layouts.emailNotifications.leadTextPlaceholder',
    defaultMessage: 'A large text that serves as leading text',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
