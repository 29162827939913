export function allowedMeetingToolsReducer(state={google: false, microsoft: false, zoom: false}, members) {
  const checkGoogleScheduler = (member) => (member.authorizedSchedulers?.google);
  const checkMicrosoftScheduler = (member) => (member.authorizedSchedulers?.outlook);
  const checkZoomScheduler = (member) => (member.authorizedSchedulers?.zoom);
  const checkWebexScheduler = (member) => (member.authorizedSchedulers?.webex);

  // some() tests at least one member has scheduler
  state.google = members.some(checkGoogleScheduler);
  state.microsoft = members.some(checkMicrosoftScheduler);
  state.zoom = members.some(checkZoomScheduler);
  state.webex= members.some(checkWebexScheduler);
  return state;
}
