import { defineMessages } from "react-intl";

export default defineMessages({
  primary: {
    id: "views.home.pages.dashboardEmpty.template.primary",
    defaultMessage: "There's no templates to be used",
  },
  secondary: {
    id: "views.home.pages.dashboardEmpty.template.secondary",
    defaultMessage:
      "The organization does not yet have a template. Let's create the first template!",
  },
});
