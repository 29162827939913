import { defineMessages } from 'react-intl';

export default defineMessages({
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  completedMessageWebpage: {
    id: 'views.booking.pages.reservationFinished.completedMessageWebpage',
    defaultMessage: 'Save changes',
  },
  completedMessageWebpagePlaceholder: {
    id: 'views.booking.pages.reservationFinished.completedMessageWebpage.placeholder',
    defaultMessage: 'Save changes',
  },
  standard: {
    id: 'components.layouts.blocked.standard',
    defaultMessage: 'You can use this feature by <a>subscribing to a paid plan.</a>',
  },
  customRedirect: {
    id: 'views.home.modules.home.customRedirect',
    defaultMessage: "Use a redirect URL",
  },
  customRedirectUrl: {
    id: 'views.home.modules.home.customRedirectUrl',
    defaultMessage: "Redirect URL",
  },
  customRedirectUrlPlaceholder: {
    id: 'views.home.modules.home.customRedirectUrl.placeholder',
    defaultMessage: "https://example.com",
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
