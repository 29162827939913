import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'views.integrations.pages.salesforce.title',
    defaultMessage: 'Salesforce Integration',
  },
  createEvent: {
    id: 'views.integrations.pages.salesforce.createEvent',
    defaultMessage: 'Create new ActivityHistory automatically',
  },
  createLead: {
    id: 'views.integrations.pages.salesforce.createLead',
    defaultMessage: 'Create new Lead automatically',
  },
  save: {
    id: 'views.integrations.pages.salesforce.save',
    defaultMessage: 'Save Changes',
  },
  notifySuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  notifyError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: 'Booking settings could\'nt be saved.',
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  createEventBrief: {
    id: 'views.integrations.pages.salesforce.createEvent.brief',
    defaultMessage: 'Automatically create a new action for the lead or the person responsible for the business partner that matches the email address entered when the reservation was confirmed. {br} A new action can be created automatically for either a lead or a contact person, in that order.',
  },
  createLeadBrief: {
    id: 'views.integrations.pages.salesforce.createLead.brief',
    defaultMessage: 'If there is no lead or contact person that matches the email address you entered when confirming the reservation, you can automatically create a new lead. {br} A new lead can be created automatically.',
  },
  linkCustomFields: {
    id: 'views.integrations.pages.salesforce.linkCustomFields',
    defaultMessage: 'Link with custom input fields'
  },
  linkCustomFieldsBrief: {
    id: 'views.integrations.pages.salesforce.linkCustomFieldsBrief',
    defaultMessage: 'For input fields that are filled from the booking page {br} You can map the customer\'s input to the field in the {br} Salesforce lead.'
  },
  integrateField: {
    id: 'views.integrations.pages.salesforce.integrateField',
    defaultMessage: 'Field Integration'
  },
  customInputField: {
    id: 'views.integrations.pages.salesforce.customInputField',
    defaultMessage: 'Booking Custom Fields'
  },
  salesforceField: {
    id: 'views.integrations.pages.salesforce.salesforceField',
    defaultMessage: 'Salesforce Field'
  },
  salesforceCustomFieldWarning: {
    id: 'views.integrations.pages.salesforce.salesforceCustomFieldWarning',
    defaultMessage: 'This settings are controlled by custom field settings.'
  },
  splitNameLabel: {
    id: 'views.integrations.pages.salesforce.splitNameLabel',
    defaultMessage: 'Save Lead name separately'
  },
  firstName: {
    id: 'common.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'common.lastName',
    defaultMessage: 'Last Name',
  },
  leadName: {
    id: 'views.customField.nameField',
    defaultMessage: 'Name',
  },
  company: {
    id: 'views.customField.companyNameField',
    defaultMessage: 'Company name',
  },
  email: {
    id: 'common.email',
    defaultMessage: 'Email',
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
