import Salesforce from "./pages/Salesforce";
import { templatesEnabledAdminValidation } from "utils/userUtils";

export const integrationRoutes = [
  {
    component: Salesforce,
    path: "/:settings(templates|booking_calendars)/:uid/integrations/salesforce",
    exact: true,
    feature: "Salesforce",
    type: "private",
    gauid: 'Bp/Integration/Sf',
    validation: templatesEnabledAdminValidation,
  },
];
