import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
  SAML: {
    id: 'views.signIn.pages.signIn.SAML',
    defaultMessage: 'SAML authentication',
  },  
  inputDomain: {
    id: 'views.samlSignIn.pages.samlSignIn.inputDomain',
    defaultMessage: 'Please input company domain.',
  },
  passwordAuthentication: {
    id: 'views.samlSignIn.pages.samlSignIn.passwordAuthentication',
    defaultMessage: 'Password authentication',
  },
  usablePlan: {
    id: 'views.samlSignIn.pages.samlSignIn.usablePlan',
    default: 'SAML authentication is only plemium plan.',
  },
  helpLink: {
    id: 'views.samlSignIn.pages.samlSignIn.helpLink',
    default: 'Go to help center',
    values: {
      // eslint-disable-next-line react/display-name
      a: msg => (
        <a
          href="https://help.receptionist.jp/?p=18189"
          target="_blank"
          rel="noopener noreferrer"
        >
          {msg}
        </a>
      ),
    },
  },
  companyDomain: {
    id: 'views.samlSignIn.pages.samlSignIn.companyDomain',
    default: 'Company domain',
  },
  login: {
    id: 'views.signIn.pages.signIn.login',
    defaultMessage: 'Login',
  },
});
