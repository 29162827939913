import React from "react";
import { Label } from "@d-lighted/design-system";

export default function Title({title}) {
  return (
    <Label
      fontSize="20px"
      fontWeight="500"
      letterSpacing="1px"
      color="#142C2B">
      {title}
    </Label>
  )
}