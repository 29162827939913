import React  from "react";
import { Field } from "redux-form";
import { useIntl } from "react-intl";
import {
  InputSelectWithIcon
} from "@d-lighted/design-system";
import { snakeCase } from "lodash";
import messages from "views/meetings/i18n/Meetings";

export function convertToOption(values, intl) {
  if (values == null) return {};

  const result = {};
  Object.keys(values).map(key => result[key] = convertValueToObject(values[key], intl));
  return result;
}

export function convertToPermission(values, convertKey = true) {
  if (values == null) return {};

  const result = {};
  Object.keys(values).map(key => result[convertKey ? snakeCase(key) : key] = values[key]["value"]);
  return result;
}

function convertValueToObject(value, intl) {
  return value === "all" ?
    {
      value: "all",
      label: intl.formatMessage({ ...messages.allEdit }),
      icon: "unlock-alt",
      color: "#00bbb5",
    }
    :
    {
      value: "administrator",
      label: intl.formatMessage({ ...messages.adminEdit }),
      icon: "lock",
      color: "red",
    }
}

const TemplatePermissionSelector = ({ name }) => {
  const intl = useIntl();
  
  // TODO: Verify the stylings of the icons & InputSelectWithIcon component
  const editAccessOptions = [
    {
      value: "administrator",
      label: intl.formatMessage({ ...messages.adminEdit }),
      icon: "lock",
      color: "red",
    },
    {
      value: "all",
      label: intl.formatMessage({ ...messages.allEdit }),
      icon: "unlock-alt",
      color: "#00bbb5",
    },
  ];
  
  return (
    <Field
      name={`permissions.${name}`}
      component={InputSelectWithIcon}
      options={editAccessOptions}
      height="34px"
      width="224px"
      border="solid 1px #d2e9e7"
      backgroundColor="#F7F9F9"
      iconPosition="left"
      optionIconSize="20px"
      dropdownIcon="caret-down"
      getOptionValue={option => option.value}
    />
  );
};

export default TemplatePermissionSelector;
