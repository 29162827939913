import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteTemplate: {
    id: 'views.template.pages.deleteTemplate',
    defaultMessage: 'この予約ページテンプレートを削除',
  },
  templateSettings: {
    id: 'views.template.pages.templateSettings',
    defaultMessage: 'テンプレート設定'
  },
  templatePageLimit: {
    id: 'views.template.pages.templatePageLimit',
    defaultMessage: 'このテンプレートから作成可能な予約ページ数',
  },
  page: {
    id: 'views.template.pages.templatePageLimit.page',
    defaultMessage: 'ページ',
  },
  save: {
    id: 'views.template.pages.templatePageLimit.save',
    defaultMessage: '保存',
  },
  templateList: {
    id: 'views.template.pages.templateList',
    defaultMessage: 'このテンプレートから作成済みの予約ページ一覧',
  },
  past30days: {
    id: 'views.template.pages.past30days',
    defaultMessage: '過去30日の予約数:',
  },
  past90days: {
    id: 'views.template.pages.past90days',
    defaultMessage: '過去90日の予約数:',
  },
  settings: {
    id: 'views.template.pages.settings',
    defaultMessage: '詳細設定',
  },
  deleteCalendar: {
    id: 'views.template.pages.deleteCalendar',
    defaultMessage: '予約ページを削除',
  },
  receptionCode: {
    id: 'views.home.modules.calendar.eventType.code',
    defaultMessage: 'Reception Code',
  },
  webConference: {
    id: 'views.home.modules.calendar.eventType.webConference',
    defaultMessage: 'Web Conference',
  },
  team: {
    id: 'views.home.modules.calendar.team',
    defaultMessage: "Team"
  },
  personal: {
    id: 'views.home.modules.calendar.personal',
    defaultMessage: 'Personal'
  },
  deleteBookingpageConfirm: {
    id: 'views.home.modules.home.deleteConfirm',
    defaultMessage: 'Are you sure you want to delete it?'
  },
  deleteConfirm: {
    id: 'notify.template.pages.detail.deleteConfirm',
    defaultMessage: 'Deleting a template will also delete the booking pages created from this template. \nAre you sure you want to delete it?'
  },
  deleteCalendarError: {
    id: 'notify.template.pages.detail.calendar.deleteError',
    defaultMessage: 'Booking page couldn\'t be deleted.',
  }, 
  deleteCalendarSuccess: {
    id: 'notify.template.pages.detail.calendar.deleteSuccess',
    defaultMessage: 'Booking page deleted successfully.',
  },
  deleteTemplateError: {
    id: 'notify.template.pages.detail.template.deleteError',
    defaultMessage: 'Template couldn\'t be deleted.',
  },
  failedGetDetail: {
    id: 'notify.template.pages.detail.fetchDetailError',
    defaultMessage: 'Template details couldn\'t be loaded.'
  },
  failedUpdateDetail: {
    id: 'notify.template.pages.detail.updateDetailError',
    defaultMessage: 'Template couldn\'t be updated.'
  },
  successUpdateDetail: {
    id: 'notify.template.pages.detail.updateDetailSuccess',
    defaultMessage: 'Template updated successfully.'
  }
});
