import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { injectIntl } from "react-intl";
import {
  Button,
  Loader,
} from "@d-lighted/design-system";

import { actions } from "../../../redux/actions";
import { useGet } from "../../../utils/api";
import TableColLayout from '../../../components/layouts/table/TableColLayout';
import TableHeadList from '../../../components/layouts/table/TableHeadList';
import AppointmentCard from '../../../components/layouts/table/AppointmentCard';
import { schedulingHeader, externalMultiguestHeader } from '../../booking/utils/constants';
import { PrimaryLightBackground } from "../../../components/utils/Helper";
import StyledRow from "../modules/StyledRow";
import BackButton from "../modules/BackButton";
import Title from "../modules/Title";
import { useMultiguestCalendarList } from '../hooks/useMultiguestCalendarList';
import { useMultiguestCalendar } from "../hooks/useMultiguestCalendar";
import { reset } from "../../../redux/reducers/multiguestCalendarReducer";

const Container = styled.div`
  margin: 41px;
  padding: 10px 0 30px;
  border-radius: 6px;
  opacity: 1;
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: center;
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;

const LoaderAnchor = styled.div`
  height: 56px;
  width: 56px;
  margin: 20px auto;
  padding-top: 30px;

  &> * {
    height: 56px;
    width: 56px;
    margin-top: -22px;
  }
`;

function generateColumnsWidth(isMultiGuest) {
  const widths = isMultiGuest ?  [250, 80, "auto", 200, 250, "auto"] :  [250, 80, 200, "auto", 100, "auto"]
  return widths;
}

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

function Scheduling(props) {
  const { useNotify, intl} = props
  const { data, isSuccess } = useSelector((state) => state.calendarSetting);
  const notify = useNotify(intl);
  let [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const { getMultiguestCalendarList } = useMultiguestCalendarList(notify)
  const { deleteMultiguestCalendar } = useMultiguestCalendar(notify)
  const [appoints, setAppoints] = useState([]);
  useGet(calendarSettingRequest(params.uid));

  const { bookingCalendarMembersCanAttend, meetingType } = data || {};

  const fetchMultiguestCalendars = () => {
    setLoading(true)
    getMultiguestCalendarList(currentPage).then(response => {
      setAppoints(response.bookingCalendarMultiguestScheduledEvents);
      setTotalPages(response.meta?.totalPages);
      setLoading(false)
    })
  }

  const handleDeleteCalendar = (calendarUid) => {
    setLoading(true)
    deleteMultiguestCalendar(calendarUid).then(response => {
      if(response.status === 200){
        fetchMultiguestCalendars()
      }
    });
  }

  useEffect(() => {
    fetchMultiguestCalendars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentPage]);

  useEffect(() => {
    fetchMultiguestCalendars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const tableHeaders = (multiguest) => {
    return multiguest ? schedulingHeader : externalMultiguestHeader;
  }

  const onClickConfirm = (appointment) => {
    dispatch(reset())
    props.history.push(`/booking_calendars/${data.uid}/multiguest/${appointment.uid}/`);
  }

  if (!isSuccess || loading) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <PrimaryLightBackground>
      <StyledRow onClick={() => { props.history.push('/') }}>
        <BackButton />
      </StyledRow>
      <StyledRow>
        <Title title={data.title || "ゲスト複数人で調整: 廣底: 【RECEPTIONISTご紹介オンラインセミナー】"} />
      </StyledRow>
      <Container>
        <TableColLayout columnWidths={generateColumnsWidth(bookingCalendarMembersCanAttend)}>
          <TableHeadList cols={tableHeaders(bookingCalendarMembersCanAttend)} />
          {appoints?.map((appointment) => {
            if (appointment && !loading) {
              return (<AppointmentCard
                isMultiGuest
                onClickConfirm={onClickConfirm}
                key={`appointmentCard${appointment.uid}`}
                appointment={appointment}
                handleDeleteCalendar={handleDeleteCalendar}
                useNotify={useNotify}
                bookingCalendarMembersCanAttend={bookingCalendarMembersCanAttend}
                intl={intl}
                meetingType={meetingType}
              />);
            }

            return null;
          })}
        </TableColLayout>
        {currentPage < totalPages && <Wrapper>
          {!loading && <Button
            mt="10px"
            mb="5px"
            onClick={() => {
              setLoading(true);
              if (currentPage <= totalPages) {
                let nextPage = currentPage += 1;
                setCurrentPage(nextPage);
              }

              return null;
            }}
          >
            もっと読み込む
          </Button>}
          {loading && <LoaderAnchor><Loader /></LoaderAnchor>}
        </Wrapper>}
      </Container>
    </PrimaryLightBackground>
  )
}

export default injectIntl(Scheduling);
