import React from 'react';
import TableBodyItem from './TableBodyItem';
import Text from './Text';

function CustomerList({ customers }) {
  const customerItems = customers.map(customer => {
    return (
      <Text key={customer.name} mb={customer.length === 1 ? 0 : 10}>
        {customer.companyName
          ? `${customer.name} 様 - ${customer.companyName}`
          : `${customer.name} 様`}
      </Text>
    );
  });
  return <TableBodyItem>{customerItems}</TableBodyItem>;
}

CustomerList.defaultProps = {
  customers: [],
};

export default CustomerList;
