import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import ReactTooltip from "react-tooltip";
import { 
  Row,
  Col,
  Button,
  Label,
  InputWithRightAddonAndLabel,
  Icon,
} from "@d-lighted/design-system";
import { OnBoardingDashboard } from "../../../../components/layouts/OnBoardingDashboard";
import { getCurrentUser, getBusinessType, isBookingTypeIrregular } from "../../../../utils/userUtils";
import { copy } from "../../../../utils/copyUtils";
import { generateLinkURL } from "../../../home/utils/utils";
import { Link } from "../../components/Link";
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../../../constants/breakpoint";
import { useOnboarding } from "../../hooks/useOnboarding";
import { leaveOnboarding, toOnboardingPage } from "../../utils/utils";
import { getOnboardingState } from "../../utils/utils";
import { capitalizedString } from "../../utils/utils";
import { events, pushToGA } from "../../../../utils/gtmUtils";

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    color: #fff;
    border-radius: 6px;
    width: 85px;
    height: 15px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: right;
    justify-content: right;
    font-size: 12px;
    font-weight: bold;
    
    transition: visibility 0s .375s, opacity .375s ease-in-out !important;
    
    &.show{
        transition: visibility 0s 0s, opacity .375s ease-in-out !important;
    }
  }
`;

const DesktopOnlyBR = styled.br`
  @media (max-width: ${MOBILEBREAKPOINT}){
    display: none;
  }
`;

const FirstBookingPage = props => {
  const isMobileView = SHOW_MOBILE(window.innerWidth);
  const { initialize } = props;
  const { onBoardingCalendarSetting } = useOnboarding();
  const { data, isSuccess } = onBoardingCalendarSetting;
  const [bookingPageUrl, setBookingPageUrl] = useState(null);
  const businessType = getBusinessType();
  const onboarding = getOnboardingState();
  const isIrregular = isBookingTypeIrregular();

  useEffect(() => {
    if(data && isSuccess){
      if(data?.meetingType === "web_conference" && data?.webConferenceType === "not_web_conference") return toOnboardingPage(props, "integrations");
      const pageUrl = generateLinkURL(data?.alias, data?.uid, isIrregular, data?.durations);
      initialize({
        bookingPageUrl : pageUrl
      });
      setBookingPageUrl(pageUrl);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if(!getCurrentUser()?.company) return toOnboardingPage(props, "register");
    if(!onboarding?.allowed || onboarding?.page !== "booking_page") return leaveOnboarding(props);

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const meetingTool = () => {
    let tool;
    switch(data?.webConferenceType) {
      case "google":
        tool = "Google Meet";
        break;
      case "microsoft":
        tool = "Teams";
        break;
      default:
        tool = capitalizedString(data?.webConferenceType);
    }
    return tool;
  };

  const handlCopyUrl = () => {
    pushToGA({ ...events.copy_bookingPage_url("fixed", "Ob/first_booking_page") });
    copy(bookingPageUrl)
  };

  const openBookingPage = () => {
    pushToGA({ ...events.click_link_bp("Ob/first_booking_page") });
    window.open(bookingPageUrl, "_blank");
  };

  return (
    <OnBoardingDashboard top="135px" hasTooltip={true}>
      <Row mt="37px">
        <Col xs>
          <Label
            fontSize="24px"
            fontWeight="700"
            letterSpacing="1px"
            color="#484F63"
          >
            {businessType === 'sales' 
              ?
                <p>
                  {meetingTool()}との連携が完了しました<span role="img" aria-label="party">🎉</span> <DesktopOnlyBR />
                  さっそく予約ページを使ってみましょう
                </p>
              :
                <p>
                  おめでとうございます！<DesktopOnlyBR />
                  最初の予約ページが作成されました🎉
                </p>
            }
          </Label>
        </Col>
      </Row>
      <Row mt="32px">
        <Col xs>
          <Label fontSize="15px" color="#484F63" fontWeight="bold">
            予約ページ共有URL
          </Label>
        </Col>
      </Row>
      <Row mt="8px">
        <Col xs>
          <Label fontSize="15px">
            お客様にお渡しして、コピペで終わる日程調整を体験しましょう。
          </Label>
        </Col>
      </Row>
      <Row mt="26px">
        <Col xs>
          <Field
            id="bookingPageUrl"
            name="bookingPageUrl"
            component={InputWithRightAddonAndLabel}
            placeholder="booking.receptionist.jp/page-link"
            addonText={<Icon text="コピー" />}
            data-tip="コピーしました"
            data-event='click'
            data-event-off='click'
            disabled={true}
            fontSize="10px"
            onClick={() => handlCopyUrl()}
          />
          <CustomReactTooltip
            isCapture={true}
            id={bookingPageUrl}
            delayHide={1000}
            place="bottom"
            effect="solid"
            backgroundColor="#6e52ff"
          />
        </Col>
      </Row>
      <Row mt="60px" pl="7px">
        <Col xs>
          <Link 
            onClick={() => toOnboardingPage(props, "more")}
          >
            後で
          </Link>
        </Col>
        <Col>
          <Button
            fontWeight="500"
            width={isMobileView ? "150px" : "284px"}
            onClick={() => openBookingPage()}
          >
            予約ページを開く
          </Button>
        </Col>
      </Row>
    </OnBoardingDashboard>
  );
}

export default reduxForm({
  form: 'FirstBookingPage',
  initialValues: {
    bookingPageUrl: ""
  }
})(FirstBookingPage);
