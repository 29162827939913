import { useState } from 'react';
import humps from 'lodash-humps';

import { _get } from '../../../utils/api';
import { useSelector, useDispatch } from "react-redux";
import {
  update,
} from '../../../redux/reducers/multiguestCalendarReducer';

export function useMultiguestAppointment(calendarUid, uid) {
  const dispatch = useDispatch();
  const calendar = useSelector(state => state.multiguest.calendar);
  const [appointmentError, setError] = useState('');

  const fetchAppointment = () => {
    const url = `/booking_calendars/${calendarUid}/multiguest/${uid}`;
    _get(url).then(response => {
      if(response.status < 300){
        dispatch(update({
          calendar: humps(response.data?.booking_calendar_multiguest_scheduled_event),
        }))
      } else {
        setError(response?.data);
      }
    });
  };

  return {
    appointment: calendar,
    appointmentError,
    fetchAppointment,
  };
}