/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useParams } from 'react-router';
import { SwitchCheckbox } from '@d-lighted/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';
import { injectIntl, defineMessages } from 'react-intl';
import { pushToGA, events } from '../../utils/gtmUtils';

import { _get, put } from '../../utils/api';
import { MOBILEBREAKPOINT } from '../../constants/breakpoint';

const SwitchWrapper = styled.div`
  margin-left: 20px;
  box-sizing: border-box;
  padding: 10px 0;
  @media (max-width:${MOBILEBREAKPOINT}){
    display:none;
  }
`;

const messages = defineMessages({
  online: {
    id: 'components.elements.onlineSwitch.online',
    defaultMessage: 'Public',
  },
  offline: {
    id: 'components.elements.onlineSwitch.offline',
    defaultMessage: 'Halted',
  },
  onlineSuccess: {
    id: 'notify.components.elements.onlineSwitch.online.success',
    defaultMessage: 'Booking page has been turned public.',
  },
  offlineSuccess: {
    id: 'notify.components.elements.onlineSwitch.offline.success',
    defaultMessage: 'Booking page has been halted.',
  },
  onlineError: {
    id: 'notify.components.elements.onlineSwitch.online.error',
    defaultMessage: 'Booking page could\'nt be halted.',
  },
  offlineError: {
    id: 'notify.components.elements.onlineSwitch.offline.error',
    defaultMessage: 'Booking page could\'nt be turned public.',
  }
});

function OnlineSwitch(props) {
  const { status } = useSelector(
    (state) => state.online
  );
  const notify = props.useNotify(props.intl);
  const dispatch = useDispatch();
  const params = useParams();
  const updateOnlineStatusAction = createAction('calendar_online_update');
  const [online, setOnline] = useState(status[props.data?.alias]?.online || false);
  const [alias, setAlias] = useState(props.data?.alias);
  const [uid, setUID] = useState(status[props.data?.alias]?.uid);
  const retrieveCalendarDebounced = useCallback(debounce((_alias) => retrieveCalendar(_alias), 500), []);

  const updateOnlineStatus = (alias, uid, value) => {
    const newStatus = {};
    newStatus[alias] = {online: value, uid: uid};
    dispatch(updateOnlineStatusAction({
      status: { ...newStatus },
    }));
  };
  
  const retrieveCalendar = async (_alias) => {
    const response = await _get(`/booking_calendars/${_alias}?alias=true`);
    setOnline(response.data.online);
    setUID(response.data.uid);
    updateOnlineStatus(_alias, response.data.uid, response.data.online);
  };
  
  useEffect(() => {
    if((params.uid != null && params.uid === props?.data?.uid)) {
      const _alias = props.data?.alias;
      if(_alias !== alias || (_alias && status[props.data?.alias] == null) || uid == null) {
        setAlias(_alias);
        retrieveCalendarDebounced(_alias);
      }
    }
    else {
      setAlias("");
      setOnline("");
      setUID("");
    }
  }, [props.data, params, uid]);

  const handleSwitchOnline = async (e) => {
    if (uid == null) return;
    
    e.persist();
    let response = await put(
      `/booking_calendars/${uid}/online`
    );
    if (response?.status > 299) {
      notify.setError(online ? messages.onlineError : messages.offlineError, response?.data?.error?.message);
    } else {
      const current_online = response?.data?.booking_calendar?.online || !online;
      notify.setNotify(current_online ? messages.onlineSuccess : messages.offlineSuccess);
      setOnline(current_online);
      updateOnlineStatus(alias, uid, current_online);
      e.target.value = current_online;
      pushToGA({...events.change_bookingPage_status(current_online ? "online" : "offline")})
    }
  };

  return (
    <SwitchWrapper>
      <SwitchCheckbox
        width={80}
        height={30}
        labels={[props.intl.formatMessage({...messages.online}), props.intl.formatMessage({...messages.offline})]}
        colors={['#00bbb5', '#d0d5d5']}
        onChange={(e) => handleSwitchOnline(e)}
        value={online}
        checked={online}
      />
    </SwitchWrapper>
  );
}

OnlineSwitch.defaultProps = {
  alias: '',
};

export default injectIntl(OnlineSwitch);
