/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CalendarHeader } from "./CalendarHeader";
import { CalendarBody } from "./CalendarBody";
import { getCalendarOptions, getHoursOfTheDay, moment } from "../momentUtils";
import { isPaidOrTrialPlan } from "../../../utils/userUtils";

function BigCalendar(props) {
  const { handleChange, selectedDuration } = props;

  const { data } = useSelector(state => state.calendarSetting);
  const isPaidOrTrial = isPaidOrTrialPlan();

  const selectedBusinessOpeningTime = useSelector(
    state => {
      const values = state.form?.BusinessHours?.values?.businessTimes || {};
      return Object.keys(values).map(i => values[i].openingTime).sort()[0]
    }
  );
  
  const selectedBusinessClosingTime = useSelector(
    state => {
      const values = state.form?.BusinessHours?.values?.businessTimes || {};
      return Object.keys(values).map(i => values[i].closingTime).sort().reverse()[0]
    }
  );

  const selectedSimpleBusiness = useSelector(
    state => state.form?.BusinessHours?.values?.simpleBusinessTimes || {}
  );

  const duration = selectedDuration || data.durations[0];
  
  const selectedSlotDuration = useSelector(
    state => {
      return state.form?.Meetings?.values?.slotDuration.value;
    }
  );
  
  const holidayBlocking = useSelector(
    state => state.form?.BusinessHours?.values?.holidayBlocking
  );
  
  const minSchedulableTime = useSelector(
    state => state.form?.BusinessHours?.values?.minSchedulableTime
  );
  const maxSchedulableTime = useSelector(
    state => state.form?.BusinessHours?.values?.maxSchedulableTime
  );

  const selectedWeekdays = useSelector(
    state => {
      const businessTimes = state.form?.BusinessHours?.values?.businessTimes || {};
      const holidayBlocking = state.form?.BusinessHours?.values?.holidayBlocking != null ?
        state.form?.BusinessHours?.values?.holidayBlocking : data.holidayBlocking;
      
      return isPaidOrTrial ?
        Object.keys(businessTimes).map(i => businessTimes[i].enabled) :
        [true, true, true, true, true, !holidayBlocking, !holidayBlocking];
    }
  );

  const minBusinessOpeningTime = Object.keys(data.businessTimes).map(i => data.businessTimes[i].openingTime).sort()[0];
  const maxBusinessClosingTime = Object.keys(data.businessTimes).map(i => data.businessTimes[i].closingTime).sort().reverse()[0];

  const bookingArgs = {
    businessOpeningTime: selectedBusinessOpeningTime || selectedSimpleBusiness.openingTime || minBusinessOpeningTime,
    businessClosingTime: selectedBusinessClosingTime || selectedSimpleBusiness.closingTime  || maxBusinessClosingTime,
    duration: duration,
    slotDuration: Math.min(
      parseInt(duration, 10),
      parseInt(selectedSlotDuration, 10) || data.slotDuration || Number.MAX_SAFE_INTEGER,
    ),
    holidayBlocking: holidayBlocking !== undefined ? holidayBlocking : data.holidayBlocking,
    minSchedulableTime:
      minSchedulableTime?.value !== undefined
        ? minSchedulableTime.value
        : data.minSchedulableTime,
    maxSchedulableTime:
      maxSchedulableTime?.value !== undefined
        ? maxSchedulableTime.value
        : data.maxSchedulableTime,
  };

  let newDate = moment();

  const [options, setOptions] = useState(getCalendarOptions({ 
    ...props,
    ...bookingArgs,
    currentDate: newDate,
  }));

  useEffect(() => {
    if (options) {
      setOptions({ 
        ...options,
        getHoursOfTheDay: getHoursOfTheDay(bookingArgs.businessOpeningTime, bookingArgs.businessClosingTime, bookingArgs.slotDuration),
        currentWeek: options.getThisWeek(options.currentDate, bookingArgs.holidayBlocking)
      });
    }
  }, [bookingArgs.businessOpeningTime, bookingArgs.businessClosingTime, bookingArgs.duration, bookingArgs.holidayBlocking, bookingArgs.minSchedulableTime, bookingArgs.maxSchedulableTime, bookingArgs.slotDuration]);

  const setDateFromSmallCalendar = (date) => {
    setOptions({
      ...options,
      currentWeek: options.getThisWeek(moment(date), bookingArgs.holidayBlocking),
    })
  };

  const getNextWeek = () => {
    setOptions({
      ...options,
      currentWeek: options.getNextWeek(options.currentWeek.selectedDate, bookingArgs.holidayBlocking),
    });
  };

  const getPrevWeek = () => {
    setOptions({
      ...options,
      currentWeek: options.getPreviousWeek(options.currentWeek.selectedDate, bookingArgs.holidayBlocking),
    });
  };

  const onChange = (e, date, currentTime) => {
    e.preventDefault();
    let selected = {
      year: date.year(),
      month: date.format("M"),
      date: date.date(),
      day: date.day(),
      time: currentTime,
      nextHour: moment(currentTime, "HH:mm")
        .add(bookingArgs.duration, "minutes")
        .format("HH:mm"),
    };
    handleChange(selected);
  };

  return (
    <CalendarHeader
      options={options}
      getPrevWeek={getPrevWeek}
      getNextWeek={getNextWeek}
      isTimeSlotLoading={!props.timeSlots.isSuccess}
      timeSlotsHasError={props.timeSlots.hasError}
      setDateFromSmallCalendar={setDateFromSmallCalendar}
    >
      <CalendarBody
        options={options}
        isTimeSlotLoading={!props.timeSlots.isSuccess}
        timeSlotsHasError={props.timeSlots.hasError}
        timeSlotsError={props.timeSlots.message}
        timeSlots={props.timeSlots}
        onChange={onChange}
        isPreview={props.isPreview}
        minSchedulableTime={bookingArgs.minSchedulableTime}
        maxSchedulableTime={bookingArgs.maxSchedulableTime}
        businessWeekDays={selectedWeekdays.length > 0 ? selectedWeekdays : Object.keys(data.businessTimes).map(i => data.businessTimes[i].enabled)}
      />
    </CalendarHeader>
  );
}

BigCalendar.defaultProps = {
  isCalendarDetailLoading: false,
  timeSlotsHasError: false,
  timeSlotsError: "",
  calendarDetailHasError: false,
  timeSlots: { availableTimeslots: [], isSuccess: true },
  handleChange: (date, currentTime) => {
    console.log(date, currentTime);
  },
};

export default BigCalendar;
