/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { copy } from "utils/copyUtils";
import { pushToGA, events } from "utils/gtmUtils";

import {
  //components
  Avatar,
  Button,
  InputSelectWithButtonAndDropdown,
  Label,
  //layouts
  Col,
  Row,
  Icon,
  DesktopBreakpoint,
  PhoneBreakpoint,
} from "@d-lighted/design-system";

import { injectIntl, FormattedMessage } from "react-intl";
import { useLocale } from "utils/localeUtils";
import {
  getSelectedDuration,
  setSelectedDuration,
  getSelectedLink,
  setSelectedLink,
  getInstructionMessageState,
  setInstructionMessageState,
  getUrlWithInstructionMessage,
} from "../utils/utils";
import { post } from "utils/api";
import IconDots from "components/icons/IconDots";
import messages from "../i18n/modules/Calendar";
import { useCalendars } from "hooks/useCalendars";
import { isMobileDevice } from "utils/mobileViewChecker";
import { MOBILEBREAKPOINT } from "constants/breakpoint";
import {
  isBookingTypeIrregular,
  isMultiGuestAllowed,
} from "utils/userUtils";

const IconWrapper = styled(Col)`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  opacity: ${(props) => props.opacity};
`;

const LabelWrapper = styled(Col)`
  box-sizing: border-box;
  flex: 1 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-shrink: 1;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const SelectorWrapper = styled(Col)`
  min-width: 370px;
  justify-content: normal;
  flex: 0 0 auto;
  display: flex;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-top: 14px;
    display: block;
    order: 1;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    min-width: 100%;
  }

  ${props => props.isIrregular && `
    div:first-child > div:first-child > div:first-child {
      display: none;
    }
    div:first-child > div:first-child > div:nth-child(2) {
      flex: 1 1 auto !important;
      button {
        border-radius: 5px 0 0 5px;
      }
    }
  `}
`;

const CodeLabel = styled.div`
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: ${(props) => props.color};
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin-right: 8px;
  display: inline-block;
`;

const PageTypeLabel = styled.div`
  padding: 3px 6px;
  border-radius: 4px;
  background-color: ${(props) => (props.team ? "#F0FBFB" : "#F2F2F2")};
  color: ${(props) => (props.team ? "#02BBB5" : "#9BB0B5")};
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin-right: 8px;
  display: inline-block;
`;

const OnlineBullet = styled.span`
   {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: ${(props) => (props.online ? "none" : "2px solid #d8d8d8")};
    background: ${(props) => (props.online ? "#00bbb5" : "none")};
  }
`;

const DropDownButton = styled.li`
  float: left;
  position: relative;
  display: inline-block;
`;

const DropDownContent = styled.div`
  width: max-content;
  display: ${(props) => (props.showDropdownOptions ? "block" : "none")};
  position: absolute;
  right: ${(props) => (props.multiGuest ? "0" : "20px")};
  margin-top: ${(props) => (props.multiGuest ? "50px" : "2px")};
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropDownItem = styled.a`
  color: #314143;
  padding: 12px 16px;
  margin: 5px;
  border-radius: 4px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: ${(props) => props.width};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const ExpandButton = styled.div`
  display: inherit;
  float: right;
  z-index: 1;
  cursor: pointer;
  margin-left: 5px;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-right: -4px;
    transform: rotate(90deg);
  }
`;

const ButtonLabelWrapper = styled.div`
  display: flex;
  padding-left: 10px;
`;

const GuidanceText = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: #00bbb5;
  margin: 1px 0 1px 6px;
  padding: 1px 5px 1px 4px;
  border-radius: 3px;
  background-color: #e6f4ef;
`;

const ContentWrapper = styled.div`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #68878d;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const MultiguestButtonWrapper = styled.div`
  display: flex;
  margin-left: 16px;
`;

const OutlineButton = styled(Button)`
  border-color: #68878d;
  color: #68878d;
`;

const EVENT_TYPE_TEXTS = {
  1: <FormattedMessage {...messages.receptionCode} />,
  2: <FormattedMessage {...messages.webConference} />,
};

const InputSelectButtonLabel = ({ label, showGuidanceText, guidanceText }) => (
  <ButtonLabelWrapper>
    <Icon justifyContent="flex-start" text={label} />
    {showGuidanceText && <GuidanceText>{guidanceText}</GuidanceText>}
  </ButtonLabelWrapper>
);

function Calendar(props) {
  const {
    uid,
    title,
    instructionMessage,
    alias,
    icon,
    settingsUid,
    durations,
    eventType,
    online,
    pageType,
    hasTemplate,
    templateCalendarsLimit,
    templateUsedCalendarsCount,
    multiguestCalendarSettingsUid
  } = props.calendar;
  const { tabCalendar, tab } = props;
  const [locale] = useLocale();
  const notify = props.useNotify(props.intl);
  const { actions } = useCalendars();

  // This could be updated to minutes hours, but we keep it in minutes dropdown space-wise
  const options = [...durations]
    .sort((a, b) => a - b)
    .map((duration) => ({
      value: `${duration}`,
      label: `${duration}${props.intl.formatMessage({
        ...messages.timeMinutes,
      })}`,
    }));
  const linkOptions = [
    {
      value: "固定URL",
      label: <FormattedMessage {...messages.fixedURL} />,
    },
    {
      value: "ワンタイムURL",
      label: <FormattedMessage {...messages.oneTimeURL} />,
    },
  ];
  let initialDuration = options.filter(
    (item) => item.value === getSelectedDuration(settingsUid, durations)
  );
  let defaultLink = linkOptions.filter(
    (item) => item.value === getSelectedLink(settingsUid)
  );
  initialDuration = initialDuration.length ? initialDuration[0] : options[0];
  defaultLink = defaultLink.length ? defaultLink[0] : linkOptions[0];

  const [duration, setDuration] = useState(initialDuration);
  const [copyInstruction, setCopyInstruction] = useState(
    getInstructionMessageState(settingsUid)
  );

  useEffect(() => {
    // TODO: check as well if the current selected is not present already
    const selectedDuration = getSelectedDuration(settingsUid, durations);
    if (selectedDuration === null) {
      return setSelectedDuration(settingsUid, durations[0]);
    }
    if(!durations.includes(Number(selectedDuration))) {
      setSelectedDuration(settingsUid, durations[durations.length - 1]);
    }
  }, [durations, settingsUid]);

  useEffect(() => {
    const updatedDuration = options.filter(
      (item) => item.value === getSelectedDuration(settingsUid, durations)
    )[0];
    setDuration(updatedDuration);
  }, [locale]);
  const [selectedLink, setLink] = useState(defaultLink);
  // eslint-disable-next-line
  const [oneTimePath, setOneTimePath] = useState("");
  const [visible, setVisibility] = useState(false);
  const [toggle, toggleVisibility] = useState(false);
  const isIrregular = isBookingTypeIrregular();
  const [toggleMultiguest, toggleMultiguestVisibility] = useState(false);

  const isMobileView = isMobileDevice();

  const getOneTimeLink = async (uid) => {
    const url = `/booking_calendars/${settingsUid}/one_time_links`;
    let urlPath = "";
    await post(url, { duration: duration?.value }).then((result) => {
      if (result.status < 200 || result.status >= 300) {
        notify.setError(messages.notifyOneTimeLinkCopyError);
      } else {
        urlPath = result?.data?.url_path;
        setOneTimePath(urlPath);
      }
    });
    return urlPath;
  };

  const toggleMenu = () => {
    toggleVisibility(!toggle);
  };

  const toggleMultiguestMenu = () => {
    toggleMultiguestVisibility(!toggleMultiguest);
  };

  // Close dropdown menu if the user clicks outside of it
  window.onclick = function (event) {
    if (
      !event.target.matches("#dropDownBtn") &&
      !event.target.closest("#checkbox-1")
    )
      setVisibility(false);
    if (
      !event.target.matches("#expandButton") &&
      !event.target.matches("svg") &&
      !event.target.matches("path")
    )
      toggleVisibility(false);
    if (
      !event.target.matches("#expandMultiguestButton") &&
      !event.target.matches("svg") &&
      !event.target.matches("path")
    )
      toggleMultiguestVisibility(false);
  };

  const handleClick = (value) => {
    switch (value) {
      case "showList":
        setVisibility(!visible);
        break;
      default:
        setVisibility(false);
        setLink(value);
        setSelectedLink(settingsUid, value.value);
        break;
    }
  };

  const handleDurationChange = (oldDuration, newDuration) => {
    if (oldDuration?.value !== newDuration?.value) {
      pushToGA({
        ...events.change_bookingPage_duration(
          `${oldDuration?.value}-${newDuration?.value}`
        ),
      });
    }
    setDuration(newDuration);
    setSelectedDuration(settingsUid, newDuration.value);
  };

  const handleCopyInstructionStateChange = (value) => {
    setCopyInstruction(value);
    setInstructionMessageState(settingsUid, value);
  };

  const handleCopyUrl = async (isIrregular) => {
    if (selectedLink.value === "ワンタイムURL") {
      const getPath = async () => {
        const result = await getOneTimeLink(settingsUid);
        const oneTimeUrl = `${process.env.REACT_APP_BOOK_ME_ENDPOINT}/${result}${isIrregular ? "" : `/${duration?.value}min`}`;
        const path = copyInstruction
          ? getUrlWithInstructionMessage(instructionMessage, oneTimeUrl)
          : oneTimeUrl;
        return new Blob([path], { type: "text/plain" });
      };

      copy(getPath());
      pushToGA({ ...events.copy_bookingPage_url("onetime", "Dashboard") });
    } else {
      const fixedUrl = `${process.env.REACT_APP_BOOK_ME_ENDPOINT}/${alias}${isIrregular ? "" : `/${duration?.value}min`}`;
      const text = copyInstruction
        ? getUrlWithInstructionMessage(instructionMessage, fixedUrl)
        : fixedUrl;
      copy(text)
      pushToGA({ ...events.copy_bookingPage_url("fixed", "Dashboard") });
    }
  };

  const setDuplicateBookingCalendarsBuilding = (
    dupBookingCalendarSettingUid
  ) => {
    const buildingSelection =
      JSON.parse(localStorage.getItem("building_selection")) || {};
    const item = buildingSelection[settingsUid];
    buildingSelection[dupBookingCalendarSettingUid] = item;
    localStorage.setItem(
      "building_selection",
      JSON.stringify(buildingSelection)
    );
  };

  const handleCopyBookingPage = async () => {
    if(hasTemplate && (templateUsedCalendarsCount + 1) > templateCalendarsLimit) {
      return notify.setError(
        messages.notifyCopyBookingPageError,
        messages.notifyCopyBookingPageLimitError
      );
    }
    const url = `/booking_calendars/${uid}/copy`;
    await post(url).then((result) => {
      if (result?.status < 200 || result?.status >= 300) {
        notify.setError(
          messages.notifyCopyBookingPageError,
          result?.data?.message
        );
      } else {
        const dupBookingCalendarSettingUid = result?.data;
        if (dupBookingCalendarSettingUid) {
          setDuplicateBookingCalendarsBuilding(dupBookingCalendarSettingUid);
          // set default duration as same as original booking calendar
          setSelectedDuration(
            dupBookingCalendarSettingUid,
            getSelectedDuration(settingsUid)
          );
          props.history.push(
            `/booking_calendars/${dupBookingCalendarSettingUid}/edit`
          );
        }
      }
    });
  };

  const handleDeleteBookingPage = () => {
    if (window.confirm(props.intl.formatMessage({...messages.deleteConfirm}))) {
      actions.deleteCalendar(uid, notify, tab, tabCalendar)
    }
  };

  return (
    <>
      <Row
        key={3}
        alignItems="center"
        mt="20px"
        bg="white"
        boxShadow="0px 6px 20px 0 rgba(193, 207, 217, 0.14)"
        width="100%"
        padding={["20px 8px 20px 8px", "20px 36px"]}
        borderRadius="6px"
        flexWrap={["wrap", "nowrap"]}
        mr={["0px", "-0.5rem"]}
        ml={["0px", "-0.5rem"]}
      >
        <IconWrapper>
          <OnlineBullet online={online} />
        </IconWrapper>
        <IconWrapper opacity={online ? 1 : 0.3}>
          <Avatar
            height={["35px", "56px"]}
            width={["35px", "56px"]}
            src={icon.url || require("assets/img/avatar-default.png")}
            alt="profile"
          />
        </IconWrapper>
        <LabelWrapper mt="5px">
          <Label
            fontSize="20px"
            fontWeight="500"
            letterSpacing="1px"
            color={online ? "#142C2B" : "#D1D5D5"}
          >
            {title}
          </Label>
          <br />
          <ContentWrapper>
            {!isIrregular && pageType !== null && (
              <PageTypeLabel team={pageType === "team"}>
                <FormattedMessage {...messages[pageType]} />
              </PageTypeLabel>
            )}
            {eventType > 0 && (
              <CodeLabel color={online ? "#9BB0B5" : "#D1D5D5"}>
                {EVENT_TYPE_TEXTS[eventType]}
              </CodeLabel>
            )}
            <Label fontSize="13px" color={online ? "#68878d" : "#D1D5D5"}>
              /{alias}
            </Label>
          </ContentWrapper>
        </LabelWrapper>
        <SelectorWrapper isIrregular={isIrregular} display="flex" justifyContent="flex-end">
          <InputSelectWithButtonAndDropdown
            //common props
            outlined
            height="44px"
            fontSize="14px"
            fontWeight="500"
            isDisabled={!online}
            wrapperMinWidth={["100%", isIrregular ? "260px" : "370px"]}
            //inputSelect props
            inputSelectButtonLabel={
              <InputSelectButtonLabel
                label={selectedLink.label}
                showGuidanceText={copyInstruction}
                guidanceText="ご案内文"
              />
            }
            inputSelectWidth={["100%", "320px"]}
            inputSelectButtonMinWidth={["175px", "210px"]}
            dropdownContentWidth="230px"
            inputSelectOptions={options}
            inputSelectValue={duration}
            inputSelectOnChange={(value) => {
              handleDurationChange(duration, value);
            }}
            showCopiedTooltip
            removeRightBorder={true}
            inputSelectOnClick={() => handleCopyUrl(isIrregular)}
            //dropdown button props
            showDropdownOptions={visible}
            dropdownBtnMarginRight={["0px", "20px"]}
            dropdownBtnPadding={["0px 22px 0px 22px", "0px 30px 0px 30px"]}
            dropdownBtnWidth="10px"
            dropdownBtnMinWidth="0px"
            dropdownBtnBorderRadius="0px 5px 5px 0px"
            dropdownBtnIconSize="20px"
            dropdownIconColor="#00bbb5"
            dropdownBtnOptions={linkOptions}
            dropdownCheckboxItems={[
              {
                id: "checkbox-1",
                value: copyInstruction,
                onChange: (value) => {
                  handleCopyInstructionStateChange(value);
                },
                label: <FormattedMessage {...messages.copyInstruction} />,
              },
            ]}
            dropdownBtnOnClick={handleClick}
            isMobileView={isMobileView}
          />
          <ButtonWrapper>
          <Button
              ml={["8px", 0]}
              mt={["14px", 0]}
              width={["45%", "128px"]}
              height="44px"
              isIconPresent
              iconContent="settings"
              iconFontFamily="Material Icons"
              iconSize="20px"
              iconColor="white"
              fontSize="14px"
              fontWeight="500"
              onClick={() => {
                actions.resetCalendars();
                props.history.push(`/booking_calendars/${settingsUid}/edit`);
              }}
            >
              <FormattedMessage {...messages.advancedSettings} />
            </Button>
              <PhoneBreakpoint>
                <OutlineButton
                  ml={["auto", 0]}
                  mt={["14px", 0]}
                  mr={["8px", 0]}
                  width={["45%", "128px"]}
                  height="44px"
                  isIconPresent
                  iconContent="format_list_bulleted"
                  iconFontFamily="Material Icons"
                  iconSize="20px"
                  iconColor="#68878d"
                  fontSize="14px"
                  fontWeight="500"
                  onClick={() =>{
                    actions.resetCalendars();
                    props.history.push({
                      pathname: `/booking_calendars/${settingsUid}/appointments`,
                      state: { calendar: props.calendar },
                    })
                  }}
                  outlined
                >
                  <FormattedMessage {...messages.appointmentList} />
                </OutlineButton>
              </PhoneBreakpoint>
          </ButtonWrapper>
          { isMultiGuestAllowed() && !isMobileDevice() && <MultiguestButtonWrapper>
            <Button
              id="expandMultiguestButton"
              ml={["8px", 0]}
              mt={["14px", 0]}
              pl={"50px"}
              pr={"18px"}
              width={["45%", "100%"]}
              height="44px"
              outlined
              isIconPresent
              iconContent="people"
              iconFontFamily="Material Icons"
              iconSize="20px"
              iconColor="#6e52ff"
              fontSize="14px"
              fontWeight="500"
              color="#6e52ff !important"
              borderColor="#6e52ff !important"
              onClick={() => toggleMultiguestMenu()}
            >
              <FormattedMessage {...messages.multiguestSettings} />
            </Button>
            <DropDownButton>
              <DropDownContent showDropdownOptions={toggleMultiguest} multiGuest={true}>
                <DropDownItem
                  width="auto"
                  onClick={() =>
                    props.history.push({
                      pathname: `/booking_calendars/${settingsUid}/multiguest`,
                      state: { calendar: props.calendar },
                    })
                  }
                >
                  <FormattedMessage {...messages.multiguestUrl} />
                </DropDownItem>
                <DropDownItem
                  width="auto"
                  onClick={() =>
                    props.history.push({
                      pathname: `/booking_calendars/${settingsUid}/multiguest/list`,
                      state: { calendar: props.calendar }
                    })
                  }
                >
                 <FormattedMessage {...messages.multiguestCalendarList} />
                </DropDownItem>
                { multiguestCalendarSettingsUid && <DropDownItem
                  width="auto"
                  onClick={() =>
                    props.history.push({
                      pathname: `/booking_calendars/${uid}/multiguest/${multiguestCalendarSettingsUid}/settings`,
                    })
                  }
                >
                 <FormattedMessage {...messages.multiguestCalendarSettings} />
                </DropDownItem>}
              </DropDownContent>
            </DropDownButton>
          </MultiguestButtonWrapper>}
        </SelectorWrapper>
        <DropDownButton>
          <ExpandButton
            id="expandButton"
            onClick={() => toggleMenu()}
            className="expand-button"
          >
            <IconDots />
          </ExpandButton>
          <DropDownContent showDropdownOptions={toggle}>
            <DesktopBreakpoint>
              <DropDownItem
                width="135px"
                onClick={() =>
                  props.history.push({
                    pathname: `/booking_calendars/${settingsUid}/appointments`,
                    state: { calendar: props.calendar },
                  })
                }
              >
                <FormattedMessage {...messages[isIrregular ? 'listAppointmentsForIrregular' : 'appointmentList']} />
              </DropDownItem>
            </DesktopBreakpoint>
            {!isIrregular && props.tab !== "all" && (
              <DropDownItem
                width="135px"
                onClick={() => handleCopyBookingPage()}
              >
                <FormattedMessage {...messages[isIrregular ? 'copyBookingPageForIrregular' : 'copyBookingPage']} />
              </DropDownItem>
            )}
              <DropDownItem
                width="155px"
                onClick={() => handleDeleteBookingPage()}
              >
                <FormattedMessage {...messages.delete} />
              </DropDownItem>
          </DropDownContent>
        </DropDownButton>
      </Row>
    </>
  );
}

export default injectIntl(Calendar);
