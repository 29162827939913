import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Field,
  reduxForm,
  change,
  formValueSelector,
  getFormValues,
} from "redux-form";
import { compose } from 'redux';
import { useSelector, useDispatch, connect } from "react-redux";
import { createAction } from "@reduxjs/toolkit";
import { useIntl, FormattedMessage, injectIntl } from "react-intl";
import { createStructuredSelector } from 'reselect';
import { useParams } from "react-router";
import {
  Button,
  Label,
  Col,
  Hr,
  Row,
  Checkbox,
  IconNew,
} from "@d-lighted/design-system/dist/index";
import { useGet, put } from "utils/api";
import { actions } from "redux/actions";
import { DashboardWithoutPreview } from "components/layouts/DashboardWithoutPreview";
import salesforce_icon from "assets/img/icon_salesforce.svg";
import Tooltip from "components/elements/Tooltip";
import { PlanBlocked } from "components/layouts/PlanBlocked";
import { apiUtils } from "utils/apiUtils";
import { pushToGA, events } from "utils/gtmUtils";
import * as messageIdConstants from "components/layouts/constants/messageIdConstants";
import PhoneBreakpoint from "@d-lighted/design-system/dist/components/Utils/PhoneBreakpoint";
import DesktopBreakpoint from "@d-lighted/design-system/dist/components/Utils/DesktopBreakpoint";
import { NoSupportWarning } from "components/layouts/NoSupportWarning";
import TemplatePermissionSelector, { convertToOption } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked } from "components/layouts/TemplateBlocked";
import messages from "../i18n/Salesforce";
import Icon from "../components/Icon";
import InputFieldList from "../components/InputFieldList";
import PlanIcon from '../../../components/elements/PlanIcon';

const Warning = styled.div`
  background-color: #e6f4ef;
  border-radius: 5px;
  box-sizing: border-box;
  color: #68878d;
  font-family: NotoSansJP, sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  padding: 13px 12px 13px 36px;
  text-align: left;
  margin-top: ${props => props.mt};
  margin-bottom: 8px;
  max-width: ${props => (props.width ? props.width : '100%')};
  
  & a {
    color: #00bbb5;
  }
`;

const InfoIcon = styled(IconNew)`
  position: absolute;
  margin-left: -24px;
`;

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const salesforceForm = formValueSelector('Salesforce');
const formSelector = state => getFormValues('Salesforce')(state);

const Salesforce = (props) => {
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const { setTemplateUid, saveWithTemplateSettings } = useSavedTemplateSettings();
  const updateCalendarSetting = createAction(
    `${actions.getCalendarSetting.name}_update`
  );

  const permissions = useSelector(state => salesforceForm(state, "permissions"));

  useGet(calendarSettingRequest(uid));
  const {
    useNotify,
    pristine,
    submitting,
    initialize,
    handleSubmit,
    history,
  } = props;
  const intl = useIntl();

  const { data, isSuccess, hasError } = useSelector((state) => state.calendarSetting);
  const dispatch = useDispatch();
  const notify = useNotify(intl);

  const [sfAuthenticated, setSfState] = useState(
    data?.companiesSalesforce?.salesforceAuthenticated || false
  );
  const [showSfFieldsAllowed, setSfFieldsVisibility] = useState(false);

  useEffect(() => {
    dispatch(
      change("InputScreenSettings", "fields", data?.bookingCalendarCustomFields)
    );
  });

  useEffect(() => {
    if (isSuccess) {
      const { salesforceRecord, salesforceEvent, salesforceInputField, bookingCalendarTemplateUid, permissions, allowSplitGuestName } = data;
      setTemplateUid(bookingCalendarTemplateUid);
      initialize({
        salesforceRecord,
        salesforceEvent,
        salesforceInputField,
        permissions: convertToOption(permissions, intl),
        allowSplitGuestName
      });
      setSfState(data?.companiesSalesforce?.salesforceAuthenticated);
      setSfFieldsVisibility(data?.salesforceInputField);
    }
    if (hasError) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  function formatBody(values) {
    let body = {
      salesforce_record: values.salesforceRecord,
      salesforce_event: values.salesforceEvent,
      salesforce_input_field: values.salesforceInputField,
      allow_split_guest_name: values.allowSplitGuestName,
    };
    return body;
  }

  function processResult(result) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(
        <FormattedMessage {...messages.notifyError} />,
        result?.data?.message
      );
    } else {
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
      dispatch(updateCalendarSetting(apiUtils.formatAxiosResponse(result)));
      return result;
    }
  }

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    const url = `/booking_calendar_settings/${uid}`;
    const body = formatBody(values);

    const result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), values.permissions) : await put(url, body);

    return processResult(result, body);
  };

  const sendToGA = (eventName, duration) => {
    pushToGA({ ...events.mouseover_featureTooltip(eventName, duration) });
  };

  const accessBlockedMessage = () => {
    if (!data?.pageType) {
      return messageIdConstants.teamOnlyAllowed;
    }
    if (!sfAuthenticated) {
      return messageIdConstants.sfNotLinked;
    }
    return null;
  };

  const handleInputFieldsVisibility = (visible) => {
    setSfFieldsVisibility(visible);
  };

  return (
    <>
      <PhoneBreakpoint>
        <NoSupportWarning />
      </PhoneBreakpoint>
      <DesktopBreakpoint>
        <DashboardWithoutPreview>
          <form>
            <PlanBlocked
              mt="26px"
              width={["100%", "400px"]}
              plan="enterprise"
              messageId={accessBlockedMessage()}
            >
              <Row mt="40px" mb="13px">
                <Col xs>
                  <Row>
                    <Col xs display="flex" alignItems="center">
                      <Label fontSize="15px">
                        <FormattedMessage {...messages.title} />
                      </Label>
                      <Icon src={salesforce_icon} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row mb="17px" maxWidth="750px">
                <Col xs display="flex" alignItems="center">
                  <TemplateBlocked
                    blocked={permissions?.allowSplitGuestName?.value}
                    fieldsStyle={{display: 'flex'}}
                    warningStyle={{marginRight: '16px'}}
                    fields={
                      <>
                        <Field
                          id="allowSplitGuestName"
                          name="allowSplitGuestName"
                          component={Checkbox}
                          useArrayValue={false}
                          onChange={(value) => (change("allowSplitGuestName", value))}
                        >
                          <FormattedMessage {...messages.splitNameLabel} />
                        </Field>
                      </>
                    }
                  />
                </Col>
                {isTemplatePage && (
                  <Col>
                    <TemplatePermissionSelector name="allowSplitGuestName" />
                  </Col>
                )}
              </Row>
              <Row mb="17px" maxWidth="750px">
                <Col xs display="flex" alignItems="center">
                  <TemplateBlocked
                    blocked={permissions?.salesforceCreateLead?.value}
                    fieldsStyle={{display: 'flex'}}
                    warningStyle={{marginRight: '16px'}}
                    fields={
                      <>
                        <Field
                          id="salesforceRecord"
                          name="salesforceRecord"
                          useArrayValue={false}
                          component={Checkbox}
                        >
                          <FormattedMessage {...messages.createLead} />
                        </Field>
                        <Tooltip
                          message={
                            <FormattedMessage
                              {...messages.createLeadBrief}
                              values={{ br: <br /> }}
                            />
                          }
                          width={340}
                          onHover={(duration) => sendToGA("salesforce_record", duration)}
                        />
                        <PlanIcon featurePlan="enterprise" />  
                      </>
                    }
                  />  
                </Col>
                {isTemplatePage && (
                  <Col>
                    <TemplatePermissionSelector name="salesforceCreateLead" />
                  </Col>
                )}
              </Row>
              <Row mb="17px" maxWidth="750px">
                <Col xs display="flex" alignItems="center">
                  <TemplateBlocked
                    blocked={permissions?.salesforceCreateEvent?.value}
                    fieldsStyle={{display: 'flex'}}
                    warningStyle={{marginRight: '16px'}}
                    fields={
                      <>
                        <Field
                          id="salesforceEvent"
                          name="salesforceEvent"
                          useArrayValue={false}
                          component={Checkbox}
                          >
                            <FormattedMessage {...messages.createEvent} />
                        </Field>
             
                        <Tooltip
                          message={
                            <FormattedMessage
                              {...messages.createEventBrief}
                              values={{ br: <br /> }}
                            />
                          }
                          width={340}
                          onHover={(duration) => sendToGA("salesforce_event", duration)}
                        />
                        <PlanIcon featurePlan="enterprise" />
                      </>
                   }
                  />
                </Col>
                {isTemplatePage && (
                  <Col>
                    <TemplatePermissionSelector name="salesforceCreateEvent" />
                  </Col>
                )}
              </Row>
              <PlanBlocked
                mt="26px"
                plan="enterprise"
                width={showSfFieldsAllowed ? "660px" : "400px"}
              >
                <Row mb="30px" maxWidth="750px">
                  <Col xs display="flex" alignItems="center">
                    <TemplateBlocked
                      blocked={permissions?.salesforceCustomFields?.value}
                      fieldsStyle={{display: 'flex'}}
                      warningStyle={{marginRight: '16px'}}
                      fields={
                        <>
                          <Field
                            id="salesforceInputField"
                            name="salesforceInputField"
                            useArrayValue={false}
                            component={Checkbox}
                            onChange={(e) => handleInputFieldsVisibility(e)}
                          >
                            <FormattedMessage {...messages.linkCustomFields} />
                          </Field>
                          <Tooltip
                            message={
                              <FormattedMessage
                                {...messages.linkCustomFieldsBrief}
                                values={{ br: <br /> }}
                              />
                            }
                            width={340}
                            onHover={(duration) =>
                              sendToGA("salesforce_mapping", duration)
                            }
                          />
                          <PlanIcon featurePlan="premium" />
                        </>
                      }
                    />
                  </Col>
                  {isTemplatePage && (
                    <Col>
                      <TemplatePermissionSelector name="salesforceCustomFields" />
                    </Col>
                  )}
                </Row>
                {showSfFieldsAllowed && (
                  <>
                    {(isTemplatePage || permissions?.salesforceCustomFields?.value === "administrator")&& (
                      <Row maxWidth={678}>
                        <Col xs>
                          <Warning>
                            <InfoIcon
                              className="material-icons"
                              fontSize="17px"
                              color="#9DDBD6"
                            >
                              info
                            </InfoIcon>
                            <FormattedMessage {...messages.salesforceCustomFieldWarning} />
                          </Warning>
                        </Col>
                      </Row>
                    )}
                    <Row maxWidth={678}>
                      <Col xs>
                        <TemplateBlocked
                          blocked={permissions?.fields?.value}
                          fields={<InputFieldList
                            customFields={
                              data?.bookingCalendarCustomFields
                                ?.filter(
                                  (field) =>
                                    !["block_of_text"]?.includes(field?.fieldType)
                                )
                                ?.sort((first, next) => {
                                  return first["rowOrder"] - next["rowOrder"];
                                }) || []
                            }
                            settingUid={uid}
                            settings={settings}
                            {...props}
                          />}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </PlanBlocked>
              <Hr
                border="none"
                height="1px"
                color="#e5e5e5"
                mt="40px"
                ml="0px"
                width={["100%", "400px"]}
              />
              <Row mt="30px">
                <Col xs>
                  <Button
                    disabled={pristine || submitting}
                    onClick={handleSubmit((values) => onSubmit(values))}
                    fontWeight="500"
                    width={["100%", "400px"]}
                    height="50px"
                  >
                    <FormattedMessage {...messages.save} />
                  </Button>
                </Col>
              </Row>
            </PlanBlocked>
            <div style={{ height: "20px" }} />
          </form>
        </DashboardWithoutPreview>
      </DesktopBreakpoint>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  formValues: formSelector,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'Salesforce',
    initialValues: {
      salesforceRecord: false,
      salesforceEvent: false,
      salesforceInputField: false,
      allowSplitGuestName: false,
    },
  }),
  injectIntl,
)(Salesforce);
