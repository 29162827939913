import React, { useEffect } from "react";
import { compose } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from "reselect";
import styled from "styled-components";
import { 
  Row,
  Col,
  InputWithLabel,
  RadioButton,
  Button,
  Label,
  Loader
} from "@d-lighted/design-system";
import { getBusinessType, getCurrentUser, getReferralSource, setReferralSource } from "../../../../utils/userUtils";
import { useValidation } from "../../../../utils/validation";
import { PrimaryWhiteBackground } from "../../../../components/utils/Helper";
import { OnBoardingDashboard } from "../../../../components/layouts/OnBoardingDashboard";
import ToolLottie from "../../../../components/elements/ToolLottie";
import LoadingLottie from "../../../../assets/lottie/loading.json";
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../../../constants/breakpoint";
import { useOnboarding } from "../../hooks/useOnboarding";
import { pushToGA, events } from "../../../../utils/gtmUtils";
import { setOnboardingAllowed, leaveOnboarding, toOnboardingPage } from "../../utils/utils";
import GoogleIcon from "../../../../assets/img/g_calendar_icon.png";
import MicrosoftIcon from "../../../../assets/img/outlook_icon.png";
import GaroonIcon from "../../../../assets/img/garoon_icon.png";
import { useAuth } from "../../../../hooks/useAuth";
import { Center } from "../../components/Center";

const Icon = styled.img`
  width: auto;
  height: 22px;
  margin-right: 5px;
  position: relative;
  top: 2px;
`;
const DesktopOnlyBR = styled.br`
  @media (max-width: ${MOBILEBREAKPOINT}){
    display: none;
  }
`;

const formSelector = state => getFormValues('BusinessRegistration')(state);

const BusinessRegistration = (props) => {
  const { pristine, submitting, intl, useNotify, handleSubmit, invalid } = props;
  const { required, emptyString, inputLength, phoneNumber } = useValidation(intl);
  const notify = useNotify(intl);
  const { onBoardingCalendarSetting, isLoading, actions } = useOnboarding(notify);
  const { authActions } = useAuth();
  const currentUser = getCurrentUser();
  const company = currentUser?.company;
  const isMobileView = SHOW_MOBILE(window.innerWidth);
  const emailProvider = currentUser?.provider === "email";
  useEffect(() => {
    if(!company && currentUser?.admin) {
      setOnboardingAllowed(true, "register");
    }
    if(!company && !currentUser) {
      return toOnboardingPage(props, "signup");
    }
    if(company) {
      return leaveOnboarding(props);
    }
    props.initialize({
      businessType: getBusinessType() || "sales",
      scheduler: currentUser?.authorized_scheduler || "google",
      employeeName: currentUser?.name || null,
      companyName: null,
    });
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(onBoardingCalendarSetting && company && emailProvider) {
      setOnboardingAllowed(false, "/");
      return toWebApp();
    }
    if(onBoardingCalendarSetting?.hasError && company) {
      if(onBoardingCalendarSetting?.error?.code === "E17005") {
        // when company exists
        return leaveOnboarding(props);
      }
    }
    else if(onBoardingCalendarSetting?.isSuccess && company) {
      if(props?.formValues?.businessType === "sales") return toOnboardingPage(props, "integrations");
      return toOnboardingPage(props, "booking_page");
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [onBoardingCalendarSetting, isLoading]);

  function formatBody(values) {
    const body = {
      onboarding: {
        business_type: values.businessType,
        company_name: values.companyName,
        employee_name: values.employeeName,
        phone_no: values.phoneNo,
        scheduler: values.scheduler,
        referral_source: getReferralSource()
      },
    };
    return body;
  }

  const toWebApp = async() => {
    const url = await authActions.getOneTimeAccessUrl('/info/integrations', 'tutorial=scheduling_onboarding');
    return window.location.href = url;
  };

  const onSubmit = async(values) => {
    const body = formatBody(values);
    actions.createBusinessInfo(body);
    pushToGA({...events.set_business_type(values.businessType)});
    setReferralSource();
  }

  return (
    isLoading 
      ?
        currentUser?.provider === "oauth" ?
          <PrimaryWhiteBackground>
            <ToolLottie
              lottie={LoadingLottie}
              headText="あなたのカレンダーから予定を抽出しています..."
              subText="あなたのカレンダーから予定を抽出し、空き状況を表示する予約ページを生成しています。"
              height={300}
              width={isMobileView ? "calc(100% - 128px)" : 400}
              duration={5000}
              top="100px"
              position="relative"
            />
          </PrimaryWhiteBackground>
        :
          <Center>
            <Loader />
          </Center>
      :
        <OnBoardingDashboard>
          <Row mt={isMobileView ? "30px" : "37px"}>
            <Col xs>
              <Label
                fontSize="24px"
                fontWeight="700"
                letterSpacing="1px"
                color="#484F63"
              >
                <p>
                  {currentUser.name ? `${currentUser.name}さん、`: null}調整アポへようこそ！ここでは <DesktopOnlyBR />
                  あなた専用の予約ページを作成します。<br />
                </p>
              </Label>
            </Col>
          </Row>
          <Row mt="12px">
            <Col xs>
              <Label 
                fontSize="15px"
                fontWeight="400"
              >
                <p>
                  あなたのビジネスについてお聞かせください。入力内容は予約ページに<DesktopOnlyBR />
                  反映されます。
                </p>
              </Label>
            </Col>
          </Row>
          <Row mt="12px">
            <Col xs>
              <Label fontSize="15px" color="#484F63" fontWeight="bold">
                日程調整ツール活用のシーン
              </Label>
            </Col>
          </Row>
          <Row mt="12px">
            <Col xs>
              <Row>
                <Col>
                  <Field
                    id="businessType"
                    name="businessType"
                    trueFor="sales"
                    component={RadioButton}
                  >
                    Web会議商談の日程調整
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    id="businessType"
                    name="businessType"
                    trueFor="recruitment"
                    component={RadioButton}
                  >
                    採用活動での日程調整
                  </Field>
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <Field
                    id="businessType"
                    name="businessType"
                    trueFor="shop"
                    component={RadioButton}
                  >
                    店舗に来られるお客様との日程調整
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    id="businessType"
                    name="businessType"
                    trueFor="others"
                    component={RadioButton}
                  >
                    その他
                  </Field>
                </Col>
              </Row> */}
            </Col>
          </Row>
          {emailProvider &&
            <>
              <Row mt="12px">
                <Col xs>
                  <Label fontSize="15px" color="#484F63" fontWeight="bold">
                    スケジューラ
                  </Label>
                </Col>
              </Row>
              <Row mt="12px">
                <Col xs>
                  <Row>
                    <Col>
                      <Field
                        id="scheduler"
                        name="scheduler"
                        trueFor="google"
                        component={RadioButton}
                      >
                        <Icon  src={GoogleIcon}/>
                        Google
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        id="scheduler"
                        name="scheduler"
                        trueFor="outlook"
                        component={RadioButton}
                      >
                        <Icon  src={MicrosoftIcon}/>
                        Microsoft
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        id="scheduler"
                        name="scheduler"
                        trueFor="garoon"
                        component={RadioButton}
                      >
                        <Icon  src={GaroonIcon}/>
                        Garoon
                      </Field>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          }
          <Row mt="17px" mb="20px">
            <Col xs>
              <Label fontSize="15px" color="#484F63" fontWeight="bold">
                あなたの会社名
              </Label>
              <Field
                id="companyName"
                name="companyName"
                component={InputWithLabel}
                placeholder="会社または店舗名"
                validate={[required, emptyString, inputLength]}
              />
            </Col>
          </Row>
          <Row mt="23px" mb="30px">
            <Col xs>
              <Label fontSize="15px" color="#484F63" fontWeight="bold">
                あなたの氏名
              </Label>
              <Field
                id="employeeName"
                name="employeeName"
                component={InputWithLabel}
                placeholder="氏名"
                validate={[required, emptyString, inputLength]}
              />
            </Col>
          </Row>
          <Row mt="23px" mb="30px">
            <Col xs>
              <Label fontSize="15px" color="#484F63" fontWeight="bold">
                あなたの電話番号
              </Label>
              <Field
                id="phoneNo"
                name="phoneNo"
                component={InputWithLabel}
                placeholder="電話番号"
                validate={[phoneNumber]}
              />
            </Col>
          </Row>
          <Row 
            mt="12px"
            ml={0}
            mb={isMobileView ? "20px" : 0}
          >
            <Button
              fontWeight="500"
              width={isMobileView ? "150px" : "204px"}
              disabled={pristine || submitting || invalid}
              onClick={handleSubmit((values) => onSubmit(values))}
            >
              さっそく作成する
            </Button>
          </Row>
        </OnBoardingDashboard>
  );
}

const mapStateToProps = createStructuredSelector({
  formValues: formSelector,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'BusinessRegistration',
    initialValues: {
      businessType: "sales",
      employeeName: null,
      companyName: null
    },
  }),
  injectIntl,
)(BusinessRegistration);
