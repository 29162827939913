import React from "react";
import { Heading } from "@d-lighted/design-system";

import { Div } from "../Helper";
import { moment, isTimeEarlier, isDateEarlier } from "../momentUtils";
import Button from "../../forms/Button";

export function SingleDate({ day, date, isDateEarlier, getJapaneseDay }) {
  return (
    <Div
      display={["flex", "block"]}
      alignItems="baseline"
      textAlign={[null, "center"]}
      width="100%"
    >
      <Div width={[null, "100%"]}>
        <Heading size={1} color={`${(isDateEarlier(day, moment()) === "earlier" && "rgba(20, 44, 43, 0.2)") || (isDateEarlier(day, moment()) === "equal" && "#00bbb5")}`} >{date}</Heading>
      </Div>
      <Div ml={["7px", "0px"]} mb="10px" width={[null, "100%"]}>
        <Heading size={5} color={`${(isDateEarlier(day, moment()) === "earlier" && "rgba(20, 44, 43, 0.2)") || (isDateEarlier(day, moment()) === "equal" && "#00bbb5")}`}>{getJapaneseDay(day.day())}</Heading>
      </Div>
    </Div>
  );
}

export const TimeSlots = ({ hoursOfTheDay, timeSlots, day, handleChange, isPreview, dayIndex, minSchedulableTime, maxSchedulableTime, duration}) => {
  return (
    <Div
      noScrollbar
      overflowX="auto"
      display="flex"
      flexDirection={["row", "column"]}
      justifyContent={[null, "space-between"]}
      width="100%"
    >
      {hoursOfTheDay.slice(0, Math.min(32, hoursOfTheDay.length)).map((currentTime, index) => {
        let disabled;
        if (isPreview) {
          disabled = ([0, 2, 4, 6].includes(dayIndex) ? [1, 3, 4, 7, 11, 13, 15, 18, 21, 28, 31].includes(index) : [0, 2, 5, 13, 17, 25, 30].includes(index));
          disabled = disabled ||
            isTimeEarlier(moment(`${day.format("YYYY-MM-DD")}-${currentTime}`, "YYYY-MM-DD-HH:mm"), moment()) === "earlier" ||
            isDateEarlier(day, moment().add(maxSchedulableTime, 'd')) === "later";
            if(minSchedulableTime !== 0){
              disabled = disabled ||
              isDateEarlier(day, moment().add(minSchedulableTime, 'd')) === "earlier"
            }
        } else {
          disabled = !timeSlots.includes(currentTime);
        }
        const endTime = moment(currentTime, 'HH:mm')
          .add(duration, 'minutes')
          .format('HH:mm');
        return (
          <Div key={currentTime} margin={["0px 5px", "5px 0px"]}>
            <Button
              outlined
              invertOnHover
              disabled={disabled}
              fontSize={["20px", "15px", "20px"]}
              borderRadius="3px"
              width={["95px", "100%"]}
              minWidth="0"
              height={["51px", "63px"]}
              boxShadow="0 1px 6px 0 #d2e9e7"
              onClick={(e) => handleChange(e, day, currentTime)}
              fontWeight="bold"
            >
              <div className="shown">{currentTime}</div>
              <div className="hidden">{endTime}</div>
            </Button>
          </Div>
        );
      })}
    </Div>
  );
};
