import { useDispatch } from "react-redux";
import { put, _get } from "../utils/api";
import { apiUtils } from "../utils/apiUtils";
import { createAction } from "@reduxjs/toolkit";
import messages from "./i18n/useCalendarSetting";
import { actions } from "../redux/actions";

function useCalendarSetting(notify, uid) {
  const updateSetting = createAction(
    `${actions.getCalendarSetting.name}_update`
  );
  const dispatch = useDispatch();

  const updateCalendarSetting = async(body) => {
    let url = `/booking_calendar_settings/${uid}`;
    const result = await put(url, body);
    if (result.status < 200 || result.status >= 300) {
      return notify.setError(messages.notifyError, result?.data?.message);;
    } 
    else {
      return dispatch(updateSetting(apiUtils.formatAxiosResponse(result)));
    }
  };

  const getCalendarSetting = async() => {
    let url = `/booking_calendar_settings/${uid}`;
    const result = await _get(url);
    if (result.status < 200 || result.status >= 300) {
      return notify.setError(messages.notifyError, result?.data?.message);;
    } 
    else {
      return dispatch(updateSetting(apiUtils.formatAxiosResponse(result)));
    }
  };

  return {
    calendarActions: {
      updateCalendarSetting,
      getCalendarSetting,
    },
  };
}

export { useCalendarSetting };
