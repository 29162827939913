import { defineMessages } from 'react-intl';

const messages = defineMessages({
  templateBlocked: {
    id: 'components.layouts.template.blocked',
    defaultMessage: 'This item is controlled by a template.',
  },
});

export default messages;
