import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import messages from "../i18n/Availability";
import { FormattedMessage } from "react-intl";

import { DatePicker } from "@d-lighted/design-system";
import { Label } from "@d-lighted/design-system";

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropDownContent = styled.div`
  display: ${(props) => (props.showDropdown ? "block" : "none")};
  position: absolute;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transform: translate(50%, -100%);
  top: 360px;
  right: 50%;

  &::after {
    content: "";
    width: 25px;
    height: 25px;
    background: white;
    position: absolute;
    right: 20%;
    top: -4%;
    border-radius: 5px;
    transform: rotate(45deg);
  }
`;

const DateLabel = styled(Label)`
  cursor: pointer;
  display: block;
  color: #68878d;
  font-size: 11px;
  font-weight: bold;
`;

const DateText = styled(Label)`
  cursor: pointer;
  display: block;
  color: #314143;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
`;

const DatePickerButton = styled.div`
  cursor: pointer;
  padding: 5px 15px;
  border: 1px solid #d2e9e7;
  border-radius: ${(props) =>
    props.type === "startDate" ? "10px 0 0 10px" : "0 10px 10px 0"};
  border-right: ${(props) => (props.type === "startDate" ? "none" : null)};
  width: 200px;

  ${(props) =>
    props.focused &&
    `
   border: solid 1px #00bbb5;
   box-shadow: 1px 1px 5px #00bbb5, -1px -1px 5px #00bbb5;
    `}

  &:hover {
    color: red;
  }
`;
const SmallCalendar = ({
  input,
  minDate,
  maxDate,
  meta: { touched, error },
}) => {
  const [calendar, showCalendar] = useState(false);
  const [focused, changedFocused] = useState(false);
  const [date, setDate] = useState(moment(input.value).toDate());
  const handleClickOutside = (event) => {
    const targetElement = event.target;
    if (
      !targetElement.matches("#" + input.name) &&
      !targetElement.parentElement?.matches("#" + input.name) &&
      !targetElement.parentElement?.closest(".dropdown-content")
    ) {
      showCalendar(false);
      changedFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onDateChanged = (value) => {
    showCalendar(false);
    changedFocused(false);

    input.onChange(moment(value).format("YYYY-MM-DD"));
    setDate(value)
  };

  const onDatePickerClicked = () => {
    showCalendar(!calendar);
    changedFocused(!focused);
  };
  return (
    <DropdownWrapper>
      <DatePickerButton
        id={input.name}
        onClick={onDatePickerClicked}
        type={input.name}
        focused={focused}
      >
        <DateLabel>
          {input.name === "startDate" ? (
            <FormattedMessage {...messages.startDate} />
          ) : (
            <FormattedMessage {...messages.endDate} />
          )}
        </DateLabel>
        <DateText>
          {input.value ? (
            moment(input.value).format("YYYY/MM/DD")
          ) : (
            <FormattedMessage {...messages.pleaseSelect} />
          )}
        </DateText>
      </DatePickerButton>
      <DropDownContent className="dropdown-content" showDropdown={calendar}>
        <DatePicker
          highlightWeek={false}
          value={date}
          selectPast
          onChange={onDateChanged}
          showActionButton={false}
          minDate={minDate != null ? moment(minDate).add(1, 'day').toDate() : null}
          maxDate={maxDate != null ? moment(maxDate).subtract(1, 'day').toDate() : null}
          id={input.name}
        />
      </DropDownContent>

      {touched && error && <span>{error}</span>}
    </DropdownWrapper>
  );
};

export default SmallCalendar;
