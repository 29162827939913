/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from "styled-components";
import moment from "moment";
import {
  Row,
  Label,
  Button,
  Loader,
  DatePicker as SmallCalendar,
} from "@d-lighted/design-system";

import { actions } from "../../../redux/actions";
import { _get, useGet, post } from "../../../utils/api";
import TableColLayout from '../../../components/layouts/table/TableColLayout';
import TableHeadList from '../../../components/layouts/table/TableHeadList';
import AppointmentCard from '../../../components/layouts/table/AppointmentCard';
import { webConferenceTypeHeader, comingToVisitTypeHeader, goingToVisitTypeHeader } from '../../booking/utils/constants';
import { PrimaryLightBackground } from "../../../components/utils/Helper";
import BackButton from "../modules/BackButton";
import { downloadFile } from "../../../utils/downloadFile";
import downloadButton from "../../../assets/img/download.svg";
import defineMessages from "../i18n/appointmentsTable";
import { useDeleteAppointment } from "../hooks/useDeleteAppointment";
import DesktopBreakpoint from "@d-lighted/design-system/dist/components/Utils/DesktopBreakpoint";
import { MOBILEBREAKPOINT } from "../../../constants/breakpoint";
import { isBookingTypeIrregular, isExportAppointmentsAllowed } from "../../../utils/userUtils";

const PageTitleRow = styled(Row)`
  flex-wrap: nowrap;
  padding: 32px 0 0 50px;
  @media (max-width: ${MOBILEBREAKPOINT}){
    padding: 16px 20px;
  }
`;

const PageTitleLabel = styled(Label)`
  padding-top: 15px;
  @media (max-width: ${MOBILEBREAKPOINT}){
    padding: 0;
  }
`;

const Container = styled.div`
  margin: 24px 40px;
  padding: 10px 0 30px;
  border-radius: 6px;
  opacity: 1;
  @media (max-width: ${MOBILEBREAKPOINT}){
    margin: 0px;
    padding: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 50%;
  margin: 0 auto;
  justify-content: "center";
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;

const LoaderAnchor = styled.div`
  height: 56px;
  width: 56px;
  margin: 20px auto;
  padding-top: 30px;
  &> * {
    height: 56px;
    width: 56px;
    margin-top: -22px;
  }
`;

const DownloadIcon = styled.img`
width: 15px;
height: 16px;
margin-right: 11px;
vertical-align: middle !important;
`;

const DownloadButtonWrapper = styled.div`
  margin: 0 34px 0 auto;
  padding: 0 15px;
  width: 300px;
`;

const DropDownContent = styled.div`
  display: ${props => props.showDropdown ? 'block' : 'none'};
  position: absolute;
  margin-top: 5px;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

function generateColumnsWidth(type) {
  switch(type) {
    case 'coming_to_visit':
      return [100, 70, 265, 265, 150, 150, 30];
    case 'web_conference':
      return [100, 265, 265, 150, 70, 150, 30];
    default:
      return [100, 300, 300, 150, 150, 30];
  }
}

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const Appointments = (props) => {
  const { intl, useNotify } = props;
  const notify = useNotify(intl);
  const { data, isSuccess, hasError } = useSelector((state) => state.calendarSetting);
  const [appointments, setAppointments] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loader, showLoader] = useState(true);
  const [calendar, showCalendar] = useState(false);
  const startOfTheDay = moment().startOf('day').toString();
  const endOfTheDay = moment().endOf('day').toString();
  const [timeRange, setTimeRange] = useState([startOfTheDay, endOfTheDay]);
  const params = useParams();
  const { status } = useSelector((state) => state.online);
  const { deleteAppointment } = useDeleteAppointment(notify);
  const isIrregular = isBookingTypeIrregular();

  useGet(calendarSettingRequest(params.uid));

  useEffect(() => {
    if(isSuccess && data.uid === params.uid && status[data.alias]?.uid) {
      const bookingCalendarUid = status[data.alias]?.uid;
      getAppointments(bookingCalendarUid).then(values => {
        const newAppointments = appointments.concat(values);
        setAppointments(newAppointments);
        setLoading(false);
      });
    }
  }, [currentPage, status]);

  async function getAppointments(uid) {
    const url = `/booking_calendars/${uid}/appointments?page=${currentPage}`;
    let appointments = [];
    showLoader(true);
    await _get(url).then((response) => {
      if (response.status >= 200) {
        appointments = response.data?.appointments;
        setTotalPages(response.data.meta?.total_pages)
      }
      showLoader(false);
    });
    return appointments;
  }

  async function downloadAppointments(range) {
    const bookingCalendarUid = status[data.alias]?.uid;
    const url = `/booking_calendars/${bookingCalendarUid}/export_appointments`;

    await post(url, { time_range: range }).then((response) => {
      if (response.status === 200) {
        const fileName = decodeURIComponent(escape(response.headers["content-disposition"]?.match(/filename="(.+)"/)[1]));
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const url = window.URL.createObjectURL(new Blob([bom, response.data]));
        downloadFile(url, fileName);
      }
    });
  }

  if (hasError) {
    return <Redirect to="/" />;
  }

  const tableHeaders = (meetingType) => {
    switch (meetingType) {
      case "going_to_visit":
        return goingToVisitTypeHeader(isIrregular);
      case "coming_to_visit":
        return comingToVisitTypeHeader(isIrregular);
      default:
        return webConferenceTypeHeader(isIrregular);
    }
  };

  if (loader) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  window.onclick = function (event) {
    if (!event.target.matches("button") &&
      !event.target.matches("abbr") &&
      !event.target.matches("span.react-calendar__navigation__label__labelText")) showCalendar(false);
  };

  const handleDeleteAppointment = function handleDeleteAppointment(appointment) {
    return function subHandleDeleteAppointment() {
      const result = window.confirm(intl.formatMessage({...defineMessages.confirmation}));
      if ( result ) {
        deleteAppointment(appointment, status[data.alias]?.uid)
          .then(result => setAppointments(appointments.filter(i => (i.uid !== result))));
      }
    }
  };

  return (
    <PrimaryLightBackground>
      <DesktopBreakpoint>
        <BackButton/>
      </DesktopBreakpoint>
      <PageTitleRow>
        <PageTitleLabel
          fontSize="20px"
          fontWeight="500"
          letterSpacing="1px"
          color="#142C2B">
          {data?.title}
        </PageTitleLabel>
        {isExportAppointmentsAllowed() && <DesktopBreakpoint>
          <DownloadButtonWrapper>
            <Button
              id="downloadButton"
              padding="0 15px"
              onClick={() => showCalendar(!calendar)}>
              <DownloadIcon
                src={downloadButton} />
              {<FormattedMessage {...defineMessages.downloadAppointments} />}
            </Button>
            <DropDownContent showDropdown={calendar}>
              <SmallCalendar
                selectRange={true}
                selectPast={true}
                highlightWeek={false}
                onChange={(v) => (setTimeRange(v))}
                buttonOnClick={() => downloadAppointments(timeRange)} />
            </DropDownContent>
          </DownloadButtonWrapper>
        </DesktopBreakpoint>}
      </PageTitleRow>
      <Container>
        <TableColLayout columnWidths={generateColumnsWidth(data.meetingType)}>
          <TableHeadList cols={tableHeaders(data.meetingType)} />
          {appointments?.map((appointment) => {
            if (appointment) {
              return (<AppointmentCard
                isIrregular={isIrregular}
                key={`appointmentCard${appointment.uid}`}
                appointment={appointment}
                meetingType={data.meetingType}
                handleDeleteAppointment={handleDeleteAppointment(appointment)}
              />);
            }

            return null;
          })}
        </TableColLayout>
        {currentPage < totalPages && <Wrapper>
          {!loading && <Button
            mt="10px"
            mb="5px"
            onClick={() => {
              setLoading(true);
              if (currentPage <= totalPages) {
                let nextPage = currentPage += 1;
                setCurrentPage(nextPage);
              }

              return null;
            }}
          >
            もっと読み込む
          </Button>}
          {loading && <LoaderAnchor><Loader /></LoaderAnchor>}
        </Wrapper>}
      </Container>
    </PrimaryLightBackground>
  );
};

Appointments.propTypes = {};

export default reduxForm({
  form: "BusinessHours",
})(injectIntl(Appointments));
