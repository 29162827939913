import { createSlice } from "@reduxjs/toolkit";

const externalMultiguestCalendarReducer = createSlice(
  {
    name: "externalMultiguestCalendarReducer",
    initialState: {
      externalMembersCanAttend: false,
      hideAttendeesInfoOnBookingPage: true,
      uid: ""
    },
    reducers: {
      updateExternalMultiguestCalendarSetting(state, action) {
        return { ...state, ...action.payload };
      }
    }
  }
)
export const { updateExternalMultiguestCalendarSetting } = externalMultiguestCalendarReducer.actions

export default externalMultiguestCalendarReducer.reducer;
