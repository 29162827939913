import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Avatar,
  Button,
  Label,
  Col,
  Row,
} from "@d-lighted/design-system";

import { injectIntl, FormattedMessage } from "react-intl";
import IconDots from "components/icons/IconDots";
import { useCalendars } from "hooks/useCalendars";
import { MOBILEBREAKPOINT } from "constants/breakpoint";

import messages from "../i18n/modules/Calendar";

const IconWrapper = styled(Col)`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  opacity: ${(props) => props.opacity};
`;

const LabelWrapper = styled(Col)`
  box-sizing: border-box;
  flex: 1 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-shrink: 1;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const SelectorWrapper = styled(Col)`
  min-width: 370px;
  flex: 0 0 auto;
  display: flex;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-top: 14px;
    display: block;
    order: 1;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    min-width: 100%;
  }
`;

const CodeLabel = styled.div`
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: ${(props) => props.color};
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin-right: 8px;
  display: inline-block;
`;

const DropDownButton = styled.li`
  float: left;
  position: relative;
  display: inline-block;
`;

const DropDownContent = styled.div`
  width: max-content;
  display: ${(props) => (props.showDropdownOptions ? "block" : "none")};
  position: absolute;
  right: ${(props) => (props.multiGuest ? "0" : "20px")};
  margin-top: ${(props) => (props.multiGuest ? "50px" : "2px")};
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropDownItem = styled.a`
  color: #314143;
  padding: 12px 16px;
  margin: 5px;
  border-radius: 4px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: ${(props) => props.width};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const ExpandButton = styled.div`
  display: inherit;
  float: right;
  z-index: 1;
  cursor: pointer;
  margin-left: 5px;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-right: -4px;
    transform: rotate(90deg);
  }
`;

const ContentWrapper = styled.div`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #68878d;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const ReservedTextWrapper = styled.div`
  margin-right: 24px;
`;

const BookingPagesLabel = styled.span`
  margin-right: 8px;
  color: #68878d;
  font-size: 15px;
  font-weight: normal;
`;

const BookingPagesNumber = styled.span`
  color: #68878d;
  font-size: 16px;
  font-weight: bold;
`;

const EVENT_TYPE_TEXTS = {
  1: <FormattedMessage {...messages.receptionCode} />,
  2: <FormattedMessage {...messages.webConference} />,
};

function Template({ template, history }) {
  const {
    title,
    icon,
    settingsUid,
    eventType,
    uid,
    bookingCalendarsCount,
  } = template;
  const { actions } = useCalendars();
  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleVisibility(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const [toggle, toggleVisibility] = useState(false);

  const toggleMenu = () => {
    toggleVisibility(!toggle);
  };

  return (
    <>
      <Row
        key={3}
        alignItems="center"
        mt="20px"
        bg="white"
        boxShadow="0px 6px 20px 0 rgba(193, 207, 217, 0.14)"
        width="100%"
        padding={["20px 8px 20px 8px", "20px 36px"]}
        borderRadius="6px"
        flexWrap={["wrap", "nowrap"]}
        mr={["0px", "-0.5rem"]}
        ml={["0px", "-0.5rem"]}
      >
        <IconWrapper>
          <Avatar
            height={["35px", "56px"]}
            width={["35px", "56px"]}
            src={icon.url || require("assets/img/avatar-default.png")}
            alt="profile"
          />
        </IconWrapper>
        <LabelWrapper mt="5px">
          <Label
            fontSize="20px"
            fontWeight="500"
            letterSpacing="1px"
            color="#142C2B"
          >
            {title}
          </Label>
          <br />
          <ContentWrapper>
            {eventType > 0 && (
              <CodeLabel color="#9BB0B5">
                {EVENT_TYPE_TEXTS[eventType]}
              </CodeLabel>
            )}
          </ContentWrapper>
        </LabelWrapper>
        <SelectorWrapper
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <ReservedTextWrapper>
            <BookingPagesLabel>
              <FormattedMessage {...messages.numberOfPagesLabel} />
            </BookingPagesLabel>
            <BookingPagesNumber>{bookingCalendarsCount}</BookingPagesNumber>
          </ReservedTextWrapper>
          <ButtonWrapper>
            <Button
              ml={["8px", 0]}
              mt={["14px", 0]}
              width={["45%", "184px"]}
              height="44px"
              fontSize="16px"
              onClick={() => {
                actions.resetCalendars();
                history.push(`/templates/${settingsUid}/edit`);
              }}
            >
              <FormattedMessage {...messages.editTemplate} />
            </Button>
          </ButtonWrapper>
        </SelectorWrapper>
        <DropDownButton ref={dropdownRef}>
          <ExpandButton
            id="expandButton"
            onClick={() => toggleMenu()}
            className="expand-button"
          >
            <IconDots />
          </ExpandButton>
          <DropDownContent showDropdownOptions={toggle}>
            <DropDownItem
              width="135px"
              onClick={() =>
                history.push({
                  pathname: `/templates/${uid}`,
                })}
            >
              <FormattedMessage {...messages.templateDetails} />
            </DropDownItem>
          </DropDownContent>
        </DropDownButton>
      </Row>
    </>
  );
}

Template.defaultProps = {
  template: {},
  history: { push: () => {}},
};

Template.propTypes = {
  template: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

export default injectIntl(Template);
