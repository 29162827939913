import React from "react";
import { 
  Row,
  Col,
} from "@d-lighted/design-system";
import styled from "styled-components";
import { customMedia } from "../../utils/mobileBreakpointUtils";
import { isMobileDevice } from "../../utils/mobileViewChecker";
import BackgroundImage from "../../assets/img/signup_bg.png";
import Logo from "../../assets/img/img-scheduling-logo.svg";
import MobileLogo from "../../assets/img/logo.png";
import GuideStep1Icon from "../../assets/img/guide_step_1.png";
import GuideStep2Icon from "../../assets/img/guide_step_2.png";
import GuideStep3Icon from "../../assets/img/guide_step_3.png";

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  background-color: #fff;
  ${customMedia.lessThan('sm')`
    display: block;
  `};
`;

const FieldWrapper = styled.div`
  width: 50%;
  padding-top: 20px;
  text-align: center;
  ${customMedia.lessThan('sm')`
    width: 100%;
    display: block;
    padding-top: 10px;
  `};
`;

const FieldSection = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: ${(props) => props.top ? props.top : "40px"} 0 0 32px;
  text-align: left;
  ${customMedia.lessThan('sm')`
    width: 100%;
    margin: 0;
    padding: 0;
  `};
`;

const FormBody = styled.div`
  ${customMedia.lessThan('sm')`
  width: auto;
  text-align: left;
  padding: 16px;
  `};
  .fieldLabel span {
  font-weight: 500;
  }
  .fieldLabel .error-text {
  color: #e84c3d;
  }
  padding: ${props => props.padding ? props.padding : "16px 0"};
`;

const GuideSection = styled.div`
  background: rgba(87, 194, 233, 0.06);
  width: 50%;
  height: ${window.innerHeight}px;
  align-items: center;
  flex-flow:column;
  margin-top: 0;
  ${customMedia.lessThan('sm')`
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 24px;
    margin: 24px 0;
    background-image: none;
  `};
  background-image: url(${BackgroundImage});
  background-position: top;
  background-size: 100% 100%;
  a {
    max-width: 100%;
  }
  img {
    width: 514px;
    height: 268px;
    cursor: pointer;
    border-radius: 2px;
    filter: drop-shadow(0px 20px 30px rgba(17, 45, 72, 0.16));
    ${customMedia.lessThan('sm')`
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 0 0 24px;
      display: block;
      margin: auto
    `};
  }
`;

const LogoImage = styled.img`
  ${customMedia.lessThan('sm')`
    max-width: 64px;
    max-height: 64px;
    margin: 16px 0 16px 0;
    text-align: left;
    padding: 0 16px;
  `};
`;

const Heading = styled.p`
  font-size: 30px;
  font-weight: 500;
  margin: 15px 0px;
  line-height: 45px;
  font-weight: 700;
  text-align: center;
  ${customMedia.lessThan('sm')`
    font-size: 20px;
    margin-top: ${props => props?.top ? props.top : "0px"};
  `};
`;

const SubHeading = styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  ${customMedia.lessThan('sm')`
    font-size: 14px;
    text-align: center;
  `};
`;

const GreenHeading = styled.p`
  font-family: Barlow;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 24px;
  color: #86E6AC;
  ${customMedia.lessThan('sm')`
    font-size: 16px;
    text-align: center;
  `};
`;

const Description = styled.p`
  font-family: YuGothic;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #66657C;
  ${customMedia.lessThan('sm')`
    font-size: 11px;
    text-align: center;
    margin-bottom: 16px;
  `};
`;

const GuideImage = styled.img`
  border-radius: 10px;
  max-width: 230px;
  max-height: 120px;
`;

const SignUpGuideDashboard = (props) => {
  const isMobileView = isMobileDevice();
  const { head } = props;

  return (
    <Wrapper>
      <FieldWrapper>
        <FieldSection top={0}>
          <Row>
            <Col xs={2}>
              <LogoImage src={isMobileView ? MobileLogo : Logo}/>
            </Col>
            {isMobileView && head &&
              <Col xs>
                <Heading top="24px">{head}</Heading>
              </Col>
            }
          </Row>
          {!isMobileView && head &&
            <Row>
              <Col>
                <Heading>{head}</Heading>
              </Col>
            </Row>
          }
          <FormBody>
              <>
                {props?.children}
              </>
          </FormBody>
        </FieldSection>
      </FieldWrapper>
      <GuideSection>
        <Row mt="20px">
          <Col xs display="block" alignItems="center">
            <Heading>
              URLを送れば、手放しで完了する <br/>
              スケジュール調整を今すぐ始めましょう。
          </Heading>
          </Col>
        </Row>
        <Row mt="30px" ml="20px">
          <Col xs alignItems="left" display="block">
            <GreenHeading>Step.1</GreenHeading>
            <SubHeading>まずは予約ページを作成</SubHeading>
            <Description>
              新規登録後、業務で使うカレンダーと連携し、空き日時が自動抽出された予約ページが発行されます。
            </Description>
          </Col>
          <Col xs={12} sm={11} md={5} lg={5}>
            <GuideImage src={GuideStep1Icon}/>
          </Col>
        </Row>
        <Row mt="60px" ml="20px">
          <Col xs alignItems="left" display="block">
            <GreenHeading>Step.2</GreenHeading>
            <SubHeading>用途に合わせてカスタマイズ</SubHeading>
            <Description>
              WEB会議、来訪、往訪、面接などの用途に合わせてカスタマイズすることで、日程調整とそれ以外の手間な業務も自動化されます。
            </Description>
          </Col>
          <Col xs={12} sm={11} md={5} lg={5}>
            <GuideImage src={GuideStep2Icon}/>
          </Col>
        </Row>
        <Row mt="60px" ml="20px">
          <Col xs alignItems="left" display="block">
            <GreenHeading>Step.3</GreenHeading>
            <SubHeading>あなたはURLを送るだけ</SubHeading>
            <Description>
              URLを送るだけで日程調整が完了します。WebサイトやメールにURLを記載すると、作業不要で面談・商談が設定されます。
            </Description>
          </Col>
          <Col xs={12} sm={11} md={5} lg={5} display="flex" alignItems="left">
            <GuideImage src={GuideStep3Icon}/>
          </Col>
        </Row>
    </GuideSection>
    </Wrapper>
  );
}

SignUpGuideDashboard.propTypes = {};

export default SignUpGuideDashboard;
