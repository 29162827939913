/* eslint-disable  react-hooks/exhaustive-deps*/
import React from "react";
import styled from "styled-components";
import { reduxForm, Field } from "redux-form";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  ErrorText,
  Input,
  Label as BaseLabel,
} from "@d-lighted/design-system";
import {
  space,
  textAlign,
} from 'styled-system';
import { useValidation } from "../../../utils/validation";
import { getSamlCompanyEmailDomain } from "../../../utils/userUtils";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../../signIn/i18n/SamlSignIn";
import { customMedia } from '../../../utils/mobileBreakpointUtils';
import { _get } from "../../../utils/api";

const Wrapper = styled.div`
  min-height: 100vh;
  display: block;
  background-color: #373a47;
`;
const Container = styled.div`
  width: 430px;
  margin: 30px auto 0 auto;
  padding: 20px 60px 30px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 10px 26px 0 rgba(37, 38, 42, 0.1);
  opacity: 1;
  @media (max-width: 768px) {
    width: calc(100% - 120px);
  }
`;

const FormBody = styled.div`
  ${customMedia.lessThan('sm')`
  width: auto;
  text-align: left;
  padding: 16px;
  `};
  .fieldLabel span {
  font-weight: 500;
  }
  .fieldLabel .error-text {
  color: #e84c3d;
  }
  padding: 16px 0;
`;

const Label = styled(BaseLabel)`
  letter-spacing: 0px;
`;

const StyledLabel = styled(BaseLabel)`
  color: #000;
  font-weight:normal;
  text-align: ${(props) => props.textAlign ? props.textAlign : "right"};
  letter-spacing: 0px;
  a{
    color: #28afd5
  }
`

const BottomTextBody = styled.div`
  ${customMedia.lessThan('sm')`
    width: auto;
    padding: 0 16px;
  `};
  text-align: right;
  margin-bottom: 34px;
  padding-top: ${(props) => props.paddingTop};
`;

const ForgotPasswordBody = styled.div`
  margin-bottom: 4px;
  text-align: center;
`;

const LoginButton = styled(Button)`
  height: 46px;
  font-size: 16px;
  border-radius: 20px;
  padding: 10px 0px 12px;
  background-color: #28afd5;
  &:hover {
    background-color: #0e8eb3;
  }
`;

const Box = styled.div`
  ${space}
  ${textAlign}
`;

const FieldWrapper = styled.div`
  position: relative;
`;

const InTextLabel = styled(Label)`
  position: absolute;
  top: 8px;
  left: 15px;
  font-size: 16px;
  font-weight: normal;
  color: #000
`;

const CheckedCircle = styled.div`
  position: relative;
  margin: 0 auto 25px;
  width: 50px;
  height: 50px;
  border: solid 4px  #28afd5;
  border-radius: 50%;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    left: 12px;
    width: 22px;
    height: 12px;
    border-left: 3px solid  #28afd5;
    border-bottom: 3px solid  #28afd5;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

const SamlSignIn = (props) => {
  const { required, companyEmailDomain } = useValidation(props.intl);
  const notify = props.useNotify(props.intl);
  const history = useHistory();

  const checkResponse = (response) => {
    if (response?.status !== 200) {
      notify.setError(messages.notifyError, response?.data?.error?.message);
    } else {
      const {
        data: {
          data: { url },
        },
      } = response;
      return window.location.href = `${url}`;
    }
  };
  
  const onSubmit = async (values) => {
    const {companyEmailDomain} = values;
    const response = await _get(`/integrations/saml/sessions?company_email_domain=${companyEmailDomain}`);
    checkResponse(response);
  };

  return (
    <Wrapper>
      <Box pt={30} pb={50}>
        <Container>         
          <Box textAlign="center">
            <Box mb={20}>
              <CheckedCircle />
            </Box>
            <Box mb={15}>
              <StyledLabel fontSize="18px">
                <FormattedMessage {...messages.SAML} />
               </StyledLabel>
            </Box>
           <Box>
              <StyledLabel fontSize="14px">
              <FormattedMessage {...messages.inputDomain} />
            </StyledLabel>
           </Box>           
          </Box>
          <Box mt={20}>
            <form>
              <FormBody>
                <Row mb="25px">
                  <Col xs>
                    <Row mb="13px">
                      <Col xs>
                        <StyledLabel fontSize="14px">
                          <FormattedMessage {...messages.companyDomain} />
                        </StyledLabel>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs>
                      <FieldWrapper>
                        <InTextLabel>@</InTextLabel>
                          <Field
                            id="companyEmailDomain"
                            name="companyEmailDomain"
                            component={Input}
                            validate={[required, companyEmailDomain]}
                            placeholder="example@co.jp"
                            height="40px"
                            borderColor="#e9eff4"
                            width="calc(100% - 46px)"
                            marginLeft="46px"
                          />
                        </FieldWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {props.error && (
                  <Row mb="20px">
                    <Col xs>
                      <ErrorText>{props.error}</ErrorText>
                    </Col>
                  </Row>
                )}
                <BottomTextBody paddingTop="16px">
                  <StyledLabel textAlign="center" fontSize="13px">
                    <Box>
                       <FormattedMessage {...messages.usablePlan} />
                    </Box>
                    <Box>
                       <FormattedMessage {...messages.helpLink} />
                    </Box>
                  </StyledLabel>              
                </BottomTextBody>
  
                <BottomTextBody>
                  <ForgotPasswordBody>
                    <Label
                      isCursor
                      fontSize="13px"
                      color= "#28afd5"
                      onClick={() =>
                        history.push({pathname: '/sign_in', state: { email: true }})
                      }
                    >
                      <FormattedMessage {...messages.passwordAuthentication} />
                    </Label>
                    <br />
                  </ForgotPasswordBody>
                </BottomTextBody>
  
                <LoginButton
                  onClick={props.handleSubmit((values) => onSubmit(values))}
                >
                  <FormattedMessage {...messages.login} />
                </LoginButton>
              </FormBody>
            </form>
          </Box>
        </Container>
      </Box>
    </Wrapper>
  );
};

SamlSignIn.propTypes = {};

export default injectIntl(
  reduxForm({
    form: "saml_sign_in",
    initialValues: {
      companyEmailDomain: getSamlCompanyEmailDomain(),
    },
  })(SamlSignIn)
);
