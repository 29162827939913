import React from "react";
import ReactDOM from "react-dom";
import Helmet from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import TagManager from "react-gtm-module";
import { ReceptionistThemeProvider } from "@d-lighted/design-system";

import { store, persistor } from "./redux/store";
import App from "./App";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <>
    <Helmet
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, maximum-scale=1",
        },
      ]}
    />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ReceptionistThemeProvider>
            <App />
          </ReceptionistThemeProvider>
        </Router>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById("root")
);
