import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import humps from 'lodash-humps';
import { debounce } from 'lodash';

import { apiUtils } from '../../../utils/apiUtils';
import {
  updateTimeSlots,
  setNotFirstTime,
  resetTimeSlots as reset,
} from '../../../redux/reducers/timeSlotsReducer';
import { _get } from '../../../utils/api'
import { moment } from '../../../components/preview/momentUtils';

function useAvailableTimeSlots(timeZone = moment.tz.guess(true)) {
  const availableTimeSlots = useSelector(state => state.timeSlots);

  const [startOfWeek, setStartOfWeek] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const originalFetchAvailableTimeSlots = (timeMin, timeMax, duration, uid) => {
    if (duration == null || uid == null) return;

    const queryParams = {
      duration,
      time_min: timeMin,
      time_max: timeMax,
      time_zone: timeZone,
    };

    if (availableTimeSlots.firstTime) {
      queryParams.next_available_week = true;
      dispatch(setNotFirstTime());
    }

    const queryString = new URLSearchParams(queryParams);

    setIsLoading(true)
    const url = `booking_calendars/${uid}/available_time_slots?${queryString.toString()}&multiguest=true`;
    _get(url)
      .then(result => {
        const { data, status } = result;

        const payload = {
          ...humps(data),
          status,
          url,
          ...apiUtils.checkStatus(status),
        };
        if (data.time_range != null) {
          setStartOfWeek(moment(data.time_range.start_time));
        }
        dispatch(updateTimeSlots(payload));
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchAvailableTimeSlots = useCallback(
    debounce(
      (timeMin, timeMax, duration, uid) => originalFetchAvailableTimeSlots(timeMin, timeMax, duration, uid),
      500,
    ),
    [],
  );

  const resetTimeSlots = () => {
    dispatch(reset());
  };

  return {
    availableTimeSlots,
    isLoading,
    startOfWeek,
    actions: {
      fetchAvailableTimeSlots,
      resetTimeSlots,
    },
  };
}

  export { useAvailableTimeSlots };
