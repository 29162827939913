import React, { useState } from "react";
import styled from "styled-components";
import {
  Avatar,
  Label,
  Col,
  Row,
  IconNew as Icon,
  DesktopBreakpoint,
} from "@d-lighted/design-system";
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from "react-intl";
import { MOBILEBREAKPOINT } from "constants/breakpoint";
import { isBookingTypeIrregular } from "utils/userUtils";
import IconDots from "components/icons/IconDots";

import messages from "../i18n/templates";

const IconWrapper = styled(Col)`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  opacity: ${(props) => props.opacity};
`;

const LabelWrapper = styled(Col)`
  box-sizing: border-box;
  flex: 1 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  flex-shrink: 1;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const CounterWrapper = styled(Col)`
  color: #9BB0B5;
  font-size: 15px;
  margin-right: 10px;
`;

const CounterRow = styled(Row)`
  min-width: 250px ;
  text-align: right;
`;

const CounterCol = styled(Col)`
  padding-right: 5px;
`;

const CodeLabel = styled.div`
  padding: 3px 6px;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: ${(props) => props.color};
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin-right: 8px;
  display: inline-block;
`;

const PageTypeLabel = styled.div`
  padding: 3px 6px;
  border-radius: 4px;
  background-color: ${(props) => (props.team ? "#F0FBFB" : "#F2F2F2")};
  color: ${(props) => (props.team ? "#02BBB5" : "#9BB0B5")};
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin-right: 8px;
  display: inline-block;
`;

const OnlineBullet = styled.span`
   {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: ${(props) => (props.online ? "none" : "2px solid #d8d8d8")};
    background: ${(props) => (props.online ? "#00bbb5" : "none")};
  }
`;

const ContentWrapper = styled.div`
  @media (max-width: ${MOBILEBREAKPOINT}) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #68878d;
  }
`;

const EVENT_TYPE_TEXTS = {
  1: <FormattedMessage {...messages.receptionCode} />,
  2: <FormattedMessage {...messages.webConference} />,
};

const DropDownButton = styled.li`
  float: left;
  position: relative;
  display: inline-block;
`;

const DropDownContent = styled.div`
  width: max-content;
  display: ${(props) => (props.showDropdownOptions ? "block" : "none")};
  position: absolute;
  right: ${(props) => (props.multiGuest ? "0" : "20px")};
  margin-top: ${(props) => (props.multiGuest ? "50px" : "2px")};
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropDownItem = styled.a`
  color: #314143;
  padding: 12px 16px;
  margin: 5px;
  border-radius: 4px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: ${(props) => props.width};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const DropDownLink = styled(Link)`
  color: #314143;
  padding: 12px 16px;
  margin: 5px;
  border-radius: 4px;
  font-size: 15px;
  text-decoration: none;
  display: block;
  width: ${(props) => props.width};
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e6f4ef;
  }
`;

const ExpandButton = styled.div`
  display: inherit;
  float: right;
  z-index: 1;
  cursor: pointer;
  margin-left: 5px;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-right: -4px;
    transform: rotate(90deg);
  }
`;

function Calendar({ calendar, onDelete }) {
  const {
    uid,
    title,
    alias,
    icon,
    eventType,
    online,
    pageType,
    settingsUid,
    analytics,
  } = calendar;
  const [toggle, toggleVisibility] = useState(false);
  const isIrregular = isBookingTypeIrregular();

  const toggleMenu = () => {
    toggleVisibility(!toggle);
  };

  // Close dropdown menu if the user clicks outside of it
  window.onclick = function (event) {
    if (
      !event.target.matches("#expandButton") &&
      !event.target.matches("svg") &&
      !event.target.matches("path")
    )
      toggleVisibility(false);
  };

  return (
    <>
      <Row
        key={3}
        alignItems="center"
        mt="20px"
        bg="white"
        boxShadow="0px 6px 20px 0 rgba(193, 207, 217, 0.14)"
        width="100%"
        padding={["20px 8px 20px 8px", "20px 36px"]}
        borderRadius="6px"
        flexWrap={["wrap", "nowrap"]}
        mr={["0px", "-0.5rem"]}
        ml={["0px", "-0.5rem"]}
      >
        <IconWrapper>
          <OnlineBullet online={online} />
        </IconWrapper>
        <IconWrapper opacity={online ? 1 : 0.3}>
          <Avatar
            height={["35px", "56px"]}
            width={["35px", "56px"]}
            src={icon.url || require("../../../assets/img/avatar-default.png")}
            alt="profile"
          />
        </IconWrapper>
        <LabelWrapper mt="5px">
          <Label
            fontSize="20px"
            fontWeight="500"
            letterSpacing="1px"
            color={online ? "#142C2B" : "#D1D5D5"}
          >
            {title}
          </Label>
          <br />
          <ContentWrapper>
            {!isIrregular && pageType !== null && (
              <PageTypeLabel team={pageType === "team"}>
                <FormattedMessage {...messages[pageType]} />
              </PageTypeLabel>
            )}
            {eventType > 0 && (
              <CodeLabel color={online ? "#9BB0B5" : "#D1D5D5"}>
                {EVENT_TYPE_TEXTS[eventType]}
              </CodeLabel>
            )}
            <Label fontSize="13px" color={online ? "#68878d" : "#D1D5D5"}>
              /{alias}
            </Label>
          </ContentWrapper>
        </LabelWrapper>
        <CounterWrapper>
          <CounterRow mb="8px">
            <CounterCol xs>
              <FormattedMessage {...messages.past30days} />
            </CounterCol>
            <Col>
              {analytics?.last30Days}
            </Col>
          </CounterRow>
          <CounterRow>
            <CounterCol xs>
              <FormattedMessage {...messages.past90days} />
            </CounterCol>
            <Col>
              {analytics?.last90Days}
            </Col>
          </CounterRow>
        </CounterWrapper>
        <DropDownButton>
          <ExpandButton
            id="expandButton"
            onClick={() => toggleMenu()}
            className="expand-button"
          >
            <IconDots />
          </ExpandButton>
          <DropDownContent showDropdownOptions={toggle}>
            <DesktopBreakpoint>
              <DropDownLink
                width="135px"
                to={`/booking_calendars/${settingsUid}/edit`}
                target="_blank"
              >
                <Row>
                  <Col xs>
                    <FormattedMessage {...messages.settings} />
                  </Col>
                  <Col>
                    <Icon fontSize="15px" color="#D0D5D5" className="material-icons">open_in_new</Icon>
                  </Col>
                </Row> 
              </DropDownLink>
              <DropDownItem
                width="135px"
                onClick={() => onDelete(uid)}
              >
                <FormattedMessage {...messages.deleteCalendar} />
              </DropDownItem>
            </DesktopBreakpoint>
          </DropDownContent>
        </DropDownButton>
      </Row>
    </>
  );
}

export default injectIntl(Calendar);
