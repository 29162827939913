import React from "react";
import styled from "styled-components";
import { FormattedMessage } from 'react-intl';
import { IconNew as Icon } from "@d-lighted/design-system";
import {
  Col,
  Row,
} from "@d-lighted/design-system/dist/index";
import FontAwesome from "react-fontawesome";
import { optionsIcon } from "../../booking/utils/options";
import CheckboxIcon from "../../../components/elements/CheckboxIcon";
import messages from '../../booking/i18n/InputScreenSetting';
import { FieldImage, FieldLabel } from "../../../components/elements/BorderedField";

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 12px 10px;
  width: 100%;
  max-width: 294.5px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 5px;
  border: solid 1px #d2e9e7;
  background-color: ${props => props.bgColor};
  display: flex;
  align-items: center;
`;

const FieldWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  min-width: 0;
`;

const FieldImageWrapper = styled.div`
  position: relative;
  display: table;
  margin-right: 12px;
  width: 30px;
  height: 30px;
  border-radius: 3px;
`;

const FontAwesomeIcon = styled(FontAwesome)`
  width: 18px;
  height: 18px;
  color: #68878d;
`;

export default function InputFieldListItem({field, handleEditField, editable}) {
  const fieldIcon = optionsIcon(field.fieldType);

  return (
    <>
      <Row mt="15px">
        <Col xs>
          <Wrapper>
            <FieldWrapper>
              <FieldImageWrapper>
                {field.fieldType === 'check_box' ? (
                  <CheckboxIcon />
                ) : (
                  <FieldImage src={fieldIcon}></FieldImage>
                )}
              </FieldImageWrapper>
              <FieldLabel>
                {field.fieldType === 'hidden_field' 
                  ? 
                    <FormattedMessage {...messages.hiddenField} /> 
                  : 
                    field.labelName
                }
              </FieldLabel>
            </FieldWrapper>
          </Wrapper>
        </Col>
        <Col
          xs={1}
          sm={1}
          md={1}
          lg={1}
          display="flex"
          justifyContent="center"
          alignSelf="center"
        >
          <FontAwesomeIcon name="arrows-h" className="fa fa-arrows-h" />
        </Col>
        <Col xs>
          <Wrapper bgColor="#e6f4ef">
            <FieldWrapper>
              <FieldLabel>{field.salesforceFieldId}</FieldLabel>
            </FieldWrapper>
            {editable &&
              <Icon
                isCursor
                onClick={(e) => handleEditField(e, field.uid)}
                className="material-icons"
                color="#00bbb5"
                fontSize="23px"
                mr="5px"
              >
                edit
              </Icon>
            }
          </Wrapper>
        </Col>
      </Row>
    </>
  );
}
