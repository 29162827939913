import Appointments from "./pages/Appointments";

export const appointmentsRoutes = [
  {
    component: Appointments,
    path: "/booking_calendars/:uid/appointments",
    exact: true,
    feature: "appointments",
    type: "private",
    gauid: 'Bp/Appointments',
  }
];
