import React from "react";
import styled, { keyframes } from "styled-components";

const loadOne = keyframes`
 30% { transform: translateY(-50%) }
`;

const loadTwo = keyframes`
 50% { transform: translateY(-50%) }
`;

const loadThree = keyframes`
 70% { transform: translateY(-50%) }
`;

const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;

  & > div {
    margin: 10px;
    width: 10px;
    height: 10px;
    background-color: #00afd5;
    border-radius: 50%;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
  }
`;
const One = styled.div`
  animation-name: ${loadOne};
`;

const Two = styled.div`
  animation-name: ${loadTwo};
`;
const Three = styled.div`
  animation-name: ${loadThree};
`;

export const DotLoader = () => {
  return (
    <Wrapper>
      <One />
      <Two />
      <Three />
    </Wrapper>
  );
};
