import { defineMessages } from 'react-intl';

export default defineMessages({
  participating: {
    id: 'views.home.pages.dashboard.participating',
    defaultMessage: 'Participating',
  },
  coordinating: {
    id: 'views.home.pages.dashboard.coordinating',
    defaultMessage: 'Participating',
  },
  all: {
    id: 'views.home.pages.dashboard.all',
    defaultMessage: 'All',
  },
  template: {
    id: 'views.home.pages.dashboard.template',
    defaultMessage: 'Template',
  },
  creationErrror: {
    id: 'notify.dashboard.create',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  creation: {
    id: 'views.home.pages.dashboard.creation',
    defaultMessage: 'Create a new booking page',
  },
  createTemplate: {
    id: 'views.home.pages.dashboard.createTemplate',
    defaultMessage: 'Create new Template',
  },
  guideDownload: {
    id: 'views.home.pages.dashboard.guideDownload',
    defaultMessage: 'Create a new booking page',
  },
  count: {
    id: 'views.home.pages.dashboard.countMessage',
    defaultMessage: 'Remaining number of team booking pages you can create for your subscription plan: {count} / {limit}',
  },
  suspendedTitle: {
    id: 'views.home.pages.dashboard.suspended.title',
    defaultMessage: 'Account Suspended',
  },
  suspendedContent: {
    id: 'views.home.pages.dashboard.suspended.content',
    defaultMessage: 'Your trial period has expired and your account has been suspended.\nTo reactivate your account, please register your credit card on the Payment Information page.',
  },
  suspendedButton: {
    id: 'views.home.pages.dashboard.suspended.button',
    defaultMessage: 'Go to the billing page',
  },
  loadMore: {
    id: 'views.home.pages.dashboard.loadMore',
    defaultMessage: 'Load more',
  },
  searchPlaceholder: {
    id: 'views.home.pages.dashboard.search.placeholder',
    defaultMessage: 'Search',
  },
  forStaffRegistration: {
    id: 'views.home.modules.calendar.forStaffRegistration',
    defaultMessage: 'Staff registration',
  },
  sortByCreated: {
    id: 'views.home.modules.calendar.sortByCreated',
    defaultMessage: 'Created',
  },
  sortByTitle: {
    id: 'views.home.modules.calendar.sortByTitle',
    defaultMessage: 'Title',
  },
  sortLastUsed: {
    id: 'views.home.modules.calendar.sortLastUsed',
    defaultMessage: 'Last Used',
  },
  searchByTitle: {
    id: 'views.home.modules.calendar.searchByTitle',
    defaultMessage: 'By Title',
  },
  searchByEmployee: {
    id: 'views.home.modules.calendar.searchByEmployee',
    defaultMessage: 'By Employee', 
  },
  createTeamTemplateLabel: {
    id: 'views.home.modules.calendar.createTeamTemplateLabel',
    defaultMessage: 'Create team booking page from template',
  },
  createPersonalTemplateLabel: {
    id: 'views.home.modules.calendar.createPersonalTemplateLabel',
    defaultMessage: 'Create personal booking page',
  },
  createPersonalTemplateButton: {
    id: 'views.home.modules.calendar.createPersonalTemplateButton',
    defaultMessage: 'Create personal booking page',
  },
});
