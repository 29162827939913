import { keyframes } from 'styled-components';
import { generateMedia } from "styled-media-query";

export const mobileBreakpoint = 599;
export const showMobile = width => width < mobileBreakpoint;

export const customMedia = generateMedia({
  xs: '0px',
  sm: `${mobileBreakpoint}px`,
  md: '768px',
  lg: '992px',
  xl: '1200px'
});

const animation = {
  show: {
      animationDuration: '0.4s',
      animationTimingFunction: 'cubic-bezier(0.7,0,0.3,1)'
  },

  hide: {
      animationDuration: '0.4s',
      animationTimingFunction: 'cubic-bezier(0.7,0,0.3,1)'
  },

  showModalAnimation: keyframes`
    from {
      opacity: 0;
      transform: translate3d(-50%, 100px, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
    }
  `,

  hideModalAnimation: keyframes`
    from {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(-50%, 100px, 0);
      }
    }
  `,

  showBackdropAnimation: keyframes`
    from {
      opacity: 0;
    }

    to {
      opacity: 0.9;
    }
  `,

  hideBackdropAnimation: keyframes`
    from {
      opacity: 0.9;
    }

    to {
      opacity: 0;
    }
  `,

  showContentAnimation: keyframes`
    from {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,

  hideContentAnimation: keyframes`
    from {
      opacity: 1;
      transform: 'translate3d(0, 0, 0);
    }

    to {
      opacity: 0;
      transform: 'translate3d(0, 50px, 0);
    }
  `
}

var showAnimation = animation.show;
var hideAnimation = animation.hide;
var showModalAnimation = animation.showModalAnimation;
var hideModalAnimation = animation.hideModalAnimation;
var showBackdropAnimation = animation.showBackdropAnimation;
var hideBackdropAnimation = animation.hideBackdropAnimation;
var showContentAnimation = animation.showContentAnimation;

export const mobileModalAnimation = {
  getRef: function(willHidden) {
    return 'modal';
  },
  getModalStyle: function(willHidden) {
      return {
          position: "fixed",
          width: "500px",
          transform: "translate3d(-50%, -50%, 0)",
          top: "50%",
          left: "50%",
          backgroundColor: "white",
          zIndex: 1050,
          animationDuration: (willHidden ? hideAnimation : showAnimation).animationDuration,
          animationFillMode: 'forwards',
          animationName: willHidden ? hideModalAnimation : showModalAnimation,
          animationTimingFunction: (willHidden ? hideAnimation : showAnimation).animationTimingFunction
      }
  },
  getBackdropStyle: function(willHidden) {
      return {
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1040,
          backgroundColor: "#373A47",
          animationDuration: (willHidden ? hideAnimation : showAnimation).animationDuration,
          animationFillMode: 'forwards',
          animationName: willHidden ? hideBackdropAnimation : showBackdropAnimation,
          animationTimingFunction: (willHidden ? hideAnimation : showAnimation).animationTimingFunction
      };
  },
  getContentStyle: function(willHidden) {
      return {
          margin: 0,
          opacity: 0,
          animationDuration: (willHidden ? hideAnimation : showAnimation).animationDuration,
          animationFillMode: 'forwards',
          animationDelay: '0.25s',
          animationName: showContentAnimation,
          animationTimingFunction: (willHidden ? hideAnimation : showAnimation).animationTimingFunction
      }
  }
}
