import React from "react";
import { Col, Row, Button, Label } from "@d-lighted/design-system";
import { Field, formValueSelector } from "redux-form";
import { useSelector } from "react-redux";
import { v4 as uuid4 } from "uuid";
import { findIndex, cloneDeep } from "lodash";
import { FormattedMessage } from 'react-intl';
import SortableList from "./RadioSortable";
import arrayMove from "array-move";
import messages from '../i18n/Modules';

const getChoices = formValueSelector("ReservationFormSettings");

export function Radio({ change }) {
  const choices = useSelector((state) => getChoices(state, "choices"));

  const removeSelectedOption = id => {
    const newChoices = choices.filter((v => v.id !== id));
    change("choices", [...newChoices]);
  };

  const updateSelectedOption = (id, fieldValue) => {
    const newChoices = cloneDeep(choices);
    const index = findIndex(newChoices, { id: id });
    newChoices[index].choiceTypeName = fieldValue;
    change("choices", newChoices);
  };

  const createDefaultOption = (e) => {
    e.preventDefault();
    change("choices", [
      ...choices,
      {
        bookingCalendarCustomFieldId: uuid4(),
        choiceTypeName: "",
        id: choices.length ? (choices[choices.length-1].id || 0) + 1 : null,
      },
    ]);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    change("choices", arrayMove(choices, oldIndex, newIndex));
  };

  return (
    <React.Fragment>
      <Row mt="25px">
        <Col xs>
          <Label>
            <FormattedMessage {...messages.options} />
          </Label>
        </Col>
      </Row>

      <Field name="choices" component="input" hidden />

      <SortableList
        lockAxis="y"
        useDragHandle
        choices={choices}
        removeChoice={removeSelectedOption}
        updateChoice={updateSelectedOption}
        onSortEnd={onSortEnd}
      />

      <Button
        outlined
        mt="10px"
        fontSize="14px"
        fontWeight="normal"
        height="32px"
        onClick={createDefaultOption}
      >
        <FormattedMessage {...messages.addOptions} />
      </Button>
    </React.Fragment>
  );
}
