import PropTypes from "prop-types";

const visitorPropTypes = PropTypes.shape({
  appointment_id: PropTypes.number,
  checked_out_at: PropTypes.string,
  company_id: PropTypes.number,
  company_name: PropTypes.string,
  created_at: PropTypes.string,
  custom_label: PropTypes.string,
  custom_label_en: PropTypes.string,
  display: PropTypes.bool,
  email: PropTypes.string,
  employee_id: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  notifier_type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phone_no: PropTypes.string,
  provisional_eids: PropTypes.string,
  provisional_times: PropTypes.string,
  setting_app_id: PropTypes.number,
  setting_custom_id: PropTypes.number,
  status: PropTypes.string,
  tablet_location: PropTypes.string,
  uid: PropTypes.string,
  updated_at: PropTypes.string,
  visited_at: PropTypes.string
});

export default visitorPropTypes;
