import React from 'react';
import styled from 'styled-components';
import { IconNew } from '@d-lighted/design-system/dist/components/Atoms';
import userDefault from '../../../../assets/img/userDefault.png';


const ItemLeft = styled.div`
  min-width: 70%;
  margin-top: ${props => props?.emailInvitationStatus ? "4px": "16px"};
  margin-bottom: ${props => props?.emailInvitationStatus ? "4px": "16px"};
`;

const ItemText = styled.p`
  padding-left: 12px;
  line-height: 1.5;
  color:#68878d;
  word-wrap: break-word;
  font-weight: ${props => props?.emailInvitationStatus ? "normal" : "500" };
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: ${props => props?.emailInvitationStatus ? props.fontSize : "13px"};
  color: ${props => props?.emailInvitationStatus ?"#fff": props.color };
`;

const ItemRight = styled.div`
  position: relative;
  margin-right: 20px;
  margin-left: auto;
  display: inline-flex;
  align-items: center;
`;

const ItemImage = styled.img`
  float: left;
  margin-top: 3px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: ${props => props.emailInvitationStatus &&  "12px" };
`;

const Wrapper = styled.div`
  margin-bottom: 12px;
  height: max-content;
  overflow:auto;
  display: flex;
  border-radius: 6px;
  padding-left: 8px;
  background: ${props => props?.emailInvitationStatus ?  "#00bbb5": "#e6f4ef" };
`;

const Icon = styled(IconNew)`
  color: ${props => props?.emailInvitationStatus && "#fff"};
  font-size: ${props => props?.emailInvitationStatus && "20px"};
  cursor: ${props => props.emailInvitationStatus ?  "default": "pointer" };
`;

const SendEmailInvitation = (props) => {
  const { item, handleClick , emailInvitationStatus, invitationText, approvalPendingText} = props;
  return <Wrapper emailInvitationStatus={emailInvitationStatus}>
    <ItemLeft emailInvitationStatus={emailInvitationStatus}>
      {emailInvitationStatus &&
        <ItemImage
          src={userDefault}
          emailInvitationStatus={emailInvitationStatus}
        />
      }
      <ItemText emailInvitationStatus={emailInvitationStatus} fontSize="15px" > {emailInvitationStatus ? item : invitationText} </ItemText>
      {emailInvitationStatus && <ItemText emailInvitationStatus={emailInvitationStatus } fontSize="12px">{approvalPendingText}</ItemText>}
    </ItemLeft>
    <ItemRight>    
      <Icon
        isCursor
        className="material-icons"
        color="#00bbb5"
        fontSize="24px"
        ml="4px"
        disabled={emailInvitationStatus}
        onClick={() => handleClick(item)}
        emailInvitationStatus={emailInvitationStatus}
      >
       {!emailInvitationStatus? "add" : "done"}
      </Icon>
    </ItemRight>
  </Wrapper>
};

export default SendEmailInvitation;
