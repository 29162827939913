import React from 'react';
import PropTypes from 'prop-types';

function Chevron({width, height, fill}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z"
        fill={fill}
      />
    </svg>
  );
}

Chevron.defaultProps = {
  width: 24,
  height: 24,
  fill: "#00bbb5",
};

Chevron.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default Chevron;
