import {
  useNotification,
} from "@d-lighted/design-system/dist/index";

function useI18NNotification() {
  const [ ResponseMessage, setNotification ] = useNotification();
  
  const useNotify = function(intl) {
    const notify = (status, message, subMessage) => {
      setNotification({
        status,
        message: message?.id != null ? intl.formatMessage(message) : message,
        subMessage: subMessage?.id != null ? intl.formatMessage(subMessage) : subMessage,
        duration: 5000,
      });
    };

    const setNotify = (message, subMessage) => notify("positive", message, subMessage);
    const setError = (message, subMessage) => notify("negative", message, subMessage);

    return {
      setNotify,
      setError
    }
  };
  
  return [ResponseMessage, useNotify];
}

export { useI18NNotification };