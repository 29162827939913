import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import copy from "copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { 
  Row,
  Col,
  Button,
  Label,
  InputWithRightAddonAndLabel,
  Icon,
} from "@d-lighted/design-system";
import { OnBoardingDashboard } from "../../../../components/layouts/OnBoardingDashboard";
import { Link } from "../../components/Link";
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../../../constants/breakpoint";
import { getCurrentUser } from "../../../../utils/userUtils";
import { leaveOnboarding, toOnboardingPage, getOnboardingState } from "../../utils/utils";
import { useOnboarding } from "../../hooks/useOnboarding";
import { generateLinkURL } from "../../../home/utils/utils";
import { pushToGA, events } from "../../../../utils/gtmUtils";

const CustomReactTooltip = styled(ReactTooltip).attrs({
  className: "custom-tooltip",
})`
  &.custom-tooltip {
    color: #fff;
    border-radius: 6px;
    width: 85px;
    height: 15px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: right;
    justify-content: right;
    font-size: 12px;
    font-weight: bold;
    
    transition: visibility 0s .375s, opacity .375s ease-in-out !important;
    
    &.show{
        transition: visibility 0s 0s, opacity .375s ease-in-out !important;
    }
  }
`;

const DesktopOnlyBR = styled.br`
  @media (max-width: ${MOBILEBREAKPOINT}){
    display: none;
  }
`;

const Wrapper = styled.div`
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MOBILEBREAKPOINT}){
    justify-content: flex-start;
  }
`;

const BookingPageDetails = props => {
  const { initialize } = props;
  const isMobileView = SHOW_MOBILE(window.innerWidth);
  const { onBoardingCalendarSetting } = useOnboarding();
  const { data, isSuccess } = onBoardingCalendarSetting;
  const [bookingPageUrl, setBookingPageUrl] = useState(null);
  const onboarding = getOnboardingState();
  const invitedEmployeeName = onBoardingCalendarSetting?.data?.members?.filter(member => member.owner)?.[0]?.name || '招待元社員名';
  const attendee = data.members?.find(member => member.email === getCurrentUser()?.email)?.attendee;

  useEffect(() => {
    if(data && isSuccess) {
      const pageUrl = generateLinkURL(data?.alias, data?.uid, false, data?.durations);
      initialize({
        bookingPageUrl : pageUrl
      });
      setBookingPageUrl(pageUrl);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if(!onboarding?.allowed || onboarding?.page !== "employee_booking_page") return leaveOnboarding(props);
    if(!getCurrentUser()?.company) return toOnboardingPage(props, "sign_in");
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  const handleLeaveOnboarding = () => {
    pushToGA({...events.leave_onboarding("Ob/employee/booking_page")});
    leaveOnboarding(props)
  };

  return (
    <OnBoardingDashboard top="0" hasTooltip={true}>
      <Wrapper>
        <Row mt="37px">
          <Col xs>
            <Label
              fontSize="24px"
              fontWeight="700"
              letterSpacing="1px"
              color="#484F63"
            >
              {attendee ?
                <p>
                  {invitedEmployeeName}さんとチームで日程調整を行う準備が完了しました<span role="img" aria-label="party">🎉</span> <DesktopOnlyBR />
                  さっそく予約ページを使ってみましょう！
                </p>
              :
                <p>
                  {invitedEmployeeName}さんの日程調整を行う準備が完了しました！
                </p>
              }
            </Label>
          </Col>
        </Row>
        <Row mt="32px">
          <Col xs>
            <Label fontSize="15px" color="#484F63" fontWeight="bold">
              予約ページ共有URL
            </Label>
          </Col>
        </Row>
        <Row mt="8px">
          <Col xs>
            <Label fontSize="15px">
              お客様にお渡しして、コピペで終わる日程調整を体験しましょう。
            </Label>
          </Col>
        </Row>
        <Row mt="26px">
          <Col xs>
            <Field
              id="bookingPageUrl"
              name="bookingPageUrl"
              component={InputWithRightAddonAndLabel}
              placeholder="booking.receptionist.jp/page-link"
              addonText={<Icon text="コピー" />}
              data-tip="コピーしました"
              data-event='click'
              data-event-off='click'
              disabled={true}
              fontSize="10px"
              onClick={() => copy(bookingPageUrl)}
            />
            <CustomReactTooltip
              isCapture={true}
              id={bookingPageUrl}
              delayHide={1000}
              place="bottom"
              effect="solid"
              backgroundColor="#6e52ff"
            />
          </Col>
        </Row>
        <Row mt="60px" pl="7px" mb={'20px'}>
          <Col xs>
            <Link onClick={() => handleLeaveOnboarding()}>
              管理画面を開く
            </Link>
          </Col>
          <Col>
            <Button
              fontWeight="500"
              width={isMobileView ? "auto" : "284px"}
              onClick={() => window.open(bookingPageUrl, "_blank")}
            >
              予約ページを開く
            </Button>
          </Col>
        </Row>
      </Wrapper>
    </OnBoardingDashboard>
  );
}

export default reduxForm({
  form: 'BookingPageDetails',
  initialValues: {
    bookingPageUrl: ""
  }
})(BookingPageDetails);
