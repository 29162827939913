/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { cloneDeep, debounce, isEmpty } from "lodash";
import {
  Button,
  Col,
  Row,
  Tab,
  UncontrolledTabs as Tabs,
  Label,
  Loader,
  InputSelectWithIcon,
} from "@d-lighted/design-system";
import { injectIntl, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { default as qS } from "query-string";

import { isMobileDevice } from "utils/mobileViewChecker";
import { post } from "utils/api";
import { useCalendars, checkTabType } from "hooks/useCalendars";
import {
  getCurrentUser,
  isPaidOrTrialPlan,
  getPlan,
  PLANS,
  isTrialExpired,
  isBookingTypeIrregular,
  isTemplatesEnabled,
  isTemplatesEnabledAndAdmin,
  isSchedulingAdmin,
} from "utils/userUtils";
import { MOBILEBREAKPOINT } from 'constants/breakpoint';
import { pushToGA, events } from "utils/gtmUtils";
import CreationDropdown from "views/home/modules/CreationDropdown";

import {
  setSelectedDuration,
  setBookingCalendarSortState,
  getBookingCalendarSortState,
  getBookingCalendarQuery,
  setBookingCalendarQuery,
} from "../utils/utils";
import messages from "../i18n/Dashboard";
import DashboardSuspended from "../modules/DashboardSuspended";
import TabContent from "../modules/TabContent";
import TemplateContent from "../modules/TemplateContent";
import SelectionDropdown from "components/elements/SelectionDropdown";
import UserBadge from "components/elements/UserBadge";
import { useEmployee } from "../hooks/useEmployee";
import { useTemplatesAvailability } from "../hooks/useTemplatesAvailability";
import beginnerIcon from "../../../assets/img/icon_beginner.svg";

const Icon = styled.span`
  position: relative;
  left: 14px;
`;

const CountWrapper = styled.div`
  color: #68878d;
  margin-right: 15px;
  margin-top: 15px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  @media (max-width: ${MOBILEBREAKPOINT}){
    display: none;
  }
`;

const DashboardWrapper = styled.div`
  padding: 0 30px 50px;
  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: 0;
  }
`;

const DropdownRow = styled(Row)`
  height: 36px;
  padding: 8px 8px 4px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e6f4ef;
  }
  
${({ disabled }) => disabled && `
  pointer-events: none;
  opacity: 0.2;
`}  
`

const DropdownScrollWrapper = styled(Col)`
  overflow-y: scroll;
  max-height: 200px;
`;

const DropdownTitleCol = styled(Col)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Separator = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin: 10px 0;
`;

const LoadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CreateButtonWrapper = styled.div`
  position: relative;
`;

const GuideDownload = styled.a`
  position: absolute;
  width: 200px;
  right: 38px;
  cursor: pointer;
  height: 40px;
  margin-top: 60px;
  color: #00bbb5;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const BeginnerGuideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BeginnerIcon = styled.img`
  height: 18px;
  filter: invert(67%) sepia(55%) saturate(6836%) hue-rotate(146deg) brightness(102%) contrast(101%);
  margin-right: 5px;
`;

const concatQueryString = (location, history, value = {}) => {
  const queryString = qS.parse(location.search);
  history.replace({
    search: new URLSearchParams({ ...queryString, ...value }).toString(),
  });
};

const Dashboard = (props) => {
  const currentUser = getCurrentUser();
  const location = useLocation();
  const history = useHistory();
  const planStatus = getPlan();
  const { calendars, templates, isLoading, types, tabs, actions } = useCalendars();
  const { isEmployeeLoading, employeeActions } = useEmployee();
  let queryString = qS.parse(location.search);
  const defaultTab = checkTabType(queryString["tab"]) || types.ATTENDEE;
  const [templateCreateBlocked, setTemplateCreateBlocked] = useState(false);
  const [tab, setTab] = useState(tabs[defaultTab]);
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const calendarQuerySettings = getBookingCalendarQuery(currentUser?.uqid);
  const [searchEmployeesResult, setSearchEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState(calendarQuerySettings?.selectedEmployees || []);
  const [query, setQuery] = useState(queryString["query"] || calendarQuerySettings?.query || "");
  const [querying, setQuerying] = useState(
    (queryString["query"] != null && queryString["query"] !== "") || selectedEmployees.length > 0
  );
  const [queryPage, setQueryPage] = useState(
    querying ? parseInt(queryString["page"], 10) || 1 : 1
  );
  const [sortOptions, setSortOptions] = useState([
    { value: "created", label: props.intl.formatMessage({ ...messages.sortByCreated }), icon: "sort-numeric-asc", param: "created_asc", selected: true },
    { value: "title", label: props.intl.formatMessage({ ...messages.sortByTitle }), icon: "", param: "title_asc", selected: false },
    { value: "used", label: props.intl.formatMessage({ ...messages.sortLastUsed }), icon: "", param: "used_asc", selected: false },
  ]);
  const [searchOptions, setSearchOptions] = useState([
    { value: 0, label: props.intl.formatMessage({ ...messages.searchByTitle }), selected: calendarQuerySettings?.isQueryByEmployee ===  undefined ? true : !calendarQuerySettings?.isQueryByEmployee },
    { value: 1, label: props.intl.formatMessage({ ...messages.searchByEmployee }), selected: calendarQuerySettings?.isQueryByEmployee || false }
  ])
  const [isQueryByEmployee, setQueryByEmployee] = useState(calendarQuerySettings?.isQueryByEmployee || searchOptions.filter(option => option.selected)?.[0]?.value === 1);
  const [showQueryByEmployeeList, setQueryByEmployeeList] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(getBookingCalendarSortState(currentUser?.uqid) || sortOptions[0]);
  const defaultSortParam = queryString["sort"] || selectedSortOption.param;
  const notify = props.useNotify(props.intl);
  const isMobileView = isMobileDevice();
  const { availabilities, loading, getTemplatesAvailability } = useTemplatesAvailability();
  const isTemplateEnabled = isTemplatesEnabled();
  const isTemplateEnabledAndAdmin = isTemplatesEnabledAndAdmin();
  
  function initializeQueryStrings() {
    if (queryString["tab"] == null && queryString["page"] == null) {
      concatQueryString(location, history, { tab: currentTab, page: 1, sort: defaultSortParam, query });
    } else if (queryString["tab"] == null) {
      concatQueryString(location, history, { tab: currentTab});
    } else if (queryString["page"] == null) {
      concatQueryString(location, history, { page: 1});
    } else if(queryString["sort"] == null) {
      concatQueryString(location, history, { sort: defaultSortParam });
    }
  }

  const handleFetchMoreCalendars = (page, sortParam = defaultSortParam, employees = selectedEmployees) => {
    const tabToFetch = querying ? isQueryByEmployee ? types.QUERY_EMPLOYEE : types.QUERY : currentTab;
    concatQueryString(location, history, { page: page, sort: sortParam });
    switch (tabToFetch) {
      case types.ALL:
        actions.fetchAll(page, sortParam, isMobileView);
        break;
      case types.ATTENDEE:
        actions.fetchAttendees(page, sortParam, isMobileView);
        break;
      case types.EDITOR:
        actions.fetchEditors(page, sortParam, isMobileView);
        break;
      case types.QUERY:
        setQueryPage(page);
        actions.fetchQuery(page, query, sortParam, isMobileView);
        break;
      case types.QUERY_EMPLOYEE:
        setQueryPage(page);
        actions.fetchEmployeeQuery(page, query, sortParam, isMobileView, employees.map(({uid}) => ({ uid })));
        break;
      case types.TEMPLATE:
        actions.fetchTemplates(page, isMobileView);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    initializeQueryStrings();

    const tabToFetch = querying ? isQueryByEmployee ? types.QUERY_EMPLOYEE : types.QUERY : currentTab;
    const sortOptionChanged = tabToFetch !== types.TEMPLATE ? calendars[tabToFetch].sort !== defaultSortParam : null;
    switch (tabToFetch) {
      case types.ALL:
        if (calendars.all.currentPage < 1 || sortOptionChanged) actions.fetchAll(1, defaultSortParam);
        break;
      case types.ATTENDEE:
        if (calendars.attendee.currentPage < 1 || sortOptionChanged) actions.fetchAttendees(1, defaultSortParam);
        break;
      case types.EDITOR:
        if (calendars.editor.currentPage < 1 || sortOptionChanged) actions.fetchEditors(1, defaultSortParam);
        break;
      case types.QUERY:
        if (calendars.query.currentPage < 1 || sortOptionChanged) actions.fetchQuery(1, query, defaultSortParam, isMobileView);
        break;
      case types.QUERY_EMPLOYEE:
        if (calendars.q_employee.currentPage < 1 || sortOptionChanged) actions.fetchEmployeeQuery(1, "", defaultSortParam, isMobileView, selectedEmployees.map(({uid}) => ({ uid })));
        break;
      case types.TEMPLATE:
        if (templates.currentPage < 1) actions.fetchTemplates();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, searchOptions]);

  useEffect(() => {
    queryString = qS.parse(location.search);
    if (queryString.token != null || queryString.client_id != null) {
      delete queryString["token"];
      delete queryString["client_id"];
      history.replace({ search: new URLSearchParams(queryString).toString() });
    }
    if (!isMobileView && (
      queryString["tab"] != null ||
      queryString["page"] != null ||
      queryString["query"] != null ||
      queryString["sort"] != null
    )) {
      const parsedTab = checkTabType(queryString["tab"]) || currentTab;
      const parsedQuery = queryString["query"] || "";
      const parsedPage = parseInt(queryString["page"], 10) || 1;
      const isQuerying = parsedQuery !== "";
      let changed = false;

      if (query !== parsedQuery) {
        setQuery(parsedQuery);
        changed = true;
      }
      if (querying !== isQuerying) {
        setQuerying(isQuerying);
        changed = true;
      }
      if (queryString["tab"] != null && currentTab !== parsedTab) {
        setCurrentTab(parsedTab);
        setTab(tabs[parsedTab]);
        changed = true;
      }

      if (
        (parsedTab !== types.TEMPLATE && calendars[parsedTab]?.currentPage !== parsedPage) ||
        (parsedTab === types.TEMPLATE && templates?.currentPage !== parsedPage)
      ) {
        changed = true;
      }

      if (changed && currentTab !== types.TEMPLATE) {
        actions.fetchCalendarsBase(
          isQuerying ? isQueryByEmployee ? types.QUERY_EMPLOYEE : types.QUERY : currentTab,
          parsedPage,
          isQuerying ? parsedQuery : "",
          defaultSortParam,
          isMobileView,
          undefined,
          selectedEmployees.map(({uid}) => ({ uid }))
        );
      }

      if (changed && currentTab === types.TEMPLATE) {
        actions.fetchTemplates(parsedPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  useEffect(() => {
    const selectedOption = ["created", "title", "used"].includes(defaultSortParam.split("_")[0]) ?  defaultSortParam.split("_")[0] : "created";
    const selectedOptionOrder = ["asc", "desc"].includes(defaultSortParam.split("_")[1]) ?  defaultSortParam.split("_")[1] : "asc";
    const option = sortOptions.filter(option => option.value === selectedOption)[0];
    const selectedOptionIndex = sortOptions.indexOf(option);

    const newSortOptions = [...sortOptions];
    newSortOptions.forEach((option, index) => {
      if (selectedOptionIndex === index) {
        option.icon = `sort-numeric-${selectedOptionOrder}`;
        option.param = defaultSortParam;
        option.selected = true;
      } else {
        option.icon = "";
        option.selected = false;
      }
    });
    setSortOptions(newSortOptions);
    setSelectedSortOption(newSortOptions[selectedOptionIndex]);
    setBookingCalendarSortState(currentUser?.uqid, newSortOptions[selectedOptionIndex]);
    if(query) {
      setQuerying(true);
    }
  }, []);

  useEffect(() => {
    setBookingCalendarQuery(
      currentUser?.uqid, 
      querying ? query || "" : "", 
      querying ? isQueryByEmployee : false,
      querying ? selectedEmployees || [] : []
    );
  }, [isQueryByEmployee, selectedEmployees, querying ]);

  const processResult = (result) => {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.creationErrror, result?.data?.message);
    } else {
      const settingsUid = result?.data?.["booking_calendar"]?.["settings_uid"] || result?.data?.["template"]?.["settings_uid"];
      setSelectedDuration(settingsUid, 60);
      currentTab !== types.TEMPLATE &&
        pushToGA({ ...events.create_bookingPage() });
      actions.resetCalendars();
      currentTab === types.TEMPLATE
        ? props.history.push(`/templates/${settingsUid}/edit`)
        : props.history.push(`/booking_calendars/${settingsUid}/edit`);
    }
  };

  const handleClickOnCreateNewCalendar = async (template) => {
    setTemplateCreateBlocked(true);
    const url = `/booking_calendars${template ? `?template=${template}` : ''}`;
    let result = await post(url);
    processResult(result);
  };

  const handleClickOnCreateNewTemplate = async () => {
    const url = "/booking_calendars/templates";
    let result = await post(url);
    processResult(result);
  };

  const handleTabChange = newTab => {
    if (newTab === currentTab) return;

    const currentPage = calendars[newTab].currentPage;
    concatQueryString(location, history, {
      tab: newTab,
      page: currentPage > 0 ? currentPage : 1,
    });
    setCurrentTab(newTab);
  };

  const handleTemplateTabChange = () => {
    const currentPage = templates.currentPage;
    concatQueryString(location, history, {
      tab: types.TEMPLATE,
      page: currentPage > 0 ? currentPage : 1,
    });
    setCurrentTab(types.TEMPLATE);
  };

  const debounceQuerySearch = useCallback(
    debounce((query, sort) => actions.fetchQuery(1, query, sort), 500),
    []
  );

  const debounceEmployeeSearch = useCallback(
    debounce((value, setSearchEmployees) => employeeActions.handleEmployeeSearch(value, setSearchEmployees), 500), 
    []
  );

  const debounceCreateBookingCalendar = useCallback(
    debounce((template) => handleClickOnCreateNewCalendar(template), 500),
    []
  );

  const handleQuerySearch = (e) => {
    const { value } = e.target;
    setQuery(value);
    concatQueryString(location, history, { query: value });
    if(value && !isQueryByEmployee) {
      return debounceQuerySearch(value, defaultSortParam);
    }
    if(value && isQueryByEmployee) {
      if(!showQueryByEmployeeList) {
        setQueryByEmployeeList(true);
      }
      return debounceEmployeeSearch(value, setSearchEmployees);
    }
  };

  const handleQueryClick = (state) => {
    const currentPage = calendars[currentTab].currentPage || 1;

    queryString = qS.parse(location.search);
    queryString["page"] = state ? queryPage : currentPage;

    if (state && query !== "") {
      queryString["query"] = query;
    } else {
      delete queryString["query"];
    }

    if(!state) {
      setBookingCalendarQuery(currentUser?.uqid);
      setSelectedEmployees([]);
      setQuery("");
    }

    history.replace({ search: new URLSearchParams(queryString).toString() });
    if (!state && (calendars[currentTab].currentPage === 0 || calendars[currentTab].sort !== defaultSortParam)) {
      return actions.fetchCalendarsBase(currentTab, currentPage, "", defaultSortParam);
    }
    if(state && isQueryByEmployee && calendars[types.QUERY_EMPLOYEE].currentPage === 0 && selectedEmployees.length > 0) {
      handleFetchMoreCalendars(1);
    }
  };

  if (planStatus === PLANS.SUSPENDED) {
    return <DashboardSuspended />;
  }

  const isIrregular = isBookingTypeIrregular();
  
  const creationButton = () => {
    if(currentTab === types.TEMPLATE) {
      return (
        <Button
          mb={["64px", 0]}
          pr="14px"
          pl="14px"
          width="auto"
          height="44px"
          onClick={() => handleClickOnCreateNewTemplate()}
        >
          <FormattedMessage {...messages.createTemplate} />
        </Button>
      );
    }
    
    if(isTemplateEnabled) {
      return (
        <CreateButtonWrapper>
          <CreationDropdown
            bpr="32px"
            bpl="14px"
            onClick={getTemplatesAvailability}
            isMobileView={isMobileView}
            buttonContent={
              <>
                <FormattedMessage {...messages.creation} />
                <Icon>&#x25BE;</Icon>
              </>
            }
          >
            <Row>
              <Col xs>
                <Row>
                  <Col xs mb="10px">
                    <Label fontSize="11px">
                      <FormattedMessage {...messages.createTeamTemplateLabel} />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <DropdownScrollWrapper xs>
                    {loading && <LoadWrapper><Loader /></LoadWrapper>}
                    {!loading && (availabilities || []).map(({ uid, title, usedCalendars, maxAllowedCalendars }) => (
                      <DropdownRow disabled={usedCalendars >= maxAllowedCalendars || templateCreateBlocked} onClick={() => debounceCreateBookingCalendar(uid)}>
                        <DropdownTitleCol xs>
                          {title}
                        </DropdownTitleCol>
                      </DropdownRow>
                    ))}
                  </DropdownScrollWrapper>
                </Row>
              </Col>
            </Row>
            <Separator />
            <Row>
              <Col xs>
                <Row>
                  <Col xs mb="10px">
                    <Label fontSize="11px">
                      <FormattedMessage {...messages.createPersonalTemplateLabel} />
                    </Label>
                  </Col>
                </Row>
                <Row>
                  <Col xs>
                    <Button
                      outlined
                      width="100%"
                      height="34px"
                      onClick={() => debounceCreateBookingCalendar()}
                    >
                      <FormattedMessage {...messages.createPersonalTemplateButton} />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CreationDropdown>
        </CreateButtonWrapper>
      );
    }

    return (
      <Button
        mb={["64px", 0]}
        pr="14px"
        pl="14px"
        width="auto"
        height="44px"
        onClick={() => debounceCreateBookingCalendar()}
      >
        <FormattedMessage {...messages.creation} />
      </Button>
    );
  }

  const handleSortOptionChange = (element) => {
    const index = sortOptions.findIndex(option => {
      return option.value === element.value;
    });
    const icon = sortOptions[index].icon === "sort-numeric-asc" ? "sort-numeric-desc" : "sort-numeric-asc";
    const sortParam = `${element.value}_${icon.split("-")[2]}`;
    
    const sortStates = [...sortOptions];
    if(sortStates[index] === selectedSortOption) {
      // when sorting order change
      sortStates[index].icon = icon;
      sortStates[index].param = sortParam;
      setSortOptions(sortStates);
    } else {
      sortStates.forEach((option) => {
        if(option.value === element.value) {
          option.icon = icon;
          option.param = sortParam;
          option.selected = true;
        } else {
          option.icon = "";
          option.param = `${element.value}_asc`;
          option.selected = false;
        }
      });
      setSortOptions(sortStates);
    }
    setBookingCalendarSortState(currentUser?.uqid, sortStates[index]);
    setSelectedSortOption(sortStates[index]);
    const currentPage = calendars[currentTab].currentPage || 1;
    handleFetchMoreCalendars(currentPage, sortParam);
  };

  const handleSearchOptionChange = (option) => {
    const dupSearchOptions = cloneDeep(searchOptions);
    dupSearchOptions.forEach((searchOption) => {
      (searchOption.value === option.value) ? searchOption.selected = true : searchOption.selected = false;
    })
    setSearchOptions(dupSearchOptions);
    setQueryByEmployee(option.value === 1);
  };

  const downloadGuide = () =>{
    pushToGA({ ...events.click_link_dl_guide() });
  }
  return (
    <DashboardWrapper>
      {!isIrregular && <Row mt={52} mb={-32} mr={0} ml={["0px", "-0.5rem"]}>
        <Col
          xs
          display="flex"
          justifyContent="flex-end"
          pr={["10px", "0.5rem"]}
          pl={["10px", "0.5rem"]}
          mt={isTrialExpired() ? '40px' : 0}
        >
          {currentUser.privilege !== "normal" &&
            calendars.teamCalendarLimit != null &&
            calendars.teamCalendarLimit > 0 &&
            currentTab !== types.TEMPLATE && (
              <CountWrapper>
                <FormattedMessage
                  {...messages.count}
                  values={{
                    count: calendars.teamCalendarCount,
                    limit: calendars.teamCalendarLimit,
                  }}
                />
              </CountWrapper>
            )}
          {creationButton()}
        </Col>
      </Row>}
      <Row mt={-40} mb={40} mr={0} ml={["0px", "-0.5rem"]}>
        {(!isMobileView)  && (
          <GuideDownload 
            href='https://drive.google.com/drive/u/0/folders/1Nm8i2Kj0F0q-0IoM7_5Nun7sb6oI_ipg' 
            target='_blank'
            onClick={() => downloadGuide()}>
            <BeginnerGuideContainer>
              <BeginnerIcon src={beginnerIcon} />
              <FormattedMessage {...messages.guideDownload} />
            </BeginnerGuideContainer>
          </GuideDownload>
        )}
      </Row>
      <Row
        mt={isIrregular ? 52 : isPaidOrTrialPlan() ? 0 : 44}
        justifyContent="space-between"
        mr={["0px", "-0.5rem"]}
        ml={["0px", "-0.5rem"]}
      >
        <Col xs pr={["0px", "0.5rem"]} pl={["0px", "0.5rem"]}>
          <Tabs
            query
            queryValue={query}
            onQueryChange={handleQuerySearch}
            onQueryClick={handleQueryClick}
            placeholder={props.intl.formatMessage({
              ...messages.searchPlaceholder,
            })}
            tab={tab}
            setTab={setTab}
            querying={querying}
            setQuerying={setQuerying}
            tabWidth={["100%", "fit-content"]}
            customStyle={{ mr: ["22px", "50px"] }}
            iconPosition={["0px", "auto"]}
            customTabStyle={{ ml: ["10px", "0px"] }}
            customInputOptionsStyle={{ height: "40px", width: isMobileView? "100px" : "160px", border: "none !important" }}
            searchOptions={searchOptions}
            defaultSearchOption={isQueryByEmployee ? searchOptions[1] : searchOptions[0]}
            forceShow={isQueryByEmployee ? (!querying || (querying && isEmpty(query) && selectedEmployees.length <= 0)) : (!query ||
              (query && !querying) ||
              (query && querying && isEmpty(query))
            )}
            onSearchOptionChange={handleSearchOptionChange}
            customInputStyle={{
              ml: ["10px", "0px"],
              width: isMobileView ?  ["calc(100% - 140px)", "calc(100% + 41px)"] : ["calc(100% - 5%)", "calc(100% + 41px)"],
            }}
            customIconStyle={{ mr: ["130px", "141px"], zIndex: 1 }}
            customTabSideStyle={{ right: `${isMobileView ? "0px" : "unset"}`, width: "unset" }}
            tabSideComponent={
              currentTab !== types.TEMPLATE ? <InputSelectWithIcon
                name="InputSelectWithIcon"
                options={sortOptions}
                isClearable={false}
                isMulti={false}
                defaultValue={selectedSortOption}
                value={selectedSortOption}
                backgroundColor="transparent"
                border='0'
                width={isMobileView ? "120px" : "140px"}
                onChange={(e) => handleSortOptionChange(e) }
              /> : null
            }
          >
            <Tab
              name={props.intl.formatMessage({ ...messages[isIrregular ? 'forStaffRegistration' : 'participating'] })}
              onTabChange={() => handleTabChange(types.ATTENDEE)}
            >
              <TabContent
                tabCalendar={calendars.attendee}
                isLoading={querying && isEmpty(query) ? false : isLoading}
                handleFetchMoreCalendars={handleFetchMoreCalendars}
                tab={types.ATTENDEE}
                {...props}
              />
            </Tab>
            {!isIrregular && <Tab
              name={props.intl.formatMessage({ ...messages.coordinating })}
              onTabChange={() => handleTabChange(types.EDITOR)}
            >
              <TabContent
                tabCalendar={calendars.editor}
                isLoading={querying && isEmpty(query) ? false : isLoading}
                handleFetchMoreCalendars={handleFetchMoreCalendars}
                tab={types.EDITOR}
                {...props}
              />
            </Tab>}
            {(!isMobileView && isSchedulingAdmin()) && (
              <Tab
                name={props.intl.formatMessage({ ...messages.all })}
                onTabChange={() => handleTabChange(types.ALL)}
              >
                <TabContent
                  tabCalendar={calendars.all}
                  isLoading={querying && isEmpty(query) ? false : isLoading}
                  handleFetchMoreCalendars={handleFetchMoreCalendars}
                  tab={types.ALL}
                  {...props}
                />
              </Tab>
            )}
            {!isMobileView && isTemplateEnabledAndAdmin && (
              <Tab
                name={props.intl.formatMessage({...messages.template})}
                onTabChange={() => handleTemplateTabChange()}
                noQuery
              >
                <TemplateContent
                  tabTemplate={templates}
                  isLoading={querying && isEmpty(query) ? false : isLoading}
                  handleFetchMoreTemplates={handleFetchMoreCalendars}
                  type={types.TEMPLATE}
                  {...props}
                />
              </Tab>
            )}
          </Tabs>
          {querying && !isEmpty(query) && !isQueryByEmployee && (
            <TabContent
              tabCalendar={calendars.query}
              isLoading={isEmpty(query) ? false : isLoading}
              handleFetchMoreCalendars={handleFetchMoreCalendars}
              tab={types.QUERY}
              {...props}
            />
          )}
          {querying && (!isEmpty(query) || selectedEmployees.length > 0) && isQueryByEmployee && (
            <>
              <UserBadge
                employees={selectedEmployees}
                setEmployees={setSelectedEmployees}
                handleFetchMoreCalendars={handleFetchMoreCalendars}
              />
              <SelectionDropdown
                loading={isEmployeeLoading}
                employees={searchEmployeesResult}
                selectedEmployees={selectedEmployees}
                setSelectedEmployees={setSelectedEmployees}
                setShow={setQueryByEmployeeList}
                show={showQueryByEmployeeList}
                handleFetchMoreCalendars={handleFetchMoreCalendars}
              />
              <TabContent
                tabCalendar={calendars.q_employee}
                isLoading={isEmpty(query) ? false : isLoading}
                handleFetchMoreCalendars={handleFetchMoreCalendars}
                tab={types.QUERY_EMPLOYEE}
                {...props}
              />
            </>
          )}
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

Dashboard.defaultProps = {
  options: [],
};

export default injectIntl(Dashboard);
