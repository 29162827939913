import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  Row,
  Col,
  Label,
} from "@d-lighted/design-system";
import messages from "../i18n/MustAttendArea";
import OptionalAttendeesSettings from "./OptionalAttendeesSettings";

const HeaderAndOptions = (props) => {
  const { ind } = props;
  return (
    <>
      <Row mt="15px">
        <Col xs display="flex" justifyContent="left">
          <Label fontSize="13px">
            {ind === 0 &&
              <FormattedMessage {...messages.mustAttendees} />
            }
            {ind === 1 &&
              <FormattedMessage {...messages.optionalAttendees} />
            }
            {ind === 2 &&
              <FormattedMessage {...messages.editors} />
            }
          </Label>
        </Col>
      </Row>
      {ind === 1 &&
        <OptionalAttendeesSettings {...props} /> 
      }
    </>
  );
};

export default injectIntl(HeaderAndOptions);
