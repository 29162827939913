/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader, ErrorText } from "@d-lighted/design-system";
import styled from "styled-components";

import { Center } from "../../../../components/preview/Helper";
import { CalendarHeader } from "./CalendarHeader";
import { CalendarBody } from "./CalendarBody";
import { getCalendarOptions, getThisWeek, moment } from "../../../../components/preview/momentUtils";
import { useAvailableTimeSlots } from '../../hooks/useAvailableTimeSlots';
import CalendarNotIntegrated from "../../../../components/preview/CalendarNotIntegrated";

const Container = styled.div`
  margin: 41px;
  padding: 35px 50px;
  border-radius: 6px;
  opacity: 1;
  background-color: white;
`;

const Wrapper = styled.div`
  padding-top: 71px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  line-height: 1.6;
  color: #314143;
`;

function BigCalendar(props) {
  const {
    appointment,
    handleChange,
    businessOpeningTime,
    businessClosingTime,
    isPreview,
    dropdownOnConfirm,
  } = props;
  const { data } = useSelector((state) => state.calendarSetting);
  const { duration, calendarDetail } = appointment;

  const bookingArgs = {
    businessOpeningTime: businessOpeningTime,
    businessClosingTime: businessClosingTime,
    duration: 30,
    holidayBlocking: data.holidayBlocking,
    minSchedulableTime: data.minSchedulableTime,
    maxSchedulableTime: data.maxSchedulableTime,
  };

  let newDate = moment();
  const multiguestWeek = getThisWeek(moment(appointment.schedulableFrom));

  const [options, setOptions] = useState(
    getCalendarOptions({
      ...props,
      ...bookingArgs,
      currentDate: newDate,
    }),
  );

  const {
    availableTimeSlots: timeSlots,
    actions,
    isLoading,
  } = useAvailableTimeSlots();

  useEffect(() => {
    actions.resetTimeSlots();
  }, []);

  const fetchWeek = (minTime, maxTime) => {
    actions.fetchAvailableTimeSlots(minTime, maxTime, duration, calendarDetail?.uid);
  };


  const getNextWeek = () => {
    actions.resetTimeSlots();

    setOptions({
      ...options,
      currentWeek: options.getNextWeek(options.currentWeek.selectedDate, bookingArgs.holidayBlocking),
    });
  };

  const getPrevWeek = () => {
    actions.resetTimeSlots();
    setOptions({
      ...options,
      currentWeek: options.getPreviousWeek(options.currentWeek.selectedDate, bookingArgs.holidayBlocking),
    });
  };

  const onChange = (e, date, currentTime) => {
    e.preventDefault();
    let selected = {
      year: date.year(),
      month: date.format("M"),
      date: date.date(),
      day: date.day(),
      time: currentTime,
      nextHour: moment(currentTime, "HH:mm")
        .add(bookingArgs.duration, "minutes")
        .format("HH:mm"),
      duration: bookingArgs.duration,
    };
    handleChange(selected);
  };

  useEffect(() => {
    // !options.currentWeek.week[0].isSame(options.lastWeek, 'day')
    fetchWeek(
      options.currentWeek.week[0].toISOString(true),
      options.currentWeek.week[
        options.currentWeek.week.length - 1
      ].toISOString(true),
    );
  }, [options?.currentWeek, appointment?.duration, calendarDetail?.uid]);

  useEffect(() => {
    if(newDate.isBefore(multiguestWeek.selectedDate)){
      const newWeek = multiguestWeek;
      setOptions({
        ...options,
        lastWeek: newWeek.week[0],
        currentWeek: newWeek,
      });
    }
  }, [appointment]);

  if (timeSlots.hasError) {
    return (
      <Wrapper>
        { timeSlots.error.code === "E13028" ? <CalendarNotIntegrated/> :
          <ErrorText dangerouslySetInnerHTML={{__html: timeSlots.error.message}} />
        }
      </Wrapper>
    );
  }

  if (!timeSlots.isSuccess || isLoading || !appointment?.schedulableFrom) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Container>
      <CalendarHeader
        options={options}
        getPrevWeek={getPrevWeek}
        getNextWeek={getNextWeek}
        isTimeSlotLoading={!timeSlots.isSuccess}
        timeSlotsHasError={timeSlots.hasError}
      >
      <CalendarBody
        options={options}
        isTimeSlotLoading={!timeSlots.isSuccess}
        timeSlotsHasError={timeSlots.hasError}
        timeSlots={timeSlots}
        onChange={onChange}
        multiguestCalendarDetail={appointment}
        isPreview={isPreview}
        duration={duration}
        minSchedulableTime={bookingArgs.minSchedulableTime}
        maxSchedulableTime={bookingArgs.maxSchedulableTime}
        dropdownOnConfirm={dropdownOnConfirm}
        businessWeekDays={Object.keys(data.businessTimes).map(i => data.businessTimes[i].enabled)}
      />
      </CalendarHeader>
    </Container>
  );
}

BigCalendar.defaultProps = {
  isCalendarDetailLoading: false,
  timeSlotsHasError: false,
  timeSlotsError: "",
  calendarDetailHasError: false,
  timeSlots: { availableTimeslots: [], isSuccess: true },
  handleChange: (date, currentTime) => {
    console.log(date, currentTime);
  },
};

export default BigCalendar;
