import React from 'react';
import styled from "styled-components";

import { TableBodyDivItem }from '../TableBodyItem';
import Text from '../Text';

const CustomFieldHeader = styled.div`
  text-align: left;
  color: #67878d;
  font-size: 13px;
  height: 40px;
  width: max-content;
`;

function getCustomFieldsValue(visitor) {
  let result = {};
  let customFieldId = 0;

  if (visitor.booking_calendar_custom_field_inputs?.length) {
    visitor.booking_calendar_custom_field_inputs.forEach(cf => {
      if (customFieldId === cf.booking_calendar_custom_field_id) {
        result[cf.label_name].push(cf.input_text_area_value || cf.input_value || cf.input_date_value);
      } else {
        result[cf.label_name] = [cf.input_text_area_value || cf.input_value || cf.input_date_value];
      }
      customFieldId = cf.booking_calendar_custom_field_id;
    })
  }
  return result;
}

function displayCustomFields(customFieldsObject, uid = '') {
  let result = [];
  for (let field of Object.keys(customFieldsObject)) {
    let labelName = field;
    result.push(
      <TableBodyDivItem first key={labelName}>
        <CustomFieldHeader>
          {labelName}
        </CustomFieldHeader>
        <Text>
          {customFieldsObject[field].length === 1 ?
            customFieldsObject[field] : (
              <ul key={`ul-${uid}-${labelName}`}>
                {customFieldsObject[field].map((value) => <li key={`ul-${labelName}-${value}`}>{value}</li>)}
              </ul>
            )
          }
        </Text>
      </TableBodyDivItem>
    );
  }
  return result;
}

export { getCustomFieldsValue, displayCustomFields }
