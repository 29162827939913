import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Label as BaseLabel } from "@d-lighted/design-system";
import { moment } from "../../../../components/preview/momentUtils";
import { useSelector } from "react-redux";

const LabelWrapper = styled.div`
  margin-bottom: 8px;
`;

const Label = styled(BaseLabel)`
  font-size: 13px;
  margin-bottom: 8px;
`;

const ParticipantsList = styled.div`
  margin-bottom: 16px;
  color: #484f63;
  font-size: 15px;
`;

const Inhouse = styled.div`
  margin-top: 24px;
  padding-top: 5px;
  border-top: 1px solid #e5e5e5;
`;

function isOverlappingSlot(slot, currentStart, currentEnd) {
  const startTime = moment(slot.startTime);
  const endTime = moment(slot.endTime);

  return (
    (currentStart.isBetween(startTime, endTime, 'minute', '[)') &&
    currentEnd.isBetween(startTime, endTime, 'minute', '(]')) ||
    startTime.isBetween(currentStart, currentEnd, 'minute', '[)') ||
    endTime.isBetween(currentStart, currentEnd, 'minute', '(]')
  );
}

function removeDuplicateEvent(events) {
  return events.filter((event, index, self) => self.findIndex(e => e.eventName === event.eventName) === index);
}

function getOverlayingEvents(currentStart, currentEnd, busySlots = []) {
  let result = [];
  const overlayingEvents = busySlots?.filter(slot => isOverlappingSlot(slot, currentStart, currentEnd));
  if (overlayingEvents.length) {
    result = removeDuplicateEvent(overlayingEvents);
  }
  return result
}

export default function Dropdown({ dropdownOnConfirm, guests, overlaySlot, day, currentTime, busySlots, attendees, mustAttendees, currentStart, currentEnd, disabled }) {
  const [availableAttendees, setAvailableAttendees] = useState([]);
  const [overlayingEvents, setOverlayingEvents] = useState([]);
  const { data } = useSelector(state => state.calendarSetting);
  const attendanceRule = data.attendanceRule;
  const optionalAttendeesByPriority = data.optionalAttendeesSelectionRule === "priority_order";
  const optionalAttendeesNumber = data.optionalAttendeesNumber;
  const externalWebConf = data.meetingType === "web_conference" && ["zoom", "webex"].includes(data.webConferenceType);

  useEffect(() => {
    if (!disabled) {
      const unavailableEmployee = [...new Set(busySlots?.filter(slot => isOverlappingSlot(slot, currentStart, currentEnd)).map(slot => slot.attendeeUid))]
      const availableEmployees = (attendees || []).filter(attendee => !(unavailableEmployee || []).includes(attendee.uid));
      const availableMustEmployees = (mustAttendees || []).filter(attendee => !(unavailableEmployee || []).includes(attendee.uid));
      const externalIntegratedEmployees = availableEmployees.filter(attendee =>  data.webConferenceType === "zoom" ? attendee.authorizedSchedulers.zoom : attendee.authorizedSchedulers.webex);

      if (attendanceRule === "one_will_attend") {
        externalWebConf ? setAvailableAttendees([externalIntegratedEmployees[0]]) : setAvailableAttendees([availableEmployees[0]]);
      }
      else if(attendanceRule === "random_one_will_attend") {
        externalWebConf ? setAvailableAttendees(externalIntegratedEmployees) : setAvailableAttendees(availableEmployees);
      }
      else if(attendanceRule === "custom_will_attend") {
        if(optionalAttendeesByPriority) {
          setAvailableAttendees(availableMustEmployees.concat(availableEmployees.splice(0, optionalAttendeesNumber)));
        }
        else {
          setAvailableAttendees(availableMustEmployees.concat(availableEmployees));
        }
      }
      else {
        setAvailableAttendees(availableEmployees);
      }

    }
    if (overlaySlot) {
      setOverlayingEvents(getOverlayingEvents(currentStart, currentEnd, busySlots));
    }

    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [busySlots]);
  return (
    <>
      <div>
        <LabelWrapper><Label>参加可能なお客様</Label></LabelWrapper>
        {guests.map((guest, index) => (
          <ParticipantsList key={index}>
            {guest.companyName
              ? `${guest.name} 様 - ${guest.companyName}`
              : `${guest.name} 様`}
          </ParticipantsList>
        ))}
      </div>
      <Inhouse>
        <LabelWrapper><Label>{overlaySlot ? "重複している予定" : "参加可能な社内メンバー"}</Label></LabelWrapper>
        { overlaySlot ?
          overlayingEvents.map((event, index) => (
            <ParticipantsList key={index}>{event.eventName}</ParticipantsList>
          ))
        :
          availableAttendees.map((host) => (
            <ParticipantsList key={host?.uid}>{host?.name}</ParticipantsList>

          ))
        }
      </Inhouse>
      <Button mt="18px" height="44px" disabled={overlaySlot} onClick={() => dropdownOnConfirm(day, currentTime, guests, availableAttendees)}>この日時で確定する</Button>
    </>
  )
}
