import { defineMessages } from 'react-intl';

const planBlockedMessages = defineMessages({
  standard: {
    id: 'components.layouts.blocked.standard',
    defaultMessage: 'You can use this feature by <a>subscribing to a paid plan.</a>',
  },
  enterprise: {
    id: 'components.layouts.blocked.enterprise',
    defaultMessage: 'You can use this feature by <a>subscribing to enterprise or above plan.</a>',
  },
  premium: {
    id: 'components.layouts.blocked.premium',
    defaultMessage: 'You can use this feature by <a>subscribing to premium plan.</a>',
  },
  sfNotLinked: {
    id: 'components.layouts.blocked.notLinked',
    defaultMessage: 'The Salesforce integration has not been completed.</br> Please go to the <a>Visitor Settings page</a> and perform the Salesforce integration.',
  },
  teamOnlyAllowed: {
    id: 'components.layouts.blocked.teamOnly',
    defaultMessage: 'This function is not available on the booking page for individuals. To use this function, go to "Meeting" > "Members" and switch the booking page to "Team".',
  },
  slackOnlyAllowed: {
    id: 'components.layouts.blocked.slackOnly',
    defaultMessage: 'This feature is only available for Slack on the personal reservation page.</br> Please go to <a>Chat Settings page</a> to link your chat.',
  },
  chatToolsNotLinked: {
    id: 'components.layouts.blocked.chat',
    defaultMessage: 'This function is only available for Slack, Chatwork, Google Chat and Microsoft Teams.{br}Please go to the <a>Chat Settings page</a> to set up chat integration.'
  },
  sfFieldMatchError: {
    id: 'components.layouts.blocked.sfFieldMatchError',
    defaultMessage: 'The types of choices do not match on the reservation page side and the Salesforce side. For more information, please see <a>here</a>'
  },
  isEnterpriseEmailCustomizations: {
    id: 'components.layouts.blocked.onlyEnterprisePlusEmailCustomizations',
    defaultMessage: 'Subscribe to Enteprise or higher plan for more email customizations. For more information <a>click here</a>'
  },
  customAttendanceRuleBlocked: {
    id: 'components.layouts.blocked.customAttendanceRuleBlocked',
    defaultMessage: 'Customisation rule is available by subscribing to the Enterprise Plan and above. For more information <a>click here</a>'
  }
});

export default planBlockedMessages;
