import styled from "styled-components";
import { fontSize } from 'styled-system';

import { MOBILEBREAKPOINT } from "../../../constants/breakpoint";

export const Link = styled.a`
  padding-top: 15px;
  float: left;
  color: #00BBB5;
  text-decoration: none;
  cursor: pointer;

  @media (max-width:${MOBILEBREAKPOINT}){
    font-size: ${props => props.fontSize};
    font-weight: 600;
    line-height: 18px;
    font-family: Fira Sans;
    font-style: normal;
    text-align: ${props => props.textAlign};
 }
`;

Link.defaultProps = {
  fontSize: '15px',
};

Link.propTypes = {
  ...fontSize.propTypes,
};
