import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from "reselect";
import { default as qS } from "query-string";
import { 
  Row,
  Col,
  InputWithLabel,
  Button,
  Label,
  Loader
} from "@d-lighted/design-system";
import styled from "styled-components";
import { Link } from "../../components/Link";
import { useValidation } from "../../../../utils/validation";
import { OnBoardingDashboard } from "../../../../components/layouts/OnBoardingDashboard";
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../../../constants/breakpoint";
import GoogleIcon from '../../../../assets/img/google_icon.svg';
import MicrosoftIcon from '../../../../assets/img/microsoft_icon.svg';
import { useOauth } from "../../hooks/useOauth";
import { useAuth } from "../../../../hooks/useAuth";
import { getCurrentUser, isProductSchedulingOrganization } from "../../../../utils/userUtils";
import { 
  saveAuthorizationInfo, 
  getAuthorizationInfo, 
  capitalizedString, 
  leaveOnboarding,
  toOnboardingPage,
} from "../../utils/utils";
import { Center } from "../../components/Center";

const SocialButton = styled(Button)`
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D3D9E5;
  border-radius: 5px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  display: flex;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  height: 43px;
  justify-content: center;
  line-height: 22px;
  padding: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const SocialImage = styled.img`
  width: 24px;
  height: 24px;
  padding-right: 20px;
`;

const Wrapper = styled.div`
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MOBILEBREAKPOINT}){
    justify-content: flex-start;
  }
`;

const formSelector = state => getFormValues('Registration')(state);

const Registration = (props) => {
  const { intl, handleSubmit, useNotify } = props;
  const notify = useNotify(intl);
  const params = qS.parse(window.location.search);
  const { required, emptyString, inputLength, password, passwordLength } = useValidation(intl);
  const [confirmationInfo, setConfirmation] = useState(getAuthorizationInfo());
  const [ registerNewUserManually, setRegisterNewUserManually ] = useState(confirmationInfo?.registerNewUserManually || false);
  const { actions, isSettingUpdated, calendarUpdateSucceeded, hasUpdateError, isLoading, onBoardingCalendarSetting, resetPasswordAllowed } = useOauth(notify);
  const capitalizedScheduler = capitalizedString(confirmationInfo?.scheduler || '');
  const invitedEmployeeName = confirmationInfo?.inviter || '招待元社員名';
  const isSchedulerGaroon = capitalizedScheduler === "Garoon";
  const isInviteeAttendee = confirmationInfo?.attendee === "1";
  const { authActions } = useAuth();

  const resetUrl = () => {
    Object.keys(params).forEach(key => delete params[key]);
    props.history.replace({ search: new URLSearchParams(params).toString() });
  }

  const formattedBody = (code) => {
    const formValues = getAuthorizationInfo();
    let body = {
      email: formValues?.email,
      name: formValues?.name,
      scheduler: formValues?.scheduler,
      code
    }
    return body;
  };

  const toWebApp = async() => {
    const url = await authActions.getOneTimeAccessUrl('/info/integrations', 'tutorial=scheduling_onboarding');
    return window.location.href = url;
  };

  useEffect(() => {
    if(params?.account_confirmation_success && params?.email) {
      // when user clicked invitation link
      const requiredParams = { email: params?.email, scheduler: params?.scheduler, provider: params?.provider, attendee: params?.attendee, inviter: params?.inviter };
      saveAuthorizationInfo(requiredParams);
      setConfirmation(requiredParams);
      return resetUrl();
    }
    const state = params?.state;
    if(["outlook_auth", "google_oauth"].includes(state)) {
      const schedulerName = state.split("_")[0];
      // after OAuth
      if(params?.error) {
        notify.setError("認証エラーが発生しました。", `${capitalizedString(schedulerName)}のトークンがありません。お手数ですが、再度連携後、もう一度お試しください。`);
        return resetUrl();
      }
      if(params?.code) {
        const body = formattedBody(params?.code);
        actions.startAuthentication(body);
        return resetUrl();
      }
    }
    if(!confirmationInfo?.email && Object.keys(params).length === 0) {
      props.history.replace("/sign_in");
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [window?.location]);

  useEffect(() => {
    if(isSettingUpdated) {
      if(!hasUpdateError) {
        actions.getInviatedCalendarSetting();
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isSettingUpdated, hasUpdateError]);

  useEffect(() => {
    if(getCurrentUser()?.email === confirmationInfo?.email && calendarUpdateSucceeded) {
      const data = onBoardingCalendarSetting?.data;
      if(data) {
        const attendee = data.members?.find(member => member?.email === getCurrentUser()?.email)?.attendee || false;
        const isPasswordSignup = registerNewUserManually || isSchedulerGaroon;
        saveAuthorizationInfo({});

        if(isProductSchedulingOrganization()) return toOnboardingPage(props, "employee_booking_page");
        if(attendee && !isPasswordSignup && data.meetingType === "web_conference" && ["zoom", "webex"].includes(data.webConferenceType))
          return toOnboardingPage(props, "employee_integrations");
        if(attendee && isPasswordSignup)
          return toWebApp();

        return toOnboardingPage(props, "employee_booking_page");
      } else {
        // when new employee is not associated to any booking calendar
        return leaveOnboarding(props);
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [onBoardingCalendarSetting, calendarUpdateSucceeded]);


  useEffect(() => {
    props.initialize({
      employeeName: null || confirmationInfo?.name,
      employeePassword: null,
    });
    if(isSchedulerGaroon && !registerNewUserManually) toggleRegisterNewUserManually();
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  },[]);

  function formatBody(values, type) {
    let body = {};
    if(type === "auth") {
      body = {
        email: confirmationInfo?.email,
        password: values.password
      };
    }
    if(type === "reset") {
      body = {
        employee: {
          password: values.newPassword,
          name: values.employeeName
        }
      };
    }
    return body;
  }

  const isMobileView = SHOW_MOBILE(window.innerWidth);

  const onSubmit = async(values, provider) => {
    if(provider === "email") {
      if(!values?.newPassword) {
        // provider: email
        const body = formatBody(values, "auth");
        return actions.firstSignIn(body);
      }
      if(values?.newPassword) {
        // reset temp password
        const body = formatBody(values, "reset");
        return actions.updateEmployeeInfo(body);
      }
    }
    // provider: oauth
    const details = getAuthorizationInfo();
    details["name"] = values.employeeName;
    saveAuthorizationInfo(details);
    actions.startOAuth(confirmationInfo?.scheduler, `${window.location.origin}/onboarding/employee/signup`);
  }

  const toggleRegisterNewUserManually = () => {
    const details = getAuthorizationInfo();
    details["registerNewUserManually"] = !registerNewUserManually;
    saveAuthorizationInfo(details);
    setRegisterNewUserManually(!registerNewUserManually);
  };

  const selectedSocialIcon = () => {
    switch(confirmationInfo?.scheduler) {
      case "google":
        return GoogleIcon;
      case "outlook":
        return MicrosoftIcon;
      default:
        return "";
    }
  }

  if(isLoading) {
    return(
      <Center>
        <Loader/>
      </Center>
    )
  }

  return (
    <OnBoardingDashboard top="0">
      <Wrapper>
        <Row mt={isMobileView ? "30px" : "0"}>
          <Col xs>
            <Label
              fontSize="24px"
              fontWeight="700"
              letterSpacing="1px"
              color="#484F63"
            >
              {isInviteeAttendee ?
                <p>
                  {invitedEmployeeName}さんとチームで日程調整を行うために、まずはあなたの仕事用カレンダーと連携しましょう！
                </p>
              :
                <p>
                  {invitedEmployeeName}さんの日程調整を代理で行うための準備をしましょう
                </p>
              }
            </Label>
          </Col>
        </Row>
        <Row mt="17px">
          <Col xs>
            <Label 
              fontSize="15px"
              fontWeight="400"
            >
              {isInviteeAttendee ?
                <p>
                  あなたのカレンダーから空き状況を抽出し、{invitedEmployeeName}さんの空き状況と合わせた予約ページを作ることができます
                </p>
              :
                <p>
                  あなたの氏名を入力し、登録を完了させてください
                </p>
              }
            </Label>
          </Col>
        </Row>
        {(!registerNewUserManually && !isSchedulerGaroon) ?
          <>
            <Row mt="17px">
              <Col xs>
                <Label fontSize="15px" color="#484F63" fontWeight="bold">
                  あなたの氏名
                </Label>
                <Field
                  id="employeeName"
                  name="employeeName"
                  component={InputWithLabel}
                  placeholder="氏名"
                  type="text"
                  validate={[required, emptyString, inputLength]}
                />
              </Col>
            </Row>
            <Row 
              mt="17px"
            >
              <Col xs>
                <SocialButton
                  fontWeight="500"
                  width={isMobileView ? "100%" : "311px"}
                  onClick={handleSubmit((values) => onSubmit(values, "oauth"))}
                >
                  <SocialImage src={selectedSocialIcon()} />
                  {capitalizedScheduler}アカウントと連携
                </SocialButton>
              </Col>
            </Row>
          </> 
        :
          !resetPasswordAllowed ?
            <>
              <Row 
                mt="17px"
                mb={"30px"} 
              >
                <Col xs>
                  <Label fontSize="15px" color="#484F63" fontWeight="bold">
                    仮パスワード
                  </Label>
                  <Label 
                    fontSize="15px"
                    fontWeight="400"
                  >
                    <p style={{ paddingTop: '12px' }}>
                      メールに記載されている仮パスワードを入力してください
                    </p>
                  </Label>
                  <Field
                    id="password"
                    name="password"
                    component={InputWithLabel}
                    placeholder="●●●●●"
                    type="password"
                    validate={[required, inputLength]}
                  />
                </Col>
              </Row>
              <Row mt="17px">
                <Col xs>
                  <Button
                    fontWeight="500"
                    width={isMobileView ? "100%" : "311px"}
                    onClick={handleSubmit((values) => onSubmit(values, "email"))}
                  >
                    次へ
                  </Button>
                </Col>
              </Row>
            </>
          :
            <>
              <Row mt="17px">
                <Col xs>
                  <Label fontSize="15px" color="#484F63" fontWeight="bold">
                    あなたの氏名
                  </Label>
                  <Field
                    id="employeeName"
                    name="employeeName"
                    component={InputWithLabel}
                    placeholder="氏名"
                    type="text"
                    validate={[required, emptyString, inputLength]}
                  />
                </Col>
              </Row>
              <Row mt="17px">
                <Col xs>
                  <Label fontSize="15px" color="#484F63" fontWeight="bold">
                    新しいパスワード
                  </Label>
                  <Label 
                    fontSize="15px"
                    fontWeight="400"
                  >
                    <p style={{ paddingTop: '12px' }}>
                      8文字以上（英大文字・英小文字・数字・特殊記号のうち3種類以上）で設定してください
                    </p>
                  </Label>
                  <Field
                    id="newPassword"
                    name="newPassword"
                    component={InputWithLabel}
                    placeholder="●●●●●"
                    type="password"
                    validate={[required, emptyString, inputLength, passwordLength, password]}
                  />
                </Col>
              </Row>
              <Row mt="21px">
                <Col xs>
                  <Button
                    fontWeight="500"
                    width={isMobileView ? "100%" : "311px"}
                    onClick={handleSubmit((values) => onSubmit(values, "email"))}
                  >
                    次へ
                  </Button>
                </Col>
              </Row>
            </>
        }
        {!resetPasswordAllowed && !isSchedulerGaroon  && <Row mt="23px" mb={"20px"}>
          <Col xs style={{textAlign: "center"}} >
            {
              <Link textAlign="center" fontSize="11px" onClick={() => toggleRegisterNewUserManually()}>
                {
                  registerNewUserManually ? 
                    `または${capitalizedScheduler}アカウントで 新規登録する` : 
                    'またはパスワードで新規登録する'
                }
              </Link>
            }
          </Col>
        </Row>}
      </Wrapper>
    </OnBoardingDashboard>
  );
}

const mapStateToProps = createStructuredSelector({
  formValues: formSelector,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: 'Registration',
    initialValues: {
      employeeName: null,
    },
  }),
  injectIntl,
)(Registration);
