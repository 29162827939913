import { useState } from 'react';
import humps from "lodash-humps";
import {  _get } from "utils/api";

function useTemplatesAvailability() {
  const [availabilities, setAvailabilities] = useState();
  const [loading, setLoading] = useState(false);

  const getTemplatesAvailability = async () => {
    const url = `/booking_calendars/templates/availability`;
    setLoading(true);
    const result = await _get(url);

    setLoading(false);
    if (result.status < 200 || result.status >= 300) {
      setAvailabilities({ error: true });
    } else {
      const templates = humps(result.data?.templates)
      setAvailabilities(templates);
      return templates;
    }
    return  null;
  };
  
  return {
    availabilities, 
    loading, 
    getTemplatesAvailability
  };
}

export { useTemplatesAvailability };
