import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { OnClickOutside } from "hooks/onClickOutside";
import { Button } from "@d-lighted/design-system";

const ButtonDropdownWrapper = styled.div``;

const Wrapper = styled.div`
  position: absolute;
  display: block;
  padding: 24px 20px 14px 20px;
  background-color: #ffffff;
  box-shadow: 0 20px 26px 0 rgba(63, 78, 90, 0.14);
  overflow: hidden;
  z-index: 3;
  border-radius: 12px;
  right: 0;
  top: 52px;
  width: ${({isMobileView}) => isMobileView ? 'calc(100vw + -56px)' : '500px'};
`;

const CreationDropdown = ({ buttonContent, children, bpr, bpl, onClick, isMobileView }) => {
  const wrapperRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function toggleDropdown() {
    onClick();
    setDropdownOpen(!dropdownOpen);
  }

  OnClickOutside(wrapperRef, setDropdownOpen);

  return <ButtonDropdownWrapper ref={wrapperRef}>
    <Button
      mb={["64px", 0]}
      width="auto"
      height="44px"
      pr={bpr}
      pl={bpl}
      onClick={() => toggleDropdown()}
    >
      { buttonContent }
    </Button>
    {dropdownOpen && (
      <Wrapper isMobileView={isMobileView}>
        { children }
      </Wrapper>
    )}
  </ButtonDropdownWrapper>
};

CreationDropdown.defaultProps = {
  onClick: () => {},
  bpr: null,
  bpl: null,
  isMobileView: false,
}

CreationDropdown.propTypes = {
  buttonContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  bpr: PropTypes.string,
  bpl: PropTypes.string,
  onClick: PropTypes.func,
  isMobileView: PropTypes.bool
}

export default CreationDropdown;