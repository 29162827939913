import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from 'react-intl';
import { IconNew as Icon} from '@d-lighted/design-system';
import { pushToGA, events } from '../../utils/gtmUtils';
import { isAccessAllowed, planBlockedOpenUrl } from "./utils/PlanBlockedHelper";
import planBlockedMessages from "./i18n/PlanBlocked";

const Blocked = styled.div`
  pointer-events: none;
  user-select: none;
  opacity: 0.2;
  
  & * {
    pointer-events: none !important;
    user-select: none !important;
  }
`;

const Warning = styled.div`
  background-color: #e6f4ef;
  border-radius: 5px;
  box-sizing: border-box;
  color: #68878d;
  font-family: NotoSansJP, sans-serif;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.5;
  padding: 13px 12px 13px 36px;
  text-align: left;
  margin-top: ${props => props.mt};
  margin-bottom: 8px;
  max-width: ${props => (props.width ? props.width : '100%')};
  
  & a {
    color: #00bbb5;
  }
`;

const InfoIcon = styled(Icon)`
  position: absolute;
  margin-left: -24px;
`;

export function PlanBlocked({messageId, plan, showDefaultMessage, children, mt, width}) {
  const allowAccess = isAccessAllowed(plan);
  
  if(allowAccess && !messageId) {
    return <>{children}</>;
  }

  if(showDefaultMessage) {
    messageId = !allowAccess ? plan : messageId;
  }
  const message = planBlockedMessages[messageId];

  const openExternalLink = (event) => {
    event.preventDefault();
    planBlockedOpenUrl(messageId).then(openUrl => {
      if(openUrl.includes('contact-plan')) {
        pushToGA({...events.click_link_lp("/contact-plan")});
      }
      window.open(openUrl, "_blank");
    });
  }

  return (
    <>
      <Warning mt={mt} width={width}>
        <InfoIcon
          className="material-icons"
          fontSize="17px"
          color="#9DDBD6"
        >
          info
        </InfoIcon>
        <FormattedMessage 
          {...message}
          values={{
            br: <br/>,
            a: chunks => (
              <a target="_blank" rel="noopener noreferrer" href="#/" onClick={(e) => openExternalLink(e)}>
                {chunks}
              </a>
            )
          }}
        />
      </Warning>
      <Blocked ref={node => node &&  node.setAttribute('inert', '')} onClick="functionCall();event.stopPropagation();">
        {children}
      </Blocked>
    </>
  );
}

PlanBlocked.defaultProps = {
  mt: '0',
  plan: 'free',
  messageId: '',
  width: '100%',
  showDefaultMessage: true,
};

PlanBlocked.propTypes = {
  children: PropTypes.node.isRequired,
  plan: PropTypes.string,
  mt: PropTypes.string,
  messageId: PropTypes.string,
  width: PropTypes.string,
  showDefaultMessage: PropTypes.bool,
};
