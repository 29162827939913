import React from "react";
import styled from "styled-components";
import { IconNew as Icon } from "@d-lighted/design-system";
import { FormattedMessage } from 'react-intl';

import sortingImg from "../../../assets/img/ico_sorting.svg";
import RequiredMark from "../modules/RequiredMark";
import messages from "../i18n/InputScreenSetting";
import { optionsIcon } from "../utils/options";
import CheckboxIcon from "../../../components/elements/CheckboxIcon";
import { FieldImageWrapper, FieldWrapper, FieldImage, FieldLabel } from "../../../components/elements/BorderedField";

const IconEdit = styled(Icon)``;

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 15px 10px;
  display: flex;
  border-radius: 5px;
  width: 100%;
  border: solid 1px;
  border-color: ${({ isBlocked }) => isBlocked ? ' rgba(210, 233, 231, 0.6)' : ' rgba(210, 233, 231, 1)'};
  align-items: center;

  &:hover {
    border-color: ${({ isBlocked }) => isBlocked ? 'inherited' : '#00bbb5'};
  }

  & > * {
    opacity: ${({ isBlocked }) => isBlocked ? 0.6 : 1.0};
    ${({ isBlocked }) => isBlocked && `
    pointer-events: none;
    user-select: none;
  `}
  }

  & > ${IconEdit} {
    opacity: 1 !important;
    pointer-events: all;
    user-select: none;
  }
`;

const SortingImage = styled.img`
  cursor: move;
  margin-right: 20px;
`;

export default function SortableComponent({
  form,
  handleEditField,
  handleDeleteField,
  draggable,
  isBlocked,
}) {
  
  const fieldIcon = optionsIcon(form.fieldType); 

  return (
    <Wrapper isBlocked={isBlocked}>
      <SortingImage src={sortingImg} {...draggable}/>
      <FieldWrapper>
        <FieldImageWrapper>
          {form.fieldType === "check_box" ? <CheckboxIcon /> :
          <FieldImage src={fieldIcon}></FieldImage>}
        </FieldImageWrapper>
        <RequiredMark required={form.bookingCalendarSettingBookingCalendarCustomField?.inputRequired || false} />
        <FieldLabel>{form?.fieldType === 'hidden_field' 
          ? <FormattedMessage {...messages.hiddenField} /> 
          : form.labelName}
        </FieldLabel>
      </FieldWrapper>
      {!isBlocked && (
        <Icon
          isCursor
          onClick={(e) => handleDeleteField(e, form)}
          className="material-icons"
          color="red"
          fontSize="23px"
          mr="25px"
        >
          close
        </Icon>
      )}
      <IconEdit
        isCursor
        onClick={(e) => handleEditField(e, form)}
        className="material-icons"
        color="#00bbb5"
        fontSize="23px"
        mr="5px"
      >
        edit
      </IconEdit>
    </Wrapper>
  );
}
