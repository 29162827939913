import React from 'react';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 21px 0 0;
  width: 100%;
`;

const TooltipTimeWrapper = styled.div`
  padding-right: 20px;
`;

const TooltipTime = styled.p`
  font-family: Fira Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #00BBB5;
  border-radius: 5px;
  height: 47.4px;
  width: 79px;
`;

const TooltipTitle = styled.div`
  font-size: 15px;
  line-height: 24px;
`;

const TooltipSubtitle = styled.div`
  padding-top: 10px;
  font-weight: 100;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
`;

export default function PreviewTooltip({ time, tooltipHeader }) {
  return <TooltipWrapper>
    <TooltipTimeWrapper>
      <TooltipTime>
        {time}
      </TooltipTime>
    </TooltipTimeWrapper>
    <div>
      <TooltipTitle>
       {tooltipHeader}
      </TooltipTitle>
      <TooltipSubtitle>
        *以下の画像はイメージです
      </TooltipSubtitle>
    </div>
  </TooltipWrapper>
}
