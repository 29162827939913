import styled from 'styled-components';

const Icon = styled.img`
  width: ${props => props.width ? props.width : "42.7px"};
  height: ${props => props.height ? props.height : "30px"};
  margin-right: 5px;
  margin-left: ${props => props.ml ? props.ml : "15px"};
  position: relative;
  top: 2px;
`;

export default Icon;
