import { createAction, createReducer } from "@reduxjs/toolkit";

const INITIAL_STATUS = {
  templates: [],
  currentPage: 0,
  totalPages:0,
};

export const fetchTemplates = createAction('fetch_templates');

export const resetTemplates = createAction('reset_templates');

const calendarsReducer =  createReducer({ ...INITIAL_STATUS },{
    'fetch_templates': (state, action) => {
      return { ...state, ...action.payload };
    },
    'reset_templates': () => {
      return { ...INITIAL_STATUS };
    }
  }
);

export default calendarsReducer;
