import React from 'react';
import styled from 'styled-components';
import UserDefault from '../../assets/img/userDefault.png';
import { isMobileDevice } from '../../utils/mobileViewChecker';

const Wrapper = styled.div`
  top: ${props => props.isMobileView ? '170px' : '136px'};
  display: flex;
  height: auto;
  max-width: 100%;
  overflow-y: scroll;
`;

const UserWrapper = styled.div`
  position: relative;
  height: 23px;
  border-radius: 100px;
  border: solid 1px #eaf0f4;
  padding: 8px 40px 10px 60px;
  margin: 20px 20px 0 0;
  text-align: center;
  background-color: #FBFCFD;
  font-size: 14px;
  color: #00bbb5;
  > span {
    position: relative;
    top: 1px;
  }
`;

const Image40= styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const IconCancel = styled.i`
  position: absolute;
  display: inline-block;
  right: 14px;
  top: 0;
  color: #8291a3;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
`;

const Image = styled(Image40)`
  position: absolute;
  left: 1px;
  top: 0;
`;

const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  display: inline-block;
  margin-top: 3px;
`;

const UserBadge = (props) => {
  const { employees, setEmployees, handleFetchMoreCalendars } = props;
  const isMobileView = isMobileDevice();

  const handleRemoveEmployee = (index) => {
    const dupEmployees = [...employees];
    dupEmployees.splice(index, 1);
    setEmployees(dupEmployees);
    handleFetchMoreCalendars(1, undefined, dupEmployees);
  }

  if(Object.keys(employees).length === 0) {
    return <></>;
  }

  return (
    <Wrapper isMobileView={isMobileView}>
      {(employees || []).map((employee, index) => {
        return (
          <UserWrapper key={index}>
            <Image src={employee?.icon_uri?.url || UserDefault} />
            <Name>{employee.name}</Name>
            <IconCancel>✕</IconCancel>
            <IconCancel onClick={() => handleRemoveEmployee(index)}>✕</IconCancel>
          </UserWrapper>  
        )
      })}
    </Wrapper>
  )
}

UserBadge.propTypes = {};

export default UserBadge;