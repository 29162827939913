import ChatNotifications from "./pages/ChatNotifications";
import EmailNotifications from "./pages/EmailNotifications";
import CompleteEmailNotification from "./pages/CompleteEmailNotification";
import ReminderEmailNotification from "./pages/ReminderEmailNotification";
import { templatesEnabledAdminValidation } from "utils/userUtils";

export const notificationsRoutes = [
  {
    component: ChatNotifications,
    path: "/:settings(templates|booking_calendars)/:uid/edit/chat_notifications",
    exact: true,
    feature: "社内チャット",
    type: "private",
    gauid: 'Bp/ChatNotification',
    validation: templatesEnabledAdminValidation,
  },
  {
    component: EmailNotifications,
    path: "/:settings(templates|booking_calendars)/:uid/edit/email_notifications",
    exact: true,
    feature: "お客様へのメール",
    type: "private",
    gauid: 'Bp/EmailNotifications',
    validation: templatesEnabledAdminValidation,
  },
  {
    component: CompleteEmailNotification,
    path: "/:settings(templates|booking_calendars)/:uid/edit/email_notifications/booking_complete",
    exact: true,
    feature: "お客様へのメール",
    type: "private",
    gauid: 'Bp/EmailNotifications/Complete',
    validation: templatesEnabledAdminValidation,
  },
  {
    component: ReminderEmailNotification,
    path: "/:settings(templates|booking_calendars)/:uid/edit/email_notifications/reminder_email",
    exact: true,
    feature: "お客様へのメール",
    type: "private",
    gauid: 'Bp/EmailNotifications/Reminder',
    validation: templatesEnabledAdminValidation,
  },
];
