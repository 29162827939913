import  PropTypes from 'prop-types';
import visitorPropTypes from './visitorPropType';

const appointmentPropTypes = PropTypes.shape({
  appo_type: PropTypes.string,
  begin_at: PropTypes.string,
  begin_date: PropTypes.string,
  code: PropTypes.string,
  code_only: PropTypes.bool,
  display: PropTypes.bool,
  edited: PropTypes.bool,
  employee_id: PropTypes.number,
  employee_name: PropTypes.string,
  end_at: PropTypes.string,
  end_date: PropTypes.string,
  hosts: PropTypes.array, // eslint-disable-line
  is_host: PropTypes.bool,
  is_integrated: PropTypes.bool,
  place: PropTypes.string,
  resource_id: PropTypes.string,
  title: PropTypes.string,
  uid: PropTypes.string,
  visitors: PropTypes.arrayOf(visitorPropTypes),
  visitor_type_uid: PropTypes.string,
  visitor_type_label: PropTypes.string,
});

/**

  TODO:
  - later for definition of hosts

 */

export default appointmentPropTypes;
