/* eslint-disable  react-hooks/exhaustive-deps*/
import React, { useEffect, useState } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from "styled-components";
import {
  ImageUploader,
  Button,
  Input,
  InputWithLeftAddonAndLabel,
  Label,
  TextAreaWithLabel,
  TextArea,
  Loader,
  Col,
  Hr,
  Row,
  Tooltip
} from "@d-lighted/design-system/dist/index";
import { actions } from "redux/actions";
import { remove, useGet, put } from "utils/api";
import { copy } from "utils/copyUtils";
import { isBookingTypeIrregular } from "utils/userUtils";
import { Dashboard } from "components/layouts/Dashboard";
import { useValidation } from "utils/validation";
import { apiUtils } from "utils/apiUtils";
import { pushToGA, events } from "utils/gtmUtils";
import TemplatePermissionSelector, { convertToOption } from "components/forms/TemplatePermissionSelector";
import { useSavedTemplateSettings } from "hooks/useSaveTemplateSettings";
import { TemplateBlocked, WarningIcon } from "components/layouts/TemplateBlocked";
import { generateLinkURLToIframe } from "../utils/utils";
import { BOOKING_PAGE_URL_VAR } from "../constants/homeModuleConstants";
import messages from '../i18n/Home';

const ClearIcon = styled.span`
  font-size: 16px !important;
  position: absolute;
  right: -16px;
  top: -8px;
  color: #e95648;
  cursor: pointer;
`;

const TCol = styled(Col)`
  margin-top: -18px;
  position: relative;
  top: 8px;
`;

const IconLock = styled.div`
  position: absolute;
  top: 34px;
  left: -2px;
  background: #ffffff99;
  height: 24px;
  width: 24px;
  border-radius: 50%;
`;

function calendarSettingRequest(uid) {
  const { getCalendarSetting } = actions;
  getCalendarSetting.query = uid;
  return getCalendarSetting;
}

const HomeFormReader = formValueSelector("General");

function Home(props) {
  const { handleSubmit, pristine, submitting, useNotify, intl } = props;
  const { required, inputLength, bookingUrl, bookingUrlVar } = useValidation(intl);
  const { uid, settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const dispatch = useDispatch();
  useGet(calendarSettingRequest(uid));
  const [ linkURL, setLinkURL ] = useState('');
  const { setTemplateUid, saveWithTemplateSettings, saveTemplateSettings } = useSavedTemplateSettings();
  const { data, isSuccess, initial, hasError } = useSelector(
    (state) => state.calendarSetting
  );

  const icon = useSelector(state => HomeFormReader(state, "icon"));
  const permissions = useSelector(state => HomeFormReader(state, "permissions"));

  const notify = useNotify(intl);
  const isIrregular = isBookingTypeIrregular();

  useEffect(() => {
    if (isSuccess) {
      const { title, alias, description, icon, instructionMessage, durations, bookingCalendarTemplateUid, permissions } = data;
      const link = generateLinkURLToIframe(alias, uid, isIrregular, durations);
      setTemplateUid(bookingCalendarTemplateUid);
      setLinkURL(link);
      props.initialize({
        title,
        alias,
        description: (isIrregular && !description) ? 'ご自身の情報を入力し、スタッフ登録を完了してください。' : description,
        calendarURL: link,
        icon: icon?.url,
        instructionMessage: (isIrregular && !instructionMessage) ? 'こちらのURLよりご自身の情報を入力し、スタッフ登録を完了してください。\n{予約ページ}' : instructionMessage,
        permissions: convertToOption(permissions, intl),
      });
    }
    if (hasError) {
      dispatch({
        type: "calendarSetting_get",
        payload: { status: null, data: null, isSuccess: false, hasError: false, initial: true }
      });
      props.history.push('/')
    }
  }, [data]);

  if (initial === true || (isSuccess && data.uid !== uid)) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Loader />
      </div>
    );
  }
  
  if (!isSuccess) {
    return <Redirect to="/" />;
  }

  function processResult(result) {
    if (result.status < 200 || result.status >= 300) {
      notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.message);
    } else {
      notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
      dispatch({
        type: `${actions.getCalendarSetting.name}_update`,
        payload: { ...apiUtils.formatAxiosResponse(result) },
      });
      return result;
    }
  }

  const getBody = values => {
    const { title, alias, description, instructionMessage } = values;
    const body = {
      title,
      alias,
      description,
      instruction_message: instructionMessage,
    };
    if (icon != null && !icon.startsWith("http")) {
      body.icon = values.icon
    }
    return body;
  };

  const onSubmit = (values) => {
    if(data.templateBookingCalendarsCount > 0 && isTemplatePage) {
      if(window.confirm(intl.formatMessage({...messages.updateTemplateConfirm}))) {
        return handleOnSubmit(values);
      } else {
        return;
      }
    }
    handleOnSubmit(values);
  }

  const handleOnSubmit = async (values) => {
    const url = `/booking_calendar_settings/${uid}`;
    const body = getBody(values);

    let result = isTemplatePage ? await saveWithTemplateSettings(put(url,body), values.permissions) : await put(url, body);

    return processResult(result);
  };

  const clearIcon = async () => {
    if (icon.startsWith("https://") || icon.startsWith("http://")) {
      const url = `/booking_calendar_settings/${uid}/icon`;
      const result = await remove(url);
      if (result.status >= 200 && result.status < 300) {
        notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
        props.change("icon", null);
        document.getElementById("icon").value = null;
        setTimeout(async () => {
          await saveTemplateSettings(convertToOption(data.permissions, intl), { data: {} });
        }, 300);
      } else {
        notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.error?.message);
      }
    } else {
      props.change("icon", null);
      document.getElementById("icon").value = null;
    }
  };

  const hadleSendToGA = (duration) => (
    pushToGA({ ...events.mouseover_featureTooltip("instruction_message", duration) })
  );

  return (
    <Dashboard>
      {isTemplatePage ?
        (<>
          <Row mt={["26px","40px"]} mb="26px">
            <Col xs>
              <Row mb="12px">
                <Col xs>
                  <Label>
                    <FormattedMessage {...messages.templateTitle} />
                  </Label>
                </Col>
              </Row>
              <Row mb="26px">
                <Col xs display="flex">
                  <div style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <Field
                        name="title"
                        component={Input}
                        validate={[required]}
                        placeholder={props.intl.formatMessage({...messages.titlePlaceholder})}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <TemplateBlocked
                blocked={permissions?.icon?.value}
                header={
                  <Row mb="12px">
                    <Col xs>
                      <Label>
                        <FormattedMessage {...messages.icon} />
                      </Label>
                    </Col>
                    <TCol>
                      <TemplatePermissionSelector name="icon"/>
                    </TCol>
                  </Row>
                }
                fields={
                  <Row>
                    <Col xs>
                      <div style={{position: 'relative', width: '50px'}}>
                        <Field
                          id="icon"
                          name="icon"
                          component={ImageUploader}
                          allowedFormats="image/*"
                          zIndex={["0", "1"]}
                        />
                        {icon && <ClearIcon className="material-icons" onClick={clearIcon}>clear</ClearIcon>}
                      </div>
                    </Col>
                  </Row>
                }
                />
            </Col>
          </Row>
        </>)
        :
        (<>
          <Row mt={["26px","40px"]} mb="26px">
            <Col xs>
              <Row>
                <Col xs>
                  <Label>
                    <FormattedMessage {...messages[isIrregular ? 'titleForIrregular' : 'title']} />
                  </Label>
                </Col>
              </Row>
              <Row mt="10px">
                <Col xs display="flex">
                  <div style={{ position: "relative", height: "50px", width: "50px" }}>
                    <Field
                      id="icon"
                      name="icon"
                      component={ImageUploader}
                      allowedFormats="image/*"
                      zIndex={["0","1"]}
                      disabled={permissions?.icon?.value === "administrator"}
                    />
                    {permissions?.icon?.value !== "administrator" && icon && <ClearIcon className="material-icons" onClick={clearIcon}>clear</ClearIcon>}
                    {permissions?.icon?.value === "administrator" && (
                      <IconLock>
                        <WarningIcon indented extraIndentation="2px"/>
                      </IconLock>
                    )}
                  </div>
                  <div style={{ marginLeft: "20px", width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <Field
                        name="title"
                        component={Input}
                        validate={[required]}
                        placeholder={props.intl.formatMessage({...messages.titlePlaceholder})}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </>)
      }
      
      {/* Link From */}
      {!isTemplatePage && (
        <Row mb="26px">
          <Col xs>
            <Field
              id="alias"
              name="alias"
              component={InputWithLeftAddonAndLabel}
              validate={[required, bookingUrl]}
              label={<FormattedMessage {...messages[isIrregular ? 'linkForIrregular' : 'link']} />}
              placeholder={props.intl.formatMessage({...messages.linkPlaceholder})}
              addonText="booking.receptionist.jp/"
            />
          </Col>
        </Row>
      )}
      {/* Link form completed */}
      {/* Explanatory text */}

      <TemplateBlocked
        blocked={permissions?.description?.value}
        header={
          <Row mb="12px">
            <Col xs>
              <Label>
                <FormattedMessage {...messages.description} />
              </Label>
            </Col>
            {isTemplatePage && (
              <TCol>
                <TemplatePermissionSelector name="description"/>
              </TCol>
            )}
          </Row>
        }
        fields={
          <Row mb="26px">
            <Col xs>
              <Field
                id="description"
                name="description"
                component={TextArea}
                placeholder={props.intl.formatMessage({ ...messages.descriptionPlaceholder })}
                validate={[inputLength]}
              />
            </Col>
          </Row>
        }
        />
      {/* Explanatory text End */}
      {/* Instruction message */}

      <TemplateBlocked
        blocked={permissions?.instructionMessage?.value}
        header={
          <Row mb="12px">
            <Col xs>
              <Label>
                <FormattedMessage {...messages.instructionMessageTitle} />
                <Tooltip
                  message={<FormattedMessage
                    {...messages.instructionMessageTooltip}
                    values={{
                      br: <br/>,
                      var: BOOKING_PAGE_URL_VAR
                    }}
                  />}
                  width={310}
                  top={2}
                  left={400}
                  onHover={(duration) => hadleSendToGA(duration)}
                />
              </Label>
            </Col>
            {isTemplatePage && (
              <TCol>
                <TemplatePermissionSelector name="instructionMessage"/>
              </TCol>
            )}
          </Row>
        }
        fields={
          <Row mb="26px">
            <Col xs>
              <Field
                id="instructionMessage"
                name="instructionMessage"
                component={TextArea}
                placeholder={props.intl.formatMessage({ ...messages.instructionMessagePlaceholder })}
                validate={[bookingUrlVar]}
              />
            </Col>
          </Row>
        }
        />
      {/* Instruction message End */}

      {/* Calendar URL */}
      {!isTemplatePage && !isIrregular && (
        <Row>
          <Col xs>
            <Field
              id="calendarURL"
              name="calendarURL"
              disabled
              component={TextAreaWithLabel}
              labelLeft={<FormattedMessage {...messages.embeddedCode} />}
              labelRight={<FormattedMessage {...messages.copyCode} />}
              height="137px"
              placeholder="Embed code"
              rightLabelOnClick={() => {
                copy(linkURL) 
              }
              }
            />
          </Col>
        </Row>
      )}
      {/* Calendar URL End Here*/}

      <Hr mt={["14px","60px"]} color="#e5e5e5" height="1px" border="none" />

      <Row mt="20px">
        <Col xs>
          <Button
            width="100%"
            disabled={pristine || submitting}
            onClick={handleSubmit(values => onSubmit(values))}
            fontWeight="500"
          >
            <FormattedMessage {...messages.save} />
          </Button>
        </Col>
      </Row>
    </Dashboard>
  );
}

Home.propTypes = {};

export default reduxForm({
  form: "General",
})(injectIntl(Home));
