import SignUp from "./pages/adminUsers/SignUp";
import BusinessRegistration from "./pages/adminUsers/BusinessRegistration";
import MeetingToolSelection from "./pages/adminUsers/MeetingToolSelection";
import FirstBookingPage from "./pages/adminUsers/FirstBookingPage";
import MoreCustomizations from "./pages/adminUsers/MoreCustomizations";
import Registration from "./pages/normalUsers/Registration";
import MeetingToolIntegration from "./pages/normalUsers/MeetingToolIntegration";
import Reservation from "./pages/normalUsers/BookingPageDetails";

export const onboardingRoutes = [
  {
    component: SignUp,
    path: "/signup",
    exact: true,
    feature: "onboard",
    type: "public",
    gauid: 'Ob/sign_up',
  },
  {
    component: BusinessRegistration,
    path: "/onboarding/business_info",
    exact: true,
    feature: "onboard",
    type: "private",
    gauid: 'Ob/business_info',
  },
  {
    component: MeetingToolSelection,
    path: "/onboarding/integrations",
    exact: true,
    feature: "onboard",
    type: "private",
    gauid: 'Ob/integrations',
  },
  {
    component: FirstBookingPage,
    path: "/onboarding/booking_page",
    exact: true,
    feature: "onboard",
    type: "private",
    gauid: 'Ob/first_booking_page',
  },
  {
    component: MoreCustomizations,
    path: "/onboarding/more_customizations",
    exact: true,
    feature: "onboard",
    type: "private",
    gauid: 'Ob/more_customizations',
  },
  {
    component: Registration,
    path: "/onboarding/employee/signup",
    exact: true,
    feature: "onboard",
    type: "public",
    gauid: 'Ob/employee/register',
  },
  {
    component: MeetingToolIntegration,
    path: "/onboarding/employee/integrations",
    exact: true,
    feature: "onboard",
    type: "private",
    gauid: 'Ob/employee/integrations',
  },
  {
    component: Reservation,
    path: "/onboarding/employee/reservation",
    exact: true,
    feature: "onboard",
    type: "private",
    gauid: 'Ob/employee/reservation',
  },
];

