import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 71px;
  text-align: center;
  margin-bottom: 95px;
`;

const Text = styled.p`
  font-size: 15px;
  color: #314143;
  letter-spacing: 0.1px;
  line-height: 1.6;
`;

export default function CalendarNotIntegrated() {
  return (
    <Wrapper>
      <Text>
        日時を確定するページを表示できません。
        <br />
        <br />
        参加メンバーのカレンダー連携が完了していないため、外部連携ページから連携を行ってください。
        <br />
        外部連携ページは
        <a href="https://staging.app.receptionist.jp/info/integrations" target="_blank" rel="noopener noreferrer">
          こちら
        </a>
      </Text>
    </Wrapper>
  )
}
