import styled from "styled-components";

export const DragIcon = styled.div`
  cursor: move;
  margin-right: 10px;
  .line + .line {
    margin-top: 4px;
  }
`

export const Line = styled.span`
  background-color: #707070;
  height: 1px;
  display: block;
  width: 15px;
`;