import { useParams } from 'react-router';

import { remove } from '../../../utils/api';
import messages from '../i18n/appointmentsTable';

function useDeleteAppointment(notify) {
  const { uid } = useParams();
  
  const deleteAppointment = async (appointment, calendarUid) => {
    const url = `/booking_calendars/${calendarUid || uid}/appointments/${appointment.uid}`;
    const result = await remove(url);

    if (result.status < 200 || result.status >= 300) {
      notify.setError(messages.notifyError, result.data?.error?.message);
    } else {
      notify.setNotify(messages.notifySuccess, result.data?.message != null ? result.data.message : null );
      return appointment.uid;
    }
    return null;
  };

  return { deleteAppointment };
}

export { useDeleteAppointment };
