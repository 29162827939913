import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Required = styled.span`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #e84c3d;
`;

const RequiredWrapper = styled.span`
  width: 7px;
  height: 100%;
  margin: 5px 1px 4px 4px;
`;

function RequiredMark({ required }) {
  return (
    <RequiredWrapper>
      { required && <Required>*</Required> }
    </RequiredWrapper>
  );
}

RequiredMark.defaultProps = {
  required: true,
};

RequiredMark.propTypes = {
  required: PropTypes.bool,
};

export default RequiredMark;
