import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATUS = {
  isSuccess: false,
  hasError: false,
};

export const updateEmailCustomizations = createAction('calendar_email_customizations_update');

const calendarEmailCustomizationsReducer = createReducer(
  { ...INITIAL_STATUS },
  {
    calendar_email_customizations_update: (state, action) => {
      return { ...action.payload };
    }
  },
);

export default calendarEmailCustomizationsReducer;
