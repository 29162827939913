import styled from 'styled-components';
import { MOBILEBREAKPOINT } from '../../../constants/breakpoint';

const TableBodyItem = styled.td`
  position: ${props => (props.position ? props.position : '')};
  padding: 16px 2px;
  padding-left: ${props => (props.first ? '40px' : '')};
  padding-right: ${props => (props.last ? '40px' : '')};
  color: ${props => (props.color ? props.color : '#484f63')};
  font-size: 14px;
  width: ${props => (props.width ? props.width : null)};
  cursor: ${props => (props.cursor ? 'pointer' : null)};
  text-align: ${props => (props.align ? props.align : 'left')};
  img {
    position: relative;
    border-radius: ${props => (props.isAppIcon ? '0px' : '50%')};
  }
  > * {
    line-height: 16px;
  }
  @media (max-width: ${MOBILEBREAKPOINT}){
    padding-left: 24px;
  }
`;

export const TableBodyDivItem = styled.div`
  position: ${props => (props.position ? props.position : '')};
  padding: 16px 2px;
  padding-left: ${props => (props.first ? '40px' : '')};
  padding-right: ${props => (props.last ? '40px' : '')};
  color: ${props => (props.color ? props.color : '#484f63')};
  font-size: 14px;
  width: ${props => (props.width ? props.width : null)};
  cursor: ${props => (props.cursor ? 'pointer' : null)};
  text-align: ${props => (props.align ? props.align : 'left')};
  img {
    position: relative;
    border-radius: ${props => (props.isAppIcon ? '0px' : '50%')};
  }
  > * {
    line-height: 16px;
  }
`;

export const TableData = styled.td`
  height: ${props => props.height ? props.height : '72px'};
  vertical-align: middle;
  padding-right: ${props => (props.field ? '10px' : '')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '')};
`;

export default TableBodyItem;
