import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'common.required',
    defaultMessage: 'Required',
  },
  saveSuccess: {
    id: 'notify.settings.save.calendar.success',
    defaultMessage: 'Booking settings saved succesfully.',
  },
  notifyTemplateSuccess: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  saveError: {
    id: 'notify.settings.save.calendar.error',
    defaultMessage: "Booking settings couldn't be saved.",
  },
  notifyTemplateError: {
    id: 'notify.settings.save.template.success',
    defaultMessage: 'Template settings saved succesfully.',
  },
  customFields: {
    id: 'views.customField.customFields',
    defaultMessage: 'Custom fields',
  },
  nameField: {
    id: 'views.customField.nameField',
    defaultMessage: 'Name',
  },
  save: {
    id: 'common.save',
    defaultMessage: 'Save changes',
  },
  standard: {
    id: 'components.layouts.blocked.standard',
    defaultMessage: 'You can use this feature by <a>subscribing to a paid plan.</a>',
  },
  companyNameField: {
    id: 'views.customField.companyNameField',
    defaultMessage: 'Company name',
  },
  emailField: {
    id: 'views.customField.emailField',
    defaultMessage: 'Email',
  },
  addCustomField: {
    id: 'views.customField.addCustomField',
    defaultMessage: 'Add custom field',
  },
  textField: {
    id: 'views.customField.textField',
    defaultMessage: 'Text field',
  },
  textArea: {
    id: 'views.customField.textArea',
    defaultMessage: 'Text area',
  },
  radioButton: {
    id: 'views.customField.radioButton',
    defaultMessage: 'Radio button',
  },
  checkBox: {
    id: 'views.customField.checkBox',
    defaultMessage: 'Check box',
  },
  freeField: {
    id: 'views.customField.freeField',
    defaultMessage: 'Free input (max 255 letter)',
  },
  email: {
    id: 'common.email',
    defaultMessage: 'Email',
  },
  phoneNo: {
    id: 'common.phoneNo',
    defaultMessage: 'Phone number',
  },
  blockOfText: {
    id: 'views.customField.blockOfText',
    defaultMessage: 'Block of text',
  },
  dropdown: {
    id: 'views.customField.dropdown',
    defaultMessage: 'Dropdown',
  },
  hiddenField: {
    id: 'views.customField.hiddenField',
    defaultMessage: 'Hidden field',
  },
  privacyField: {
    id: 'views.customField.privacyField',
    defaultMessage: 'Privacy field',
  },
  multipleguestAllowed: {
    id: 'views.customField.multipleguestAllowed',
    defaultMessage: 'Allow for two or more additional people to be present.',
  },
  dateField: {
    id: 'views.customField.dateField',
    defaultMessage: 'Date',
  },
  privacyHeadLabel: {
    id: 'views.customField.privacyHeadLabel',
    defaultMessage: 'Privacy Policy Agreement'
  },
  updateTemplateConfirm: {
    id: 'notify.template.pages.detail.updateTemplateConfirm',
    defaultMessage: 'Any changes to the settings will be immediately reflected on any booking pages using this template. Are you sure you want to change it?',
  },
});
