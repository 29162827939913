import React from "react";
import { Field } from "redux-form";
import { TextAreaWithLabel, Col, Row } from "@d-lighted/design-system";
import messages from '../i18n/Modules';
import { useValidation } from "../../../utils/validation";

export function BlockOfText(props) {
  const { intl } = props;
  const { blockOfTextLength, required } = useValidation(intl);

  return (
    <>
      <Row mt="25px">
        <Col xs>
          <Field
            id="blockOfText"
            name="blockOfText"
            component={TextAreaWithLabel}
            validate={[required, blockOfTextLength]}
            labelLeft={intl.formatMessage({ ...messages.blockOfTextLabel })}
            placeholder={intl.formatMessage({ ...messages.blockOfTextPlaceholder })}
            height="216px"
          />
        </Col>
      </Row>
    </>
  );
}
