import { post } from "../utils/api";
import { getSubdomain } from "../utils/userUtils";

function useAuth() {
  const getOneTimeAccessUrl = async(redirectUri='/', redirectParams=null) => {
    const { data: { token } } = await post('/auth/one_time_token');
    const client = JSON.parse(localStorage.getItem('auth_headers')).client;
    const path = `${window.location.protocol}//${getSubdomain()}${process.env.REACT_APP_WEB_PATH}/sign_in`;
    if(redirectParams) {
      return(`${path}?one_time_token=${token}&client_id=${client}&return_to=${redirectUri}?${redirectParams}`);
    }
    return(`${path}?one_time_token=${token}&client_id=${client}&return_to=${redirectUri}`);
  };

  const getOneTimeAccessRoomsUrl = async(redirectUri='/', redirectParams=null) => {
    const { data: { token } } = await post('/auth/one_time_token');
    const client = JSON.parse(localStorage.getItem('auth_headers')).client;
    const path = `${window.location.protocol}//${getSubdomain()}${process.env.REACT_APP_ROOMS_WEB_PATH}/sign_in`;
    if(redirectParams) {
      return(`${path}?one_time_token=${token}&client_id=${client}&return_to=${redirectUri}?${redirectParams}`);
    }
    return(`${path}?one_time_token=${token}&client_id=${client}&return_to=${redirectUri}`);
  };

  return {
    authActions: {
      getOneTimeAccessUrl,
      getOneTimeAccessRoomsUrl
    },
  };
}

export { useAuth };
