import { isEqual } from "lodash";
import humps from "lodash-humps";
import moment from "moment";

export const apiUtils = {
  concatUrlQuery: (url, query) => `${url}${query ? query : ""}`,

  checkStatus: (status) => {
    return status < 300
      ? { isSuccess: true, hasError: false, initial: false }
      : { isSuccess: false, hasError: true, initial: false };
  },

  timeStamp: () => moment().toISOString(),

  isStateNew: (oldState, newState) =>
    isEqual(oldState, newState) ? false : true,

  formatAxiosResponse: (response) => {
    const { data, status, request } = response;
    return {
      data: humps(data),
      status,
      url: request.responseURL,
      ...apiUtils.checkStatus(status),
    };
  },
};
