import React from "react";
import styled from "styled-components";
import moment from "moment";

const Wrapper = styled.div`
  margin-bottom: 5px;
  width: 50%;
`;

const Input = styled.input`
  min-width: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: ${(props) =>
    props.isStartDate ? "10px 0 0 10px" : "0 10px 10px 0"};
  border: solid 1px #d2e9e7;
  border-right: ${(props) => props.isStartDate && "none"};
  font-size: 15px;
  color: #314143;
  outline: 0;
  padding: 10px 12px;
  -webkit-transition: border-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out;
  background-color: white;
  &:focus {
    border-color: #00bbb5;
    outline: none;
  }
`;

function DefaultCalendar(props) {
  const {
    id,
    input: { name, onChange, value },
    minDate,
    maxDate,
    meta: { touched, error },
    isStartDate,
  } = props;

  const changeDate = (value, onChange) => {
    if(value !== ""){
      onChange(value);
    }
  };
  const minLimit =
    minDate != null ? moment(minDate).add(1, "day").format("YYYY-MM-DD") : null;
  const maxLimit =
    maxDate != null
      ? moment(maxDate).subtract(1, "day").format("YYYY-MM-DD")
      : null;

  return (
    <Wrapper>
      <Input
        type="date"
        name={name}
        value={value}
        id={id}
        min={minLimit}
        max={maxLimit}
        onChange={(e) => changeDate(e.target.value, onChange)}
        required
        isStartDate={isStartDate}
      />
      {touched && error && <span>{error}</span>}
    </Wrapper>
  );
}

DefaultCalendar.defaultProps = {
  name: "",
  input: undefined,
  width: "100%",
  minDate: null,
  maxDate: null,
};

export default DefaultCalendar;
