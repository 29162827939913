import React from 'react';

function getProductPromotionImage(productType, link) {
  if (!productType || !link) return null;

  const image = `${window.location.origin}/promotion/image.jpg`;
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" >
      <img src={image} alt="" />
    </a>
  );
}

export { getProductPromotionImage };
