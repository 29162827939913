 import PropTypes from 'prop-types';
 import styled from 'styled-components';
 import {
   position,
   top,
   left,
   width,
   space,
   height,
   minHeight,
   border,
   borderLeft,
   borderBottom,
   borderColor,
   borderRadius,
 } from 'styled-system';
 
 
 const Flex = styled.div`
   display: flex;
   align-items: ${p => (p ? p.align : null)};
   justify-content: ${p => (p.justify ? p.justify : null)};
   flex-direction: ${p => p.direction ? p.direction : null};
   flex-wrap: ${p => (p.wrap ? p.wrap : null)};
   ${position}
   ${top}
   ${left}
   ${space}
   ${width}
   ${height}
   ${minHeight}
   ${border}
   ${borderLeft}
   ${borderBottom}
   ${borderColor}
   ${borderRadius}
 `;
 
 Flex.propTypes = {
   align: PropTypes.string,
   justify: PropTypes.string,
   direction: PropTypes.string,
   wrap: PropTypes.string,
   ...position.propTypes,
   ...width.propTypes,
   ...space.propTypes,
   ...height.propTypes,
   ...minHeight.propTypes,
   ...border.propTypes,
   ...borderBottom.propTypes,
   ...borderLeft.propTypes,
   ...borderColor.propTypes,
   ...borderRadius.propTypes,
 };
 
 export default Flex;
