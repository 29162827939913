import BusinessHours from "./pages/BusinessHours";
import AdvancedSettings from "./pages/AdvancedSettings";
import { templatesEnabledAdminValidation } from "utils/userUtils";

export const availabilityRoutes = [
  {
    component: BusinessHours,
    path: "/:settings(templates|booking_calendars)/:uid/edit/availability/business_hours",
    exact: true,
    feature: "営業日時",
    type: "private",
    gauid: 'Bp/Availability',
    validation: templatesEnabledAdminValidation,
  },
  {
    component: AdvancedSettings,
    path: "/:settings(templates|booking_calendars)/:uid/edit/availability/advanced_settings",
    exact: true,
    feature: "詳細設定",
    type: "private",
    validation: templatesEnabledAdminValidation,
  },
];
