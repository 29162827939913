import React from 'react';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 16px;
  font-weight: bold;
  height: 16px;
  font-size: 12px;
  padding: 1px;
  border-radius: 4px;
  border: solid 2px #68878d;
  color: #68878d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CheckboxIcon = () => (
  <CheckboxWrapper>
    <div>✔</div>
  </CheckboxWrapper>
)

export default CheckboxIcon;
