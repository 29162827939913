import React from "react";
import styled from "styled-components";
import {
  Checkbox,
  Label,
  Row,
} from "@d-lighted/design-system";
import RequiredMark from "./RequiredMark";

const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: ${(props) => props.width};
`;

const InputLabel = styled(Label)`
  margin-bottom: 10px;
`;

const InputDiv = styled.div`
  position: relative;
  width: 100%;
`;

export default function CheckBoxCase({ data }) {
  const checkboxLabels = data.bookingCalendarMultipleChoiceOptions;
  const required = data.bookingCalendarSettingBookingCalendarCustomField.inputRequired;
  return (
    <Row mt="15px" alignItems="center" mb="1rem">
      <InputWrapper width="100%">
      <InputLabel>
        <span>
          {data.labelName}
          {required && <RequiredMark />}
        </span>
      </InputLabel>
      <InputDiv>
      {checkboxLabels.map((label) => (
        <Checkbox key={label.choiceTypeName} useArrayValue={false}>{label.choiceTypeName}</Checkbox>
      ))}
      </InputDiv>
    </InputWrapper>
    </Row> 
  )
}
