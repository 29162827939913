import React, { useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import styled from "styled-components";
import { connect } from "react-redux";
import { default as qS } from "query-string";
import { 
  Row,
  Col,
  RadioButton,
  Button,
  Label,
  Loader
} from "@d-lighted/design-system";
import { setFirstCalendarSetting } from "../../utils/utils";
import { getCurrentUser, getBusinessType } from "../../../../utils/userUtils";
import { useOnboarding } from "../../hooks/useOnboarding";
import { Link } from "../../components/Link";
import { OnBoardingDashboard } from "../../../../components/layouts/OnBoardingDashboard";
import GoogleMeetIcon from "../../../../assets/img/g_meet_icon.svg";
import MSTeamsIcon from "../../../../assets/img/ms_teams_icon.svg";
import ZoomIcon from "../../../../assets/img/zoom.svg";
import WebexIcon from "../../../../assets/img/ico_webex.svg";
import { MOBILEBREAKPOINT, SHOW_MOBILE } from "../../../../constants/breakpoint";
import { pushToGA, events } from "../../../../utils/gtmUtils";
import { leaveOnboarding, toOnboardingPage } from "../../utils/utils";
import { getOnboardingState } from "../../utils/utils";
import { Center } from "../../components/Center";

const Icon = styled.img`
  width: auto;
  height: 18px;
  margin-right: 5px;
  position: relative;
  top: 2px;
`;

const DesktopOnlyBR = styled.br`
  @media (max-width: ${MOBILEBREAKPOINT}){
    display: none;
  }
`;

const MeetingToolSelection = props => {
  const isMobileView = SHOW_MOBILE(window.innerWidth);
  const { submitting, intl, useNotify, handleSubmit, initialize } = props;
  const notify = useNotify(intl);
  const { isSettingUpdated, onBoardingCalendarSetting, actions, isLoading, hasUpdateError } = useOnboarding(notify);
  const authorizedSchedulers = onBoardingCalendarSetting?.data?.members[0].authorizedSchedulers;
  const currentUser = getCurrentUser();
  const businessType = getBusinessType();
  const params = qS.parse(window.location.search);

  const resetUrl = () => {
    Object.keys(params).forEach(key => delete params[key]);
    props.history.replace({ search: new URLSearchParams(params).toString() });
  }

  const formattedBody = (scheduler) => {
    let body = {
      onboarding: {
        web_conference_type: scheduler
      }
    }
    if(["webex", "zoom"].includes(scheduler)) {
      body.onboarding["oauth_code"] = params?.code;
      body.onboarding["redirect_path"] = "/onboarding/integrations";
    }

    return body;
  };
  

  useEffect(() => {
    const onboarding = getOnboardingState();
    if(!onboarding?.allowed || onboarding?.page !== "integrations") return leaveOnboarding(props);
    if(!currentUser?.company) return toOnboardingPage(props, "register");
    if(businessType !== "sales") return toOnboardingPage(props, "booking_page");
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(onBoardingCalendarSetting?.isSuccess && onBoardingCalendarSetting?.data) {
      const { webConferenceType } = onBoardingCalendarSetting?.data;
      initialize({
        webConferenceType: webConferenceType
      });
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [onBoardingCalendarSetting]);

  useEffect(() => {
    const state = params?.state;
    if(state === "webex" || state === "zoom") {
      if(state?.error) {
        const capitalizedState = state.charAt(0).toUpperCase() + state.slice(1);
        notify.setError("ミーティングツールの連携エラーが発生しました。", `${capitalizedState}のトークンがありません。お手数ですが、再度連携後、もう一度お試しください。`);
      }
      if(params?.code) {
        const body = formattedBody(state);
        actions.updateOnboardingCalendar(body);
      }
      return resetUrl();
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [window?.location]);

  useEffect(() => {
    if(isSettingUpdated) {
      if(!hasUpdateError) {
        return toOnboardingPage(props, "booking_page");
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isSettingUpdated]);

  const onSubmit = async(values) => {
    setFirstCalendarSetting(onBoardingCalendarSetting);
    if(["webex", "zoom"].includes(values.webConferenceType)) {
      return actions.startOAuth(values.webConferenceType, "/onboarding/integrations");
    }

    const body = formattedBody(values.webConferenceType);
    actions.updateOnboardingCalendar(body);
  };

  const handleExitOnboarding = () => {
    pushToGA({...events.leave_onboarding("Ob/integrations")});
    leaveOnboarding(props);
  };

  if(isLoading) {
    return(
      <Center>
        <Loader/>
      </Center>
    )
  }

  return (
    <OnBoardingDashboard top="103px">
      <Row mt="37px">
        <Col xs>
          <Label
            fontSize="24px"
            fontWeight="700"
            letterSpacing="1px"
            color="#484F63"
          >
            <p>
              おめでとうございます！<DesktopOnlyBR />
              最初の予約ページが作成されました🎉 <DesktopOnlyBR />
              {!isMobileView && "ご利用のWeb会議ツールを選択してください"}<br />
            </p>
          </Label>
        </Col>
      </Row>
      <Row mt="13px">
        <Col xs>
          <Label fontSize="15px">
            {!isMobileView ? 
              <p>
                予約ページからアポが確定した際、<DesktopOnlyBR />
                選択されたWeb会議ツール用のWeb会議URLが自動で発行されます。
              </p> :
              <p>
                あなたの予定が空いている⽇程候補が予約ページに反映されました。
              </p>
            }
          </Label>
        </Col>
      </Row>
      <Row mt="24px">
        <Col xs>
          <Label fontSize="15px" color="#484F63" fontWeight="bold">
            {isMobileView ? "Web会議プラットフォーム" : "Web会議ツール"}
          </Label>
        </Col>
      </Row>
      <Row mt="13px">
        <Col xs>
          {authorizedSchedulers?.google && <Row>
            <Col>
              <Field
                id="webConferenceType"
                name="webConferenceType"
                trueFor="google"
                component={RadioButton}
              >
                <Icon  src={GoogleMeetIcon}/>
                Google Meet
              </Field>
            </Col>
          </Row>}
          {authorizedSchedulers?.outlook && <Row>
            <Col>
              <Field
                id="webConferenceType"
                name="webConferenceType"
                trueFor="microsoft"
                component={RadioButton}
              >
                <Icon  src={MSTeamsIcon}/>
                Teams
              </Field>
            </Col>
          </Row>}
          <Row>
            <Col>
              <Field
                id="webConferenceType"
                name="webConferenceType"
                trueFor="zoom"
                component={RadioButton}
              >
                <Icon  src={ZoomIcon}/>
                Zoom
              </Field>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                id="webConferenceType"
                name="webConferenceType"
                trueFor="webex"
                component={RadioButton}
              >
                <Icon src={WebexIcon} />
                Webex
              </Field>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row mt="60px" pl="7px">
        <Col xs>
          <Link 
            onClick={() => handleExitOnboarding()}
          >
            後で
          </Link>
        </Col>
        <Col xs>
          <Button
            fontWeight="500"
            width={isMobileView ? "204px" : "284px"}
            disabled={submitting}
            onClick={handleSubmit((values) => onSubmit(values))}
          >
            {isMobileView ? "アカウント連携" : "連携する"}
          </Button>
        </Col>
      </Row>
    </OnBoardingDashboard>
  );
}

const OnboardingMeetingToolSelection = connect((state) => {
  const selector = formValueSelector("MeetingToolSelection");
  return {
    webConferenceType: selector(state, "webConferenceType"),
  };
})(
  reduxForm({
    form: "MeetingToolSelection",
  })(MeetingToolSelection)
);
export default OnboardingMeetingToolSelection;
