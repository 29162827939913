import InputScreenSetting from "./pages/InputScreenSetting";
import ReservationFormSetting from "./pages/ReservationFormSetting";
import ReservationFinished from "./pages/ReservationFinished";
import { templatesEnabledAdminValidation } from "utils/userUtils";

export const reservationFormRoutes = [
  {
    component: InputScreenSetting,
    path: "/:settings(templates|booking_calendars)/:uid/edit/booking_form/input_screen_settings",
    exact: true,
    feature: "フィールド設定",
    type: "private",
    gauid: 'Bp/Form/Field',
    validation: templatesEnabledAdminValidation,
  },
  {
    component: ReservationFormSetting,
    path:
      "/:settings(templates|booking_calendars)/:uid/edit/booking_form/:itemId/booking_form_settings",
    exact: true,
    feature: "予約フォーム設定",
    type: "private",
    gauid: 'Bp/Form/Field/Edit',
    validation: templatesEnabledAdminValidation,
  },
  {
    component: ReservationFinished,
    path: "/:settings(templates|booking_calendars)/:uid/edit/booking_form/confirmation_message",
    exact: true,
    feature: "予約完了文",
    type: "private",
    gauid: 'Bp/Form/Confirmation',
    validation: templatesEnabledAdminValidation,
  },
];
