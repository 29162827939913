import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import humps from 'lodash-humps';
import { _get, put, remove } from "../../../utils/api";
import { updateEmailCustomizations } from '../reducers/calendarEmailCustomizationsReducer';
import { apiUtils } from '../../../utils/apiUtils';
import messages from '../i18n/CompleteEmailNotification';

function useCalendarEmailCustomizations(notify, uid) {
  const dispatch = useDispatch();
  const { settings } = useParams();
  const isTemplatePage = [settings].includes('templates');
  const emailCustomizations = useSelector(state => state.calendarEmailCustomizations);
  
  const fetchCalendarEmailCustomizations = () => {
    const url = `/booking_calendar_settings/${uid}/email_customizations`;
    _get(url)
      .then(result => {
        const { data, status } = result;
        const payload = {
          ...humps(data),
          status,
          url,
          ...apiUtils.checkStatus(status)
        };
        dispatch(updateEmailCustomizations(payload));
      });
  };

  const updateCalendarEmailCustomizations = function (values, middleware = promise => promise) {
    const url = `/booking_calendar_settings/${uid}/email_customizations`;
    return middleware(put(url, values))
      .then(result => {
        if (result.status < 200 || result.status >= 300) {
          notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.message);
          return;
        } 
        else {
          notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
          const { data, status } = result;
          const payload = {
            ...humps(data),
            status,
            url,
            ...apiUtils.checkStatus(status)
          };
          dispatch(updateEmailCustomizations(payload));
        }
        return result;
    });
  };

  const resetEmailLogo = (props, logo) => {
    if (logo.startsWith("https://") || logo.startsWith("http://")) {
      const url = `/booking_calendar_settings/${uid}/email_customizations`;
      remove(url)
        .then(result => {
          if (result.status < 200 || result.status >= 300) {
            notify.setError(isTemplatePage ? messages.notifyTemplateError : messages.notifyError, result?.data?.error?.message);
          } 
          else {
            notify.setNotify(isTemplatePage ? messages.notifyTemplateSuccess : messages.notifySuccess);
            const { data, status } = result;
            const payload = {
              ...humps(data),
              status,
              url,
              ...apiUtils.checkStatus(status)
            };
            return dispatch(updateEmailCustomizations(payload));
          }
      });
    }
    else {
      props.change("logo", null);
      document.getElementById("logo").value = null;
    }
  } 

  return {
    isSuccess: emailCustomizations?.isSuccess,
    bookingCompleteCustomization: emailCustomizations?.bookingCompleteEmailCustomization,
    reminderEmailCustomization: emailCustomizations?.reminderEmailCustomization,
    commonEmailCustomization: emailCustomizations?.commonEmailCustomization,
    actions: {
      fetchCalendarEmailCustomizations,
      updateCalendarEmailCustomizations,
      resetEmailLogo
    },
  };
}

export { useCalendarEmailCustomizations };
