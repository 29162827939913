import { useState } from 'react';
import { _get } from './api';

function useBuildings(uid) {
  const [buildings, setBuildings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getBuildings = () => {
    setIsLoading(true);
    _get(`/booking_calendar_settings/${uid}/integrations/buildings`).then(
      (response) => {
        const { data, status } = response;
        setIsLoading(false);
        if(status < 300) {
          setBuildings(Array.from(data || []));
        } else {
          setBuildings([]);
        }
      }
    );
  };

  return [buildings, isLoading, getBuildings];
}

function useResources(uid) {
  const [resources, setResources] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  const getResources = (id) => {
    setIsLoading(true);
    _get(`/booking_calendar_settings/${uid}/integrations/resources?building_id=${id != null ? encodeURIComponent(id) : ''}`).then(
      (response) => {
        const { data, status } = response;
        setIsLoading(false);
        if(status < 300) {
          setResources(Array.from(data || []));
        } else {
          setResources([]);
        }
      }
    );
  };
  
  return [resources, isLoading, getResources];
}

export { useBuildings, useResources };