import Moment from "moment-timezone";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

moment.tz.setDefault("Japan");

moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

function getThisWeek(date) {
  const range = date.range("week");

  const week = Array.from(range.by("day"));

  return { week, selectedDate: moment(date), range };
}

function getNextWeek(date) {
  date.add(1, "week");

  const range = date.range("week");

  const week = Array.from(range.by("day"));

  return { week, selectedDate: date };
}

function getPreviousWeek(date) {
  date.subtract(1, "week");

  const range = date.range("week");

  const week = Array.from(range.by("day"));

  return { week, selectedDate: date };
}

function getHoursOfTheDay(
  businessOpeningTime,
  businessClosingTime,
  duration,
) {
  const today = moment().format('YYYY-MM-DD');
  let time = moment(`${today}T${businessOpeningTime}+09:00`).tz('Asia/Tokyo');
  const data = [];
  let hour = time.format('HH');
  let minute = time.minutes();
  minute = minute < 10 ? `0${minute}` : minute;
  let fullTime = `${hour}:${minute}`;

  while (
    time.diff(
      moment(`${today}T${businessClosingTime}+09:00`).tz('Asia/Tokyo'),
    ) < 0
  ) {
    data.push(fullTime);
    time = time.add(duration, 'm');
    hour = time.format('HH');
    minute = time.minutes();
    minute = minute < 10 ? `0${minute}` : minute;
    fullTime = `${hour}:${minute}`;
  }
  return data.sort();
}

function isDateEarlier(date1, date2) {
  const difference = date1.startOf("day").diff(date2.startOf("day"));
  return difference < 0 ? "earlier" : difference > 0 ? "later" : "equal";
}

function isTimeEarlier(time1, time2) {
  const difference = time1.diff(time2);
  return difference < 0 ? "earlier" : difference > 0 ? "later" : "equal";
}

function getJapaneseDay(day) {
  switch (day) {
    case 0:
      return "日";
    case 1:
      return "月";
    case 2:
      return "火";
    case 3:
      return "水";
    case 4:
      return "木";
    case 5:
      return "金";
    case 6:
      return "土";
    default:
      return "";
  }
}

function calculateAvailableTime(timeSlots) {
  let m = timeSlots.reduce((obj, item) => {
    if (item && item.length) {
      let date = moment(item[0].startTime).format("MM-DD-yyyy");
      let slots = item.map(({ startTime, endTime }) => {
        if (isTimeEarlier(moment(startTime), moment()) === "later") {
          return moment(startTime).format("HH:mm");
        }
        return null;
      });
      obj[date] = [...slots];
      return obj;
    } else {
      return obj;
    }
  }, {});

  return m;
}

const getCalendarOptions = (options) => {
  return {
    today: moment(),
    currentDate: moment(),
    selectedDate: moment(),
    calculateAvailableTime: calculateAvailableTime,
    duration: options.duration,
    currentWeek: getThisWeek(options.currentDate),
    businessOpeningTime: options.businessOpeningTime,
    businessClosingTime: options.businessClosingTime,
    //fns
    getThisWeek: getThisWeek,
    getNextWeek: getNextWeek,
    getPreviousWeek: getPreviousWeek,
    getJapaneseDay: getJapaneseDay,
    isTimeEarlier: isTimeEarlier,
    isDateEarlier: isDateEarlier,
    getHoursOfTheDay: getHoursOfTheDay(
      options.businessOpeningTime,
      options.businessClosingTime,
      options.duration
    ),
    getDay: getDay
  };
};

function getDay(day) {
  switch (day) {
    case 0:
      return "sunday";
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 5:
      return "friday";
    case 6:
      return "saturday";
    default:
      return "";
  }
}

export {
  getThisWeek,
  getPreviousWeek,
  getNextWeek,
  getHoursOfTheDay,
  isDateEarlier,
  isTimeEarlier,
  getJapaneseDay,
  moment,
  getCalendarOptions,
  getDay
};
